@import 'Styling/import.scss';

.main {
  height: 100%;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  background-image: url('/Assets/Images/homepage/employer-homepage_428px.jpg');
  @include breakpoint('900px') {
    background-image: url('/Assets/Images/homepage/employer-homepage_900px.jpg');
  }
  @include breakpoint('1160px') {
    background-image: url('/Assets/Images/homepage/employer-homepage_1160px.jpg');
    position: relative;
  }
  @include breakpoint('1440px') {
    background-image: url('/Assets/Images/homepage/employer-homepage_1440px.jpg');
  }
  @include breakpoint('1920px') {
    background-image: url('/Assets/Images/homepage/employer-homepage_1920px.jpg');
  }
  @include breakpoint('2560px') {
    background-image: url('/Assets/Images/homepage/employer-homepage_2560px.jpg');
  }
  @include breakpoint('3000px') {
    background-image: url('/Assets/Images/homepage/employer-homepage_3000px.jpg');
  }
  @include breakpoint('4000px') {
    background-image: url('/Assets/Images/homepage/employer-homepage_4000px.jpg');
  }
  .blueBackground {
    backdrop-filter: blur(40.5px);
    background: rgba(0, 58, 96, 0.5);
    padding-top: 50px;
    @include breakpoint('1160px') {
      padding-top: 10px;
    }
  }
  .content {
    margin: 0 auto;
    width: 100%;

    .EmployerLandingTextPosition {
      display: flex;
      justify-content: flex-end;
      padding: 0 30px;

      @include breakpoint('1160px') {
        padding: 0;
      }
    }

    @include breakpoint('1160px') {
      max-width: 2020px;
      padding: 0;
    }
    @include breakpoint('1920px') {
      max-width: 1350px;
    }
    @include breakpoint('2560px') {
      max-width: 2022px;
    }
    @include breakpoint('3000px') {
      max-width: 2096px;
    }
    @include breakpoint('4000px') {
      max-width: 1906px;
    }
    .sectionWrapper {
      padding: 0 30px;

      @include breakpoint('1160px') {
        padding: 0;
      }
    }
    .gausianBlurMobileBackground {
      background-color: $blue2-opacity-6;
      backdrop-filter: blur(23.5px);
      margin-top: 75px;
      padding-top: 50px;
    }
  }
}
