@import 'Styling/import.scss';

.consentItem {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $link-water;
  padding-top: 19px;

  @include breakpoint(desktop) {
    flex-direction: row;
    padding: 0;
  }

  .label {
    font-size: 12px;
    font-weight: bold;
    color: $gray12;
    margin-right: 30px;
    width: 55px;
    line-height: 16px;

    @include breakpoint(desktop) {
      display: none;
    }
  }

  .titleWrapper {
    display: flex;

    .title {
      font-size: 14px;
      color: $blue1;
      margin-bottom: 0;
      font-weight: 500;
    }
  }

  .switchConsentTitleWrapper {
    align-items: center;

    @include breakpoint(desktop) {
      padding: 10px 0;
    }

    .title {
      font-weight: normal;
    }

    .label {
      @include breakpoint('toDesktop') {
        transform: translateY(22px);
      }
    }
  }

  .translatedTitle {
    @include breakpoint(desktop) {
      transform: translateX(30px);
    }
  }

  .statusWrapper {
    display: flex;
    align-items: center;
  }

  .titleWrapper,
  .statusWrapper {
    margin-bottom: 20px;

    @include breakpoint(desktop) {
      margin-bottom: 0;
    }
  }

  .dateWrapper,
  .contentWrapper,
  .actionWrapper {
    display: flex;
    align-items: center;
  }

  .featherIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 39px;
    height: 39px;
    margin: 12px 0;
    background-color: $light-blue;
    border: solid 1px $gray18;
    border-radius: 50%;
    padding-right: 3px;
    cursor: pointer;

    @include breakpoint(desktop) {
      margin: 0;
    }

    .borderedFeatherIcon {
      width: 20px;
      height: auto;
      color: $black;
      opacity: 0.3;
    }
  }

  .buttonWrapper {
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;
    margin-top: 0;

    @include breakpoint(desktop) {
      align-items: center;
      margin: 0;
    }

    button {
      line-height: normal;
    }
  }

  .iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    background-color: $gray18;
    border-radius: 50%;
    margin-right: 8px;
  }

  .checkIconWrapper {
    background-color: $green4;

    .checkIcon {
      height: 16px;
      width: 11px;
      color: $white;
    }
  }

  .closeIcon {
    width: 16px;
    height: 16px;
    color: $white;
  }

  .titleTextWrapper {
    .validTillText {
      display: none;

      @include breakpoint(desktop) {
        display: block;
        font-size: 12px;
        font-weight: bold;
        color: $gray12;
        margin-top: 10px;
      }
    }
  }

  .validTillWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    @include breakpoint(desktop) {
      display: none;
    }

    .validTillLabel {
      font-size: 12px;
      font-weight: bold;
      color: $gray12;
      margin-right: 30px;
    }

    .validTillText {
      color: $gray12;
      font-size: 12px;
      font-weight: 12px;
    }
  }

  .dateWrapper {
    font-size: 14px;
  }

  .contact {
    font-size: 12px;
    color: $gray12;
    margin-top: 7px;
  }

  .dateWithMargin {
    margin-bottom: 5px;

    @include breakpoint(desktop) {
      margin-bottom: 0;
    }
  }

  .switchTitleContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include breakpoint(desktop) {
      flex-direction: row;
      padding-left: 20px;
      align-items: center;
    }

    div {
      &:first-of-type {
        margin-right: 22px;
      }
    }

    .titleContainer {
      display: flex;
      flex-direction: column;

      h2 {
        margin-bottom: 5px;
      }

      p {
        font-size: 12px;
        color: $gray12;
        @include ellipsisSimple();
        max-width: 170px;
        transition: width 0.3s ease-in-out;
        cursor: pointer;

        &:hover {
          max-width: 100%;
        }
      }
    }
  }
}

.modalContainer {
  .blockquoteContainer {
    display: flex;
    flex-direction: column;
    color: $grey2;
    font-family: 'DMSerifDisplay', serif;
    position: relative;

    @include breakpoint(desktop) {
      flex-direction: row;
    }

    blockquote,
    .modalTextTests {
      color: $grey2;
      font-family: 'DMSerifDisplay', serif;
      font-size: 18px;
      hyphens: auto;
      line-height: 30px;

      a {
        color: $grey2;
        text-decoration: underline;
        &:hover,
        &:focus,
        &:active {
          text-decoration: none;
        }
      }
    }

    .quote {
      font-size: 60px;

      @include breakpoint(desktop) {
        position: absolute;
      }

      &:first-of-type {
        margin-right: 21px;
        align-self: start;
        line-height: 40px;
        top: 0;
        left: -40px;
      }

      &:last-of-type {
        line-height: 40px;
        margin-left: 21px;
        align-self: end;
        bottom: 0;
        right: -35px;

        @include breakpoint('desktop') {
          line-height: 1px;
        }
      }
    }
  }

  .consentTitle {
    font-family: 'DMSerifDisplay', serif;
    font-size: 24px;
    margin-bottom: 36px;
    font-weight: 400;
  }

  .hrWrapper {
    margin: 39px 0;
  }

  .modalContent {
    color: $grey2;
  }

  .modalText {
    color: $grey2;
    line-height: 24px;
    font-size: 14px;
    text-align: justify;
  }
}

.modalMarketingText {
  font-size: 14px;
  color: $grey2;
  line-height: 1.71;
  .link {
    color: $grey2;
    text-decoration: underline;
  }
}

.modalSecondText {
  margin-top: 20px;
}
