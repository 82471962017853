@import '/src/Styling/import';

.wrap {
  padding: 0 30px;

  @include breakpoint('toDesktop') {
    padding: 0 21px;
  }

  .conversation {
    display: flex;
    align-items: center;
    flex-direction: row;
    border-bottom: 1px solid $gray23;
    cursor: pointer;
    user-select: none;
    padding: 20px 0;
    position: relative;

    .avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      background-color: $gray335;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      margin-right: 20px;
      flex-shrink: 0;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .avatarLetters {
      background-color: $yellow3;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 50px;
      min-height: 50px;
      font-size: 20px;
      color: $black;
      padding-top: 2px;
      max-height: 50px;
    }

    .checkbox {
      color: $yellow3;
      cursor: pointer;
    }

    .checked {
      path:first-child {
        fill: $gray335;
        opacity: 1;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      font-family: $font-family;
      color: $gray-cyan;
      text-align: left;

      .senderWrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;

        .senderName {
          color: $blue2;
          font-size: 14px;
          font-weight: bold;
        }

        .createdAt {
          color: $black;
          font-size: 12px;
          position: absolute;
          right: 0;
        }

        .unarchiveIconContainer {
          position: absolute;
          right: 0;
          color: $gray12;
        }
      }

      .messageWrap {
        color: $gray335;
        font-size: 14px;

        .lastSender {
          margin-right: 3px;
          display: inline-block;
        }
        .ellipsisStyle {
          @include ellipsis(1);
        }
      }

      .messageBold {
        color: $black;
        font-weight: 700;
      }
    }
  }
}
.opened {
  background-color: $green3-opacity-1;
}
