@import 'Styling/import.scss';

.container {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 100px;
  align-items: center;
  justify-content: center;
  position: relative;
  .back {
    margin-top: 3px;
  }
  .next {
    width: 100%;
    max-width: 266px;
  }

  @include breakpoint('desktop') {
    .back {
      margin-top: 0;
      position: absolute;
      left: -52px;
      top: 0;
      width: 70px;
      height: 65px;
      display: flex;
      align-items: center;
    }
    .next {
      max-width: 386px;
    }
  }
}
