@import '/src/Styling/import.scss';

.background {
  background-color: rgba(211, 185, 185, 0);
  width: 100vw;
  height: 100vh;
  z-index: 1;
  top: 0;
  left: 0;
  position: fixed;
  display: block;
}
.hidden {
  display: none;
}
.dropdownContainer {
  position: relative;
  cursor: pointer;
  text-align: left;

  .dropdownBTN {
    background-color: $white2;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 20px;
    border-radius: 8px;
    position: relative;
    z-index: 0;
    text-align: left;
    border: 1px solid $input-border;
    &:hover,
    &:focus {
      border: 1px solid $input-focus-border;
    }
    &:active {
      border: 1px solid $input-active-border;
    }

    &.errorBorder {
      border: $error-border solid 1px;
      border-radius: 8px;
      position: relative;
    }

    .dropdownValues {
      font-size: 16px;
      color: $grey2;
      pointer-events: none;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .dropdownLabel {
      font-size: 14px;
      color: $grey1;
      padding-bottom: 12px;
    }

    .arrow {
      transform: rotate(0deg);
      transition: 0.2s all ease !important;
    }

    .rotate {
      transform: rotate(180deg);
      transition: 0.2s all ease !important;
    }

    .selected {
      display: flex;
      max-width: calc(100% - 24px);
    }
  }

  .dropdownList {
    border: $gray9 2px solid;
    border-radius: 12px;
    background-color: $white;
    box-shadow: 0 0 15px 2px $gray9;
    position: absolute;
    display: none;
    width: 100%;
    z-index: 30 !important;
    overflow-y: hidden;

    &.selectAll .dropdownListScrollWrap {
      padding: 0 0 14px;
    }

    &.listUp {
      top: -250px;
    }

    &.open {
      display: block;
    }

    .selected {
      display: flex;
      max-width: 100%;
    }

    .dropdownListScrollWrap {
      overflow-y: auto;
      display: block;
      max-height: 250px;
      padding: 14px 0;
    }

    .itemLabel {
      color: $grey2;
      font-size: 14px;
      position: relative;
      display: inline-flex;
      align-items: center;
      width: 100%;
      height: 38px;
      cursor: pointer;
      padding: 0 18px;

      &:hover {
        color: black;
        background-color: $white2;
      }

      .translation {
        margin: 0 18px;
      }
    }

    .selectAllWrap {
      display: flex;
      align-items: center;
      border-bottom: 1px solid $gray5;
      height: 47px;
      .itemLabel {
        width: auto;
        padding-right: 0;
        &:hover {
          background-color: $white;
        }
        .translation {
          margin-right: 0;
        }
      }
    }
  }

  &.white {
    @include breakpoint('desktop') {
      margin: $input-modal-top-margin 0 $input-modal-bottom-margin;
      overflow-y: visible;
    }

    .dropdownBTN {
      background-color: $white;
    }
  }

  &.blue,
  &.blue-left {
    &.open {
      .dropdownBTN {
        background-color: $white;
        color: $grey2;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      .dropdownList {
        width: calc(200% + 22px);
        border-top: none;
        border-top-left-radius: 0;
        box-shadow: none;
      }
      .dropdownValues {
        color: $grey2;
      }
    }
    .dropdownValues {
      color: $white;
    }
    .dropdownBTN {
      background-color: rgb(245, 247, 248, 0.2);
      color: $white;
      padding-right: 8px;
      height: 48px;
    }
    .itemLabel {
      min-height: 38px;
      height: auto;
      padding: 5px 18px;
    }
    .selected {
      max-width: calc(100% - 24px);
    }
  }

  &.blue-left {
    &.open .dropdownList {
      left: calc(-100% - 22px);
      border-top-right-radius: 0;
      border-top-left-radius: 12px;
    }
  }
  &.white-right {
    .dropdownBTN {
      padding-right: 13px;
      .selected {
        flex-direction: column;
        .dropdownValues {
          color: $gray-opacity-5;
        }
      }
    }

    &.open {
      .dropdownBTN {
        background-color: $white;
        color: $grey2;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: none;
      }
      .dropdownList {
        width: 365px;
        border-top: none;
        border-top-left-radius: 0;
        box-shadow: none;
        .selectAllWrap {
          border-bottom: 1px solid rgba(156, 162, 170, 0.3);
        }

        .translation {
          margin: 0 0 0 18px;
        }
      }
    }
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
