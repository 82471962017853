@import 'Styling/import.scss';

.iconsContainer {
  display: flex;
  margin-right: 0;
  margin-bottom: 29px;

  @include breakpoint(desktop) {
    margin-right: 49px;
    margin-bottom: 0;
  }

  .iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $yellow;
    width: 40px;
    height: 40px;

    &:first-of-type {
      margin-right: 10px;
    }

    .icon {
      color: $white;
    }
  }
}

.textMicrophoneNotifications {
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
}
