@import '/src/Styling/import';

.wrap {
  position: relative;

  .passwordInput {
    width: 100%;
  }

  .toogleShowBtn {
    position: absolute;
    right: 18px;
    top: 14px;
  }

  .showIcon {
    fill: $black-opacity-3;
  }
}
