@import 'Styling/import.scss';

.mailCandidateSearchWrap {
  padding: 10px 20px 49px 20px;
  min-height: 100vh;
  @include breakpoint('desktop') {
    padding: 20px 50px 50px 50px;
  }
}
.searchLoader {
  padding-top: 130px;
}
.sideContentWrap {
  margin-top: 85px;
  padding-left: 25px;
  padding-right: 25px;
}
