@import '/src/Styling/import.scss';

.checkbox {
  color: $black;
}

.checked {
  path:first-child {
    fill: $yellow1;
    opacity: 1;
  }
}
