@import 'Styling/import.scss';

.labelsGroupContainer {
  display: none;

  @include breakpoint(desktop) {
    display: grid;
  }

  .labelItemFixedWidth {
    font-size: 12px;
    font-weight: bold;
    color: $gray12;
  }
}
