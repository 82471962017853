@import 'Styling/import.scss';

.fileUpload {
  border: $gray5 solid 2px;
  background-color: $white;
  border-radius: 5px;
  border-style: dotted;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;

  &.dropzone {
    border: 2px dashed #222;
    opacity: 0.5;
  }
  > span {
    padding: 25px 0 30px 0;
    display: block;

    @include breakpoint(desktop) {
      padding: 8px 0 17px 0;
    }
  }
  &.whiteTheme {
    background-color: #f5f7f8;
  }
  &.profileImageTheme {
    border: none;
    width: 100%;
    background-color: transparent;

    > span {
      padding: 0;
      width: 100%;
    }
  }
}
