@import 'Styling/import.scss';

.title {
  font-family: 'DMSerifDisplay', serif;
  color: $black3;
  text-transform: uppercase;
  margin-right: 10px;
  font-size: 24px;
  line-height: 40px;
}

.regular, .title.modalRegular {
  font-weight: 400;
}

.title.modalRegular {
  color: $black;
}

.onboarding {
  font-size: 18px;
  line-height: 26px;
  text-align: left;
  margin: 32px 0;

  @include breakpoint('desktop') {
    font-size: 24px;
    line-height: 40px;
  }
}

.title.modal, .title.modalRegular {
  margin: 19px 0 19px;
  @include breakpoint('desktop') {
    margin: $title-modal-top-margin 0 $title-modal-bottom-margin;
  }

  &:first-child {
    margin: 0 0 19px;
    @include breakpoint('desktop') {
      margin: $title-modal-top-margin 0 $title-modal-bottom-margin;
    }
  }
}
