@import 'Styling/import.scss';

.rateCandidate {
  display: flex;
  justify-content: left;
  align-items: center;
  position: relative;

  .starsContainer {
    display: flex;
    align-items: baseline;
  }

  p {
    position: absolute;
    left: 0;
    font-size: 14px;
    color: $gray30;
    height: 100%;
    display: flex;
    align-items: center;
  }

  span {
    cursor: pointer;
  }

  .yellowStarIcon {
    color: $yellow;
  }

  .grayStarIcon {
    color: $white-opacity-2;
  }
}

.widget {
  display: flex;
  justify-content: center;
  align-items: center;
}

@include breakpoint('desktop') {
  .rateCandidate {
    justify-content: center;
    margin: 0;
  }
}
.starBorderOutlined {
  color: $gray15;
}
