@import 'Styling/import.scss';

.header {
  display: flex;
  padding-top: 25px;
  justify-content: space-between;
  line-height: 50px;

  .logoContainer {
    padding-left: 30px;
    padding-top: 5px;
    display: flex;
    align-items: center;
    font-size: 30px;
    color: $white;
    font-family: 'DMSerifDisplay', serif;
    font-weight: 400;

    @include breakpoint('550px') {
      font-size: 48px;
    }

    .logoStyle {
      width: 276px;
      height: auto;

      svg {
        margin-left: 25px;
      }
    }
  }

  .hamburgerIconContainer {
    display: none;
  }

  .topLink {
    color: $white;
  }

  .linkContainer {
    display: flex;
  }

  .optionContainer {
    display: none;

    .optionStyle {
      font-family: 'DMSerifDisplay', serif;
      letter-spacing: 0.8px;
      font-weight: 400;
    }

    .linkStyle {
      color: $white;
      text-transform: uppercase;
    }
  }

  @include breakpoint('550px') {
    .logoContainer {
      padding-top: 0;
      .logoStyle {
        width: 367px;
      }
    }
  }

  @include breakpoint('1160px') {
    .logoContainer {
      padding-left: 43px;
    }

    .optionContainer {
      display: flex;
      flex-wrap: wrap;
      color: $white;
      padding-right: 51px;
      gap: 40px;

      .optionStyle {
        font-size: 18px;
        margin-left: 28px;
      }
    }
  }

  @include breakpoint('1217px') {
    .optionContainer {
      .optionStyle {
        margin-left: 40px;
      }
    }
  }

  @include breakpoint('1440px') {
    .logoContainer {
      padding-left: 96px;
    }
  }

  @include breakpoint('1920px') {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    .optionContainer {
      padding-right: 140px;
    }
  }

  .mobileMenuClose {
    display: none;
  }

  @include breakpoint('toDesktop') {
    .logoContainer {
      justify-content: space-between;
      width: 100%;
    }
    .optionContainer.mobileOpened {
      display: flex;
      margin: 30px;
      margin-top: 0;

      .linkContainer {
        display: flex;
        flex-direction: column;

        .optionStyle {
          font-size: 32px;
          font-weight: normal;
        }
      }
    }

    &.mobileOpened {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 5000;
      background: rgba(0, 0, 0, 0.6);
      backdrop-filter: blur(36px);
      -webkit-backdrop-filter: blur(36px);

      flex-direction: column;
      justify-content: unset;
    }

    .mobileMenuClose {
      justify-content: flex-end;
      font-size: 30px;
      margin: 30px;
      padding-top: 30px;
      margin-bottom: 0;
      border-top: 1px solid $white;

      svg {
        fill: $white;
        font-size: 35px;
      }

      &.mobileOpened {
        display: flex;
      }
    }

    .hamburgerIconContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 30px;

      .hamburgerIcon {
        font-size: 35px;
      }
    }
  }
}

.headerPlaceholder {
  display: none;
  height: 75px;

  &.mobileOpened {
    display: block;
  }
}
