@import 'Styling/import.scss';

.testMobileLabel {
  font-size: 12px;
  font-weight: bold;
  color: $gray12;
  line-height: 16px;
  margin-right: 28px;

  @include breakpoint(desktop) {
    display: none;
  }
}

.testName {
  display: flex;
  flex-wrap: wrap;
  line-height: 15px;
}
