@import 'Styling/import.scss';

.container {
  width: 100%;
  text-align: left;
  position: relative;

  .infoPopoverWrap {
    position: absolute;
    top: 25px;
    right: -20px;
    background-color: $white;
    padding: 20px 30px;
    border-radius: 30px;
    z-index: 5;
    box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
    text-align: left;
    color: $grey2;
    font-size: 14px;
    line-height: 22px;
    width: 313px;

    .title {
      font-weight: bold;
    }
    .projectInfoWrap {
      display: flex;
      justify-content: space-between;
    }
    .dateAdded {
      color: $gray-opacity-5;
    }
  }
  @include breakpoint('desktop') {
    text-align: right;
  }
}
