@import 'Styling/import.scss';

.button {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 160px;
  min-height: 42px;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 0;

  .paint, .paintBlue {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .paint {
    opacity: 1;
    transition: .2s ease-out opacity;
  }

  .paintBlue {
    opacity: 0;
    transition: .3s ease-in opacity;
  }

  .text {
    z-index: 3;
    position: relative;
    color: $blue1;
    font-weight: bold;
    font-size: 16px;
    transition: .3s linear color;

    @include breakpoint('900px') {
      font-size: 20px;
    }
  }

  &:hover, &:focus, &:active {
    .paint {
      opacity: 0;
      transition: .3s ease-in opacity;
    }
    .paintBlue {
      opacity: 1;
      transition: .2s ease-out opacity;
    }
    .text {
      color: $white;
    }
  }
}

@include breakpoint('900px') {
  .button {
    min-width: 250px;
    min-height: 60px;
  }
}

.disabled {
  pointer-events: none;
  .text {
    color: $white;
  }
}
