@import 'Styling/import.scss';

.welcomeNoteContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $gray97;
  .coverImageContainer {
    width: 100%;
    height: 100px;
    object-fit: cover;
    position: relative;
    @include breakpoint(desktop) {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      height: 140px;
    }
  }
  .contentContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    top: -15px;
    @include breakpoint(desktop) {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .contentTop {
      display: flex;
      flex-direction: column;
      @include breakpoint(desktop) {
        flex-direction: row;
      }
    }

    .contentBottom {
      padding-left: 20px;
      padding-right: 20px;
    }

    .logoContainer {
      position: absolute;
      top: -60px;
      left: 20px;
      width: 100px;
      height: 100px;
      background-color: $white;

      @include breakpoint(desktop) {
        top: -90px;
        left: 30px;
        width: 170px;
        height: 170px;
      }

      .logoStyle {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .headerWelcomeMessageContainer {
      position: relative;
      left: 0;
      top: 50px;
      height: 130px;
      padding-left: 20px;
      padding-right: 20px;

      @include breakpoint(desktop) {
        left: 200px;
        top: 8px;
        height: 73px;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 30px;
        width: calc(100% - 200px);
      }

      .welcomeMessage {
        font-family: 'DMSerifDisplay', sans-serif;
        display: flex;
        flex-direction: row;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: 1.2px;
        text-align: left;
        padding-top: 12px;
        @media all and (max-width: 407px) {
          font-size: 19px;
        }

        .avatar {
          font-family: Roboto;
          width: 50px;
          height: 50px;
          border: solid $orange1;

          > span {
            line-height: 46px;
            font-size: 20px;
          }
        }

        .welcomeMessageText {
          padding-top: 14px;
          padding-left: 20px;
        }
      }
    }

    .companyName {
      font-family: 'DMSerifDisplay', sans-serif;
      font-size: 36px;
      line-height: 36px;
      letter-spacing: 1.8px;
      text-align: left;
      padding-top: 14px;
      padding-bottom: 15px;
      margin-left: 20px;
      margin-right: 20px;

      @include breakpoint(desktop) {
        border-bottom: none;
        padding-top: 30px;
        padding-bottom: 10px;
      }
    }

    .contactInfoContainer {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      line-height: 40px;
      text-align: left;
      padding-top: 10px;
      padding-left: 20px;
      @include breakpoint(desktop) {
        flex-direction: row;
      }

      .iconAndTextContainer {
        display: flex;
        flex-direction: row;

        .iconStyle {
          font-size: 21px;
          color: $black;
          opacity: 0.3;
          margin-top: 9px;
        }
      }

      .iconAndTextPadding {
        @include breakpoint(desktop) {
          padding-left: 50px;
        }
      }

      .infoText {
        padding-left: 18px;
      }
    }
  }
}
