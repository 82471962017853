@import 'Styling/import.scss';
.container {
  position: relative;
  background: $white;
  padding-top: 10px;
  padding-bottom: 20px;
  @include breakpoint('desktop') {
    border-radius: 5px;
    padding-top: 0;
  }

  .addPhotoBtn {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: $white;
  }
  .webLink {
    color: $blue2;
  }
  // COVER
  .coverWrap {
    height: 57px;
    position: relative;
    @include breakpoint('desktop') {
      height: 166px;
      .coverImageBackground {
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
      }
    }

    .coverImageBackground {
      background-color: $white;
      background-size: cover;
      width: 100%;
      height: 100%;
      position: relative;
    }

    .addCoverPhotoBtn {
      position: absolute;
      right: 12px;
      bottom: 10px;
      padding: 4px;
      @include breakpoint('desktop') {
        right: 19px;
        bottom: 20px;
      }
    }
  }

  // CONTENT
  .mainContent {
    .avatarAndEditWrap {
      border: 1px solid transparent;
      position: relative;

      .avatarWrap {
        background-color: $white;
        width: 100px;
        height: 100px;
        margin-top: -37px;
        margin-left: 20px;
        position: relative;
        @include breakpoint('desktop') {
          width: 170px;
          height: 170px;
          margin-left: 30px;
          margin-top: -116px; // 170 - 54
        }
        img {
          width: 100%;
          height: 100px;
          object-fit: cover;
          @include breakpoint('desktop') {
            height: 170px;
          }
        }

        .addLogotypePhotoBtn {
          position: absolute;
          right: 8px;
          bottom: 6px;
          padding: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .editBtn {
        position: absolute;
        right: 15px;
        top: 20px;
        svg {
          font-size: 24px;
        }
      }
    }

    .textualContentsWrap {
      position: relative;
      .title {
        margin-bottom: 30px;
        font-size: 36px;
        line-height: 1.11;
        letter-spacing: 1.8px;
        text-align: left;
        font-family: 'DMSerifDisplay', serif;
        margin-left: 20px;
        margin-top: 16px;
        @include breakpoint('desktop') {
          font-size: 36px;
          font-family: 'DMSerifDisplay', serif;
          letter-spacing: 1.8px;
          margin-left: 30px;
          padding-top: 20px;
        }
      }
      .description {
        @include breakpoint('desktop') {
          display: flex;
        }
        .leftContent {
          @include breakpoint('desktop') {
            background-color: $white;
            width: 57%;
            padding-bottom: 10px;
            border-bottom-left-radius: 5px;
          }
          .paragraph {
            line-height: 1.57;
            font-size: 14px;
            margin: 21px 24px 27px 20px;
            border-bottom: 1px solid $grey1;
            padding-bottom: 27px;
            overflow-wrap: break-word;
            white-space: pre-wrap;

            @include breakpoint('desktop') {
              border-right: 1px solid $grey1;
              padding-right: 57px;
              margin-left: 30px;
              font-size: 14px;
              margin-top: 31px;
              line-height: 1.57;
              border-bottom: none;
              padding-bottom: 10px;
            }
          }
        }
        .rightContent {
          border: 1px solid transparent;
          @include breakpoint('desktop') {
            background-color: $white;
            width: 43%;
            padding-bottom: 10px;
            border-bottom-left-radius: 5px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }

          .rightContentItemWrap {
            display: flex;
            padding-left: 20px;
            line-height: 30px;
            margin-bottom: 14px;
            align-items: center;
            overflow-wrap: break-word;

            .rightContentItemText {
              margin-left: 18px;
              font-size: 14px;
            }

            .languageIcon {
              margin-right: 18px;
            }

            .websiteLinks {
              font-size: 14px;
              display: flex;
              flex-wrap: wrap;
              max-width: 250px;

              .websiteParagraph {
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }

  .break {
    margin-top: 30px;
  }

  .hr.hrStronger {
    margin: 40px 0;
  }
}

// EDIT MODAL
.editModal {
  @include breakpoint('desktop') {
    width: 800px;
    display: flex;
    flex-direction: column;
  }

  .mainTitle.mainTitleStronger {
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
  }

  .textField {
    > textarea {
      border-radius: 10px;
      font-size: 16px;
      color: $grey2;

      @include breakpoint('toDesktop') {
        margin-bottom: 10px;
      }
    }
  }

  .companyNameField {
    @include breakpoint('toDesktop') {
      margin-bottom: 30px;
    }
  }

  .dropdown {
    @include breakpoint('toDesktop') {
      margin-bottom: 30px;
    }
  }

  .locationContainer {
    @include breakpoint('desktop') {
      display: flex;
    }
    .typeaheadOne {
      margin-right: 0;
      width: 100%;

      @include breakpoint('toDesktop') {
        margin-bottom: 20px;
        max-height: 65px;
      }
      @include breakpoint('desktop') {
        margin-right: 30px;
      }
    }

    .typeaheadTwo {
      width: 100%;
      max-height: 65px;
      @include breakpoint('toDesktop') {
        margin-bottom: 30px;
      }
    }
  }

  .titleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .textFieldWrapper {
    display: flex;
    margin-bottom: 20px;
    .textFieldWebsite {
      margin-right: 20px;
    }
  }
}

.typeahead.typeaheadStronger {
  input {
    height: 16px;
  }
  > div > div:first-child {
    padding: 12px 20px;
  }
}

.modal {
  @include breakpoint('desktop') {
    min-width: 980px;
  }
}
