@import 'Styling/import.scss';
.FacetWrapper {
  margin-right: 20px;
}
.FacetContainer {
  background-color: rgba(73, 78, 79, 0.1);
  width: 240px;
  margin-bottom: 20px;
  border-radius: 8px 8px 0 0;
  max-height: 50px;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;

  &.open {
    max-height: 999px;
    border-radius: 8px;
  }
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid;
  border-bottom-color: rgba(156, 162, 170, 0.5);
  margin-bottom: 15px;
  height: 50px;
  .titleTxt {
    color: $blue1;
    margin-left: 21px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .arrowIcon {
    margin-right: 15px;
    color: $gray35;
  }
}
.CheckboxContainer {
  display: flex;
  margin-left: 20px;
  line-height: 25px;

  .checkboxComponent {
    > div {
      margin-left: 9px;
    }
    .labelTxt {
      font-size: 12px;
      color: $gray227;
    }
    .number {
      font-size: 12px;
    }
  }
}
.showMore {
  padding-bottom: 23px;
  font-size: 12px;
  text-decoration: underline;
  border-top: 1px solid;
  border-top-color: $grey1;
  padding-top: 15px;
  color: $gray36;
  .showMoreTxt {
    margin-left: 21px;
    padding-top: 19px;
  }
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
}
