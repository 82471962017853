@import 'Styling/import.scss';

.item {
  position: relative;
  background-color: $white;
  padding: 20px;
  margin-bottom: 20px;
}

@include breakpoint('desktop') {
  .item {
    border-radius: 8px;
    padding: 20px 20px 30px 30px;
  }
}
