@import 'Styling/import.scss';

.modal {
  @include breakpoint('desktop') {
    width: 570px;
    :global {
      .content {
        padding: 29px 54px;
      }
    }
  }
}
.modalDetails {
  @include breakpoint('desktop') {
    width: 720px;
    :global {
      .content {
        padding: 25px 35px 20px;
      }
    }
  }
}

.modalDetailsWithoutFooter {
  @include breakpoint('desktop') {
    :global {
      .content {
        padding-bottom: 50px;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
      }
      .footer {
        border: none;
      }
    }
  }
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .titleContainer {
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid $grey1;
    padding-bottom: 10px;
    .avatar {
      border-radius: 50%;
      height: 50px;
      width: 50px;
      margin-right: 20px;
    }
    .title {
      color: $grey2;
      font-family: 'DMSerifDisplay', serif;
      font-size: 24px;

      width: 100%;
    }
  }

  .button {
    color: $darkblue;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 5px;
    @include breakpoint('tablet') {
      margin-top: 17px;

      margin-left: 20px;
    }
  }

  .item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    border-bottom: 1px solid $grey1;

    .itemLeft {
      display: flex;
      align-items: center;
      padding-top: 0px;
      min-width: 150px;
      @include breakpoint('tablet') {
        min-width: 256px;
      }

      .icon {
        display: none;
        color: $silver2;
        margin-right: 20px;
        @include breakpoint('tablet') {
          display: block;
        }
      }

      .itemLeftText {
        color: $brown;
        font-size: 14px;
        padding: 20px 0px;
      }
    }

    .itemRight {
      .itemRightTextBlue {
        color: $blue1 !important;
      }
      @include breakpoint('tablet') {
        display: flex;
      }
      .itemRightText {
        font-size: 14px;
        font-weight: bold;
        padding: 20px 0px;
        color: $brown;
      }

      .expirationValueNormal {
        font-size: 14px;
        padding: 20px 0px;
        color: $brown;
        font-weight: bold;

        .date {
          font-weight: normal;
        }
      }
      .expirationValueContainer {
        display: flex;
        font-size: 14px;
      }
      .expirationValueText {
        font-size: 14px;
        padding: 20px 0px;
        color: $brown;
      }
      .expirationValueTextRed {
        color: $red-cinnabar;
        font-weight: bold;
        padding: 20px 4px 20px 0px;
      }

      .offerText {
        font-weight: bold;
        font-size: 14px;
        color: $offer-request-type-text;
        background-color: $offer-request-type;
        border-radius: 8px;
        border: $gray18 1px solid;
        width: 155px;
        padding: 6px;
        margin-top: 12px;
      }
      .pending {
        font-weight: bold;
        font-size: 14px;
        color: $purple15343568;
        background-color: $purple13153135;

        border-radius: 8px;
        border: $gray18 1px solid;
        width: 155px;
        padding: 6px;
        margin-top: 12px;
      }
      .accepted {
        color: $green68455348;
        background-color: $green43545434;
      }
      .expired {
        color: $black315451354;
        background-color: $white43535135;
      }
      .rejected {
        color: $rejected-text-color;
        background-color: $red35123534;
      }

      .completed {
        color: $green68455348;
        background-color: $green43545434;
      }
      .done {
        color: $white;
        background-color: $blue12;
      }

      .newTimeProposal {
        color: $brown12313213;
        background-color: $yellow251515;
      }
    }
  }
  .dateTime {
    font-size: 14px;
    font-weight: bold;
    padding: 15px 0px;
    line-height: 20px;
    color: $brown;
    @include breakpoint('tablet') {
      display: flex;
    }
    .line {
      display: none;
      @include breakpoint('tablet') {
        display: block;
      }
    }
  }
  .paragraphMessage {
    color: $brown;
    font-size: 14px;
    margin-bottom: 15px;
  }

  .dateTimeLeft {
    color: $brown;
    font-size: 14px;
    padding: 15px 0px;
    line-height: 20px;
    @include breakpoint('tablet') {
      display: flex;
    }
  }
}

.avatarChar {
  border-radius: 50%;
  min-height: 50px;
  min-width: 50px;
  background-color: $yellow3;
  text-align: center;
  user-select: none;
  text-transform: uppercase;
  overflow: hidden;
  margin-right: 15px;

  > span {
    line-height: 50px;
    font-size: 28px;
    color: white;
  }
}
