@import 'Styling/import.scss';

.statusContainer {
  display: flex;
  align-items: center;

  @include breakpoint(desktop) {
    transform: translateX(10px);
  }

  .text {
    font-size: 14px;
    margin-left: 5px;

    @include breakpoint(desktop) {
      margin-left: 15px;
    }

    &::first-letter {
      text-transform: uppercase;
    }
  }
}
