@import 'Styling/import.scss';

.formWrap {
  display: flex;
  align-content: center;
  align-items: center;
  max-width: 386px;
  width: 100%;
  justify-content: center;
  flex-direction: column;
}
.title {
  color: $white;
  font-family: 'DMSerifDisplay', serif;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 23px;
}

.link {
  color: $white;
  text-decoration: underline;
  text-decoration-color: currentColor;
  font-size: 14px;
  &:focus, &:visited {
    color: $white;
  }
}
.linktxt {
  color: $white;
}

.inputWrap {
  max-width: 386px;
  margin: 0 auto 17px;
  position: relative;
  .cardCheckInput {
    > svg {
      top: auto;
    }
    label svg {
      color: $white;
    }
    > div {
      color: $white;
      font-size: 14px;
    }
  }
}

.form {
  width: 100%;
  margin-bottom: 18px;
}

.button {
  height: 66px;
}

@include breakpoint('desktop') {
  .formWrap {
    margin-top: 60px;
  }
  .inputWrap {
    margin-bottom: 31px;
  }
  .title {
    font-size: 36px;
    margin-bottom: 21px;
  }
  .link {
    color: $white;
  }
  .linktxt {
    color: $white;
  }
}
