@import 'Styling/import.scss';

.messagesSectionMobileFooterContainer {
  padding: 28px 20px;
  background-color: $guyabano;
  border-top: 1px solid $gray39;

  a {
    color: $gray40;
  }
}
