@import '/src/Styling/import';

.checkContainer, .checkContainer.none {
  margin-bottom: 20px;
  display: flex;
  position: relative;
  align-items: center;
  &.topAlign {
    align-items: flex-start;
    .checkBoxText {
      margin-top: 2px;
    }
  }
  .error {
    color: $red-error;
  }
}

.label {
  &:focus-visible {
    outline: none;
  }
}

.hidden {
  display: none;
}

.reportProblemIcon {
  position: absolute;
  top: -1px;
  left: -30px;
  color: $red-error;
}

.checkBoxText {
  font-size: 12px;
  line-height: 18px;
  color: $black2;
  margin-left: 18px;
  &.error {
    color: $red-error;
    a {
      color: $red-error;
    }
  }
}

.statement {
  font-size: 14px;
  color: $black2;
  line-height: 1.29;
}

@include breakpoint('desktop') {
  .checkContainer.topAlign {
    align-items: center;
    .checkBoxText {
      margin-top: 0;
    }
    .reportProblemIcon {
      top: calc(50% - 15px);
    }
  }
}
.checkboxIcon{
  cursor: pointer;
}
.errorText {
  color: $red-error;
  font-size: 12px;
}

.checkContainer.modal {
  @include breakpoint('desktop') {
    margin: 0 0 $title-modal-bottom-margin;
  }
}
