@import 'Styling/import.scss';

.myOffersContainer {
  .titleContainer {
    display: flex;
    align-items: center;
    padding: 20px 0;
    border-top: 1px solid $gray-cyan;
    border-bottom: 1px solid $gray-cyan;

    svg {
      color: $gray36;
      margin-right: 22px;
      width: 38px;
      height: 38px;
    }

    h2 {
      font-family: 'DMSerifDisplay', serif;
      font-size: 24px;
      color: $gray36;
    }
  }

  p {
    color: $gray36;
    padding: 20px 0;
    border-bottom: 1px solid $gray-cyan;
    font-size: 14px;
  }
}
