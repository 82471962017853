@import 'Styling/import.scss';

.layout {
  display: flex;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(/Assets/Images/employer-login-layout-mobile.png);
  position: relative;
}

.container {
  display: flex;
  width: 100%;
  align-items: center;
  align-content: center;
  flex-direction: column;
  padding: 34px 42px;
  justify-content: center;
  backdrop-filter: blur(5px);
  &.fullWidth {
    background: $white;
  }
}

.childrenContainer {
  display: flex;
  flex: 1;
  width: 100%;
}

.logoContainer {
  margin-bottom: 46px;
  .logo {
    font-size: 25px;
    color: $white;
    font-family: 'DMSerifDisplay', serif;
    width: 153px;
  }
}

@include breakpoint('desktop') {
  .layout {
    background-image: url(/Assets/Images/employer-login-layout-desktop.png);
    background-position: center;
  }
  .logoContainer {
    position: absolute;
    top: 40px;
    left: 50px;
    margin-top: 0;
    .logo {
      font-size: 25px;
      font-family: 'DMSerifDisplay', serif;
      width: 153px;
    }
  }
  .container {
    width: 50%;
    &.fullWidth {
      width: 100%;
      padding: 0;
      background: rgba(255, 255, 255, 0.3);
    }
  }
  .childrenContainer {
    flex: none;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}
