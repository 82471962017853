@import 'Styling/import.scss';

.dropdownBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  text-align: left;
  height: 51px;
  cursor: pointer;

  @include breakpoint('desktop') {
    height: 59px;
    margin: 0 20px;
  }

  .dropdownTitleWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;

    .medalIcon {
      color: $yellow2;
    }
  }

  .testCategoryTitle {
    justify-content: center;
    align-items: center;
    margin-left: 25px;
    color: $blue1;
    font-weight: 500;

    &::first-letter {
      text-transform: uppercase;
    }

    @include breakpoint('desktop') {
      margin-left: 22px;
    }
  }
}

.arrow {
  margin-right: 22px;
  @include breakpoint('desktop') {
    margin-right: 0;
  }
}

.dropdownContent {
  background-color: $white;
  overflow: hidden;
  font-size: 14px;

  @include breakpoint('desktop') {
    font-size: 14px;
    line-height: 4.29;
  }
}

.testContainer {
  border-top: 1px solid $gray25;
  padding-bottom: 30px;

  @include breakpoint('desktop') {
    padding-bottom: 0;
  }
}

.testMobileExpirationDateWrapper {
  margin-right: 17px;
  max-width: 67px;
}

.testMobileInfoWrapper {
  margin-right: 59px;
}

.testContent {
  display: flex;

  .chartIcon {
    width: 18px;
    height: auto;
    color: $black-opacity-3;
  }

  .trainingIcon {
    width: 40px;
    height: 40px;
    margin-right: 19px;
  }

  @include breakpoint('desktop') {
    height: 59px;
  }

  .dynamicTestValues {
    margin-left: 6px;

    @include breakpoint('desktop') {
      display: grid;
      grid-template-columns: 29.4% 16% 13.4% 16% 1fr;
      width: 100%;
      margin-left: 0;

      div {
        display: flex;
        align-items: center;
      }
    }

    .testWrapper {
      display: flex;
      align-items: flex-start;
      margin-top: 20px;
      color: $blue1;
      line-height: 16px;

      @include breakpoint('desktop') {
        align-items: center;
        margin-top: 0;
      }
    }

    .iconWrapper {
      display: flex;
      margin-top: 20px;
      @include breakpoint('desktop') {
        margin-top: 0;
        justify-content: flex-end;
      }
      .icon {
        margin-right: 10px;
      }
    }

    .infoWrapper {
      align-items: center;
      margin-top: 10px;
      margin-bottom: 12px;

      @include breakpoint('desktop') {
        margin: 0;
      }
    }

    .button {
      width: 110px;
      height: 39px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      padding: 0;

      span {
        line-height: normal;
      }

      @include breakpoint('desktop') {
        font-size: 12px;
        margin-top: 0;
      }
    }

    .pendingButton {
      border: none;
      background-color: transparent;
      cursor: default;

      span {
        img {
          width: 110px;
          height: 39px;
        }
      }
    }

    .infoButton {
      width: 39px;
      height: 39px;
      border-radius: 50%;
      border: solid 1px $gainsboro;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      background-color: $white2;
      div {
        display: flex;
      }
    }
  }

  .skillTestsName {
    p {
      margin-left: 0;
    }

    @include breakpoint('desktop') {
      padding-left: 63px;
    }
  }

  .testMobileDateWrapper {
    margin-right: 6px;
  }

}

.modalTestsContainer {
  .modalTitle {
    text-align: center;
    margin-bottom: 35px;
    font-size: 24px;
    font-family: 'DMSerifDisplay', serif;
    text-transform: uppercase;
    font-weight: 400;
  }

  .modalText {
    color: $grey2;
    font-size: 14px;

    a {
      color: $grey2;
    }
  }

  .checkboxTextWrapper {
    display: flex;
  }
}
