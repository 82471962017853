@import 'Styling/index.scss';

.container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  .icon {
    margin-right: 10px;
    height: 20px;
  }
  .text {
    .primaryText {
      font-size: 14px;
      line-height: 22px;
      color: $gray15;
    }
    .secondaryText {
      font-size: 14px;
      line-height: 22px;
      padding-left: 3px;
      overflow-wrap: anywhere;
    }
  }

  .hideOnMobile {
    display: none;
    @include breakpoint('desktop') {
      display: inline-block;
    }
  }
}
