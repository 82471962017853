@import 'Styling/import.scss';

$darkblue: #0f2b4d;
$blue: #113a60;
$gray: #e1e3e5;
$white: #fff;

.badgeGreen {
  > span {
    background: #8ac550;
  }
}
.badgeRed {
  > span {
    background: #e64144;
  }
}

.sidebar {
  display: block;
  background: $darkblue;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  max-width: 100%;
  @include breakpoint('desktop') {
    height: 100%;
  }
  .verticalStipe {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 75px;
    height: 100%;
    background: $blue;
    z-index: 2;
    @include breakpoint('desktop') {
      display: block;
      width: 100px;
    }
  }

  .sidebarControl {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    height: 80px;
    line-height: 96px;
    color: $white;
    cursor: pointer;
    z-index: 3;
    margin: 0;
    width: 75px;
    padding: 26px;

    &.desktopControl {
      display: none;
      text-align: center;
    }

    &.opened {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    &.mobileControl {
      display: block;
      text-align: center;
    }
    &.mobileControlHidden {
      display: none;
    }
    @include breakpoint('desktop') {
      margin: 0 10px;
      width: 80px;
      padding: 0;
      background: $blue;
      &.desktopControl {
        display: block;
      }
      &.mobileControl {
        display: none;
      }
    }
  }
  .sidebarContent {
    margin-top: 80px;
    z-index: 4;
    position: relative;
    display: none;
    @include breakpoint('desktop') {
      display: block;
    }
    .logoWrap {
      position: absolute;
      display: none;
      top: -50px;
      right: 182px;
      z-index: 0;
      .logo {
        width: 150px;
      }
      @include breakpoint('desktop') {
        display: block;
      }
    }
    > ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: block;
      > li {
        margin: 0;
        padding: 0;
        display: block;
        position: relative;
        width: 75px;

        &.active {
          width: 100%;
          .iconWrap {
            background: $darkblue;
            margin-right: 0;
            @include breakpoint('desktop') {
              padding-right: 10px;
              width: 90px;
            }
          }
          > div {
            display: block;
          }
        }
        @include breakpoint('desktop') {
          width: 100px;
          &:not(.withoutContent):hover {
            width: 470px;
            .iconWrap {
              background: $darkblue;
              width: 90px;
              margin-right: 0;
              padding-right: 10px;
            }
            > div {
              display: block;
            }
          }
        }

        .iconWrap {
          display: block;
          height: 70px;
          line-height: 70px;
          text-align: center;
          color: $white;
          cursor: pointer;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          width: 75px;
          margin: 0;
          @include breakpoint('desktop') {
            width: 80px;
            margin: 0 10px;
          }
        }
        > div {
          display: none;
          position: absolute;
          top: 0;
          left: 75px;
          padding: 0 20px 130px 30px;
          width: calc(100% - 75px);
          height: calc(100vh - 80px);

          &.secondLinks {
            top: -70px;
          }
          &.thirdLinks {
            top: -140px;
          }
          &.fourthLinks {
            top: -210px;
          }
          &.fifthLinks {
            top: -280px;
          }
          &.sixthLinks {
            top: -350px;
          }
          @include breakpoint('desktop') {
            width: auto;
            left: 90px;
          }
          h2 {
            font-size: 36px;
            line-height: 42px;
            margin: 14px 0 14px 20px;
            padding: 0;
            font-family: 'DMSerifDisplay', serif;
            font-size: 36px;
            color: $white;
          }
          > ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: block;
            > li > a {
              padding: 0 20px;
              display: block;
              width: 100%;
              height: 50px;
              line-height: 50px;
              color: $white;
              margin: 0 0 5px;
              font-size: 16px;
              border-radius: 8px;
              cursor: pointer;
              &:hover,
              &.active {
                background-color: rgba(255, 255, 255, 0.1);

                span {
                  color: $yellow3;
                }
              }
              @include breakpoint('desktop') {
                width: 320px;
              }
              span {
                display: inline-flex;
                align-items: center;
                svg {
                  margin-left: 12px;
                }
              }
            }
          }
        }
      }
    }

    .newMessagesIndicator {
      display: inline-flex;
      position: absolute;
      left: 15px;
      top: -8px;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $red-error;
      color: $white;
      font-size: 11px;
    }
  }
}

.hamburgerIcon {
  color: black;
  font-size: 30px;
  display: none;
  @include breakpoint('desktop') {
    color: $white;
  }
  &.hamburgerMenuIcon {
    display: block;
  }
  &.hamburgerCloseIcon {
    display: none;
  }
}

:global {
  .lsh-sidebar-opened {
    overflow: hidden;
    :local {
      .sidebar {
        width: 100%;
        height: 100%;
        .verticalStipe {
          display: block;
        }
        .sidebarContent {
          display: block;
        }
        .hamburgerMenuIcon {
          display: none;
          @include breakpoint('desktop') {
            display: block;
          }
        }
        .hamburgerCloseIcon {
          display: block;

          @include breakpoint('desktop') {
            display: none;
          }
        }
        .sidebarContent {
          .logoWrap {
            display: block;
            right: auto;
            left: 122px;
          }
        }
      }
    }
  }
}
