@import 'Styling/import.scss';

.completenessElement {
  margin-bottom: 10px;
}

.completenessDone {
  color: $green2;
}
.completenessNotDone {
  color: $red-error333;
}

.completenessElementText {
  font-size: 14px;
}

.iconStyle {
  font-size: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: -5px;
}
