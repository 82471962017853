@import 'Styling/import.scss';

@mixin tableGrid() {
  @include breakpoint('desktop') {
    display: grid;
    grid-column-gap: 6px;
    grid-template-columns: minmax(85px, 1fr) repeat(6, 80px);
  }
  @include breakpoint(1024px) {
    grid-template-columns: minmax(85px, 1fr) repeat(6, 85px);
  }
  @include breakpoint(1100px) {
    grid-template-columns: 1fr 90px 90px 90px 90px 100px 110px;
  }
  @include breakpoint(1200px) {
    grid-template-columns: 1fr 90px 90px 100px 120px 120px 110px;
  }
  @include breakpoint(1400px) {
    grid-template-columns: 1fr 120px 120px 120px 120px 150px 110px;
  }
}

.container {
  @include breakpoint('desktop') {
    margin: 0 50px;
  }
  @include breakpoint('toDesktop') {
    .headerWrap {
      padding: 20px 20px 0;
    }

    .listWrap {
      padding: 0 20px;
    }
  }

  .title {
    font-family: 'DMSerifDisplay', serif;
    letter-spacing: 1.8px;
    color: $yellow;
    font-size: 36px;
    margin-top: 46px;
    margin-bottom: 50px;
  }
  .listWrap {
    .listContainer {
      border-radius: 9px;
      background-color: $white;
      padding: 30px 30px 40px;
      margin-top: 22px;

      .listHeader {
        color: $gray12;
        font-size: 12px;
        font-weight: bold;
        line-height: 14px;
        text-transform: uppercase;
        padding-bottom: 20px;
        border-bottom: 1px solid $gray23;
        display: none;
        @include tableGrid();

        &.withScrollbar {
          padding-right: 30px;
        }
        .center {
          justify-content: center;
        }
        > div {
          display: inline-flex;
          align-items: center;
          &:first-child {
            min-width: 0;
          }
        }
      }
      .list {
        border-bottom: 1px solid $gray23;
        @include breakpoint('desktop') {
          max-height: 233px;
          overflow-y: auto;

          &.withScrollbar {
            max-height: 232px;
            .listItem {
              margin-right: 15px;
            }
          }
        }

        .listItem {
          min-height: 58px;
          border-bottom: 1px solid $gray98;
          justify-content: center;
          position: relative;
          display: flex;
          flex-wrap: wrap;
          @include tableGrid();
          @include breakpoint('toDesktop') {
            margin-bottom: 10px;
          }

          &:last-child {
            border-bottom: none;
          }

          > div {
            display: flex;
            align-items: center;
            font-size: 14px;
            @include breakpoint('toDesktop') {
              width: 100%;
              padding: 5px 0;
            }
            .label {
              padding-right: 5px;
            }
          }
          .name {
            font-size: 14px;
            font-weight: 500;
            color: $blue1;
            min-width: 0;

            span {
              @include ellipsisSimple();
            }
          }
          .checkboxWrap {
            @include breakpoint('toDesktop') {
              width: 50%;
            }
            @include breakpoint('desktop') {
              justify-content: center;
            }
            .checkbox {
              margin-bottom: 0;
              position: relative;
              background-color: transparent;

              &.disabledCheckbox {
                label {
                  position: relative;
                }
                &:before {
                  content: '';
                  background-color: $gray14;
                  width: 16px;
                  height: 16px;
                  position: absolute;
                  left: 4px;
                  top: 4px;
                }
              }
              svg {
                color: rgba(0, 0, 0, 0.3);
                background-color: transparent;
              }
              > div {
                display: none;
              }
            }
          }
          .frequency {
            text-transform: capitalize;
          }
          .actions {
            display: flex;
            @include breakpoint('toDesktop') {
              margin-bottom: 10px;
              > * {
                margin-right: 10px;
              }
            }
            @include breakpoint('desktop') {
              justify-content: space-between;
            }
            .icon {
              color: rgba(0, 0, 0, 0.3);
            }
          }
          .label {
            color: $gray12;
            font-size: 12px;
            font-weight: bold;
            line-height: 14px;
            text-transform: uppercase;
            @include breakpoint('desktop') {
              display: none;
            }
          }
        }
      }
      .errorSaveWrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .buttons {
        flex: 1;
        padding-top: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .cancel {
          position: static;
          margin-right: 27px;
          padding: 11px 22px;
        }
        .save {
          min-width: 102px;
          max-width: 102px;
        }
      }
    }
    .infoPopoverWrap {
      width: 300px;
      font-size: 14px;
      line-height: 18px;
      color: $grey2;
      padding: 33px 29px;
      position: fixed;
      background-color: $white;
      border-radius: 30px;
      z-index: 1;
      box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
      text-align: left;
      .closeButton {
        position: absolute;
        width: 30px;
        height: 30px;
        background-color: $white;
        border-radius: 50%;
        box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
        top: -37px;
        right: -15px;
      }
    }
  }
}
.sidebarLinks {
  margin-top: 85px;
  padding-left: 24px;
  padding-right: 24px;
}
