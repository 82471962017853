@import 'Styling/import.scss';

.section {
  .sectionItem {
    display: flex;
    width: 100%;
    padding-bottom: 20px;
    .content {
      flex: 1;
    }
    .editButton {
    }
  }
}

.modal {
  @include breakpoint('desktop') {
    min-width: 980px;
  }
}
