@import 'Styling/import.scss';

.actionWrap {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  justify-content: flex-start;

  @include breakpoint(1500px) {
    justify-content: flex-end;
  }
  .confirmationAction {
    font-size: 14px;
  }
  .confirmedMessage {
    display: flex;
    font-size: 14px;
    align-items: center;
    color: $blue1;

    span {
      margin-left: 6px;
    }

    svg {
      color: $green3;
    }
  }

  .moreAction {
    color: $gray30;
    transition: .3s fill linear;
  }

  .startInterviewBtn {
    padding: 7px 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9px;
    background-color: $yellow3;
    border: solid 1px $gray31;
    color: $white;
    cursor: pointer;
    white-space: nowrap;

    svg {
      color: $white;
    }

    span {
      margin-left: 5px;
    }
  }

  .startInterviewBtnDisabled {
    background-color: $white2;
    color: $gray12;
    cursor: not-allowed;

    svg {
      color: $gray12;
    }
  }

  .mainAction {
    font-size: 14px;
    min-width: 190px;
    padding: 7px 15px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9px;
    background-color: $white2;
    border: solid 1px $gray31;
    white-space: nowrap;
    cursor: pointer;

    @include breakpoint(1500px) {
      padding-left: 0;
    }
  }
  .mainActionDisabled {
    background-color: $white;
    color: $gray12;
    position: relative;
    user-select: none;
    cursor: not-allowed;

    .leftIcon {
      svg {
        color: $green3;
        font-size: 14px;
        position: absolute;
        top: 12px;
        left: -7px;
        background-color: $white;
        border-radius: 100%;
      }
    }
    .rightIcon {
      display: none;

      @include breakpoint(1500px) {
        display: block;
      }
      svg {
        color: $black-opacity-3;
        cursor: pointer;
        font-size: 14px;
        position: absolute;
        top: 12px;
        right: 12px;
      }
    }

    .greenColor {
      svg {
        color: $green3;
      }
    }
    .yellowColor {
      svg {
        color: $yellow3;
      }
    }
    .redColor {
      svg {
        color: $red-cinnabar;
      }
    }
  }

  .infoPopoverWrap {
    position: absolute;
    top: 40px;
    right: -50px;
    width: 320px;
    background-color: $white;
    padding: 20px 30px;
    border-radius: 30px;
    z-index: 5;
    box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);

    .infoPopoverTitle {
      text-align: left;
      color: $yellow3;
      font-size: 16px;
      margin-bottom: 20px;
      font-family: 'DMSerifDisplay', serif;
    }

    .greenText {
      color: $green3;
    }

    .candidateStatus,
    .seekerStatus {
      color: $grey2;
      font-size: 14px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      span {
        display: flex;
        align-items: center;
      }

      svg {
        color: $black-opacity-3;
        cursor: pointer;
        font-size: 14px;
        position: unset;
        margin-right: 20px;
      }
    }

    .greenColor {
      svg {
        color: $green3;
      }
    }
  }
}

.pendingIcon {
  color: $yellow;
}
