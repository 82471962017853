@import 'Styling/import.scss';

.title.titleStronger {
  @include breakpoint('toDesktop') {
    font-weight: 400;
    line-height: 25px;

    &:first-child {
      margin: 0 0 30px;
    }
    &:not(:first-child) {
      margin: 30px 0;
    }
  }
}

@include breakpoint('desktop') {
  .modal {
    min-width: 980px;
  }
}

.text {
  font-size: 14px;
  line-height: 22px;
  white-space: pre-wrap;

  @include breakpoint('desktop') {
    padding: 0 40px 0 48px;
  }
}


.up {
  display: flex;
  padding-top: 10px;
  flex-direction: column;
  @include breakpoint('tablet') {
    flex-direction: row;
  }
  @include breakpoint('desktop') {
    margin-left: 50px;
    margin-right: 50px;
  }
  .left {
    margin-right: 50px;
    display: flex;
    .logo {
      width: 100px;
      height: 100px;

      margin-right: 10px;
      margin-bottom: 20px;

      @include breakpoint('tablet') {
        width: 170px;
        height: 170px;
        margin-right: 50px;
        margin-bottom: 30px;
      }
    }
    .titleBizi {
      font-weight: bold;
      color: $black;
      font-size: 14px;
      margin-bottom: 6px;
    }

    .titleBiziParagraph {
      color: $gray28;
      font-size: 14px;

      margin-bottom: 6px;
    }
  }

  .right {
    display: flex;

    flex-direction: column;
    @include breakpoint(1200px) {
      flex-direction: row;
    }

    .textRightFirst {
      margin-right: 50px;
      max-width: 500px;
    }
    .textRight {
      display: flex;
      flex-direction: column;
      overflow: visible;
      display: block;
    }

    .titleBiziLeft {
      font-size: 14px;
      font-weight: bold;
      color: $gray28;
      margin-bottom: 6px;
    }
    .paragraphBiziLeft {
      font-size: 14px;
      color: $black;
      margin-bottom: 30px;
    }
  }
}

.down {
  margin-top: 0px;

  .logoBizi {
    width: 170px;
  }
  @include breakpoint('desktop') {
    margin-left: 50px;
    margin-right: 50px;
  }
}

.header {
  h2 {
    font-weight: 400;
  }
}

.sectionItem {
  margin-bottom: 0;
}

.itemHeader {
  @include breakpoint('toDesktop') {
    margin-bottom: 20px;
  }

  div {
    &:first-of-type {
      margin-right: 11px;

      @include breakpoint('desktop') {
        margin-right: 20px;
      }
    }
  }
}

.showMoreContent.showMoreContentStronger {
  @include breakpoint('desktop') {
    button {
      margin-left: 40px;
    }
    hr {
      margin-left: 50px;
      margin-right: 50px;
    }
  }
  &.onlyBiziSi hr{
    display: none;
  }
}

.hr {
  margin: 30px 0;
  @include breakpoint('desktop') {
    margin: 30px 50px;
  }
}

.hrModal {
  @include breakpoint('toDesktop') {
    margin: 30px 0;
  }
}

.textField {
  textarea {
    border-radius: 10px;
    font-size: 16px;
    color: $grey2;
    padding: 25px 20px;

    @include breakpoint('toDesktop') {
      padding: 20px;
    }
  }
}
