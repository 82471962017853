@import 'Styling/import.scss';


.container {
  .content {
    line-height: 22px;
    font-size: 14px;

    @include breakpoint('desktop') {
      padding: 0 40px 0 48px;
    }

    .text {
      font-size: 14px;
      line-height: 22px;
      white-space: pre-wrap;

      @include breakpoint('desktop') {
        padding: 0 40px 0 48px;
      }
    }
  }
}

.portfolioModalContent {
  @include breakpoint('desktop') {
    min-width: 80%;
    color: $grey2;
  }
}

.header {
  h2 {
    font-weight: 400;
  }
}

.sectionItem {
  margin-bottom: 0;
}

.itemHeader {
  @include breakpoint('toDesktop') {
    margin-bottom: 20px;
  }

  div {
    &:first-of-type {
      margin-right: 11px;

      @include breakpoint('desktop') {
        margin-right: 20px;
      }
    }
  }
}

.textArea {
  textarea {
    border-radius: 10px;
    font-size: 16px;
    color: $grey2;
    padding: 25px 20px;

    @include breakpoint('toDesktop') {
      padding: 20px;
    }
  }
}
