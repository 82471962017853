@import 'Styling/import.scss';

.title {
  font-size: 24px;
  color: $black;
  line-height: 40px;
  text-align: center;
  font-family: 'DMSerifDisplay', serif;
  width: 100%;
  font-weight: 400;
  margin-bottom: 14px;
}

@include breakpoint('desktop') {
  .title {
    font-size: 36px;
    margin-bottom: 16px;
  }
}
