@import 'Styling/import.scss';

.section {
  .itemHeader {
    margin-bottom: 20px;
  }
  .galleryWrap {
    margin: 0;
  }
  .singleVideoWrap {
    font-size: 14px;
  }
  .galleryItemTitle {
    margin-top: 16px;
    font-weight: 700;
    line-height: 22px;
    font-size: 14px;
  }
  .slideWrap {
    position: relative;
    .playCircle {
      position: absolute;
      color: $white;
      font-size: 50px;
      top: calc(50% - 25px);
      left: calc(50% - 25px);
      pointer-events: none;
    }
  }
  :global(.react-blueimp-thumbnails) {
    display: flex;
    flex-wrap: wrap;
    a,
    > div {
      width: 100%;
      margin-bottom: 20px;

      img {
        width: 100%;
      }
    }
    @include breakpoint('tablet') {
      a,
      > div  {
        width: calc((100% - 25px) / 2);
        margin: 0 12.5px 25px;
        &:nth-child(2n + 1) {
          margin-left: 0;
        }
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
    @include breakpoint('desktop') {
      a,
      > div  {
        width: calc((100% - 75px) / 4);
        &:nth-child(4n) {
          margin-right: 0;
        }
        &:nth-child(4n + 1) {
          margin-left: 0;
        }
        &:nth-child(4n + 2), &:nth-child(4n + 3) {
          margin: 0 12.5px 25px;
        }
      }
    }
  }

  @include breakpoint('desktop') {
    .itemHeader {
      margin-bottom: 30px;
    }
    .hr {
      margin: 30px 0 20px;
    }
    .galleryWrap {
      margin: 0 40px 0 48px;
    }
  }
}

.modal {
  .title.titleStronger {
    @include breakpoint('toDesktop') {
      margin: 0 0 22px;
    }
  }
  .title.titleStrongerEdit {
    @include breakpoint('toDesktop') {
      margin-bottom: 22px;
    }
  }

  .addYtWrap {
    @include breakpoint('toDesktop') {
      margin-top: 22px;
    }

    .button {
      min-width: 100px;
      font-weight: 400;
      font-size: 14px;
      height: 40px;
      padding: 12px 10px;
      margin-top: 30px;
      display: flex;
      justify-content: center;
    }
  }
  .buttonContainer{
    display: flex;
    justify-content: center;
  }

  .addContainer {
    margin-top: 40px;
    .bigVideo {
      position: relative;
      img {
        width: 100%;
      }
      .playCircleBig {
        position: absolute;
        color: $white;
        font-size: 150px;
        top: calc(50% - 75px);
        left: calc(50% - 75px);
        pointer-events: none;
      }
    }
  }
  @include breakpoint('desktop') {
    min-width: 980px;

    .addYtWrap {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .textInput {
        flex: 1;
        width: auto;
      }
      .button {
        margin-left: 30px;
        margin-bottom: 30px;
      }
    }
    .addContainer {
      display: flex;
      flex-direction: column;
    }
    .textField > div:last-child {
      position: absolute;
    }
  }
}

.dndContainer {
  display: flex;
  align-items: center;

  > div {
    width: 100%;
  }

  .item {
    display: flex;
    flex-direction: column;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    .firstRow {
      display: flex;
      align-items: center;
      margin-bottom: 18px;
    }

    .imageItemContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;

      .image {
        width: 107px;
      }
    }

    .desktopOnly {
      display: none;
    }
  }
  @include breakpoint('desktop') {
    margin-top: 5px;
    .item {
      .mobileOnly {
        display: none;
      }
      .desktopOnly {
        display: block;
        flex: 1;
        margin: 0 30px;
      }
    }
  }

  .handle {
    margin-right: 10px;
  }
}

.header {
  h2 {
    font-weight: 400;
  }
}

.sectionItem {
  margin-bottom: 0;
}

.itemHeader {
  @include breakpoint('toDesktop') {
    margin-bottom: 20px;
  }

  div {
    &:first-of-type {
      margin-right: 11px;

      @include breakpoint('desktop') {
        margin-right: 20px;
      }
    }
  }
}

.showMoreContent.showMoreContentStronger {
  @include breakpoint('desktop') {
    hr {
      margin: 0 40px 20px 48px;
    }
    button {
      margin-left: 40px;
    }
  }
}
