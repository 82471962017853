@import 'Styling/import.scss';

.rateCandidate {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  p {
    position: absolute;
    left: 0;
    font-size: 14px;
    color: $gray30;
  }

  span {
    cursor: pointer;
  }

  .yellowStarIcon {
    color: $yellow;
  }

  .grayStarIcon {
    color: $white-opacity-2;
  }
}
