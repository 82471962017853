@import 'Styling/import.scss';

.testContainer {
  padding: 0 20px 100px 20px;

  @include breakpoint(desktop) {
    padding: 9px 50px 49px 50px;
  }

  .psychometricIcon {
    margin-right: 19px;
    width: 38px;
    height: 45px;
  }

  .testContent {
    margin-top: 25px;

    .title {
      margin-bottom: 39px;
      font-size: 24px;
      font-family: 'DMSerifDisplay', serif;
      color: $black3;
      font-weight: normal;
      line-height: 32px;

      @include breakpoint(desktop) {
        margin-bottom: 20px;
      }
    }
    .text {
      margin-bottom: 39px;
      font-size: 14px;
      line-height: 24px;

      @include breakpoint(desktop) {
        margin-bottom: 20px;
      }
    }

    ul {
      margin-bottom: 39px;
      @include breakpoint(desktop) {
        margin-bottom: 20px;
      }

      li {
        list-style-type: disc;
        list-style-position: inside;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }

  .testMicrophoneCameraNotificationWrapper {
    margin-bottom: 37px;
    display: flex;
    justify-content: center;
    margin-top: 18px;

    @include breakpoint(desktop) {
      margin-bottom: 40px;
      padding: 0 120px;
    }
  }

  .buttonWrapper {
    display: flex;
    justify-content: center;
  }

  .buttonContainer {
    width: 266px;
    align-self: center;

    @include breakpoint(desktop) {
      width: 386px;
      height: 65px;
    }
  }

  .psychometricTestHeader {
    font-weight: normal;
  }

  .pendingButton {
    cursor: default;
  }
}

.modalPsychometricTestContainer {
  .modalTitle {
    text-align: center;
    margin-bottom: 35px;
    font-family: 'DMSerifDisplay', serif;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: normal;
  }

  a {
    text-decoration: underline;
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
  }

  .modalText {
    color: $grey2;
    font-size: 14px;

    a {
      color: $grey2;
    }
  }

  .checkboxTextWrapper {
    display: flex;
  }

  .headerTests {
    padding-bottom: 12px;

    h2 {
      margin-bottom: 0;
    }
  }
}
