@import 'Styling/import.scss';

.header {
  h2 {
    font-weight: 400;
  }
}

.modalWrapper {
  padding-bottom: 170px;
  background-color: $gray13;
}
.modal {
  & > div:nth-child(2) {
    background-color: $gray13 !important;
  }
  overflow: hidden;
}
.add {
  visibility: hidden;
}
.mainContent {
  background-color: $yellow;
  border-radius: 5px;
  margin-top: 15px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.leftContent {
  visibility: hidden;
  display: none;
}
.edit {
  float: right;
  color: $white;
  padding-top: 10px;
  padding-right: 15px;
}
.editButton {
  display: flex;
  justify-content: right;
}
.spaceWithoutEdit {
  margin-top: 35px;
}

.rightContent {
  width: 100%;
}

.radioBox {
  display: flex;
  justify-content: center;
  padding-top: 22px;
  padding-bottom: 40px;

  .radioBoxButton {
    padding-bottom: 0;
    color: $white;
    margin-right: 5px;
    border-radius: 10px;
    border: 1px solid $white;
    width: 10px;
    height: 10px;
    cursor: pointer;
    &.radioBoxButtonActive {
      background: $white;
      color: $white;
    }
  }
}

.title {
  font-size: 24px;
  color: $white;
  font-family: 'DMSerifDisplay', serif;
  text-align: left;
  padding-left: 19px;
  padding-right: 22px;
  word-break: break-all;
}
.paragraph {
  font-size: 14px;
  color: $white;
  margin-top: 14px;
  text-align: left;
  padding-left: 19px;
  padding-right: 22px;
  word-break: break-all;
  margin-bottom: 20px;
}
.addButton {
  display: flex;
  justify-content: right;
  padding: 40px 15px 12px 20px;
}

@include breakpoint('desktop') {
  .modalWrapper {
    padding: 40px 78px 50px 78px;
    padding-bottom: 170px;
  }
  .modal {
    & > div:nth-child(2) {
      background-color: $gray13 !important;
    }
    border-radius: 30px;
    width: 800px;
    overflow: hidden;

    :global {
      .content {
        padding: 0;
      }
    }
  }
  .addButton {
    display: flex;
    justify-content: right;
    padding: 40px 15px 12px 20px;
  }
  .mainContent {
    display: flex;
    background-color: $yellow;
    border-radius: 5px;
    position: relative;
  }
  .leftContent {
    visibility: visible;
    display: block;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .heartIcon {
    width: 41px;
    height: 38px;
    color: $white;
  }
  .breakLine {
    border-right: 1px solid $white;
  }
  .edit {
    float: right;
    color: $white;
    padding-top: 13px;
  }

  .radioBox {
    display: flex;
    justify-content: center;
    padding-top: 22px;
    padding-bottom: 40px;
    margin-left: calc(-104px + -45px); // left content

    .radioBoxButton {
      margin-right: 2px;
      padding-bottom: 0;
      color: $white;
      margin-right: 5px;
      border-radius: 10px;
      border: 1px solid $white;
      width: 10px;
      height: 10px;
      cursor: pointer;
      &.radioBoxButtonActive {
        background: $white;
        color: $white;
      }
    }
  }

  .title {
    font-size: 24px;
    color: $white;
    line-height: 26.4px;
    font-family: 'DMSerifDisplay', serif;
    padding-top: 0px;
    text-align: left;
  }

  .paragraph {
    font-size: 14px;
    line-height: 25px;
    color: $white;
    margin-top: 15px;
    text-align: left;
  }
}

.addPersonaButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  background-color: transparent;
  color: $black;
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.languageBtn {
  border: 1px solid rgba(0, 0, 0, 0.6);
  border-radius: 8px;
  font-size: 9px;
  padding: 0px 7px 0px 7px;
  margin-left: 20px;
  opacity: 0.3;
  line-height: 2px;
  height: 12px;
  font-family: 'DMSerifDisplay', serif;
  &:hover {
    opacity: 0.6;
  }
}

.languageWrapper {
  float: right;
  border-radius: 8px;
  height: 17px;
}
