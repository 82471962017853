@import 'Styling/import.scss';

.modal {
  @include breakpoint('desktop') {
    :global {
      min-width: 720px;
      .content {
        padding: 25px 35px 20px;
      }
    }
  }
}

.containerInterview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .text {
    font-size: 18px;
    text-align: center;
    line-height: 30px;
    color: $grey2;
    font-family: 'DMSerifDisplay', serif;
    margin-bottom: 26px;
  }

  .paragraph {
    display: flex;
    font-size: 14px;
    color: $grey2;
    text-align: center;
    margin-bottom: 26px;
    line-height: 24px;
  }

  .timeText {
    padding-left: 20px;
    font-size: 14.5px;
    color: $white;
  }

  .icon {
    color: $white;
    margin-top: 15px;
    margin-bottom: 20px;
  }
  .buttons {
    display: flex;
  }

  .line {
    padding: 0px 15px;
    color: $darkblue;
  }
  .button {
    color: $darkblue;
    font-size: 14px;
    font-weight: bold;
  }
}

.containerInterviewDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .title {
    color: $grey2;
    font-size: 14px;
    padding-bottom: 20px;
    border-bottom: 1px solid $silver;
    width: 100%;
  }

  .button {
    color: $darkblue;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 5px;
    @include breakpoint('tablet') {
      margin-top: 17px;

      margin-left: 20px;
    }
  }

  .item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    border-bottom: 1px solid $grey1;
    &:first-child{
      border-top: 1px solid $grey1;
    }
    
    .itemLeft {
      display: flex;
      align-items: center;
      padding-top: 0px;
      width: 150px;
      @include breakpoint('tablet') {
        width: 256px;
      }

      .icon {
        display: none;
        color: $silver2;
        margin-right: 20px;
        @include breakpoint('tablet') {
          display: block;
        }
      }

      .itemLeftText {
        color: $brown;
        font-size: 14px;
        padding: 20px 0px;
      }
    }

    .itemRight {
      @include breakpoint('tablet') {
        display: flex;
      }
      .itemRightText {
        font-size: 14px;
        font-weight: bold;
        padding: 20px 0px;
        color: $brown;
      }

      .expirationValueNormal {
        font-size: 14px;
        padding: 20px 0px;
        color: $brown;
        font-weight: bold;

        .date {
          font-weight: normal;
        }
      }
      .expirationValueContainer {
        display: flex;
        font-size: 14px;
      }
      .expirationValueText {
        font-size: 14px;
        padding: 20px 0px;
        color: $brown;
      }
      .expirationValueTextRed {
        color: $red-cinnabar;
        font-weight: bold;
        padding: 20px 4px 20px 0px;
      }
      .itemRightTextBlue {
        color: $blue1;
      }

      .ContactRequestText {
        font-weight: bold;
        font-size: 14px;
        color: $brown;
        background-color: $green35153432;
        padding: 6px;
        margin-top: 12px;
        border-radius: 8px;
        border: $gray18 1px solid;
        width: 155px;
      }
      .statusText {
        font-weight: bold;
        font-size: 14px;
        padding: 6px;
        padding-left: 0px;
        margin-top: 12px;
        border-radius: 8px;
      }
      .accepted {
        color: $placement-confirmed;  
      }
      .expired {
        color: $expired-text-color;    
      }
      .rejected {
        color: $rejected-text-color;
      }
      .pending {
        color: $pending-text-color;
      }
    }
  }
  .itemOffer {
    display: flex;
    flex-direction: column;
    @include breakpoint('tablet') {
      flex-direction: row;
      align-items: flex-start;
    }

    .itemOfferLeft {
      display: flex;
      @include breakpoint('tablet') {
        width: 256px;
      }
    }
    .itemOfferRight {
      margin-top: 20px;
      max-width: 370px;
    }
    .icon {
      margin-top: 15px;
      display: none;
      color: $silver2;
      margin-right: 20px;
      @include breakpoint('tablet') {
        display: block;
      }
    }

    .itemOfferTitle {
      margin: 20px 0px;
      font-size: 14px;
      color: $brown;
    }

    .itemOfferName {
      font-size: 14px;
      color: $brown;
      font-weight: bold;
    }
  }
  .dateTime {
    font-size: 14px;
    font-weight: bold;
    padding: 15px 0px;
    line-height: 20px;
    color: $brown;
    @include breakpoint('tablet') {
      display: flex;
    }
    .line {
      display: none;
      @include breakpoint('tablet') {
        display: block;
      }
    }
  }

  .dateTimeLeft {
    color: $brown;
    font-size: 14px;
    padding: 15px 0px;
    line-height: 20px;
    @include breakpoint('tablet') {
      display: flex;
    }
  }
}
