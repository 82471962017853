@import 'Styling/import.scss';

.container {
  position: relative;
  width: 100px;
  height: 39px;

  .label {
    position: absolute;
    color: $gray15;
    font-size: 10px;
    left: 15px;
  }
  .purpleIcon {
      svg {
        cursor: pointer;
        color: $purple;
        font-size: 14px;
        position: absolute;
        top: 12px;
        left: -7px;
        }
    }
    .redIcon {
        svg {
          cursor: pointer;
          color: $red384354354;
          font-size: 14px;
          position: absolute;
          top: 12px;
          left: -7px;
         }
     }
}

.btn {
  width: 100px;
  @include breakpoint('desktop') {
    min-width: 100px;
  }
}

.progressTitle {
  top: 45px;
  left: 15px;
  font-size: 10px;
  font-style: italic;
  color: $gray15;
  display: flex;
  justify-content: center;
}
.hidden {
  display: none;
}
.infoPopoverWrap {
  position: absolute;
  top: 64px;
  right: 44px;
  width: 320px;
  background-color: $white;
  padding: 20px 30px;
  border-radius: 30px;
  z-index: 5;
  box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);

  .infoPopoverTitle {
    text-align: left;
    color: $yellow3;
    font-size: 16px;
    margin-bottom: 20px;
    font-family: 'DMSerifDisplay', serif;
  }

  .expiredIcon {
    svg {
      color: $purple;
    }
  }

  .rejectedIcon { 
    svg {
      color: $red384354354;
    }
  }

  .candidateStatus {
    color: $grey2;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin: 0 20px 11px 0;
    display: flex;
    align-items:flex-start;

  .infoDetailslWrap{
    display: flex;
    flex-direction: column;
  }
    svg {
      cursor: pointer;
      font-size: 14px;
      position: unset;
      margin-right: 10px;
    }
  }

}