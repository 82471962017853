@import 'Styling/import.scss';

.pageWrap {
  height: 100%;

  @include breakpoint('desktop') {
    margin: 0 50px;
  }

  .info,
  .noData {
    color: $grey2;
    font-size: 14px;
  }
  .noData {
    margin-top: 30px;
  }

  .sectionItem {
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .itemsWrap {
    margin-top: 30px;
    border-top: 1px solid $gray23;

    .itemWrap {
      display: grid;
      grid-template-areas: 'logo name cta' 'info info info';
      grid-template-columns: auto 1fr auto;
      grid-template-rows: auto auto;
      border-bottom: 1px solid $gray23;
      padding: 20px 0;

      @include breakpoint('desktop') {
        grid-template-areas: 'logo name cta' 'logo info cta';
      }

      .companyName {
        color: $blue1;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        grid-area: name;
        @include breakpoint('desktop') {
          display: block;
        }
      }

      .companyDescription {
        color: $black-opacity-7;
        font-size: 12px;
        grid-area: info;
        margin-top: 10px;
        @include breakpoint('desktop') {
          margin-top: 0;
        }
      }

      .companyLogo {
        flex-shrink: 0;
        border: 2px solid $gray23;
        padding: 5px;
        margin-right: 30px;
        grid-area: logo;
        width: 50px;
        height: 50px;

        @include breakpoint('desktop') {
          width: 60px;
          height: 60px;
        }
        .imgplaceholder {
          background-color: $gray23;
          width: 100%;
          height: 100%;
        }
        .img {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;

          img {
            object-fit: contain;
            width: 100%;
            height: auto;
          }
        }
      }

      .actionWrap {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 20px;
        margin-left: 30px;
        border-left: 1px solid $gray23;
        cursor: pointer;
        grid-area: cta;

        @include breakpoint('desktop') {
          padding: 0 30px;
        }
        svg {
          fill: $black-opacity-3;
        }
      }
    }
  }

  .mainTitle {
    font-family: 'DMSerifDisplay', serif;
    font-size: 36px;
    color: $yellow;
    margin: 0 20px;

    @include breakpoint('desktop') {
      margin: 0;
    }
  }
}
.modalContent {
  color: $grey2;
  font-size: 14px;

  .companyTitle {
    font-weight: bold;
  }
}
