@import 'Styling/import.scss';

.fileUploadCombo {
  cursor: default;
}

.demo {
  display: block;
}

.supportedFiles {
  margin: 10px 0 26px 0;
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.57;
  color: $grey3;

  @include breakpoint(desktop) {
    margin: 15px 0 26px 0;
  }
}

.AttachmentFileUpload {
  &.whiteTheme {
    .integIconHolder {
      background-color: #f5f7f8;
    }
  }
}
.middleText {
  display: none;
  font-size: 14px;
  color: $grey1;
  margin-bottom: 9px;
}
@include breakpoint('desktop') {
  .middleText {
    display: block;
  }
}
.integIconHolder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: $white;
  width: 100%;
  border-radius: 8px;
  padding: 5px 0;
  > p {
    margin: 0;
    font-family: Roboto;
    font-size: 16px;
    line-height: 1.38;
    color: $grey2;
  }
  > div {
    margin: 5px auto 0;
  }
}
.gridWrapper {
  margin-top: 9px;
  margin-bottom: 20px;
}
.progressBar {
  position: relative;
  width: 100%;
  height: 7px;
  margin: 5px 0;
  border-radius: 2px;
  background-color: $gray41;

  .progressBarProgress {
    position: absolute;
    width: 0%;
    height: 100%;
    left: 0;
    top: 0;
    background: $yellow;
    transition: all 0.2s;
  }
}
// .progressBarWrap {
//   position: relative;
//   overflow: hidden;
//   width: 100%;
//   height: 7px;
//   margin: 5px 0;
//   border-radius: 2px;

// }
.progressBarDone {
  width: 100%;
  height: 7px;
  margin: 5px 0;
  border-radius: 2px;
  background-color: #fcb116;
}
.uploadContainer {
  text-align: center;

  .driveFolderUploadOutlinedIcon {
    margin-bottom: 22px;

    @include breakpoint(desktop) {
      margin-bottom: 0;
    }
  }
}
.uploadFile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 222px;
  height: 65px;
  margin: 0;
  border-radius: 8px;
  border: solid 1px $gray18;
  background-color: $gray13;
}
.uploadText {
  font-weight: bold;
  line-height: 40px;
  font-family: Roboto;
  font-size: 16px;
  color: $blue1;
}
.descriptionContainer {
  margin-top: 26px;
  margin-right: 20px;
}
.fileNameInProgress {
  margin-top: 20px;
}
.fileProgressPercent {
  margin-left: auto;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  color: $grey1;
  font-size: 12px;
}
.fileInfoProgressWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.iconEmpty {
  margin-top: 28px;
  margin-left: 12px;
  width: 25px;
  height: 25px;
  background-color: #dcdcdc;
  border-radius: 50%;

  @include breakpoint(desktop) {
    margin-top: 35px;
  }
}
.fileNameDone,
.fileNameDone > a {
  margin-top: 35px;
  color: #fbb015;
  text-decoration: none;
  &:hover {
    color: #fbb015;
    text-decoration: underline;
  }
}
.iconDone {
  margin-top: 35px;
  margin-left: 12px;
  color: #f8a113;
}
.iconDelete {
  margin-top: 32px;
  margin-left: 12px;
  button {
    svg {
      margin-bottom: -5px;
    }
  }
}
.statusLeft {
  min-width: 30px;
}
.statusRight {
  min-width: 40px;
}
.statusRightLong {
  min-width: 100px;
}

.error {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: $red-error;
}
