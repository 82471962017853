@import 'Styling/import.scss';

.resultFileContainer {
  display: flex;
  border-bottom: 1px solid $gray38;

  &:first-of-type {
    margin-top: 20px;
  }

  @include breakpoint('toDesktop') {
    flex-direction: column;
  }

  .infomations {
    min-width: 60%;
    padding: 22px 0;
    position: relative;

    @include breakpoint('toDesktop') {
      padding-bottom: 18px;
    }
  }

  .title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .textIconWrapper {
    display: flex;
    align-items: center;
    padding-right: 20px;
  }

  .statement {
    font-size: 11px;
    color: $gray12;
    line-height: 21px;

    @include breakpoint('desktop') {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin-right: 8px;
    }
  }

  a {
    display: flex;

    @include breakpoint('toDesktop') {
      display: none;
    }
  }

  svg {
    color: $yellow;
  }

  .scoreTable {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    border-right: 1px solid $gray38;

    @include breakpoint('toDesktop') {
      border-left: 1px solid $gray38;
      border-top: 1px solid $gray38;
    }
  }
}
