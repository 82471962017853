@import 'Styling/import.scss';

.container {
  display: flex;
  align-items: center;
  padding: 4px 0 12px;
  margin: 0 20px;
  hr {
    color: $gray16;
    flex: 1;
  }
  .button {
    font-size: 14px;
    color: $grey3;
    padding: 0 27px;
    display: inline-flex;
    align-items: center;
    font-weight: bold;
    color: $blue2;
    &:hover,
    &:focus,
    &:active {
      color: $black;
    }
  }
}

@include breakpoint('desktop') {
  .container {
    margin: 0 0;
  }
}
