@import '/src/Styling/import';

.messageCenterContent {
  position: relative;
  z-index: 0;
}

.breadcrumbsDesktop {
  display: none;

  @include breakpoint('desktop') {
    display: block;
    margin: 0 50px;
  }
}

.messageCenterWrap {
  margin: 0 20px;

  @include breakpoint('desktop') {
    margin: 0 50px;
  }

  @include breakpoint('toDesktop') {
    padding-bottom: 100px;
  }

  .messageCenterHeaderWrap {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 5;
    border-radius: 8px;

    @include breakpoint('desktop') {
      background-color: $white;
    }

    @include breakpoint('desktop') {
      .breadcrumbsMobile {
        display: none;
      }
    }

    .heading {
      font-size: 36px;
      font-family: 'DMSerifDisplay', serif;
      color: $yellow;
      padding-bottom: 23px;
      padding-top: 15px;
      font-weight: 400;
      line-height: 43px;

      @include breakpoint('desktop') {
        padding-top: 20px;
        padding-left: 30px;
        padding-bottom: 0;
      }
    }
  }

  .tabsWrap {
    display: none;
    flex-direction: row;
    margin: 20px;
    border-bottom: 1px solid $gray23;
    padding-left: 30px;
    width: calc(100% - 40px);

    @include breakpoint('desktop') {
      display: flex;
      margin: 20px 30px;
      margin-bottom: 10px;
    }
  }

  .content {
    display: grid;
    margin-top: 10px;
    gap: 10px;
    z-index: 0;

    @include breakpoint('desktop') {
      grid-template-columns: max(400px) auto;
    }
  }
}
