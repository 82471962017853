@import 'Styling/import.scss';
// sidebar for search page

.widgetHeaderBackgroundWrap {
  position: fixed;
  top: 0;
  width: 100%;
  height: 80px;
  z-index: 1;
  background-color: $gray97;

  @include breakpoint(desktop) {
    display: none;
  }
}
.searchSidebar {
  transition: transform 0.2s ease-out;
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: 0;
  z-index: 6;
  min-height: 100vh;
  height: 100%;
  background-color: $black3;

  &::-webkit-scrollbar {
    width: 14px;
  }

  &::-webkit-scrollbar-track {
    background-color: $black-opacity-3;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: $white-opacity-3;
  }

  .avatarIconContainer {
    justify-content: space-between;

    @include breakpoint(desktop) {
      margin: 40px 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @include breakpoint(smallDesktop) {
      margin-top: 18px;
      margin-bottom: 30px;
    }

    .closeButtonAvatarWrapper {
      position: relative;
      margin-left: 40px;

      @include breakpoint(smallDesktop) {
        position: relative;
        top: 0;
        right: unset;
        display: flex;
        align-items: center;
        margin-right: 18px;

        .rhsDesktopAvatar {
          margin-right: 30px;
        }

        .closeButton {
          position: static;
          margin-top: 0;
          border-radius: 50%;
        }
      }
    }
    .closeButtonAvatarClosedWrapper {
      @include breakpoint(smallDesktop) {
        position: fixed;
        top: 12px;
        right: 0;
      }
    }

    .iconsContainer {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;

      @include breakpoint(desktop) {
        display: none;
      }

      @include breakpoint(smallDesktop) {
        align-items: center;
        margin-left: 20px;
      }

      .iconWrapper {
        margin-left: 40px;

        & > svg {
          color: $orange;
        }
      }
    }

    .sidebarContentHolder {
      @include breakpoint(desktop) {
        display: none;
      }
    }
  }

  &.searchSidebarOpened {
    width: 360px;

    @include breakpoint(smallDesktop) {
      width: 100vw;
    }

    .avatarIconContainer {
      display: flex;
      justify-content: flex-end;
    }

    .iconsContainer {
      display: flex;
    }

    .sidebarContentHolder {
      display: block;
    }
  }

  &.searchSidebarClosed {
    padding-left: 20px;
    overflow-y: visible;

    @include breakpoint(desktop) {
      transform: translate(72%);
      width: 360px;
    }

    @include breakpoint(smallDesktop) {
      min-height: fit-content;
      height: fit-content;
      background-color: transparent;
    }

    .linksContainer {
      display: none;
    }

    .avatarIconContainer {
      justify-content: flex-start;
      margin-left: 0;

      .closeButtonAvatarWrapper {
        margin-left: 0;
        .rhsDesktopAvatar {
          @include breakpoint(smallDesktop) {
            display: none;
          }
        }
      }

      .iconsContainer {
        display: none;
      }
    }

    .sidebarContentHolder {
      display: none;
    }
  }

  .closeButton {
    position: absolute;
    margin-top: 25px;
    width: 53px;
    height: 51px;
    background-color: $cyan-dark;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    svg {
      color: $yellow;
    }
  }
}

.settingsMenuWrap {
  @include breakpoint('toDesktop') {
    margin-right: 30px;
  }
}

.settingsMenuWrapMobile {
  @include breakpoint('toDesktop') {
    display: none;
  }
}

.rhsDesktopAvatar {
  width: 50px;
  height: 50px;
  cursor: pointer;
  > span {
    line-height: 50px;
    font-size: 20px;
  }
}

.widgetBarApplicationsMenu {
  & > div {
    @include breakpoint('desktop') {
      transform: translateX(17.5px);
    }
  }
}
