@import 'Styling/import.scss';

.main {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.title {
  font-size: 180px;
  color: $black3;
  font-weight: 100;
  font-style: normal;
}

.header {
  margin-bottom: 10px;
  font-size: 20px;
  color: $grey3;
}

.txt {
  margin-bottom: 20px;
  font-size: 20px;
  color: $grey3;
}

.button {
  width: 200px;
}
