@import 'Styling/import.scss';

.contentLogoBackgroundWrap {
  position: fixed;
  top: 0;
  background-color: $gray17;
  width: 100%;
  height: 80px;
  z-index: 1;

  @include breakpoint('desktop') {
    display: none;
  }
}
.avatarHolder {
  position: fixed;
  top: 12px;
  right: 12px;
  z-index: 1;
}

@include breakpoint('desktop') {
  .avatarHolder {
    position: absolute;
    display: none;
  }
}

.rhsAvatar,
.rhsMiniAvatar,
.rhsDesktopAvatar {
  width: 50px !important;
  height: 50px !important;
  cursor: pointer;
  > span {
    line-height: 50px !important;
    font-size: 20px !important;
  }
}
.profileName {
  margin-bottom: 9px;
  color: $black;
  font-family: 'DMSerifDisplay', serif;
  font-size: 18px;
}
.seeProfile {
  color: $grey3;
  font-size: 12px;
  text-decoration: underline;
}
.avatarWrap {
  margin-left: 0px;
}
.editText {
  color: $grey2;
  font-size: 12px;
}
.profileMenuItemContainer {
  margin-top: 25px;
  margin-bottom: 49px;
}
.profileMenuItem {
  border-bottom: solid 1px $gray18;
  border-top: solid 1px $gray18;
  padding-top: 13px;
  padding-bottom: 10px;
}
.editIcon {
  color: $black;
}
.editNavigate {
  color: $gray18;
}
.closeIcon {
  margin: 8px;
}
.sidebarContent {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 6;
  .sidebarContentHeader {
    height: 84px;
    text-align: right;
    button {
      margin: 22px;
      background-color: $gray33;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      color: white;
    }
  }

  .sidebarBody {
    height: 100%;
    background-color: $gray33;
    color: $white;
    padding-top: 10px;
    padding-bottom: 8px;
  }
}

.profileMenu {
  background: $white;
  margin: 0 17px;
  border-radius: 8px;
  padding: 30px;
  color: $grey3;
}

.desktopSidebar {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 6;
  height: 100%;
  min-height: 100vh;
  width: 0;
  background-color: $gray33;
  transition-property: width;
  transition-duration: 0.1s;
  transition-timing-function: linear;
}

.settingsMenuWrap {
  margin: 25px;
  float: right;
  display: block;
}
.linksContainer {
  display: block;
  padding: 0 24px;
}

.linkWrap {
  display: flex;
  justify-content: space-between;
}

@include breakpoint('desktop') {
  .iconContainer {
    margin: 40px;
    margin-left: 105px;
  }
}

@include breakpoint('desktop') {
  .desktopSidebar {
    display: block;
    width: 100px;
    .linksContainer,
    .iconContainer {
      display: none;
    }
    .helpIcon {
      color: $orange1;
      margin-right: 39px;
    }
    .appsIcon {
      color: $orange1;
    }
    .settingsIcon {
      color: $orange1;
      margin-right: 39px;
    }
    &.opened {
      display: block;
      width: 360px;
      .linksContainer,
      .iconContainer {
        display: block;
      }
    }
  }
}

@include breakpoint('1200px') {
  .desktopSidebar {
    display: block;
    width: 360px;
    .linksContainer {
      display: block;
    }
    .iconContainer {
      display: block;
      text-align: right;
    }
    &:hover {
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
}

:global {
  .rsh-sidebar-opened {
    overflow: hidden;
    :local {
      .sidebarContent {
        display: block;
      }
      .rhsAvatar {
        display: none;
      }
    }
  }
}

.rightSidebarApplicationsMenu {
  & > div {
    @include breakpoint('desktop') {
      transform: translateX(8px);
    }
  }
}
