@import '/src/Styling/import';

.container {
  display: flex;
  flex-direction: column;
  margin: 31px 0;
  width: 100%;
  .hr {
    margin: 40px 0 0;
  }
  .firstTitle {
    margin-top: 14px;
  }
}

@include breakpoint('desktop') {
  .container {
    .hr {
      margin: 50px 0 0;
    }
  }
}
