@import 'Styling/import.scss';

.scoreTableItemContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 14px;
    font-weight: bold;
    color: $gray12;
  }
}

.borderedScoreTableItem {
  padding: 10px;

  &:not(:first-child) {
    border-left: 1px solid $gray38;
  }
}

.activeRedItem {
  background-color: $red-cinnabar;
}

.activeGreenItem {
  background-color: $green3;

  p {
    color: $black;
  }
}
