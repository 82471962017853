@import 'Styling/import.scss';
.circleProgressBarContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .circleSvg {
    display: block;
  }

  .circleBackground,
  .circleProgress {
    fill: none;
  }

  .circleBackground {
    stroke: $gray10;
  }

  .circleProgress {
    stroke: $orange2;
    stroke-linejoin: round;
  }

  .circleText {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    color: $gray15;
    top: 85px;
    left: 139px;

    @include breakpoint('desktop') {
      top: 130px;
      left: 40px;
    }

    .percentage {
      font-family: 'DMSerifDisplay', serif;
      font-size: 24px;
      color: $yellow;

      @include breakpoint('desktop') {
        margin-top: 5px;
      }
    }
  }

  .image {
    position: absolute;
    overflow: hidden;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    border: none;
  }

  .avatarInitials {
    position: absolute;
    overflow: hidden;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: none;
    border-radius: 50%;
    background-color: $yellow3;
    text-align: center;
    -webkit-user-select: none;
    user-select: none;
    text-transform: uppercase;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    color: white;
    font-size: 40px;
  }
}
