@import 'Styling/import.scss';

.modal {
  @include breakpoint('desktop') {
    min-width: 80%;
  }
}
.editWrap {
  text-align: right;
  margin-bottom: 25px;
}

.box {
  background-color: $white;
  padding: 20px 20px 40px;
  margin-bottom: 20px;
}

.fileRow,
.editFileRow {
  display: flex;
  flex-direction: row;
  border-top: 1px solid $link-water;

  a {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 57px;
    color: $black;
    display: block;
    text-decoration: underline;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.fileRow:last-of-type {
  border-bottom: 1px solid $link-water;
}

.editBox {
  .editFileRow {
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-top: none;
    border-bottom: 1px solid $link-water;
  }

  .attachmentNameInput {
    width: 100%;
  }

  .iconHolder {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
  }
  .deleteHolder {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px;
    padding-right: 0;

    .deleteIcon {
      color: #000;
      font-size: 27px;
      cursor: pointer;
    }
  }
}

.iconHolder {
  text-align: center;
  padding: 13px;

  .icon {
    color: #fcb116;
    width: 27px;
    height: 27px;
    cursor: pointer;
  }
}

.editIcon {
  cursor: pointer;
}

@include breakpoint('desktop') {
  .box {
    border-radius: 8px;
    padding: 20px 20px 30px 30px;
  }
}

.errorWrap {
  display: flex;
  flex-direction: row;

  .errorIcon {
    color: $red-error;
  }
}

@include breakpoint('toDesktop') {
  .title.titleStrong, .title.titleStrong:first-child {
    margin: 0 0 24px;
  }
  .titleUpload.titleUploadStrong {
    margin: 0;
  }
  .hr.hrStrong {
    margin: 31px 0 17px;
  }
}
