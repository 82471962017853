@import 'Styling/import.scss';

.datePickerContainer {
  .textField {
    width: 100%;
    position: relative;

    & > div {
      border-radius: 10px;
    }
  }

  .datePicker {
    background-color: $white;
    height: 64px;
    padding: 12px 20px;

    fieldset {
      border: none;
    }

    input {
      color: $grey2;
      width: 100%;
      border: none;
      font-size: 16px;
      line-height: 40px;
      padding: 0;

      &::placeholder {
        font-family: 'Roboto', sans-serif;
        color: $grey1;
        font-size: 14px;
        font-style: italic;
        opacity: 1;
      }
    }
  }
}

.datePickerContainerError {
  .textField {
    & > div {
      border: 1px solid $red-error;
    }
  }
}
