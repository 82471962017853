body {
  background-color: $white;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

p {
  font-family: 'Roboto', sans-serif;
}
h1 {
  font-size: 20px;
}

h2 {
  font-size: 15px;
}

h3 {
  font-size: 10px;
}

h4 {
  font-size: 8px;
}

h5 {
  font-size: 5px;
}

a:visited,
a:focus {
  color: initial;
}
