@import 'Styling/import.scss';
.mainContainer {
  margin-top: 64px;
  margin-bottom: 70px;
  @include breakpoint('900px') {
    margin-left: 27px;
    margin-bottom: 108px;
    margin-top: 151px;
  }
  @include breakpoint('1160px') {
    margin-top: 380px;
    margin-bottom: 168px;
    margin-left: 0;
  }
}

.title {
  width: 100px;
  font-size: 34px;
  color: $white;
  font-family: 'DMSerifDisplay', serif;
  font-weight: 400;
  text-transform: uppercase;

  @include breakpoint('900px') {
    font-size: 58px;
    width: 581px;
  }
  @include breakpoint('1160px') {
    font-size: 58px;
    line-height: 60px;
  }
}
.textContainer {
  margin-top: 19px;
  line-height: 22px;
  color: $white;
  width: 240px;
  font-weight: 400;
  font-size: 16px;

  @include breakpoint('900px') {
    width: 320px;
  }

  @include breakpoint('1160px') {
    width: 450px;
    font-size: 20px;
    margin-top: 23px;
  }
}
.buttonContainer {
  display: flex;
  flex-direction: row;
  margin-top: 28px;
  gap: 10px;
  width: 100%;
  justify-content: space-between;

  @include breakpoint('900px') {
    justify-content: flex-start;
    margin-top: 16px;
    gap: 30px;
  }

  @include breakpoint('1160px') {
    margin-top: 31px;
  }
}

.button span {
  font-size: 10px;

  @include breakpoint('420px') {
    font-size: 16px;
  }
  @include breakpoint('900px') {
    font-size: 20px;
  }
}

.yellowButton {
  width: 160px;
  height: 42px;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: uppercase;
}

@include breakpoint('900px') {
  .yellowButton {
    width: 250px;
    height: 60px;
  }
}
