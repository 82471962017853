@import 'Styling/import.scss';

.container {
  width: 100%;
  height: 50px;
  display: flex;
  background-color: $white2;
  border-radius: 8px;
  align-items: center;
  margin-top: auto;
  &:focus,
  &:hover {
    border: solid 1px $gray39;
  }
  &:active {
    border: solid 1px $blue2;
  }

  .buttons {
    :nth-child(2) {
      transform: rotate(-90deg);
    }
    .sendMessageIcon {
      font-size: 16px;
      margin: 0;
      padding: 0;
    }

    .svgIconButton {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $yellow3;
      height: 38px;
      width: 38px;
      border-radius: 8px;
      color: $blue2;
      font-size: 14px;
      &:active {
        background-color: $blue2;
        color: $white;
        svg {
          color: $white;
        }
      }
      &:hover {
        background-color: $blue2;
        color: $white;
        svg {
          color: $white;
        }
      }
      &:focus {
        background-color: $blue2;
        color: $white;
        svg {
          color: $white;
        }
      }
    }

    .iconButton {
      background-color: $yellow3;
      height: 38px;
      min-width: 62px;
      border-radius: 8px;
      margin: 5px 5px;
      color: $blue2;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      &:active {
        background-color: $blue2;
        color: $white;
      }
      &:hover {
        background-color: $blue2;
        color: $white;
      }
      &:focus {
        background-color: $blue2;
        color: $white;
      }
    }

    .buttonDisabled {
      background-color: $grey1;
      color: $white;
      pointer-events: none;
    }
  }

  .input {
    border-radius: 8px;
    width: 100%;
    height: 30px;
    background-color: $white2;
    border: none;
    padding: 16px 30px 16px 17px;
    font-size: 14px;

    &:focus {
      outline: none;
    }
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $grey1;
      opacity: 1; /* Firefox */
      font-size: 14px;
      font-style: italic;
    }
  }

  .disabled {
    color: $gray-opacity-5;
  }
}
