@import 'Styling/import.scss';

.requestItemWrap {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $gray23;
  margin-bottom: 15px;

  @include breakpoint(1440px) {
    display: grid;
    gap: 30px;
    grid-template-columns: 3fr 150px 75px 75px 300px;
    padding: 10px;
    margin-bottom: 0;
  }

  .detailWrap {
    display: grid;
    grid-template-columns: 3fr 5fr;
    align-items: center;
    margin-bottom: 15px;

    .label {
      font-size: 12px;
      color: $gray12;
    }

    @include breakpoint(1440px) {
      .label {
        display: none;
      }
    }
  }

  @include breakpoint(1440px) {
    .detailWrap {
      display: flex;
      flex-direction: row;
    }
  }
  .companyValue {
    font-size: 14px;
    font-weight: bold;
    color: $blue1;
    word-break: break-word;
  }

  .detailsValue {
    font-size: 14px;
    background-color: $blue2;
    border: solid 1px $gray31;
    border-radius: 8px;
    padding: 10px 13px;
    width: 100%;
    cursor: pointer;
    max-width: 150px;
  }

  @include breakpoint(1440px) {
    max-width: none;
  }

  .statusValue {
    font-size: 14px;
  }

  .yellowText {
    color: $yellow;
  }

  .greenText {
    color: $green68455348;
  }

  .redCinnabarText {
    color: $red384354354;
  }

  .purpleText {
    color: $pending_text;
  }
  .blackText {
    color: $expired-text-color;
  }
  .brownText {
    color: $brown12313213;
  }
  .expirationText {
    font-size: 14px;
    color: $red-cinnabar;
  }

  .expirationValue {
    font-size: 14px;
  }

  .justifyEnd {
    justify-content: flex-end;
  }
}

.greenBackground {
  background-color: $green3-opacity2;
}
