@import 'Styling/import.scss';
.mainContent {
  background-color: $white;
  border-radius: 8px;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 30px;
  padding-bottom: 40px;
  margin-bottom: 40px;
}
.searchContent {
  margin: 20px;
}
.searchToolbar {
  display: grid;
  padding: 14px;
  grid-template-columns: 1fr;
  gap: 20px;
  .searchFieldWrap {
    position: relative;
    .typeahead {
      background-color: $white2;
      border-radius: 8px;
      input {
        height: 44px;
      }
    }
    .searchIcon {
      position: absolute;
      right: 15px;
      top: 20px;
      color: $grey1;
    }
    .textField {
      > div > input {
        background-color: $white2;
      }
    }
  }
  .filterDropdown {
    height: 64px;
  }
  @include breakpoint(1440px) {
    grid-template-columns: 1fr 1fr 1fr 190px;
  }
}
.resetSearchBtn {
  font-size: 14px;
  margin: 0 auto;
  margin-bottom: 20px;
  cursor: pointer;
  color: $black-opacity-4;
  border-bottom: 1px $black-opacity-4 solid;
  @include breakpoint(1440px) {
    margin: 0;
    margin-bottom: 20px;
    margin-right: 20px;
    margin-left: auto;
  }
}
.thickHr {
  background-color: $grey1;
  height: 1px;
  border-top: none;
  opacity: 1;
  margin: 0 20px 20px;
}

.tooltipStyle {
  :global {
    .MuiTooltip-tooltip {
      background-color: $white;
      color: $grey2;
      padding: 18px 30px;
      border-radius: 18px;
      box-shadow: 7px 7px 7px -2px rgb(0 0 0 / 20%),
        0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
    }
  }
}
.option {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;

  @include breakpoint('desktop') {
    margin: 0;
  }
}
.title {
  color: #113a60;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.8;
}
.candidatesBox {
  display: flex;
  line-height: 17px;

  @include breakpoint('desktop') {
    align-items: center;
    justify-content: center;
  }
}
.candidateTitle {
  margin-right: 36px;
  margin-left: 30px;
  color: #a6a6a7;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;

  @include breakpoint('desktop') {
    display: none;
  }
}
.optionCard {
  display: flex;
  margin-left: 30px;
  margin-top: 27px;
  @include breakpoint('toDesktop') {
    > * {
      flex: 0 1 60px;
    }
  }
  @include breakpoint('desktop') {
    margin: 0;
  }
}
.optionTxt {
  color: #a6a6a7;
  font-size: 12px;
  font-weight: bold;
  margin-right: 25px;
  text-transform: uppercase;

  @include breakpoint('desktop') {
    display: none;
  }
}
.iconsBox {
  margin-left: 30px;
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
  @include breakpoint('toDesktop') {
    > * {
      flex: 0 1 67px;
    }
  }
  @include breakpoint('desktop') {
    margin: 0;
  }
}
.break {
  border-bottom: 1px solid $link-water2;
  margin-right: 20px;
  margin-left: 20px;
}
.mobileBreak {
  border-bottom: 1px solid $link-water2;
  margin-right: 20px;
  margin-left: 20px;

  @include breakpoint('desktop') {
    display: none;
  }
}
.optionIcon {
  color: #a6a6a7;
  margin-right: 35px;
  @include breakpoint('desktop') {
    margin-right: auto;
    margin-left: auto;
    cursor: pointer;
  }
}
.optionLink {
  @include breakpoint('desktop') {
    margin-right: auto;
    margin-left: auto;
  }
}
.optionButton {
  margin-right: 14px;
  @include breakpoint('desktop') {
    margin-right: auto;
    margin-left: auto;
  }
}
.desktopHeaderItem {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  @include breakpoint('desktop') {
    margin-right: auto;
    margin-left: auto;
  }
}
.candidatesDesktopHeaderItem {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  color: $gray12;
  font-weight: bold;
  font-size: 12px;
  margin-right: 10px;
  &:first-child {
    margin-right: 30px;
  }
  .checkboxLabel {
    margin-left: 10px;
  }
}
.ascending {
  > svg {
    transform: rotate(180deg);
  }
}
.projectsHeader {
  display: flex;
  justify-content: flex-start;
}
.icon {
  color: #fbb116;
  margin-right: 18px;
  margin-left: 30px;
  @include breakpoint('desktop') {
    margin-left: 20px;
  }
}
@include breakpoint('desktop') {
  .optionBox {
    display: grid;
    grid-template-columns: 5fr 120px 0px 280px 0px;
    border-bottom: 1px solid $link-water;
    margin: 0 30px;
    padding-top: 20px;
    padding-bottom: 20px;

    &:nth-last-child(2) {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}
.candidatesCount {
  font-weight: bold;
  font-size: 14px;
}
.desktopHeader {
  display: none;
  @include breakpoint('desktop') {
    color: $gray12;
    font-weight: bold;
    font-size: 12px;
    display: grid;
    grid-template-columns: 1fr 110px 70px 70px 70px 70px;
    margin: 0 30px;
    padding-bottom: 20px;
  }
}

.headerWrap {
  padding: 10px 20px;
}

.breadcrumbsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.newProjectBtnBreadcrumbs,
.newProjectBtn {
  padding: 7px 15px;
  width: 215px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  background-color: $yellow3;
  border: solid 1px $gray31;
  color: $white;
  cursor: pointer;
  white-space: nowrap;
  margin-bottom: 20px;
}

.newProjectBtnWrap {
  margin: 20px;
  margin-top: 0;
  border-bottom: solid 2px $grey1;
  @include breakpoint('desktop') {
    display: none;
  }
}

.newProjectBtnBreadcrumbs {
  display: none;
  margin: 0;
  @include breakpoint('desktop') {
    display: flex;
  }
}

.modalTextInput {
  margin-top: 30px;
}

.temporary {
  margin: 30px;
  text-align: center;
}
.searchCandidatesLink {
  margin-top: 85px;
  padding-left: 24px;
  padding-right: 24px;
}
