@import 'Styling/import.scss';

.red {
  color: $red3;
  font-size: 27px;
}
.green {
  color: $green4;
  font-size: 27px;
}

.buttonThumb {
  transition: 0.2s ease-in-out;

  &:hover,
  &:focus,
  &:active {
    color: $white;
    background-color: $blue2;
  }
  div {
    margin-right: 5px;
  }
  @include breakpoint('tablet') {
    margin-right: 20px;
  }
}

.footer {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  position: relative;

  @include breakpoint('desktop') {
    display: block;
    flex-direction: row-reverse;
    justify-content: stretch;
  }
}
.cancel {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  button {
    position: static;
    padding-top: 10px;
    transition: 0.2s ease-in-out;

    &:hover,
    &:focus,
    &:active {
      color: $blue2;
      text-decoration: none;
    }
  }
  @include breakpoint('desktop') {
    position: absolute;

    bottom: 30px;
    justify-self: flex-start;
  }
}

.footerButton {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  button {
    margin-top: 30px;
  }
  @include breakpoint('tablet') {
    flex-direction: row;
    justify-self: center;
    align-self: center;
    margin-left: 40px;

    button {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
}
.marginThirdBtn {
  @include breakpoint('tablet') {
    margin-left: 20.5px;
  }
}
