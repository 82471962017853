@import 'Styling/import.scss';

.layout {
  background-image: url('/Assets/Images/homepage/candidate-homepage_1160px.jpg');
  width: 100%;
  height: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  @include breakpoint(tablet) {
    flex-direction: row;
  }

  @include breakpoint(1160px) {
    background-image: url('/Assets/Images/homepage/candidate-homepage_1440px.jpg');
  }
  @include breakpoint(1440px) {
    background-image: url('/Assets/Images/homepage/candidate-homepage_1920px.jpg');
  }
  @include breakpoint(1920px) {
    background-image: url('/Assets/Images/homepage/candidate-homepage_2560px.jpg');
  }
  @include breakpoint(2560px) {
    background-image: url('/Assets/Images/homepage/candidate-homepage_3000px.jpg');
  }
}

.container {
  h1 {
    font-weight: normal;
  }
  padding: 0 25px 40px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $blue2-opacity-5;
  backdrop-filter: blur(20px);

  .image {
    background-color: aliceblue;
    width: 100%;
    height: 100%;
    align-self: center;
    @include breakpoint(tablet) {
      width: 294px;
      align-self: flex-start;
    }
    @include breakpoint(1920px) {
      width: 491px;
      height: 328px;
    }
  }

  .section {
    border-bottom: 1px solid $yellow24523;
    padding-bottom: 10px;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    @include breakpoint(tablet) {
      flex-direction: row;
      padding-bottom: 35px;
      &:last-child {
        border-top: 1px solid $yellow24523;
        border-bottom: none;
      }
    }
    @include breakpoint(desktop) {
      width: 958px;
    }
    @include breakpoint(1920px) {
      width: 1520px;
    }
    .sectionLeft {
      @include breakpoint(tablet) {
        padding-left: 50px;
      }
    }
  }

  .sectionBig {
    display: flex;
    flex-direction: column;
    &:first-child {
      border-top: none;
    }

    border-top: 1px solid $yellow24523;

    padding-bottom: 10px;
    padding-top: 40px;
    .sectionUp {
      @include breakpoint(tablet) {
        display: flex;
        margin-bottom: 45px;
      }
    }
    .sectionLeft {
      @include breakpoint(tablet) {
        padding-left: 50px;
      }
    }
    @include breakpoint(desktop) {
      width: 958px;
    }
    @include breakpoint(1920px) {
      width: 1520px;
    }
  }

  .titleContainer {
    position: relative;
    margin-top: 100px;
    @include breakpoint(tablet) {
      margin-top: 150px;
      margin-bottom: 60px;
    }
    .title {
      font-family: 'DMSerifDisplay', serif;
      font-size: 24px;
      color: $white;
      text-align: center;
      text-transform: uppercase;
      z-index: 2;
      position: relative;

      @include breakpoint(tablet) {
        font-size: 45px;
      }
    }
  }

  .paragraphTitle {
    font-size: 18px;
    font-weight: bold;
    color: $white;
    padding: 24px 0px;
    @include breakpoint(tablet) {
      padding: 0px 0px 7px;
      font-size: 28px;
    }
  }
  .link{
    font-weight: bold;
    color: $black;
  }
  .paragraphTitleGrid {
    font-size: 18px;
    font-weight: bold;
    color: $white;
    padding: 24px 0px;

    @include breakpoint(tablet) {
      padding: 0px 0px 54px;
      font-size: 28px;
    }
  }

  .paragraph {
    font-size: 14px;
    line-height: 24px;
    color: $white;
    .ol {
      list-style: decimal;
      margin-left: 20px;
      li {
        list-style: decimal;
      }
    }
  }

  .paragraphStart {
    padding-bottom: 20px;
  }

  .showMore {
    border: none;
    color: $white;
  }
}
.gridNumber {
  color: $yellow3;
  font-size: 100px;
}
.grid {
  display: grid;
  grid-template-columns: 1fr;

  @include breakpoint(tablet) {
    grid-gap: 150px;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 110px;

    div:nth-child(1) {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        bottom: -50px;
        left: 0;
        width: 100%;
        border-bottom: 1px solid $yellow3;
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 0px;
        right: -78px;
        height: 100%;
        width: 100%;
        border-right: 1px solid $yellow3;
      }
    }
    div:nth-child(2) {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        bottom: -50px;
        left: 0;
        width: 100%;
        border-bottom: 1px solid $yellow3;
      }
      @include breakpoint(1920px) {
        &::after {
          content: '';
          position: absolute;
          bottom: 0px;
          right: -78px;
          height: 100%;
          width: 100%;
          border-right: 1px solid $yellow3;
        }
      }
    }
    div:nth-child(3) {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        bottom: -50px;
        left: 0;
        width: 100%;
        border-bottom: 1px solid $yellow3;
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 0px;
        right: -78px;
        height: 100%;
        width: 100%;
        border-right: 1px solid $yellow3;
      }
    }
    div:nth-child(4) {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        bottom: -50px;
        left: 0;
        width: 100%;
        border-bottom: 1px solid $yellow3;
      }
      @include breakpoint(1920px) {
        &::after {
          content: '';
          position: absolute;
          bottom: 0px;
          right: -78px;
          height: 100%;
          width: 100%;
          border-right: 1px solid $yellow3;
        }
        &::before {
          border-bottom: none;
        }
      }
    }
    div:nth-child(5) {
      position: relative;
      grid-row: 3/4;
      grid-column: 1/3;
      @include breakpoint(1920px) {
        grid-row: 2/3;
        grid-column: 2/4;
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 0px;
        right: -78px;
        height: 100%;
        width: 100%;
        border-right: 1px solid $yellow3;
      }
    }
    div:nth-child(6) {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0px;
        right: -78px;
        height: 100%;
        width: 100%;
        border-right: 1px solid $yellow3;
      }
      &::before {
        content: '';
        position: absolute;

        top: -50px;
        width: 100%;
        height: 100%;
        border-top: 1px solid $yellow3;
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 0px;
        right: -78px;
        height: 100%;
        width: 100%;
        border-right: 1px solid $yellow3;
      }
    }
    div:nth-child(7) {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: -50px;
        width: 100%;
        border-top: 1px solid $yellow3;
      }
      @include breakpoint(1920px) {
        &::after {
          top: -50px;
          content: '';
          position: absolute;
          left: 500px;
          width: 100%;
          height: 100%;
          border-top: 1px solid $yellow3;
        }
      }
    }
  }
  @include breakpoint(1920px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 150px;
    grid-row-gap: 110px;
  }
}
