@import 'Styling/import.scss';

.questionsContainer {
  background-color: $white;
  padding: 23px 20px 40px 20px;

  @include breakpoint(desktop) {
    padding: 30px 20px 40px 29px;
    border-radius: 8px;
  }

  .itemHeaderWrapper {
    margin-bottom: 6px;
  }

  .hr {
    border-top: 1px solid $cyan-blue;
    margin-top: 0;
    margin-bottom: 21px;
    @include breakpoint('desktop') {
      margin: 0 40px 21px 48px;
    }
  }

  .questionsShowMore {
    padding: 0;

    button {
      display: flex;
      align-items: center;
      padding: 0;

      @include breakpoint('desktop') {
        margin-left: 40px;
      }

      svg {
        margin-right: 11px;
      }
    }
  }

  .questionsContainer {
    padding: 0 40px 0 48px;
  }
}

.modalContent {
  @include breakpoint('desktop') {
    width: 800px;
    display: flex;
    flex-direction: column;
  }

  .title:first-child {
    @include breakpoint('toDesktop') {
      margin-bottom: 30px;
      line-height: 25px;
    }
  }

  .addFormikItemButton {
    display: flex;
    align-items: center;
    background-color: transparent;
    color: $grey2;
    height: 100%;
    font-size: 16px;
    font-weight: bold;

    div {
      display: flex;
      align-items: center;
      margin-right: 20px;
      color: $black;
    }

    span {
      color: $grey2;
    }
  }
}

.header {
  h2 {
    font-weight: 400;
  }
}

.itemHeader {
  @include breakpoint('toDesktop') {
    margin-bottom: 20px;
  }

  div {
    &:first-of-type {
      margin-right: 11px;

      @include breakpoint('desktop') {
        margin-right: 20px;
      }
    }
  }
}
