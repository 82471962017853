@import 'Styling/import.scss';

.container {
  background-color: $blue3;
  padding: 62px 22px 26px;

  @include breakpoint('desktop') {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .logoAndCollapseWrap {
    position: absolute;
    top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100vw - 40px);

    .logoWrap {
      width: 160px;
      display: flex;
      align-items: center;
      img {
        width: 100%;
      }
    }

    .collapseWrap {
      display: none;
    }
  }

  .title {
    font-family: 'DMSerifDisplay', serif;
    letter-spacing: 1.8px;
    color: $yellow;
    font-size: 36px;
    margin-top: 46px;
  }

  .hr {
    margin: 20px 0 30px;
  }

  .hr2 {
    margin: 20px 0 8px;
  }

  .basicSearch > div {
    margin-bottom: 20px;
  }

  //.location {
  //  display: flex;
  //  margin: 20px 0;
  //  height: 64px;
  //  .locationInput {
  //    width: 100%;
  //  }
  //  &.locationWithTown {
  //    background-color: white;
  //    border-radius: 10px;
  //    .locationInput {
  //      border-radius: 0;
  //      width: 62%;
  //      & > div > div:first-child {
  //        border-top-right-radius: 0;
  //        border-bottom-right-radius: 0;
  //      }
  //    }
  //    .radiusInput {
  //      width: 38%;
  //      > div:first-child {
  //        border-top-left-radius: 0;
  //        border-bottom-left-radius: 0;
  //        svg {
  //          display: none;
  //        }
  //      }
  //      > div > div > label {
  //        padding-left: 20px;
  //        text-align: center;
  //        span {
  //          margin: 0;
  //        }
  //      }
  //    }
  //  }
  //}

  .searchButton {
    height: 76px;
    &.searchButtonMobile {
      margin-top: 8px;
    }
  }

  .searchFilters,
  .advancedFilters {
    transition: max-height 0.2s ease-in-out;
    .filter {
      width: calc((100% - 22px) / 2);
    }
  }

  .searchFilters .advancedCollapseButton {
    display: none;
  }

  .advancedFilters {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
    opacity: 0;
    &.advancedFiltersOpen {
      max-height: 700px;
      overflow: visible;
      opacity: 1;
    }
    .filters {
      padding-top: 5px;
      display: flex;
      flex-flow: wrap;
      gap: 22px;
    }
  }

  .advancedCollapseButton {
    margin-top: 10px;
    > svg {
      transition: transform 0.3s ease-in-out;
    }

    &.advancedCollapseButtonOpen > svg {
      transform: rotate(180deg);
    }
  }

  .advancedHr {
    margin-top: 8px;
  }

  .currentFilters {
    display: flex;
    color: $white;

    .currentFiltersLabel {
      display: flex;
      align-items: flex-start;
      margin-right: 15px;
      margin-bottom: 15px;
      font-size: 12px;
      font-weight: 700;
      line-height: 27px;
    }

    .currentFiltersChips {
      display: flex;
      flex-flow: wrap;
      flex: 1;
      .chip {
        height: 27px;
        color: $white;
        margin-right: 15px;
        margin-bottom: 15px;
        border-radius: 8px;
        font-size: 11px;

        span {
          overflow: visible;
        }

        svg {
          font-size: 19px;
          color: $white;
        }

        &.chipYellow {
          color: $yellow3;
          border-color: $yellow3;

          svg {
            color: $yellow3;
          }
        }
      }
    }
  }

  .resetSearchButtonWrap {
    display: flex;
    align-items: flex-start;

    .resetSearchButton {
      color: $white;
      display: inline-flex;
      margin-bottom: 15px;
      .resetSearchIcon {
        font-size: 20px;
        margin-right: 6px;
        margin-top: 2px;
      }
      .resetSearchText {
        font-size: 14px;
        line-height: 27px;
      }
    }
  }

  .resetMinMaxWrap {
    display: flex;
    justify-content: space-between;
    .resetSearchButtonWrap {
      align-items: center;
      .resetSearchButton {
        margin-bottom: 0;
      }
    }
    .advancedCollapseButton {
      display: inline-flex;
      font-size: 14px;
      color: $white;
      line-height: 38px;
      align-items: center;
      margin: 0 auto;
    }
  }

  .breadcrumbWrap {
    margin-top: 10px;
  }

  .headerWrap {
    display: flex;
    justify-content: space-between;
    @include breakpoint('toDesktop') {
      flex-direction: column;
    }

    .filterAndSavedSearchWrap {
      @include breakpoint('toDesktop') {
        margin-top: 10px;
      }
      display: flex;
      align-items: flex-end;

      .savedSearchWrap {
        display: flex;
        align-items: flex-end;
        .savedSearchInput {
          width: 100%;
          min-width: 172px;
          > div:first-child {
            height: 48px;
            padding: 11px 20px;
          }
        }
      }

      .filterTypeWrap {
        display: flex;
        align-items: flex-end;
        .filterTypeButton {
          background-color: $white;
          color: $grey2;
          height: 48px;
          border: none;
          text-transform: none;
          min-width: 76px;
          &:first-child {
            border-radius: 8px 0 0 8px;
            border-right: 1px solid $icon-gray;
          }
          &:last-child {
            border-radius: 0 8px 8px 0;
          }
          &.filterTypeButtonActive {
            background-color: $yellow3;
            color: $white;
          }
        }
      }
    }
  }
  .yellowButton span {
    font-size: 20px;
    font-weight: bold;
  }

  @include breakpoint('tablet') {
    .searchFilters,
    .advancedFilters {
      .filter {
        width: calc((100% - 44px) / 3);
      }
    }
  }

  @include breakpoint('desktop') {
    padding: 42px 50px 0px;

    .logoAndCollapseWrap {
      position: static;
      justify-content: space-between;
      width: 100%;
      .collapseWrap {
        display: flex;
      }
    }

    .title {
      margin-top: 26px;
    }

    .hr {
      margin: 20px 0;
    }
    .basicSearch {
      display: flex;

      .keywords {
        width: 32.20%;
        margin-right: 20px;
      }

      //.location {
      //  margin: 0;
      //  width: 32.0175%;
      //  margin-right: 20px;
      //}
      &> div {
        margin-bottom: 0;
      }

      .jobCategories, .regions {
        margin-right: 20px;
        width: 20.29%;
      }

      .searchButton {
        flex: 1;
        height: 64px;
        button {
          min-width: 150px;
        }
      }
    }
    .searchFilters {
      display: flex;
      flex-direction: column;

      .advancedCollapseButton {
        display: inline-flex;
        font-size: 16px;
        color: $white;
        line-height: 40px;
        align-items: center;
        margin: 10px auto 0;
      }
    }
    .searchFilters,
    .advancedFilters {
      &.advancedFiltersOpen {
        max-height: 300px;
      }
      .filters {
        padding-top: 0;
      }
      .filter {
        width: calc((100% - 110px) / 6);
      }
    }

    .headerWrap {
      .savedSearchWrap {
        .savedSearchInput {
          width: 172px;
        }
      }
    }
  }
}
