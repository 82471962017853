@import 'Styling/import.scss';

.hr {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(156, 162, 170, 0.85);
  margin: 15px 0;
  @include breakpoint('desktop') {
    margin: 20px 0;
  }
}

.hrMargin {
  border-top: 1px solid rgba(156, 162, 170, 0.85);
  margin-top: 10px;
}

.hrNoMarginMobileOnly {
  border-top: 1px solid rgba(156, 162, 170, 0.85);
  @include breakpoint('desktop') {
    display: none;
  }
}

.hr.modal {
  margin: 30px 0 22px;
  @include breakpoint('desktop') {
    margin: $hr-modal-top-margin 0 $hr-modal-bottom-margin;
  }
}

.hr.modalBig {
  margin: 40px 0 32px;
  @include breakpoint('desktop') {
    margin: $hr-modal-top-margin 0 $hr-modal-bottom-margin;
  }
}

.hr.modalSmall {
  @include breakpoint('desktop') {
    margin: $hr-modal-top-margin 0 $hr-modal-bottom-margin;
  }
}

.hr.light {
  border-top: 1px solid $gray15;
}

.hr.widget {
  border-top: 1px solid $gray335;
}
