@import 'Styling/import.scss';

.footerContainer {
  display: grid;
  grid-template-rows: auto auto;
  margin-top: auto;
  padding-bottom: 28px;
  background-color: $white;

  @include breakpoint('900px') {
    padding-bottom: 42px;
  }

  @include breakpoint('1160px') {
    grid-template-rows: none;
    grid-template-columns: auto 1fr;
    padding: 10px 30px;
  }
  @include breakpoint('1440px') {
    padding-left: 100px;
    padding-right: 100px;
  }
  @include breakpoint('1920px') {
    padding-left: 350px;
    padding-right: 300px;
  }
  // TODO: Add another div and center content on when screen larger than 1920px
  // @include breakpoint('1920px') {
  //   max-width: 1350px;
  //   margin: 0 auto;
  // }

  .footerLogo {
    place-self: center;
    align-self: center;
    margin: 25px 0;

    .logoStyle {
      width: 225px;
      height: auto;
    }
  }
  .linkAndIconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 30px;

    @include breakpoint('900px') {
      justify-content: center;
    }

    @include breakpoint('1160px') {
      justify-content: flex-end;
      margin: 0;
      margin-left: 45px;
    }

    .linkContainer {
      display: flex;
      align-items: center;
      gap: 30px;

      @include breakpoint('1160px') {
        margin-right: 50px;
      }
      .linkList {
        display: flex;
        > li {
          flex: 1 1 0px;
          text-align: center;
        }
      }
      @include breakpoint('1920px') {
        .linkList {
          flex-direction: row;
        }
      }

      &:first-of-type {
        margin-right: 0;
      }

      .linkStyle {
        display: flex;
        align-items: center;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        color: $black;
        margin-left: 25px;
        text-transform: uppercase;
      }

      .mobileHidden {
        display: none;
        @include breakpoint('833px') {
          display: block;
        }
      }
    }

    .iconContainer {
      display: flex;
      align-items: center;

      @include breakpoint('900px') {
        margin-left: 46px;
      }

      @include breakpoint('1160px') {
        margin-left: 0;
      }

      .iconLink {
        display: flex;
        align-items: center;
      }
      .iconStyle {
        font-size: 29px;
        fill: $black;
      }
      .spacingFix {
        margin-right: 11px;
      }
    }
  }
}
