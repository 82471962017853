@import 'Styling/import.scss';

.basicConsentsLabel {
  border-bottom: 1px solid $link-water;
  padding-bottom: 20px;
  @include breakpoint(desktop) {
    display: grid;
    grid-template-columns: 36.59% 18.29% 13.29% 10.85% 1fr;
  }
}
.consentsSectionItem {
  padding: 40px 20px 40px 20px;
  border-radius: 7px;
  margin-bottom: 0;

  @include breakpoint(desktop) {
    padding: 31px 30px 40px 30px;
  }
}

.basicConsents {
  @include breakpoint(desktop) {
    display: grid;
    grid-template-columns: 36% 20.52% 13.8% 11.14% 1fr;
    align-items: center;
    padding: 10px 0;
    padding-right: 2.56%;
  }
}

.consentSectionsHr {
  margin: 0;
  color: $link-water2;

  @include breakpoint(desktop) {
    display: none;
  }
}

.privacyContact {
  font-size: 12px;
  color: $gray12;
}

.consentsHeader {
  padding-top: 39px;
  padding-bottom: 18px;

  @include breakpoint(desktop) {
    padding-top: 35px;
    padding-bottom: 15px;
  }

  &:first-of-type {
    padding-top: 18px;

    @include breakpoint(desktop) {
      padding-top: 17px;
    }
  }

  h2 {
    margin-bottom: 0;
    line-height: 30px;
    font-weight: normal;
  }
}
