@import 'Styling/import.scss';

.header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  .icon {
    margin-right: 14px;
    height: 28px;
  }
  .title {
    flex: 1;
    font-size: 20px;
    line-height: 28px;
    color: $black;
    margin-right: 5px;
    @include breakWord();
    .titleExpand {
      font-size: 16px;
      color: $gray15;
    }
  }
}

.deleteIcon {
  margin-left: 13px;
}

@include breakpoint('desktop') {
  .header {
    margin-bottom: 25px;
    .icon {
      margin-right: 9px;
    }
  }
}
