@import 'Styling/import.scss';

.mainContainer {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $white;
  padding-bottom: 96px;
  @include breakpoint('1160px') {
    padding-bottom: 200px;
    margin-top: 100px;
  }

  h2 {
    margin: 0;
  }

  .mainTitleStyle {
    text-align: center;
    font-size: 41px;
    line-height: 58px;
    font-family: 'DMSerifDisplay', sans-serif;
    font-weight: normal;
    padding: 0 30px 30px 30px;
    text-shadow: $text-shadow-1;
    @include breakpoint('1160px') {
      font-size: 58px;
      padding: 0;
      padding-bottom: 30px;
    }
  }

  .stepsContainer {
    display: flex;
    flex-direction: column;
    gap: 80px;
    @include breakpoint('900px') {
      flex-direction: row;
      align-items: flex-start;
    }

    .stepsItem {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 258px;
      @include breakpoint('900px') {
        width: 240px;
      }
      @include breakpoint('1160px') {
        width: 258px;
      }
      .numberAndTitle {
        width: 294px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        .titleFixPos {
          padding-bottom: 25px;
        }
      }
      .numberStyle {
        font-size: 50px;
        color: $orange;
      }
      .titleStyle {
        text-align: center;
        font-family: 'Roboto', sans-serif;
        font-weight: 900;
        font-size: 18px;
        margin: 0;
        @include breakpoint('1160px') {
          font-size: 24px;
        }
      }
      .paragraphStyle {
        font-family: 'Roboto', sans-serif;
        font-weight: 900;
        font-size: 18px;
        text-align: center;
        @include breakpoint('1160px') {
          font-size: 24px;
        }
      }
    }
  }
  .buttonContainer {
    display: flex;
    margin-top: 36px;

    .button {
      width: 160px;
      height: 42px;
      padding-left: 10px;
      padding-right: 10px;
    }

    @include breakpoint('900px') {
      .button {
        width: 250px;
        height: 60px;
      }
    }
  }
}
