@import 'Styling/import.scss';

.textField {
  width: 100%;
  position: relative;
  &.minWidth,
  &.mobileGrey {
    min-height: 94px;
  }

  .horizontalLabelInputWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: $white;
    border-radius: 10px;
    padding-left: 20px;
    border: solid 1px $input-border;

    &.withError {
      border: 1px solid $error-border;
      &:hover,
      &:focus {
        border: solid 1px $error-border;
      }
    }

    &:hover,
    &:focus {
      border: solid 1px $input-focus-border;
    }
    &:active {
      border: solid 1px $input-active-border;
    }

    label {
      color: $grey2;
      font-weight: bold;
    }

    input {
      &::placeholder {
        color: $grey1;
        font-style: italic;
        font-weight: normal;
      }
    }
  }
}

.textInput {
  border-radius: 10px;
  background-color: $white;
  height: 64px;
  color: $grey2;
  width: 100%;
  font-size: 16px;
  line-height: 40px;
  padding: 12px 20px;
  border: solid 1px $input-border;

  &:focus {
    outline: none;
  }
  &.withLabel {
    padding-top: 36px;
  }
  &:hover,
  &:focus {
    border: solid 1px $input-focus-border;
  }
  &:active {
    border: solid 1px $input-active-border;
  }
}

.textLabel {
  position: absolute;
  padding: 10px 0 0 20px;
  font-size: 14px;
  color: $grey1;
  pointer-events: none;
}

.error {
  color: $red-error;
}

.inputError {
  border: $error-border solid 1px;
  padding-right: 50px;

  &:hover,
  &:focus {
    border: solid 1px $error-border;
  }
}

.horizontalLabelInput {
  padding-left: 0;
  border: none;
  &:hover,
  &:focus {
    border: none;
  }
}

.errorIcon {
  position: absolute;
  right: 20px;
  top: 20px;
  color: $red-error;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $grey1;
  opacity: 1; /* Firefox */
  font-size: 14px;
  font-style: italic;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $grey1;
  font-size: 14px;
  font-style: italic;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $grey1;
  font-size: 14px;
  font-style: italic;
}

.textField.grey {
  .textInput {
    background-color: $white2;
  }
}

.textField.modal {
  @include breakpoint('desktop') {
    margin: $title-modal-top-margin 0 $title-modal-bottom-margin;
    &:last-child {
      margin: $title-modal-top-margin 0 0;
    }
  }
}

.textField.mobileGrey {
  .textInput {
    @include breakpoint('desktop') {
      background-color: $white;
    }
  }
}
.textField.questions-input {
  .textInput {
    border-radius: 10px;
    background-color: $white;
    height: 64px;
    color: $grey2;
    width: 100%;
    font-size: 16px;
    line-height: 40px;
    padding: 12px 20px;
    border: solid 1px $input-border;
    &:focus {
      outline: none;
    }
    &.withLabel {
      padding-top: 36px;
    }
    &:hover,
    &:focus {
      border: solid 1px $input-focus-border;
    }
    &:active {
      border: solid 1px $input-active-border;
    }
  }
}
.styledPlaceholderTextField {
  position: relative;

  span {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);
    color: $grey1;
    font-style: italic;
    font-size: 14px;

    &::first-letter {
      color: $grey2;
      font-weight: bold;
    }
  }
}

.textField.disabled {
  .textInput {
    color: $gray-opacity-5;
  }
}

.textField.disabledStrong {
  .textInput {
    background-color: $gray42;
    color: $gray-opacity-5;
  }
}

.disabledTransparent {
  input {
    background-color: $grey1-opacity-1;
  }
}
