@import 'Styling/import.scss';

.titleSectionContainer {
  display: flex;
  justify-content: space-between;
  padding-bottom: 21px;
  border-bottom: 1px solid $gray38;
  margin-top: 35px;

  @include breakpoint('toDesktop') {
    margin-top: 39px;
    padding-bottom: 19px;
  }

  .titleContainer {
    min-width: 60%;
  }

  .scoreTable {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    align-self: end;

    @include breakpoint('toDesktop') {
      display: none;
    }
  }
}
