@import 'Styling/import.scss';

.modal {
  @include breakpoint('desktop') {
    width: 570px;
    :global {
      .content {
        padding: 29px 54px;
      }
    }
  }
}
.modalDetails {
  @include breakpoint('desktop') {
    width: 720px;
    :global {
      .content {
        padding: 25px 35px 20px;
      }
    }
  }
  &.modalDetailsWithoutFooter {
    @include breakpoint('desktop') {
      :global {
        .content {
          padding-bottom: 50px;
          border-bottom-left-radius: 30px;
          border-bottom-right-radius: 30px;
        }
        .footer {
          border: none;
        }
      }
    }
  }
}

.containerInterview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .text {
    font-size: 18px;
    text-align: center;
    line-height: 30px;
    color: $grey2;
    font-family: 'DMSerifDisplay', serif;
    margin-bottom: 26px;
    display: flex;
  }

  .time {
    display: flex;
    align-items: center;
    background-color: $yellow;
    border-radius: 8px;
    justify-content: flex-start;
    width: 308px;
    margin-bottom: 30px;
    padding-left: 20px;
  }

  .timeText {
    padding-left: 20px;
    font-size: 14.5px;
    color: $white;
  }

  .icon {
    color: $white;
    margin-top: 15px;
    margin-bottom: 20px;
  }
  .buttons {
    display: flex;
  }

  .line {
    padding: 0px 15px;
    color: $darkblue;
  }
  .button {
    color: $darkblue;
    font-size: 14px;
    font-weight: bold;
  }
}

.containerInterviewDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .titleContainer {
    display: flex;
    padding-bottom: 20px;
    border-bottom: 1px solid $silver;
    align-items: center;
    width: 100%;
    .avatar {
      border-radius: 50%;
      height: 50px;
      width: 50px;
      margin-right: 20px;
    }
    .title {
      color: $grey2;
      font-family: 'DMSerifDisplay', serif;
      font-size: 24px;

      width: 100%;
    }
  }

  .button {
    color: $darkblue;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 5px;
    text-transform: uppercase;
    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
    @include breakpoint('tablet') {
      margin-top: 17px;

      margin-left: 20px;
    }
  }

  .item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    border-bottom: 1px solid $grey1;

    .itemLeft {
      display: flex;
      align-items: center;
      padding-top: 0px;
      min-width: 150px;
      @include breakpoint('tablet') {
        min-width: 256px;
      }

      .icon {
        display: none;
        color: $silver2;
        margin-right: 20px;
        @include breakpoint('tablet') {
          display: block;
        }
      }

      .itemLeftText {
        color: $brown;
        font-size: 14px;
        padding: 20px 0px;
      }
    }

    .itemRight {
      @include breakpoint('tablet') {
        display: flex;
      }
      .itemRightText {
        font-size: 14px;
        font-weight: bold;
        padding: 20px 0px;
        color: $brown;
      }

      .expirationValueNormal {
        font-size: 14px;
        padding: 20px 0px;
        color: $brown;
        font-weight: bold;

        .date {
          font-weight: normal;
        }
      }
      .expirationValueContainer {
        display: flex;
        font-size: 14px;
      }
      .expirationValueText {
        font-size: 14px;
        padding: 20px 0px;
        color: $brown;
      }
      .expirationValueTextRed {
        color: $red-cinnabar;
        font-weight: bold;
        padding: 20px 4px 20px 0px;
      }
      .rejectionValue {
        font-size: 14px;
        padding: 20px 0px;
        color: $brown;
        font-weight: bold;
      }

      .videoText {
        font-weight: bold;
        font-size: 14px;
        color: $brown;
        background-color: $pink456;
        border-radius: 8px;
        border: $gray18 1px solid;
        width: 155px;
        padding: 6px;
        margin-top: 12px;
      }
      .pending {
        font-weight: bold;
        font-size: 14px;
        color: $pending_text;
        background-color: $pending_background;

        border-radius: 8px;
        border: $gray18 1px solid;
        width: 155px;
        padding: 6px;
        margin-top: 12px;
      }
      .accepted {
        color: $green68455348;
        background-color: $green43545434;
      }
      .expired {
        color: $black315451354;
        background-color: $white43535135;
      }
      .redStatus {
        color: $red384354354;
        background-color: $red35123534;
      }

      .completed {
        color: $green68455348;
        background-color: $green43545434;
      }
      .done {
        color: $white;
        background-color: $blue12;
      }

      .newTimeProposal {
        color: $brown12313213;
        background-color: $yellow251515;
      }
    }
  }
  .dateTime {
    font-size: 14px;
    font-weight: bold;
    padding: 15px 0px;
    line-height: 20px;
    color: $brown;
    @include breakpoint('tablet') {
      display: flex;
    }
    .line {
      display: none;
      @include breakpoint('tablet') {
        display: block;
      }
    }
  }
  .paragraphMessage {
    color: $brown;
    font-size: 14px;
    margin-top: 15px;
  }

  .dateTimeLeft {
    color: $brown;
    font-size: 14px;
    padding: 15px 0px;
    line-height: 20px;
    @include breakpoint('tablet') {
      display: flex;
    }
  }
}
