@import 'Styling/import.scss';

.layout {
  background-image: url('/Assets/Images/background-man-Mobile-428-px.jpg');
  width: 100%;
  height: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  @include breakpoint(tablet) {
    flex-direction: row;
  }

  @include breakpoint(428px) {
    background-image: url('/Assets/Images/background-man-Mobile-900-px.jpg');
  }
  @include breakpoint(900px) {
    background-image: url('/Assets/Images/background-man-1160-px.jpg');
  }
  @include breakpoint(1160px) {
    background-image: url('/Assets/Images/background-man-1440-px.jpg');
  }
  @include breakpoint(1440px) {
    background-image: url('/Assets/Images/background-man-1920-px.jpg');
  }
  @include breakpoint(1920px) {
    background-image: url('/Assets/Images/background-man-2560-px.jpg');
  }
  @include breakpoint(2560px) {
    background-image: url('/Assets/Images/background-man-3000-px.jpg');
  }
}

.container {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(20px);

  h1 {
    font-weight: normal;
  }

  .containerSecond {
    padding: 0px 25px 40px 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .image {
    // TO DO when images arrived
    background-color: aliceblue;
    width: 100%;
    height: 196px;
    align-self: center;
    @include breakpoint(tablet) {
      width: 294px;
      align-self: flex-start;
    }
    @include breakpoint(1920px) {
      width: 491px;
      height: 328px;
    }
  }

  .section {
    border-bottom: 1px solid $yellow24523;
    padding-bottom: 10px;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    @include breakpoint(tablet) {
      flex-direction: row;
      padding-bottom: 35px;
    }
    @include breakpoint(desktop) {
      width: 958px;
    }
    @include breakpoint(1920px) {
      width: 1520px;
    }
    .sectionLeft {
      @include breakpoint(tablet) {
        padding-left: 50px;
      }
    }
  }

  .sectionBig {
    display: flex;
    flex-direction: column;

    padding-bottom: 10px;
    padding-top: 40px;
    .sectionUp {
      @include breakpoint(tablet) {
        display: flex;
      }
    }
    .sectionLeft {
      @include breakpoint(tablet) {
        padding-left: 50px;
      }
    }
    @include breakpoint(desktop) {
      width: 958px;
    }
    @include breakpoint(1920px) {
      width: 1520px;
    }
  }

  .titleContainer {
    position: relative;
    margin-top: 100px;
    @include breakpoint(tablet) {
      margin-top: 150px;
      margin-bottom: 60px;
    }
    .title {
      font-family: 'DMSerifDisplay', serif;
      font-size: 24px;
      color: $white;
      text-align: center;
      text-transform: uppercase;
      z-index: 2;
      position: relative;

      @include breakpoint(tablet) {
        font-size: 45px;
      }
    }
  }

  .paragraphTitle {
    font-size: 18px;
    font-weight: bold;
    color: $white;
    padding: 24px 0px;
    @include breakpoint(tablet) {
      padding: 0px 0px 7px;
      font-size: 28px;
    }
  }
  .paragraphTitleGrid {
    font-size: 18px;
    font-weight: bold;
    color: $white;
    padding: 24px 0px;
    @include breakpoint(tablet) {
      padding: 0px 0px 54px;
      font-size: 28px;
    }
  }

  .paragraph {
    font-size: 14px;
    line-height: 24px;
    color: $white;
  }

  .showMore {
    border: none;
    color: $white;
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  @include breakpoint(tablet) {
    margin-top: 45px;
    grid-template-columns: 1fr 1fr;
    grid-gap: 150px;
    grid-row-gap: 120px;

    div:nth-child(1) {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        bottom: -50px;
        left: 0;
        width: 100%;
        border-bottom: 1px solid $yellow3;
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 0px;
        right: -78px;
        height: 100%;
        width: 100%;
        border-right: 1px solid $yellow3;
      }
    }
    div:nth-child(2) {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        bottom: -50px;
        left: 0;
        width: 100%;
        border-bottom: 1px solid $yellow3;
      }
      @include breakpoint(1920px) {
        &::after {
          content: '';
          position: absolute;
          bottom: 0px;
          right: -78px;
          height: 100%;
          width: 100%;
          border-right: 1px solid $yellow3;
        }
      }
    }
    div:nth-child(3) {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        bottom: -50px;
        left: 0;
        width: 100%;
        border-bottom: 1px solid $yellow3;
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 0px;
        right: -78px;
        height: 100%;
        width: 100%;
        border-right: 1px solid $yellow3;
      }
    }
    div:nth-child(4) {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        bottom: -50px;
        left: 0;
        width: 100%;
        border-bottom: 1px solid $yellow3;
      }
      @include breakpoint(1920px) {
        &::after {
          content: '';
          position: absolute;
          bottom: 0px;
          right: -78px;
          height: 100%;
          width: 100%;
          border-right: 1px solid $yellow3;
        }
      }
    }
    div:nth-child(5) {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0px;
        right: -78px;
        height: 100%;
        width: 100%;
        border-right: 1px solid $yellow3;
      }
    }
  }
  @include breakpoint(1920px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 150px;
  }
}
