@import 'Styling/import.scss';

.questionContainer {
  border-top: 1px solid $cyan-blue;

  .questionWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 0;
    cursor: pointer;

    p {
      font-size: 14px;
      font-weight: bold;
    }
  }

  .answerWrapper {
    margin-top: 8px;
    margin-bottom: 17px;

    @include breakpoint(desktop) {
      margin-top: 15px;
      margin-bottom: 33px;
    }

    p {
      font-size: 14px;
      white-space: pre-wrap;
    }
  }
}
