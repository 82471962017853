@import 'Styling/import.scss';
.top {
  height: 200px;
  position: relative;
  background-color: $black;
  .bg {
    background-image: url('/Assets/Images/public-profile-header-image.png');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    opacity: 0.7;
  }
}
.main {
  position: relative;
  background-color: $white;
}
.editBtn {
  position: absolute;
  right: 20px;
  top: 20px;
}
.left {
  font-size: 14px;
  border-bottom: solid 1px $onb-divider-color;
  background: $white;
  margin: 0 22px;
  position: relative;
}
.title {
  font-family: 'DMSerifDisplay', serif;
  font-size: 36px;
  color: $black;
  padding-top: 90px;
}
.textContainer {
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  color: $black;
  padding-top: 16px;
  @include breakWord();
  @include breakpoint('desktop') {
    padding-right: 25px;
  }
  .greyText{
    color: $gray15;
  }
}
.locationWrap {
  display: flex;
  align-items: flex-start;
  .streetText {
    margin-top: -4px;
  }
}
.birthday {
  display: flex;
  padding-top: 15px;
  padding-bottom: 26px;
}
.birthdayText {
  padding-left: 7px;
  font-size: 14px;
}
.birthdayIcon {
  margin-bottom: -2px;
}
.birthdayLockIcon {
  padding-left: 5px;
}
.right {
  font-size: 14px;
  background: $white;
  padding-left: 22px;
  padding-bottom: 25px;
}
.street {
  display: flex;
  padding-top: 15px;
  line-height: 30px;
  padding-bottom: 15px;
}
.streetText {
  padding-left: 15px;
  padding-right: 5px;
}
.streetIcon {
  margin-left: 5px;
}
.mail {
  display: flex;
  line-height: 25px;
  padding-bottom: 15px;
}
.mailText {
  padding-left: 15px;
}
.phone {
  display: flex;
  line-height: 25px;
}
.phoneNumber {
  padding-left: 15px;
}
.icon {
  line-height: 25px;
  padding-left: 5px;
}
.avatarHolder {
  position: absolute;
  top: -130px;
  left: 50%;
  margin-left: -85px;
}
.profileAvatar {
  width: 170px;
  height: 170px;

  > span {
    line-height: 170px;
    font-size: 80px;
  }
}

@include breakpoint('desktop') {
  .top {
    height: 165px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;
  }
  .main {
    justify-content: center;
    display: flex;
    border-radius: 8px;
  }
  .title {
    letter-spacing: 1.8px;
    padding-left: 30px;
    margin-top: 60px;
    padding-top: 0;
    font-family: 'DMSerifDisplay', serif;
    font-weight: 400;
  }
  .right {
    padding-top: 120px;
    border-radius: 0 0 8px 0;
    width: 50%;
    padding-left: 0;
  }
  .left {
    padding-top: 10px;
    border-bottom: none;
    border-radius: 0 0 0 8px;
    margin: 0;
    padding-bottom: 42px;
    width: 50%;
  }
  .street {
    padding-top: 5px;
    margin-top: 10px;
  }
  .mail {
    padding-bottom: 10px;
  }
  .phone {
    padding-top: 15px;
  }
  .street,
  .mail,
  .phone {
    border-left: solid 1px $onb-divider-color;
    padding-left: 19px;
  }
  .streetText {
    padding-left: 10px;
    padding-right: 55px;
  }
  .textContainer {
    padding-top: 0px;
    margin-top: 13px;
  }
  .leftContainer {
    padding-left: 30px;
  }
  .avatarHolder {
    top: -125px;
    left: 30px;
    margin-left: 0;
  }
  .editBtnDesktop {
    display: inline;
  }
}

.modal {
  @include breakpoint('desktop') {
    min-width: 80%;
  }
}

textarea.editAboutMeTxt.editAboutMeTxtArea {
  height: 140px;
}

.modalLegend {
  color: #9ca2aa;
  font-size: 14px;
  display: flex;
  align-items: center;
  > span {
    display: inline-block;
    margin-left: 10px;
    margin-top: 3px;
  }
}
