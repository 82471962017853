@import 'Styling/import.scss';

.downloadAndPrintHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 33px;
  padding-bottom: 60px;

  @include breakpoint('toDesktop') {
    justify-content: center;
    flex-direction: column;
    padding-bottom: 29px;
  }

  .contentLogoWrap {
    .logo {
      width: 150px;
    }
  }

  .iconsWrapper {
    display: flex;
    align-items: center;

    @include breakpoint('toDesktop') {
      justify-content: flex-end;
      width: 100%;
      margin-top: 25px;
    }

    .iconTextWrapper {
      display: flex;
      align-items: center;
      cursor: pointer;

      a {
        display: flex;
        align-items: center;

        @include breakpoint('toDesktop') {
          margin-right: 20px;
        }

        @include breakpoint('desktop') {
          margin-right: 29px;
        }
      }

      .icon {
        color: $gray10;
        font-size: 24px;

        @include breakpoint('desktop') {
          margin-right: 9px;
        }

        &Title {
          color: $gray12;
          text-decoration: underline;
          font-size: 12px;
          text-transform: uppercase;

          &:hover,
          &:focus,
          &:active { 
            text-decoration: none;
          }
          @include breakpoint('toDesktop') {
            display: none;
          }
        }
      }
    }
  }
}
