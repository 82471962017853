@import 'Styling/import.scss';

.noActiveCandidateModal {
  @include breakpoint('desktop') {
    max-width: 420px;
  }

  :global {
    .content {
      font-size: 14px;
      line-height: 1.57;
      color: $grey2;
      padding: 30px 29px 28px 30px;
    }
  }
  div > div ~ button {
    display: none;
  }
  .infoWrapper {
    display: flex;
    flex-direction: column;
    ul {
      padding-left: 17px;
    }
    li {
      list-style-type: disc;
      padding-right: 0px;
    }
  }
}
