@import 'Styling/import.scss';

.container {
  position: relative;
  width: 100% !important;
  border-radius: 10px !important;

  .label {
    position: absolute;
    font-size: 14px;
    color: $grey1;
    top: 12px;
    left: 21px;
  }
}

.button {
  border: none !important;
  border-radius: 10px !important;

  ul div {
    border-radius: 0px !important;
  }

  div {
    background-color: $white2 !important;
    border-radius: 10px !important;

    div {
      border-radius: 50px !important;
      width: 24px !important;
      height: 24px !important;
      margin-top: -2px !important;
      margin-left: 13px !important;

      div {
        width: 7px !important;
        height: 4px !important;
        border-radius: 0% !important;
        left: 15px !important;
      }
    }
  }
}

.input {
  width: calc(100% - 20px) !important;
  height: 64px !important;
  padding-top: 20px !important;
  background-color: $white2 !important;
  border: none !important;
  font-size: 16px !important;
  color: $grey2;
  border-radius: 10px !important;
  margin-left: 20px !important;
}

.error {
  color: $red-error !important;
}
.errorBorder {
  border: 1px solid $error-border;
  border-radius: 8px;
  &:hover,
  &:focus {
      border: 1px solid $error-border;
  }
  &:active {
      border: 1px solid $error-border;
   }
}

.container {
  border-radius: 10px !important;
}

.dropdown {
  border-radius: 10px !important;
  padding: 14px 0px !important;
  box-shadow: 0 0 15px 2px $gray9;
  li {
    padding: 10px 9px 7px 74px !important;
    div {
      top: 6px !important;
      left: 40px !important;
    }
    color: $grey2;
    font-size: 14px;
    height: 38px;
    padding: 0px 18px;
    &:nth-child(7) {
      height: 0px !important;
    }
    &:hover {
      color: $black;
      background-color: $white2;
    }
  }
}
