@import 'Styling/import.scss';

.testNameWrapper {
  display: flex;
  align-items: center;
  margin-top: 20px;

  @include breakpoint('desktop') {
    margin-top: 0;
    padding-left: 18px;
  }

  .testName {
    display: flex;
    flex-wrap: wrap;
    margin-left: 19px;
    color: $blue1;
    font-weight: 500;
    line-height: 16px;
  }

  .iconDesktop {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $yellow2;
  }
}
