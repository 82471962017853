@import 'Styling/import.scss';

.container {
  ol {
    li {
      list-style-type: decimal;
    }
  }
  li {
    list-style: disc;
    list-style-position: inside;
  }
}
