@import 'Styling/import.scss';

.actionWrap {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  justify-content: flex-start;

  @include breakpoint(1500px) {
    justify-content: flex-end;
  }
 .moreAction {
   color: $gray30;
   transition: .3s fill linear;
  }

  .mainAction {
    font-size: 14px;
    min-width: 190px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9px;
    background-color: $white2;
    border: solid 1px $gray31;
    white-space: nowrap;
    cursor: pointer;

    @include breakpoint(1500px) {
      padding-left: 0;
    }
  }

  .mainActionEnabled {
    padding: 7px 15px;
    cursor: pointer;
    color: $blue1;
  }

  .mainActionDisabled {
    padding: 7px 15px;
    background-color: $white;
    color: $gray12;
    position: relative;
    user-select: none;
    cursor: not-allowed;

    .leftIcon {
      svg {
        color: $green3;
        font-size: 14px;
        position: absolute;
        top: 12px;
        left: -7px;
        background-color: $white;
        border-radius: 100%;
      }
    }
    .rightIcon {
      display: none;

      @include breakpoint(1500px) {
        display: block;
      }
      svg {
        color: $black-opacity-3;
        cursor: pointer;
        font-size: 14px;
        position: absolute;
        top: 12px;
        right: 12px;
      }
    }

    .greenColor {
      svg {
        color: $green3;
      }
    }
    .yellowColor {
      svg {
        color: $yellow;
      }
    }
    .redColor {
      svg {
        color: $red-error;
      }
    }
    .pinkColor {
      svg {
        color: $pink;
      }
    }
  }
}
