@import 'Styling/import.scss';

.edit {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @include breakpoint('desktop') {
    margin-right: 215px;
    min-width: 260px;
  }
}

.editLevel {
  padding-left: 20px;
  color: $gray227;
  font-size: 14px;
}

.darkerText {
  color: $black;
}
