@import 'Styling/import.scss';

.onboarding {
  display: block;
  @include clearFix();
  .main {
    float: left;
    display: block;
    width: 100%;
  }
  .rightColumn {
    float: left;
    display: none;
    width: 360px;
    height: 100%;
    background-color: $black3;
    position: fixed;
    top: 0;
    right: 0;
  }
  .inner {
    padding: 0 42px;
    .title {
      font-size: 24px;
      line-height: 1;
      text-align: center;
      font-family: 'DMSerifDisplay', serif;
      color: $black;
      padding-top: 30px;
    }
    .description {
      margin: 23px 0;
      font-size: 14px;
      line-height: 1;
      text-align: center;
      color: $black2;
    }
    .hr {
      margin: 40px 0 0;
    }
  }
  .link {
    text-decoration: underline;
    text-decoration-color: currentColor;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    line-height: 1.29;
    color: $black2;
  }
  .logo {
    text-align: center;
    margin: 34px 10px 24px;
    img {
      height: 17px;
    }
  }

  .desktopSteps {
    margin-top: 220px;
    .desktopStep {
      margin-top: 21px;
      @include clearFix();
      &.filled {
        .stepCnt {
          background-color: $orange1;
          border: 1px solid $orange1;
          color: $black3;
        }
      }

      .stepCnt {
        height: 46px;
        width: 46px;
        border-radius: 23px;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        line-height: 46px;
        color: $white;
        background-color: $black3;
        border: 1px solid $white;
        float: left;
        display: block;
        margin-left: 20px;
      }
      .stepName {
        float: left;
        display: block;
        margin-left: 20px;
        line-height: 46px;
        font-size: 16px;
        color: $white;
      }
    }
  }
}
.footer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;
}

.button {
  width: 386px;
}
.title {
  color: $white;
  font-family: 'DMSerifDisplay', serif;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 23px;
}

.form {
  width: 100%;
  margin-bottom: 18px;
}

.inputWrap {
  margin-top: 40px;
}
@include breakpoint('desktop') {
  .onboarding {
    .main {
      width: calc(100% - 360px);
    }
    .rightColumn {
      display: block;
    }
    .inner {
      padding: 107px 10% 0px 10%;
      .title {
        font-size: 36px;
        margin-top: 18px;
      }
      .description {
        margin: 28px 0;
        font-size: 14px;
      }
      .link {
        font-size: 14px;
      }
    }
    .logo {
      margin: 51px 0 0 42px;
      text-align: left;
    }
  }
  .inputWrap {
    margin-bottom: 31px;
    .cardCheckInput {
      > svg {
        top: auto;
      }
      > div {
        font-size: 14px;
      }
    }
  }

  .title {
    font-size: 36px;
    margin-bottom: 21px;
  }
  .footer {
    flex-direction: row;
    gap: 179px;
    margin-top: 77px;
  }
}
