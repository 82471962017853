@import 'Styling/import.scss';

.container {
  display: flex;
  border-bottom: 1px solid $gray23;
  padding-bottom: 7px;
  justify-content: flex-start;
  align-items: center;

  @include breakpoint('toDesktop') {
    padding-bottom: 20px;
  }

  .avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .avatarLetters {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $yellow3;
    min-width: 32px;
    min-height: 32px;
    border-radius: 50%;
    margin-right: 10px;
    padding-top: 2px;

    @include breakpoint('toDesktop') {
      min-width: 50px;
      min-height: 50px;
      margin-right: 19px;
      font-size: 20px;
    }
  }

  .containerText {
    display: flex;
    flex-direction: column;

    .content {
      display: flex;

      .nameUser {
        color: $blue2;
        font-size: 14px;
        font-weight: bold;
        display: inline-block;
      }

      .comma {
        font-size: 14px;
        color: $black;
        font-weight: normal;
      }

      .occupation {
        font-size: 14px;
        color: $black;
      }
    }
    .companyName {
      font-size: 14px;
      margin-top: 3px;
      color: $gray-cyan;
      line-height: 18px;
    }
  }

  .moreIconBtn,
  .closeButtonContainer {
    margin-left: auto;
  }

  .closeButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white2;
    border: 1px solid $gray29;
    border-radius: 8px;
    width: 39px;
    height: 39px;

    &:hover {
      cursor: pointer;
    }
  }

  .closeButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  }
}
