@import '/src/Styling/import';

.modal {
  :global {
    .content {
      background-color: $white;
    }
  }

  .contentHeading {
    font-family: 'DMSerifDisplay', serif;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 40px;
  }
}

.contentWrap {
  height: 300px;
}

@include breakpoint('desktop') {
  .contentWrap {
    height: 200px;
    width: 800px;
  }
}
