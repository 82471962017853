@import 'Styling/import.scss';

.wrap {
  width: 100%;

  .input {
    color: $grey2;
    border: none;
    background-color: $white2;
    border-radius: 8px;
    height: 48px;
    width: 100%;
    margin-top: 9px;
    padding: 17px;

    &:focus {
      outline: none;
      border: solid 1px $gray39;
    }
    &:hover { 
      border: solid 1px $gray39;
    }
    &:active { 
      border: solid 1px $blue2;
    }
    &::placeholder {
      color: $grey2;
      font-size: 16px;
    }
  }

  .recipientItemsWrap {
    position: relative;
    width: 100%;
    max-height: 260px;
    overflow-y: auto;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
    border-radius: 6px;

    &::-webkit-scrollbar {
      border: none;
      background-color: transparent;
      width: 0;
    } /* webkit browsers(safari, chrome) */

    scrollbar-width: none; /*firefox*/
  }

  .noResults {
    display: flex;
    background: $white;
    padding: 11px 20px;
    border-top: 1px solid $white3;

    .messageParticipantPictureWrap {
      margin-right: 19px;
    }
  }
}
