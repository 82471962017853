@import 'Styling/import.scss';

.main {
  height: 100%;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-image: url('/Assets/Images//homepage/candidate-homepage_350px.jpg');
  @include breakpoint('420px') {
    background-image: url('/Assets/Images//homepage/candidate-homepage_420px.jpg');
  }
  @include breakpoint('900px') {
    background-image: url('/Assets/Images/homepage/candidate-homepage_900px.jpg');
  }
  @include breakpoint('1160px') {
    background-image: url('/Assets/Images/homepage/candidate-homepage_1160px.jpg');
  }

  @include breakpoint('1440px') {
    background-image: url('/Assets/Images/homepage/candidate-homepage_1440px.jpg');
  }
  @include breakpoint('1920px') {
    background-image: url('/Assets/Images/homepage/candidate-homepage_1920px.jpg');
  }
  @include breakpoint('2560px') {
    background-image: url('/Assets/Images/homepage/candidate-homepage_2560px.jpg');
  }
  @include breakpoint('3000px') {
    background-image: url('/Assets/Images/homepage/candidate-homepage_3000px.jpg');
  }
  @include breakpoint('4000px') {
    background-image: url('/Assets/Images/homepage/candidate-homepage_4000px.jpg');
  }
  .content {
    margin: 0 auto;
    width: 100%;
    padding: 0 30px;

    @include breakpoint('900px') {
      padding: 0 45px;
    }

    @include breakpoint('1160px') {
      max-width: 1020px;
      padding: 0;
    }
    @include breakpoint('1920px') {
      max-width: 1350px;
    }
  }
}
