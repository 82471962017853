@import 'Styling/import.scss';

.testResultsContainer {
  width: 100%;
  h3 {
    font-size: 14px;
    line-height: 24px;
    color: $orange;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  p {
    font-size: 14px;
    text-align: center;
  }
}
