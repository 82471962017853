@import 'Styling/import.scss';

.comments {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  .title {
    font-size: 14px;
    font-style: italic;
    color: $gray36;
  }
}
