@import '/src/Styling/import';

.container {
  height: 100%;
}

.checkBoxGroup {
  position: relative;
  padding: 5px 0;
  border: rgba(255, 255, 255, 0) 1px solid;
  display: grid;
  height: inherit;
  grid-auto-flow: column;
  grid-template-columns: 1fr;
  @include breakpoint(768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include breakpoint(1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.checkBox {
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 20px;
}

.checkBoxLabel {
  text-align: start;
  font-size: 14px;
  color: $grey2;
  line-height: 18px;
  cursor: pointer;
  margin-left: 18px;
  margin-right: 18px;
}

.hidden {
  display: none;
}

.error {
  border: 1px solid $red-error2;
}

.errorIcon {
  color: $red-error2;
}
