@import 'Styling/import.scss';

.text {
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  color: $black;
  padding-right: 33px;
}

.textField {
  margin-bottom: 30px;
  @include breakpoint('desktop') {
    margin-bottom: 40px;
  }
}

.modal {
  @include breakpoint('desktop') {
    min-width: 80%;
  }
}

.employer {
  margin-bottom: 30px;
  @include breakpoint('desktop') {
    margin-bottom: 40px;
  }
}

.employmentDateUp {
  margin-bottom: 30px;
  @include breakpoint('desktop') {
    display: flex;
    margin-bottom: 0px;
  }
  & > div {
    margin-bottom: 20px;
    width: 100%;
    @include breakpoint('desktop') {
      &:first-child {
        margin-right: 30px;
      }
    }
  }
}
.employmentDateDown {
  padding-top: 30px;
  border-top: $white 1px solid;
  @include breakpoint('desktop') {
    display: flex;
    padding-top: 0px;
    border-top: none;
  }
  & > div {
    margin-bottom: 20px;
    width: 100%;
    @include breakpoint('desktop') {
      &:first-child {
        margin-right: 30px;
      }
    }
  }
}

.employerCurrent {
  &Label {
    display: flex;
    margin-bottom: 20px;
    font-size: 14px;
    color: $brown;
    line-height: 22px;
  }
}

.workExperienceItemsWrap {
  .sliderWrap {
    padding-right: 20px;
    @include breakpoint('desktop') {
      padding: 0;
    }
  }

  .workExperienceItem {
    display: grid;
    align-items: center;
    grid-template-rows: 1fr 1fr;

    @include breakpoint('desktop') {
      grid-template-rows: none;
      grid-template-columns: 1fr 1fr;
      margin-left: 30px;
      margin-right: 40px;
    }
  }
}

.editButton {
  position: absolute;
  right: 20px;
  top: 20px;
}

.addSliderWrap,
.editWorkExperienceItem {
  background-color: $white;
  border-radius: 8px;
  padding: 20px 25px 20px 20px;
}

.editWorkExperienceItem {
  display: grid;
  align-items: center;
  grid-template-rows: 1fr 1fr auto;
  margin-top: 13px;
  &:first-child {
    margin-top: 8px;
  }

  .experienceTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include breakpoint('desktop') {
      .deleteAction {
        display: none;
      }
    }
  }

  .deleteActionDesktop {
    padding-left: 45px;
    display: none;
    @include breakpoint('desktop') {
      display: block;
    }
  }

  .sliderWrap {
    padding-right: 20px;
  }

  @include breakpoint('desktop') {
    grid-template-rows: none;
    grid-template-columns: 1fr 1fr auto;

    .sliderWrap {
      padding: 0;
    }
  }
}
