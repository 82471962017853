@import '/src/Styling/import.scss';

.dropdownContainer {
  position: relative;
  cursor: pointer;

  .hidden {
    display: none;
  }

  .dropdownBTN {
    background-color: $white2;
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 20px;
    border-radius: 8px;
    position: relative;
    z-index: 0;
    border: 1px solid $input-border;
    &:hover,
    &:focus {
      border: 1px solid  $input-focus-border;
    }
    &:active {
      border: 1px solid $input-active-border;
    }

    &.errorBorder {
      border: $error-border solid 1px;
      border-radius: 8px;
      position: relative;
    }

    .dropdownLabel {
      font-size: 14px;
      color: $grey1;
      padding-bottom: 12px;
    }

    .dropdownValue {
      font-size: 16px;
      color: $grey2;
      pointer-events: none;
      cursor: pointer;
      @include ellipsis(1);
    }

    .arrow {
      transform: rotate(0deg);
      transition: 0.2s all ease !important;
    }

    .rotate {
      transform: rotate(180deg);
      transition: 0.2s all ease !important;
    }
  }

  .dropdownList {
    padding: 14px 0;
    border: $gray9 2px solid;
    border-radius: 12px;
    background-color: $white;
    box-shadow: 0 0 15px 2px $gray9;
    position: absolute;
    display: none;
    width: 100%;
    z-index: 30 !important;
    max-height: 250px;
    overflow-y: auto;

    &.open {
      display: block;
    }

    &.listUp {
      top: -250px;
    }

    .itemLabel {
      color: $grey2;
      font-size: 14px;
      position: relative;
      display: inline-flex;
      align-items: center;
      width: 100%;
      min-height: 38px;
      cursor: pointer;
      padding: 7px 18px;

      &:hover {
        color: black;
        background-color: $white2;
      }

      .translation {
        margin: 0 18px;
      }
    }
  }

  &.white, &.whiteShortList {
    @include breakpoint('desktop') {
      margin: $input-modal-top-margin 0 $input-modal-bottom-margin;
      overflow-y: visible;
    }

    .dropdownBTN {
      background-color: $white;
      height: $input-modal-height;
    }
  }

  &.whiteShortList {
    .dropdownList {
      height: 140px;
      &.listUp {
        top: -140px;
      }
    }
  }

  &.disabled {
    .dropdownBTN {
      opacity: 0.5;
      background-color: $gray41;
    }
  }

  &.withoutShadow {
    .dropdownBTN {
      height: 64px;
    }
    .dropdownList {
      box-shadow: none;
      border-top-right-radius: 0;
      border-top-left-radius: 0;

      .itemLabel {
        padding: 7px 0;
        span {
          @include ellipsisSimple();
        }
      }
    }
    &.open {
      .dropdownBTN {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}
