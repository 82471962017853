@import 'Styling/import.scss';

.footerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  @include breakpoint(desktop) {
    padding: 0 50px;
  }

  .copyrightNavigationContainer {
    display: flex;
  }

  .footerNavigation {
    display: flex;
  }

  .copyright,
  li {
    font-size: 12px;
    line-height: 22px;
    color: $grey2; 
  }

  li {
    cursor: pointer;
    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }

    span {
      margin: 0 10px;
    }
  }

  .footerNavigation {
    @include breakpoint(mobile) {
      li {
        display: none;

        &:first-of-type {
          display: block;

          span {
            display: none;
          }
        }
      }
    }
  }

  .socialIconsContainer {
    div {
      div {
        &:not(:nth-child(6)) {
          display: none;
        }

        &:nth-child(6) {
          margin-right: 29px;
        }
      }
    }
    @include breakpoint(desktop) {
      div {
        div {
          &:not(:nth-child(6)) {
            display: flex;
          }

          &:nth-child(6) {
            margin-right: 0;
          }
        }
      }
    }
  }
}
