@import 'Styling/import.scss';

.section {
  .content {
    @include breakpoint('desktop') {
      margin-left: 40px;
      margin-right: 40px;
    }
    .mainContent {
      justify-content: left;
    }
  }
}
.mainContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  @include breakpoint('desktop') {
    justify-content: flex-start;
  }
}

.paragraphContent {
  color: $gray15;
  margin-right: 5px;
}
.paragraphMain {
  display: flex;
  font-size: 14px;
  align-items: center;
  .uppercase {
    text-transform: uppercase;
  }
}
.item {
  margin-right: 11px;
  background: $white;
  padding: 2px 5px 2px 5px;
  border: 3px solid $gray26;
  font-size: 14px;
  color: $gray26;
  border-radius: 4px;
  font-weight: bold;
  min-width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.selected {
    background-color: $black;
    color: $white;
    border: 3px solid $black;
  }
}
 .withPointer {
   cursor: pointer;
 }
.hidden {
  display: none;
}

.hr {
  margin: 20px 0;
  @include breakpoint('desktop') {
    margin: 30px 0 20px;
  }
}

.modal {
  .subtitle {
    font-size: 20px;
    margin-bottom: 26px;
    color: $black;
  }
  @include breakpoint('desktop') {
    min-width: 980px;
    .item {
      border: 3px solid $gray15;
      color: $gray15;
      background-color: $gray13;

      &.selected {
        color: $white;
        border: 3px solid $black;
        background-color: $black;
      }
    }
  }
}

.filterTypeWrap {
  padding-left: 8px;

  .filterTypeButton {
    background-color: $white2;
    color: $grey2;
    height: 33px;
    border: none;
    text-transform: none;
    width: 45px;
    &:hover {
        border: none;
        width: 45px;
      }
    &:first-child {
      border-radius: 8px 0 0 8px;
      border-right: 1px solid $icon-gray;

    }

    &:last-child {
      border-radius: 0 8px 8px 0;

    }

    &.filterTypeButtonActive {
      background-color: $yellow3;
      color: $white;

    }
  }
}
