@import 'Styling/import.scss';

.section {
  .content {
    @include breakpoint('desktop') {
      margin-left: 35px;
      margin-right: 35px;
    }
  }
  .textContainer {
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    color: $black;
    @include breakpoint('desktop') {
      padding-right: 33px;
    }
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $onb-divider-color;
  margin: 20px 0;
}
.dividerWhite {
  width: 100%;
  height: 1px;
  background-color: $white;
  margin: 20px 0;
}
.buttonsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
.cancelButton {
  padding-top: 29px;
  color: #505459;
  font-size: 14px;
  text-decoration: underline;
}
.space {
  margin-bottom: 20px;
}
.dropdownsWrapper {
  display: flex;
  flex-direction: column;
  &:first-of-type {
    margin-bottom: 29px;
  }
}
.dropdownWrapper {
  margin-bottom: 30px;

  @include breakpoint(desktop) {
    width: 50%;
    margin-bottom: 0;

    &:first-of-type {
      margin-right: 3.84%;
    }
  }
}
.whiteHr {
  display: block;
  border-top: solid 1px $white;
  margin-top: 0;
  margin-bottom: 30px;

  @include breakpoint(desktop) {
    display: none;
  }
}

.whiteDivider {
  border-bottom: solid 2px $white;
  margin-top: 30px;
  margin-bottom: 30px;
}
.whiteBackgroundTextArea {
  background: $white;
}

@include breakpoint('desktop') {
  .modalContent {
    width: 800px;
    display: flex;
    flex-direction: column;
  }

  .dropContainer {
    display: flex;
  }

  .left {
    display: flex;
    width: 385px;
    margin-top: 30px;
  }
  .right {
    display: flex;
    margin-top: 30px;
  }
}
