@import 'Styling/import.scss';
.title {
  font-family: 'DMSerifDisplay', serif;
  font-size: 24px;
  color: $black3;
  margin: 40px 0px 30px;
  @include breakpoint(tablet) {
    margin-bottom: 20px;
  }
}

.questionsCategory {
  width: 100%;

  background-color: $white;
  border-radius: 8px;
  padding: 20px 30px;

  .showMore {
    margin: 25px 0px 0px;
    color: $gray32;
    font-size: 12px;
    cursor: pointer;
    text-decoration: underline;
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
  }
}
