@import 'Styling/import.scss';

.switchContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 22px;

  @include breakpoint(desktop) {
    display: block;
    margin-bottom: 0;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 88px;
    height: 38px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $gray18;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 3px;
    background-color: $white;
    transition: 0.4s;
    background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2248%22%20width%3D%2248%22%3E%3Cpath%20fill%3D%22%23cdd0d4%22%20d%3D%22m12.45%2037.65-2.1-2.1L21.9%2024%2010.35%2012.45l2.1-2.1L24%2021.9l11.55-11.55%202.1%202.1L26.1%2024l11.55%2011.55-2.1%202.1L24%2026.1Z%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 90%;
  }

  input:checked + .slider {
    background-color: $blue1;
  }

  input:focus + .slider {
    outline: none;
  }

  input:checked + .slider:before {
    transform: translateX(47px);
    background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2248%22%20width%3D%2248%22%3E%3Cpath%20fill%3D%22%23113a60%22%20d%3D%22M18.9%2035.7%207.7%2024.5l2.15-2.15%209.05%209.05%2019.2-19.2%202.15%202.15Z%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 90%;
  }

  .slider.round {
    border-radius: 18.5px;
    width: 88px;
    height: 38px;
  }

  .slider.round:before {
    border-radius: 50%;
    width: 32px;
    height: 32px;
  }
}
