@import 'Styling/import.scss';

.parseCvModal {
  @include breakpoint('desktop') {
    min-width: 980px;
  }

  .title {
    margin-bottom: 25px;
  }

  .grayText {
    font-size: 14px;
    color: $grey3;
    margin-bottom: 25px;
  }

  .warningContainer {
    display: flex;

    p {
      font-size: 14px;
      color: $grey3;
    }

    svg {
      color: $grey3;
      margin-right: 11px;
    }
  }

  .errorWrap {
    display: flex;
    flex-direction: row;

    .errorIcon {
      color: $red-error;
    }
  }
}
