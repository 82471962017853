@import 'Styling/import.scss';

.titleWrap {
  margin-top: 30px;
  display: flex;
  align-items: center;
  .icon {
    font-size: 40px;
    fill: $gray36;
  }
  .title {
    margin-left: 20px;
    font-size: 24px;
    font-family: 'DMSerifDisplay', serif;
  }
}
.hr {
  border-top: 1px solid $gray36;
}
.text {
  font-size: 14px;
  line-height: 22px;
  text-align: justify;

  a {
    color: inherit;
  }
}
