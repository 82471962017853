@use 'sass:color';
@import 'Styling/import';

.regular {
  display: flex;
  flex-direction: column;
  @include breakpoint('tablet') {
    flex-direction: row;
  }
}

.autocomplete {
  background: transparent;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: hidden;
}

.label {
  color: $grey1;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 12px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid $input-border;
  border-radius: 10px;
  height: 68px;
  position: relative;
  background-color: $select-item-background-color;
  z-index: 0;
  padding: 10px 8px 0 20px;

  &.inputWrapperError {
    border-color: $error-text;
  }

  &.inputWrapperFocused,
  &:hover {
    border-color: $input-focus-border;
  }

  &.inputWrapperDisabled {
    border-color: $gray-opacity-5;
    color: $gray-opacity-5;

    label,
    input {
      color: $gray-opacity-5;
    }

    &:hover {
      border-color: $gray-opacity-5;
    }
  }
}

.input {
  width: calc(100% - 26px);
  font-size: 16px;
  line-height: 25px;
  height: 24px;
  outline: none;
  border: none;
  background-color: $select-item-background-color;
  color: $grey2;
  @include ellipsisSimple;

  &.inputHidden {
    cursor: pointer;
    pointer-events: auto;
  }

  &::placeholder {
    color: $grey1;
    font-style: italic;
    font-weight: normal;
    opacity: 1;
    font-size: 14px;
  }

  &:focus {
    border-color: transparent;
  }

  &:placeholder-shown {
    @include ellipsisSimple;
  }

  &:disabled {
    background-color: $black-opacity-3;
    border-radius: 10px;
  }

  &.inputHiddenButton {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: transparent;
    height: 64px;
  }
}

.inputIcon {
  display: flex;
  position: absolute;
  right: 8px;
  top: 27px;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;

  svg {
    font-size: 22px;
  }

  &.inputIconOpen {
    transform: rotate(180deg);
  }

  &.inputIconDisabled {
    cursor: not-allowed;
    pointer-events: none;
  }
}

.option {
  min-height: 38px;
  display: inline-flex;
  width: 100%;
  align-items: center;
  padding: 0 20px;
  font-size: 14px;
  color: $grey2;
  @include ellipsisSimple;

  &:hover,
  &:global(.Mui-focused) {
    background-color: $select-item-background-color;
    cursor: pointer;
  }

  &:first-child {
    margin-top: 22px;
  }
  &:last-child {
    margin-bottom: 22px;
  }
}

.listWrap {
  width: 100%;
  border: none;
  border-radius: 10px;
  position: absolute;
  top: 68px;
  left: 0;
  overflow: hidden;
  z-index: 1;
  pointer-events: none;
}

.list {
  max-height: 414px;
  overflow-y: auto;
  line-height: 1;
}

.checkboxIcon {
  margin-right: 12px;
}

.radioIcon {
  margin-right: 17px;
}

.text {
  white-space: wrap;
  line-height: 26px;
}

.error {
  color: $black-opacity-3;

  .circleIcon {
    color: $black-opacity-3;
  }
}

.dropdownContainer {
  width: 100%;
}

.dropdownContainer.open {
  .autocomplete {
    overflow-y: visible;
  }

  .inputWrapper {
    border-color: $input-active-border;
  }

  .listWrap {
    background-color: $white;
    pointer-events: all;
    box-shadow: 2px 1px 8px rgba(0, 0, 0, 0.3);
  }
}

.container.white {
  &.withMargin {
    @include breakpoint('desktop') {
      margin: 5px 0 10px;
    }
  }

  .inputWrapper {
    background-color: $white;
  }

  .label {
    font-size: 12px;
    line-height: 12px;
  }

  .input {
    background-color: $white;
  }
}
