@import 'Styling/import.scss';

.inputField {
  margin-bottom: 30px;
}

.hr {
  margin: 30px 0 22px;
}

.datesLine {
  border-top: solid 1px $white;
  margin: 30px 0;
}

.date {
  &:first-child {
    margin-bottom: 20px;
  }
}

.dates {
  margin-bottom: 30px;
}

.text {
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  color: $black;
}

@include breakpoint('desktop') {
  .text {
    padding-right: 33px;
  }
  .section {
    .content {
      margin-left: 35px;
      margin-right: 35px;
    }
  }
  .title {
    margin-bottom: 29px;
    &:not(:first-child) {
      margin-top: 29px;
    }
  }
  .modal {
    min-width: 80%;
  }
  .hr {
    margin: 40px 0 32px;
  }
  .inputField {
    margin-bottom: 50px;
  }
  .datesLine {
    display: none;
  }
  .dates {
    // Old approach, not used at moment
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    // grid-column-gap: 30px;
    width: 100%;
    margin-bottom: 10px;
    &:nth-child(2) {
      margin-bottom: 20px;
    }
  }
}
