.iframeWrap {
  min-height: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  iframe {
    min-height: 100%;
    min-width: 100%;
    flex: 1;
  }
}
