@import 'Styling/import.scss';

.requestItemWrap {
  display: flex;
  flex-direction: column;
  padding: 20px 0 30px;
  @include breakpoint(1500px) {
    display: grid;
    border-top: none;
    gap: 30px;
    grid-template-columns: 5fr 185px 120px 120px 260px;
    &.all {
      padding: 10px 0;
      grid-template-columns: 5fr 185px 120px 260px;
    }
  }

  .detailWrap {
    display: grid;
    grid-template-columns: 3fr 5fr;
    align-items: center;
    margin-bottom: 18px;

    .label {
      font-size: 12px;
      color: $gray12;
    }

    @include breakpoint(1500px) {
      margin-bottom: 0px;
      .label {
        display: none;
      }
    }
  }
  .detailWrap:last-child {
    margin-top: 12px;
    margin-bottom: 0;

    @include breakpoint(1500px) {
      margin-top: 0;
    }
  }

  .flexContainer {
    display: flex;
  }
  @include breakpoint(1500px) {
    .detailWrap {
      display: flex;
      flex-direction: row;
    }
  }

  .detailsValue {
    font-size: 14px;
    background-color: $blue2;
    border: solid 1px $gray31;
    border-radius: 8px;
    padding: 10px 13px;
    width: 100%;
    cursor: pointer;
    max-width: 150px;
  }

  .date {
    font-size: 14px;

    @include breakpoint(1500px) {
      font-size: 14px;
    }
  }

  @include breakpoint(1500px) {
    max-width: none;
  }

  .statusValue {
    font-size: 14px;
  }

  .yellowText {
    color: $yellow3;
  }

  .greenText {
    color: $green3;
  }

  .green2Text {
    color: $placement-confirmed;
  }
  .redText {
    color: $red-error;
  }

  .expired {
    color: $expired-text-color;
  }

  .redCinnabarText {
    color: $rejected-text-color;
  }

  .red2Text {
    color: $rejected-text-color;
  }

  .purpleText {
    color: $purple;
  }

  .expirationValue {
    font-size: 14px;
  }

  .justifyEnd {
    justify-content: end;
  }

  .hideOnSmall {
    display: none;
  }
}

.greenBackground {
  background-color: $green3-opacity2;
}
.selectedCandidateRow {
  background-color: $green3-opacity2;
  border: solid 1px #8bc650;
  border-radius: 10px;
  outline: solid 1px #8bc650;
}
.candidateRow {
  border-bottom: 1px solid $gray23;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}
