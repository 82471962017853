@import 'Styling/import.scss';

.sidebarTitleWrapper {
  margin-bottom: 20px;

  &:last-of-type {
    margin: 20px 0;
  }

  .title {
    font-family: 'DMSerifDisplay', serif;
    font-size: 18px;
    color: $yellow;
  }
}
