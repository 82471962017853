@import 'Styling/import.scss';

.candidateInfoWrap{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-size: 14px;
    font-weight: normal;
    margin-top: 10px;

    .textWrap{
        margin-left: 20px;
        line-height: 22px;
    }

    .infoIcon{
        color: $gray12;
        font-size: 22px;
    }

    .infoLabel{
        color: $gray12;
    }

    .infoValue{
        padding-left: 4px;
    }

}
