@import 'Styling/import.scss';

.requestItemWrap {
  display: flex;
  flex-direction: column;
  padding: 20px 0 30px;

  @include breakpoint(1500px) {
    display: grid;
    border-top: none;
    padding: 0;
    gap: 10px;
    grid-template-columns: 5fr 165px 90px 260px;
    &.all {
      grid-template-columns: 5fr 165px 100px 90px 260px;
    }
  }

  .detailWrap {
    display: grid;
    grid-template-columns: 3fr 5fr;
    align-items: center;
    margin-bottom: 18px;

    .label {
      font-size: 12px;
      color: $gray12;
    }

    @include breakpoint(1500px) {
      margin-bottom: 10px;
      .label {
        display: none;
      }
    }
  }
  .detailWrap:last-child {
    margin-top: 30px;
    margin-bottom: 0;

    @include breakpoint(1500px) {
      margin-top: 0;
      margin-bottom: 10px;
    }
  }

  .contactRequestText {
    font-size: 14px;
    color: $black;
    background-color: $blue351;
    border-radius: 8px;
    border: $gray18 1px solid;
    width: 155px;
    padding: 6px;
    margin-top: 12px;
  }

  .flexContainer {
    display: flex;
  }
  @include breakpoint(1500px) {
    .detailWrap {
      display: flex;
      flex-direction: row;
      padding-top: 10px;
    }
  }

  .detailsValue {
    font-size: 14px;
    background-color: $blue351;
    border: solid 1px $gray31;
    border-radius: 8px;
    padding: 10px;
    width: 100%;
    cursor: pointer;
    max-width: 150px;
    margin-left: -10px;
  }

  .date {
    font-size: 14px;

    @include breakpoint(1500px) {
      font-size: 14px;
    }
  }

  @include breakpoint(1500px) {
    max-width: none;
  }

  .statusValue {
    font-size: 14px;
  }

  .yellowText {
    color: $yellow3;
  }

  .greenText {
    color: $green68455348;
  }

  .redText {
    color: $red384354354;
  }

  .pinkText {
    color: $pink;
  }

  .redCinnabarText {
    color: $red-cinnabar;
  }

  .purpleText {
    color: $purple15343568;
  }

  .blackText {
    color: $black315451354;
  }

  .expirationValue {
    font-size: 14px;
  }

  .justifyEnd {
    justify-content: end;
  }
}
.selectedCandidateRow {
  background-color: $green3-opacity2;
  border: solid 1px $green2;
  border-radius: 10px;
  outline: solid 1px $green2;
}

.candidateRow {
  border-bottom: 1px solid $gray23;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}
