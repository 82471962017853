@import 'Styling/import.scss';

.chip {
  background-color: rgba(181, 181, 181, 0.5);
  height: 24px;
  font-size: 16px;
  line-height: 16px;
  color: $grey2;
  display: flex;
  padding: 0 4px 0 20px;
  align-items: center;
  text-align: center;
  border-radius: 9.5px;
  margin-right: 10px;
}

.text {
  margin-right: 10px;
  @include ellipsis(1);
  word-break: break-all;
}

.iconWrap {
  height: 16px;
  color: #7D7E7E;
}
