@import 'Styling/import.scss';

.container {
  padding: 10px 30px;
  background-color: $white;
  border-radius: 0 8px 8px 0;
  display: flex;
  flex-direction: column;
  height: 465px;

  @include breakpoint('toDesktop') {
    background-color: $white;
    padding: 10px 21px;
    border-radius: none;
    display: grid;
    height: 76vh;
    grid-template-rows: max-content max-content 1fr max-content;
    align-items: start;
  }

  .sendMessageMobileTitle {
    font-size: 24px;
    font-family: 'DMSerifDisplay', serif;
    margin-top: 19px;
    margin-bottom: 13px;
    text-transform: uppercase;
  }

  .messagesContainer {
    display: flex;
    flex-direction: column-reverse;
    overflow-y: auto;
    max-height: 345px;

    @include breakpoint('toDesktop') {
      overflow-x: hidden;
      max-height: 100%;
    }

    &::-webkit-scrollbar {
      width: 6px;
      border-radius: 3px;
      background: transparent;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      width: 6px;
      border-radius: 3px;
      background-color: rgba($color: $gray-cyan, $alpha: 0.3);
    }
  }

  .newMessagesContainer {
    .newMessagesHeader {
      color: $gray12;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 12px;
      padding: 10px 0px 12px 0px;
      border-bottom: 1px solid $gray23;
    }
  }

  .progressSpinner {
    display: flex;
    justify-content: center;
    width: 100%;

    svg {
      color: $gray-cyan;
    }
  }
}

.textField {
  display: flex;
  padding-top: 9px;
  margin-top: auto;

  @include breakpoint('toDesktop') {
    margin-top: 17px;
    padding-top: 0;
  }
}

.hideSectionContainer {
  @include breakpoint('toDesktop') {
    display: none;
  }
}

.messagesSectionModal {
  > div:nth-child(2) {
    padding: 0;
  }
}

.mobileMainContainer {
  min-height: 90vh;
  background-color: $gray14;
  padding-bottom: 70px;
  display: grid;
}
