@import 'Styling/import.scss';

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 41px;
  font-size: 14px;
  cursor: pointer;
  width: 180px;
  border: 1px solid $gainsboro;
  background-color: $white2;
  color: $blue2;
  padding: 0;
  padding-top: 13px;
  padding-bottom: 10px;
  text-align: center;
  border-bottom: none;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  .newMessagesIndicator {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $red-error;
    color: $white;
    font-size: 11px;
  }
}

.tabActive {
  position: relative;
  color: $gray12;
  background-color: $white;

  &:visited {
    color: $gray12;
  }

  &:focus {
    color: $gray12;
  }
}

.tabActive::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 4px;
  bottom: -3px;
  left: 0;
  background-color: $white;
  z-index: 4;
}
