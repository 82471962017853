@import 'Styling/import.scss';

.sectionItem {
  padding-bottom: 10px;
}

.trainingTitlesContainer {
  font-size: 14px;
  line-height: 18px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @include breakpoint('tablet') {
    grid-template-columns: repeat(4, 1fr);
  }
  @include breakpoint('desktop') {
    grid-template-columns: repeat(5, 1fr);
    margin-left: 36px;
    margin-right: 36px;
  }

  .trainingTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    padding: 0 5px;

    .trainingTitleIcon {
      width: 86px;
      height: 86px;
    }
    .trainingTitleName {
      margin-top: 20px;
      color: $black;
      text-align: center;
      width: 100%;
    }
    .trainingTitleDate {
      color: $gray15;
      text-align: center;
      width: 100%;
    }
  }
}
