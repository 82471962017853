@import 'Styling/import.scss';

.container {
  .children {
    overflow: hidden;
    transition: max-height 0.2s ease-in-out;
  }
  .button {
    display: inline-flex;
    align-items: center;
    font-family: 'Roboto', sans-serif;
  }
  .button.noMargin {
    margin-left: -7px;
  }
  .span {
    color: $grey3;
    font-size: 14px;
    margin-left: 10px;
    font-weight: bold;
    color: $blue2;
    &:hover,
    &:focus,
    &:active {
      color: $black;
    }
  }
  .span.noMargin {
    margin-left: 0;
  }

  .hr {
    margin: 30px 0 13px;
  }

  .hr.orange {
    margin: 10px 0 13px;
  }

  .hr.whiteIcon {
    display: none;
  }
}
.container.orange {
  span {
    color: $yellow3;
    text-decoration: none;
  }
}
.container.whiteIcon {
 
  svg {
    color: $white;
  }
  span {
    color: $white;
  }
}

.container.black {
  span {
    text-decoration: none;
    font-weight: bold;
    color: $blue2;
    &:hover,
    &:focus,
    &:active {
      color: $black;
    }
  }
  .hr.black {
    margin: 10px 0 13px;
    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
  }
}
