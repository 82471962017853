.progressSpinner {
  display: flex;
  justify-content: center;
  width: 100%;
}

.scrollEnd {
  width: 100%;
  height: 20px;
}
