@import 'Styling/import.scss';

.contentItem {
  display: flex;
  flex-direction: column;

  p {
    &:nth-child(1) {
      font-size: 11px;
      color: $white2;
      margin-bottom: 10px;
    }

    &:nth-child(2) {
      font-size: 14px;
      color: $gray30;
    }
  }

  .italicText {
    font-style: italic;
  }

  .evaluationContainer {
    margin-top: 5px;
    .grayStarIcon {
      color: $white-opacity-2;
    }
  }
}
