@import 'Styling/import.scss';

.messageParticipantBasicInformations {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;

  .content {
    display: flex;

    .name {
      color: $blue2;
      font-weight: bold;
      line-height: 17px;
    }
  }

  .companyName {
    margin-top: 3px;
    color: $gray-cyan;
    line-height: 18px;
  }
}
