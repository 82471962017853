@import '@csstools/normalize.css';

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

button {
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: #000;
  font-family: 'Roboto', sans-serif;
}

text-area {
  font-family: 'Roboto', sans-serif;
}

ins {
  text-decoration: underline;
}

del {
  text-decoration: line-through;
}
