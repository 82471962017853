@import 'Styling/import.scss';

:global {
  .privacyPolicyLogo {
    display: none;
    @include breakpoint(tablet) {
      display: block;
      margin-top: 8px;
    }
  }
  .privacyPolicyAnchor {
    text-decoration: none;
    &:link {
      color: black;
    }
  }

  .privacyPolicyIcon {
    color: $gray11;
    margin-right: 10px;
    &:first-child {
      margin-right: 20px;
    }

    &Title {
      color: $gray11;
      text-decoration: underline;
      display: none;
    }
  }

  .privacyPolicyTermsAndConditionsTitle {
    font-size: 48px;
    font-family: 'DMSerifDisplay', sans-serif;
    line-height: 58px;
    text-align: left;
    color: $yellow;
    font-weight: normal;

    @include breakpoint(tablet) {
      font-size: 60px;
      letter-spacing: 3px;
    }
  }
  .privacyPolicyContainer {
    padding: 0 22px 70px 20px;

    a {
      color: $black;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }

    @include breakpoint(tablet) {
      padding: 0 55px 128px 49px;
    }

    &Middle {
      border-bottom: 1px solid $grey1;
      display: flex;
      align-items: center;
      padding-bottom: 26px;
      padding-top: 24px;
      @include breakpoint(tablet) {
        padding-top: 56px;
      }
    }

    &Down {
      padding-top: 20px;
      border-bottom: 1px solid $black;
      padding-bottom: 60px;

      &Title {
        padding: 26px 0px 35px;
        font-family: 'DMSerifDisplay', serif;
        font-size: 24px;
        line-height: 30px;
        color: $black3;

        &:first-of-type {
          padding-top: 40px;
        }
      }

      &Text {
        font-size: 14px;
        line-height: 24px;
        color: $black3;
      }
    }
  }

  .privacyPolicyDateText {
    font-style: italic;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
    color: $black3;
    font-weight: bold;
    padding-top: 20px;
  }

  .privacyPolicyOl {
    counter-reset: item;
    position: relative;

    > li {
      display: block;
      padding-left: 50px;
    }

    li::before {
      content: counters(item, '.') '. ';
      counter-increment: item;
      font-weight: bold;
      position: absolute;
      left: 10px;
    }
    ol {
      counter-reset: item;
    }
  }

  .privacyPolicyOlParagraph {
    margin: 20px 0px 20px 20px;
    li {
      list-style: decimal;
      display: list-item;
      margin-left: 20px;
      font-size: 14px;
      line-height: 24px;
      color: $black3;
    }
  }
  .privacyPolicyOlLine {
    position: relative;
    margin: 20px 0px 20px 20px;
    li {
      display: list-item;
      margin-left: 20px;
      font-size: 14px;
      line-height: 24px;
      color: $black3;
    }
    li:before {
      content: '-';
      position: absolute;
      left: 00px;
    }
  }

  .privacyPolicyHr {
    border: 0;
    height: 0;
    border-top: 1px solid rgba(156, 162, 170, 0.85);
    margin: 15px 0;
    @include breakpoint('desktop') {
      margin: 20px 0;
    }
  }
}
