@import 'Styling/import.scss';

.aboutCandidate {
  display: flex;
  flex-direction: column;
  align-items: center;

  .name {
    font-size: 24px;
    color: $gray36;
    margin-top: 17px;
    margin-bottom: 11px;
    font-family: 'DMSerifDisplay', serif;
  }
  .withNoImg {
    margin-top: 0;
  }

  .proffession {
    font-size: 14px;
    color: $yellow;
    font-weight: bold;
  }
  .proffessionOnSearch {
    font-size: 24px;
    color: $yellow;
    margin-bottom: 11px;
    font-family: 'DMSerifDisplay', serif;
    text-align: center;
  }
  .location {
    font-size: 14px;
    font-weight: bold;
    color: $gray36;
  }
  .darkGrayHr {
    border: 1px solid $gray35;
  }
}
