@import 'Styling/import.scss';

.container {
  position: relative;
  z-index: 1;

  .background {
    position: absolute;
    left: 0px;
    z-index: -1;
    object-fit: cover;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url('/Assets/Images/O-nas-background-350px.jpg');
    opacity: 0.6;

    @include breakpoint('420px') {
      background-image: url('/Assets/Images/O-nas-background-420px.jpg');
    }
    @include breakpoint('900px') {
      background-image: url('/Assets/Images/O-nas-background-900px.jpg');
    }
    @include breakpoint('1160px') {
      background-image: url('/Assets/Images/O-nas-background-1160px.jpg');
    }

    @include breakpoint('1440px') {
      background-image: url('/Assets/Images/O-nas-background-1440px.jpg');
    }
    @include breakpoint('1920px') {
      background-image: url('/Assets/Images/O-nas-background-1920px.jpg');
    }
    @include breakpoint('2560px') {
      background-image: url('/Assets/Images/O-nas-background-2560px.jpg');
    }
    @include breakpoint('3000px') {
      background-image: url('/Assets/Images/O-nas-background-3000px.jpg');
    }
    @include breakpoint('4000px') {
      background-image: url('/Assets/Images/O-nas-background-4000px.jpg');
    }
  }
  .contentLayout {
    min-height: 100vh;
    height: 100%;
    margin: 0 22px;
    padding-bottom: 60px;
    @include breakpoint('1160px') {
      margin: 0 150px;
    }
    .logoContainer {
      display: flex;
      padding-top: 34px;
      padding-bottom: 63px;
      justify-content: center;

      @include breakpoint('1160px') {
        justify-content: flex-start;
        display: block;
        padding-top: 42px;
        padding-bottom: 26px;
      }
      .logoStyle {
        width: 160px;
        height: auto;
      }
    }
  }

  .title {
    font-size: 24px;
    font-family: 'DMSerifDisplay', serif;
    text-align: center;
    margin-bottom: 60px;

    @include breakpoint('1160px') {
      font-size: 48px;
      margin-bottom: 170px;
    }
  }

  .info {
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: none;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-content: center;
    gap: 30px;

    .circle2 {
      svg {
        margin-bottom: 13px;
      }
    }
    .circle2 {
      svg {
        margin-top: 30px;
        margin-bottom: 10px;
        width: 116px;
        height: 116px;
        @include breakpoint('1160px') {
          margin-bottom: 0;
          margin-top: 0;
        }
      }
    }

    .circle3 {
      svg {
        width: 83px;
        height: 83px;
        margin-top: 70px;
        @include breakpoint('1160px') {
          margin-top: 0;
        }
      }
    }

    @include breakpoint('1160px') {
      grid-template-columns: repeat(3, auto);
      grid-template-rows: none;
      gap: 80px;
    }

    & > div {
      width: 270px;
      height: 270px;
      background-color: $white-opacity-4;
      border-radius: 50%;
      margin: 0;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      svg {
        margin-top: 30px;
        margin-bottom: 20px;
        fill: $black-opacity-3;
        width: 86px;
        height: 86px;
      }

      .text {
        font-size: 16px;
        text-align: center;
        max-width: 200px;
        color: $black2;

        .bold {
          font-weight: bold;
        }
      }

      .desktopQText {
        display: none;
        font-weight: bold;
        font-size: 18px;
        color: $black2;

        @include breakpoint('1160px') {
          display: block;
        }
      }

      &:hover {
        .desktopQText {
          display: none;
        }
      }

      @include breakpoint('1160px') {
        justify-content: center;

        .text {
          @include breakpoint('1160px') {
            display: none;
          }
        }

        &:hover {
          .text {
            @include breakpoint('1160px') {
              display: block;
              margin: auto;
            }
          }
        }

        svg {
          display: block;
          margin-top: 0;
          margin-bottom: 10px;
        }
        &:hover {
          svg {
            display: none;
          }
        }
      }
    }
  }
}
