@import 'Styling/import.scss';

.trigger {
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  background-color: $white2;
  border: solid 1px $gray31;
  cursor: pointer;
  white-space: nowrap;
}
   
.threeDotsBtn{
  transition: .3s fill linear;
}
.container{
    &:hover,
    &:focus,
    &:active {
      .threeDotsBtn {
        background-color: $blue2;
        color: $white;
      }   
    }
}