@import 'Styling/import.scss';

.confirmWrap {
  display: flex;
  justify-content: center;
  padding-top: 51px;
  padding-bottom: 101px;
}
.requestContact {
  margin-top: 85px;
  padding-left: 24px;
  padding-right: 24px;
}
.sidebarContent {
  margin-top: 75px;
}
