@import 'Styling/import.scss';

.container {
  background: $white;
  border-radius: 10px;
  width: 100%;
  border: 1px solid $input-border;
  box-sizing: border-box;
  padding: 0 20px 10px;
  resize: vertical;
  &:hover,
  &:focus {
    border: 1px solid $input-focus-border;
  }
  &:active {
    border: 1px solid $input-active-border;
  }

  @include breakpoint('desktop') {
    margin: 5px 0;
    &:last-child {
      margin: 5px 0 0;
    }
  }
  .charactersCalculator {
    display: flex;
    justify-content: flex-end;
    color: rgba(0, 0, 0, 0.4);
    font-size: 14px;
  }
}

.wrapper {
  .editor {
    height: 200px;
    background: $white;
    padding-top: 20px;
    color: $grey2;
    font-family: 'Roboto', sans-serif;
    overflow: auto;
    :global {
      .public-DraftStyleDefault-block {
        margin: 0;
        line-height: 1.5;
      }
      .DraftEditor-root {
        height: 160px;
      }
    }
  }
  .toolbar {
    background: $white;
    border-radius: 10px 10px 0 0;
    color: $grey2;
    border-top: none;
    border-left: none;
    border-right: none;

    :global {
      .rdw-option-wrapper {
        border: none;
        &:last-child {
          padding-right: 5px;
          border-right: 1px solid $grey2;
        }
      }
    }
  }
}

.inputError {
  border: 1px solid $error-border;

  &:hover,
  &:focus {
    border: 1px solid $error-border;
  }
  &:focus {
    border: 1px solid $error-border;
  }
}
