@import 'Styling/import.scss';

.modalContent {
  display: flex;
  flex-direction: column;
  .title {
    font-size: 24px;
    color: $black;
    font-family: 'DMserifDisplay', serif;
    padding-bottom: 25px;
  }
  .paragraph {
    font-size: 14px;
    color: $grey3;
    padding-bottom: 25px;
  }
   .textArea{
    textArea {
      border: 1px solid $input-border;
      &:hover,
      &:focus {
        border: 1px solid $input-focus-border;
      }
      &:active {
        border: 1px solid $input-active-border;
      }
    }

   
  }
}

.modal {
  :global {
    .content {
      background-color: $white;
      @include breakpoint(desktop) {
        padding: 40px 30px 50px;
      }
    }
    @include breakpoint(desktop) {
      width: 720px;
    }
  }
}
