@import 'Styling/import';

.containerBadges {
  display: flex;
  background-color: $blue2;
  border-radius: 5px;
  width: fit-content;
  padding: 2px 11px 2px 14px;
  gap: 5px;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  .whiteTxt {
    color: $white;
  }
  .yellowTxt {
    color: $yellow3;
  }
}
