@import 'Styling/import.scss';

$onb-green-color: #8bc750;
$onb-right-col-bg-color: #303738;
$onb-title-color: #000000;
$onb-description-color: #373636;
$onb-divider-color: #b5b9c0;
$onb-right-col-step-bg: #fbb20d;
$onb-right-col-step-color: #fff;
$onb-right-col-step-active-bg: $onb-green-color;
$onb-right-col-step-color: #838383;

$onb-circle-bg: $onb-right-col-step-bg;
$onb-circle-border: #e0e0e0;
$onb-circle-legend-color: #9ca2aa;

.onboarding {
  display: block;
  @include clearFix();
  .main {
    float: left;
    display: block;
    width: 100%;
  }
  .rightColumn {
    float: left;
    display: none;
    width: 360px;
    height: 100%;
    background-color: $onb-right-col-bg-color;
    position: fixed;
    top: 0;
    right: 0;
  }
  .inner {
    padding: 0 42px;
    .title {
      font-size: 24px;
      line-height: 1;
      text-align: center;
      font-family: 'DMSerifDisplay', serif;
      color: $onb-title-color;
      padding-top: 30px;
    }
    .description {
      margin: 23px 0;
      font-size: 14px;
      line-height: 1;
      text-align: center;
      color: $onb-description-color;
    }
    .divider {
      width: 100%;
      height: 1px;
      background-color: $onb-divider-color;
      margin: 4px 0;
    }
  }

  .logo {
    text-align: center;
    margin: 34px 10px 24px;
    img {
      height: 17px;
    }
  }

  .desktopSteps {
    margin-top: 220px;
    .desktopStep {
      margin-top: 21px;
      @include clearFix();
      &.filled {
        .stepCnt {
          background-color: $onb-right-col-step-bg;
          border: 1px solid $onb-right-col-step-bg;
          color: $black3;
        }
      }
      &.done {
        .stepCnt {
          background-color: $white;
          border: 1px solid $white;
          color: $black3;
        }
      }
      .stepCnt {
        height: 46px;
        width: 46px;
        border-radius: 23px;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        line-height: 46px;
        color: $onb-right-col-step-color;
        background-color: $onb-right-col-bg-color;
        border: 1px solid $onb-right-col-step-color;
        float: left;
        display: block;
        margin-left: 20px;
      }
      .stepName {
        float: left;
        display: block;
        margin-left: 20px;
        line-height: 46px;
        font-size: 16px;
        color: $onb-right-col-step-color;
      }
    }
  }
}

.cicleSteps {
  display: flex;
  @include clearFix();
  align-items: center;
  justify-content: center;
  margin: 26px auto 30px;
  position: relative;
  width: 110px;
  .circle {
    width: 14px;
    height: 14px;
    border-radius: 50px;
    margin-right: 10px;
    float: left;
    display: block;
    border: 1px solid $onb-title-color;
    &:last-child {
      margin-right: 0;
    }
    &.filled {
      background-color: $onb-right-col-step-bg;
      border-color: $onb-right-col-step-bg;
    }
  }
  .legend {
    position: absolute;
    right: -82px;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
    color: $onb-circle-legend-color;
    margin-left: 12px;
    width: 70px;
    .nonBrackets {
      display: none;
    }
    .brackets {
      display: inline;
    }
  }

  &.mobileWhite {
    .circle {
      border: 1px solid $gray21;
      @include breakpoint('desktop') {
        border: 1px solid $onb-title-color;
        background-color: $white;
      }
      &.filled {
        background-color: $onb-right-col-step-bg;
        border-color: $onb-right-col-step-bg;
      }
    }
    .legend {
      color: $select-item-background-color;
    }
  }
}

@include breakpoint('desktop'){
  .cicleSteps {
    margin-top: 27px;
    width: 120px;
    .circle {
      width: 16px;
      height: 16px;
    }
    .legend {
      .nonBrackets {
        display: inline;
      }
      .brackets {
        display: none;
      }
    }
  }

  .onboarding {
    .main {
      width: calc(100% - 360px);
    }
    .rightColumn {
      display: block;
    }
    .inner {
      padding: 0 10%;
      .title {
        font-size: 36px;
        margin-top: 18px;
      }
      .description {
        margin: 28px 0;
        font-size: 14px;
      }
      .divider {
        width: 100%;
        height: 1px;
        background-color: $onb-divider-color;
        margin: 3px 0 4px;
      }
    }
    .logo {
      margin: 51px 0 0 42px;
      text-align: left;
    }
  }
}
