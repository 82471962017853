@import 'Styling/import.scss';

.whiteBoxWrapper {
  display: flex;
  flex-direction: column;
  background-color: $white;
  border-radius: 28px;
  align-items: center;
  padding: 30px 20px;
  width: 100%;

  @include breakpoint(desktop) {
    flex-direction: row;
    padding: 40px;
    width: 641px;
  }
}
