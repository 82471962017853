@import 'Styling/import.scss';

.seekerProfileWrap {
  display: grid;
  grid-template-columns: 3fr 5fr;
  align-items: center;
  width: 100%;
  height: 100%;

  .avatarWrap {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;

    .avatarImg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .avatarLocked {
      background-color: $yellow;
      width: 100%;
      height: 100%;
      color: $white;
      line-height: 50px;
      font-size: 20px;
      text-align: center;
      a {
        color: $white;
      }
    }
  }

  .seekerInfo {
    .jobTitle {
      font-size: 14px;
      color: $blue1;
      font-weight: bold;
    }

    .fullName {
      font-size: 12px;
      color: $yellow3;
      font-weight: bold;
    }

    .age,
    .location {
      font-size: 12px;
      color: $black;
      font-style: italic;
      a {
        color: $black;
      }
    }
  }
}

@include breakpoint(1500px) {
  .seekerProfileWrap {
    display: flex;
    flex-direction: row;
  }
  .seekerInfo {
    margin-left: 19px;
  }
}
