@import 'Styling/import.scss';

.overlay {
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 5;
}

.modal {
  position: absolute;
  border: none;
  background-color: transparent;
  outline: none;
  padding: 0;
  max-width: 980px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    background-color: $yellow;
    min-height: 65px;
    display: flex;
    padding: 12px 20px;
    align-items: center;
    position: relative;
    .icon {
      margin-right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .title {
      font-family: 'DMSerifDisplay', serif;
      font-size: 18px;
      color: $white;
      flex: 1;
    }
    .closeButton {
      background-color: $gray14;
      outline: none;
      border: none;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.error {
      background-color: $red-error;
    }
  }
  .content {
    background-color: $gray13;
    padding: 24px 22px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 14px;
    }

    &::-webkit-scrollbar-track {
      background-color: $chinese-silver;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: $dim-gray;
    }
  }
  .footer {
    background-color: $white;
    flex: 1;
    z-index: 2;
    border-top: solid 1px $gray20;
  }
  .submitFooter {
    .checkboxWrapper {
      margin: 33px 37px 0 21px;
      color: $black2;
      > div {
        align-items: start;
      }

      @include breakpoint('desktop') {
        margin: 36px 81px 0 81px;
      }
    }
    .buttonsContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 30px;

      .spaceBetweenNext {
        min-width: 222px;
        margin-bottom: 15px;
      }
      .understandButton {
        width: 161px;
        height: 40px;
        margin-right: 75px;
        padding-top: 13px;
        padding-bottom: 13px;
      }
      .redTextCloseButton {
        color: $red-error;
        &:hover,
        &:focus,
        &:active {
          &:not([disabled]) {
            color: $white;
          }
        }
      }
    }

    .consentLink {
      text-decoration: underline;
      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
      }
    }
  }
}

@include breakpoint('desktop') {
  :global {
    .ReactModal__Overlay {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .modal {
    width: auto;
    height: auto;
    max-height: 80vh;

    .header {
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      padding: 12px 24px;
      .icon {
        margin-right: 24px;
      }
      .title {
        font-size: 22px;
      }
      .closeButton {
        position: absolute;
        top: -20px;
        right: -26px;
      }
    }
    .content {
      padding: 40px 78px 50px 78px;
      &:last-child {
        background-color: $white;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
      }

      &.desktopFlex {
        @include breakpoint('desktop') {
          display: flex;
          flex-direction: column;
          padding: $modal-content-padding-top $modal-content-side-padding
            $modal-content-padding-bottom;
        }
      }
    }
    .footer {
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
    }
    .submitFooter {
      flex-direction: row-reverse;
      justify-content: center;
      align-content: center;
      position: relative;
      min-width: 500px;

      .buttonsContainer.spaceBetween {
        flex-direction: row-reverse;
        align-content: center;
        position: relative;
        justify-content: space-between;

        .spaceBetweenBack {
          padding: 10px 22px;
          @include breakpoint('desktop') {
            position: static;
            padding-left: 0;
          }
        }
        .spaceBetweenNext {
          line-height: 20px;
          padding: 10px 24px;
          min-width: 161px;
          margin-bottom: 0;
        }
      }
    }
  }
}

.lockedBody {
  overflow: hidden;
}
