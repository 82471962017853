@import 'Styling/import.scss';

.modalDetails {
  @include breakpoint('desktop') {
    width: 980px;
  }
}

.containerInterviewDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  .container{
    width: 100%;
    display: flex;
    flex-direction: column;
    .textArea{
      textArea{
        border: 1px solid $input-border;

        &:hover,
        &:focus {
          border: 1px solid $input-focus-border;
        }
        &:active {
          border: 1px solid $input-active-border;
        }
      }

    }
  }

  .hr {
    width: 100%;
  }

  .titleContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .avatar{
      border-radius: 50%;
      height: 50px;
      width: 50px;
      margin-right: 20px;
    }
    .titleAvatar {
      color: $grey2;
      font-family: 'DMSerifDisplay', serif;
      font-size: 24px;
      width: 100%;
    }
    @include breakpoint('desktop') {
      margin-bottom: 8px;
    }
  }
  .bodyTitle {
    @include breakpoint('toDesktop') {
      margin: 0 0 8px;
    }
  }
}

