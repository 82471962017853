@import 'Styling/import.scss';

.hr {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(156, 162, 170, 0.85);
  margin: 15px 0;
  @include breakpoint('desktop') {
    margin: 20px 0;
  }
}

.hrWrap {
  width: 100%;
  position: relative;
}

.textWrap {
  position: absolute;
  color: $icon-gray;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $gray13;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  svg {
    font-size: 3rem;
  }
}
