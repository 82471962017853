@import 'Styling/import.scss';

.breadcrumbsWrapper {
  display: none;

  @include breakpoint('desktop') {
    display: block;
    margin-left: 49px;
  }
}

.mainContentWrapper {
  .heading {
    font-size: 24px;
    font-family: 'DMSerifDisplay', serif;
    color: $yellow;
    border-bottom: 1px $gray29 solid;
    text-align: center;
    padding-bottom: 10px;
  }
  .journeyTrackerWrap {
    position: relative;
    z-index: 0;
  }
  .candidateDashboardWrap {
    position: relative;
    z-index: 2;
    margin-top: -24px;
  }

  .linkWrap {
    display: flex;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .viewMoreLink {
    color: $grey3;
    font-size: 14px;
    border-bottom: 1px solid $grey3;
    &:hover,
    &:focus,
    &:active {
      border-bottom: 1px solid $white;
    }
  }

  @include breakpoint('desktop') {
    padding: 0 50px;

    .myRequestsWrap {
      position: relative;
      display: flex;
      flex-direction: column;
      background-color: $white;
      margin-bottom: 20px;
      padding: 10px;
      margin-top: -24px;

      .heading {
        font-size: 36px;
        text-align: left;
        margin: 20px 20px 0 20px;
        padding-bottom: 20px;
        width: auto;
      }
    }

    @include breakpoint('desktop') {
      .myRequestsWrap {
        border-radius: 8px;
      }
    }
  }
}

@include breakpoint(desktop) {
  .pageWrap {
    margin: 0 50px;
    margin-top: 15px;
  }
}
