@import 'Styling/import.scss';

.pageWrap {
  height: 100%;

  .headerWrap {
    padding: 0 20px;
  }

  @include breakpoint('desktop') {
    margin: 0 50px;

    .headerWrap {
      padding: 0;
    }
  }

  .mainTitle {
    font-family: 'DMSerifDisplay', serif;
    font-size: 36px;
    color: $yellow;
    margin: 0 20px;

    @include breakpoint('desktop') {
      margin: 0;
    }
  }

  .title {
    margin-left: 20px;
    margin-right: 20px;

    @include breakpoint('desktop') {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .cardItemWrap {
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: 8px;
    margin-top: 20px;
    margin-bottom: 30px;
    padding: 20px;

    @include breakpoint('desktop') {
      padding: 30px;
    }

    .emailInput,
    .oldEmailInput {
      border-radius: 8px;
      background-color: $white2;
      margin-bottom: 30px;

      :global {
        .MuiFilledInput-root {
          border-radius: 8px;
          background-color: $white2;
          border: 1px solid $input-border;
          &:hover,
          &:focus{
            border: 1px solid $input-focus-border;
          }
          &:active{
            border: 1px solid $input-active-border;
          }
        }

        .MuiInputLabel-animated {
          color: $grey1;
          padding-left: 10px;
        }

        .MuiInputBase-input {
          color: $grey2;
          font-size: 16px;
        }

        .MuiFilledInput-input {
          margin-left: 10px;
        }
      }
    }

    .emailInput {
      margin-bottom: 0;
    }
  }

  .withError {
    border-radius: 8px;
    background-color: $white2;
    margin-bottom: 0;

    :global {
      .MuiFilledInput-root {
        border-radius: 8px;
        background-color: $white2;
        border: 1px solid $error-border;
        &:hover,
        &:focus{
          border: 1px solid $error-border;
        }
        &:active{
          border: 1px solid $error-border;
        }
      }

      .MuiInputLabel-animated {
        color: $grey1;
        padding-left: 10px;
      }

      .MuiInputBase-input {
        color: $grey2;
        font-size: 16px;
      }

      .MuiFilledInput-input {
        padding-left: 10px;
        padding-right: 20px;
      }
    }
  }
  .ctasWrap {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    gap: 20px;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;

    @include breakpoint('desktop') {
      justify-content: center;
      flex-direction: row;
      grid-template-rows: none;
      padding-left: 0;
      padding-right: 0;
    }

    .saveBtn {
      width: 100%;
      height: 76px;

      @include breakpoint('desktop') {
        height: 65px;
        width: 400px;
      }
    }

    .cancelBtn {
      color: $grey3;
      font-size: 14px;
      border-bottom: 1px solid $grey3;
      margin: 0 auto;

      @include breakpoint('desktop') {
        position: absolute;
        left: 0;
        top: 28px;
          &:hover,
          &:focus,
          &:active {
            color: $blue2;
            border-bottom: none;
        }
      }
    }
  }

  .successMessage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $green2;
    margin-bottom: 45px;

    svg {
      fill: $green2;
      margin-right: 18px;
    }
  }
}

.linksContainer {
  padding: 45px 24px 0;
}
