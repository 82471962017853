@import 'Styling/import.scss';

.container {
  .mainTitle {
    font-family: 'DMSerifDisplay', serif;
    font-size: 36px;
    color: $yellow;
    margin: 0 20px;
  }

  .hr {
    margin-left: 20px;
    margin-right: 20px;
  }

  .title {
    margin-left: 20px;
    margin-right: 20px;
    padding-bottom: 20px;
  }

  .uploadImage {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .iconWrap {
      background-color: $white2;
      border-radius: 8px;
      width: 100%;
      min-height: 158px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: $grey1;
      font-size: 14px;

      .photoIcon {
        fill: $grey1;
        font-size: 40px;
        margin-bottom: 10px;
      }
    }
    img {
      width: 140px;
      height: 140px;
      border-radius: 50%;
    }
  }

  .profileImage {
    position: relative;
    background-color: $white2;
    border-radius: 8px;
    min-width: 174px;
    min-height: 158px;
    display: flex;
    align-items: center;
    justify-content: center;

    .deleteIcon {
      position: absolute;
      right: 10px;
      top: 7px;
    }
  }

  .inputContainer {
    background-color: $white;
    padding: 30px 20px;
    margin-bottom: 22px;
    position: relative;

    .input {
      margin-bottom: 20px;
    }

    .dropdown {
      margin-bottom: 20px;

      div {
        &:first-of-type {
          font-size: 14px;
        }

        input {
          &::placeholder {
            font-size: 14px;
          }
        }
      }
    }

    .dayDropdown {
      margin-top: 30px;
    }

    .yearDropdown {
      margin-bottom: 0;
    }

    &.inputContactData {
      margin-bottom: 40px;

      .preview {
        padding-top: 30px;
      }
    }
  }

  .buttonsWrap {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding-bottom: 50px;

    .saveBtn {
      width: 100%;
    }

    .previewBtn {
      color: $grey3;
      font-size: 14px;
      border-bottom: 1px solid $grey3;
      margin: 0 auto;
    }

    .cancelBtn {
      color: $grey3;
      font-size: 14px;
      border-bottom: 1px solid $grey3;
      margin: 0 auto;

      @include breakpoint('desktop') {
        position: absolute;
        left: 0;
        top: 28px;
        &:hover,
        &:focus,
        &:active {
          color: $blue2;
          border-bottom: none;
        }
      }
    }
  }
  .preview {
    .previewRow {
      width: 100%;
      padding: 19px 0;
      border-bottom: 1px solid $gray29;
      &:first-child {
        border-top: 1px solid $gray29;
      }

      .label {
        margin-bottom: 19px;
        font-size: 14px;
        color: $silver;
      }

      .value {
        font-size: 16px;
        color: $brown;
      }
    }
    .editBtn {
      position: absolute;
      right: 17px;
      top: 27px;
    }
  }

  .headerWrap {
    padding: 10px 20px;
  }

  .phoneRow {
    margin-bottom: 20px;
    border: 1px solid $input-border;
    border-radius: 10px;
    &:hover,
    &:focus {
      border: 1px solid $input-focus-border;
    }
    &:active {
      border: 1px solid $input-active-border;
    }
  }
  .errorBorder {
    border: none;

    &:hover,
    &:focus {
      border: none;
    }
    &:active {
      border: none;
    }
  }

  .disabledInput.disabledInputStrong {
    input {
      background-color: $gray42;
      color: $gray-opacity-5;
    }
  }

  .emailRow {
    position: relative;

    .lock {
      position: absolute;
      top: 20px;
      right: 16px;
      opacity: 0.3;
    }
  }

  .previewPhone {
    label {
      display: none;
    }
    input {
      background: transparent !important;
      margin-left: 0 !important;
      padding-left: 35px;
      padding-top: 0 !important;
      height: 20px !important;
      display: none;
    }
    > div > div {
      border: none;
    }
    :global {
      .react-tel-input {
        border: none;

        .selected-flag {
          width: 30px;
          padding: 0;
          background-color: transparent !important;
          display: flex;
          align-content: center;
          justify-content: center;

          .arrow {
            display: none;
          }

          .flag {
            margin-left: 0 !important;
            position: relative;
            top: initial;
          }
        }

        .flag-dropdown {
          background-color: transparent;
          pointer-events: none;
        }
      }
    }
  }

  .formatedPhoneNumber {
    display: flex;
    align-items: center;
    background: transparent !important;
    margin-left: 0 !important;
    padding-left: 35px;
    padding-top: 0 !important;
    height: 20px !important;
  }

  @include breakpoint('desktop') {
    margin: 0 50px;

    .headerWrap {
      padding: 0;
    }

    .mainTitle {
      margin: 0;
    }

    .hr {
      margin-left: 0;
      margin-right: 0;
    }

    .title {
      margin-left: 0;
      margin-right: 0;
      padding-top: 12px;
    }

    .inputContainer {
      border-radius: 8px;
      padding: 30px;
    }

    .buttonsWrap {
      flex-direction: row;
      grid-template-rows: none;

      .saveBtn {
        width: 400px;
      }

      .previewBtn {
        position: absolute;
        left: 0;
        top: 28px;
      }
    }

    .inputPersonalData {
      display: flex;

      .uploadImage {
        width: 158px;
        margin-right: 30px;
        margin-bottom: 0;
      }

      .inputRows {
        flex: 1;

        .inputRow {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          .input {
            width: calc(50% - 9px);
            margin-bottom: 30px;
          }

          .dropdown {
            margin: 0;
            width: calc((100% - 40px) / 3);
          }
        }
      }
    }
    .inputContactData {
      margin-bottom: 50px;

      .secondRow,
      .thirdRow {
        display: flex;
        justify-content: space-between;
        .input,
        .dropdown {
          width: calc(50% - 10px);
        }
      }

      .dayDropdown {
        margin: 0;
      }
      .preview {
        padding-top: 30px;
      }
    }
    .preview {
      flex: 1;
      position: relative;
      margin-right: 30px;

      .previewRow {
        display: flex;

        .label {
          width: 24%;
          margin-bottom: 0;
        }
      }

      .editBtn {
        top: -12px;
        right: -40px;
      }
    }
    .avatar {
      width: 140px;
      height: 140px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .apiError {
    color: $error-text;
    text-align: center;
    padding-bottom: 20px;
  }
}

.linksContainer {
  padding: 45px 24px 0;
}
