@import 'Styling/import.scss';

.candidateInformationsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include breakpoint('desktop') {
    flex-direction: row;
  }

  .namePositionContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: $white;
    width: 100%;

    @include breakpoint('desktop') {
      margin-right: 109px;
      align-items: flex-start;
      max-width: 191px;
    }

    h2 {
      font-family: 'DMSerifDisplay', sans-serif;
      font-size: 36px;
      margin-bottom: 18px;
      margin-top: 21px;
      text-align: center;
      color: $white;
      font-weight: 400;

      @include breakpoint('desktop') {
        margin-bottom: 20px;
        margin-top: 34px;
        text-align: left;
      }
    }

    p {
      font-size: 14px;
      font-weight: 900;
      margin-bottom: 17px;
      text-align: center;

      @include breakpoint('desktop') {
        margin-bottom: 0;
        text-align: left;
      }
    }
  }

  .basicInfoContainer {
    padding-bottom: 20px;
    border-top: 1px solid $white;
    padding-left: 20px;

    @include breakpoint('desktop') {
      border-top: none;
      border-left: 1px solid $white;
      margin-top: 30px;
      margin-bottom: 30px;
    }

    .iconTextWrapper {
      display: flex;
      align-items: center;
      color: $white;
      font-size: 14px;
      margin-top: 20px;

      @include breakpoint('desktop') {
        margin-top: 22px;

        &:first-of-type {
          margin-top: 10px;
        }
      }

      .iconWrapper {
        display: flex;
        align-items: center;
        margin-right: 20px;
      }
    }
  }
}
