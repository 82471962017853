@import 'Styling/import.scss';

.backgroundImg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: -3;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('/Assets/Images/homepage/backgroundman_1160px.jpg');

  @include breakpoint('1160px') {
    background-image: url('/Assets/Images/homepage/backgroundman_1440px.jpg');
  }
  @include breakpoint('1920px') {
    background-image: url('/Assets/Images/homepage/backgroundman_1920px.jpg');
  }
  @include breakpoint('2560px') {
    background-image: url('/Assets/Images/homepage/backgroundman_2560px.jpg');
  }
  @include breakpoint('3000px') {
    background-image: url('/Assets/Images/homepage/backgroundman_3000px.jpg');
  }
}

.backgroundColor {
  background-color: $black;
  opacity: 0.5;
  height: 100%;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: -2;
}

.main {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 6;

  .title {
    color: $white;
    text-align: center;
    padding-top: 19px;
    font-size: 24px;
    font-family: 'DMSerifDisplay', serif;
    text-transform: uppercase;
    margin: 0 30px;

    @include breakpoint('1160px') {
      display: block;
      font-size: 48px;
      max-width: 1250px;
      margin: 0 auto;
      margin-top: 100px;
    }
  }
  .handwritingTitle {
    color: $yellow3;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 43px;

    font-family: 'FingerPaint', serif;
    text-transform: uppercase;

    @include breakpoint('900px') {
      font-size: 18px;
      margin-bottom: 50px;
    }

    @include breakpoint('1160px') {
      display: block;
      font-size: 35px;
    }
  }
  .paragraphs1Wrap,
  .paragraphs2Wrap,
  .paragraphs3Wrap {
    color: $white;
    text-align: left;
    font-size: 14px;
    padding-bottom: 20px;
    border-bottom: solid 1px $brown1;

    p {
      margin-bottom: 15px;
    }

    @include breakpoint('900px') {
      font-size: 18px;
    }
  }
  .paragraphs2Wrap {
    margin-top: 50px;
  }
  .paragraphs2Wrap.handwritingTitle {
    margin-bottom: 30px;
  }
  .paragraphs3Wrap {
    margin-bottom: 40px;
  }

  .list {
    color: $white;
    font-size: 16px;
    margin-top: 25px;
    border-bottom: solid 1px $brown1;
    padding-bottom: 20px;
    margin-bottom: 20px;

    li {
      display: flex;
      flex-direction: row;
    }

    .numbering {
      margin-right: 20px;
    }
  }

  .row1 {
    margin-top: 50px;
  }

  @include breakpoint('900px') {
    .paragraphs1Wrap,
    .list {
      margin-top: 0;
      margin-right: 70px;
    }
    .paragraphs2Wrap,
    .paragraphs3Wrap {
      margin-left: 70px;
    }
    .paragraphs3Wrap,
    .list {
      border-bottom: none;
    }

    .row1 {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      margin-bottom: 30px;
      span {
        height: calc(100% - 60px);
        border-right: solid 1px $brown1;
      }
    }

    .row2 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-top: 50px;
      margin-bottom: 30px;
    }

    .paragraphs1Wrap {
      margin-top: 50px;
    }
  }

  .content {
    margin: 0 auto;
    width: 100%;
    padding: 0 30px;

    @include breakpoint('1160px') {
      max-width: 1020px;
      padding: 0;
    }
    @include breakpoint('1920px') {
      max-width: 1350px;
    }

    .subtitle {
      font-size: 18px;
      font-weight: bold;
      color: $white;
      margin-top: 30px;

      @include breakpoint('900px') {
        font-size: 25px;
        margin-bottom: 30px;
        margin-top: 0;
      }
    }

    .yellowText,
    .numbering {
      color: $yellow3;
    }
  }
  .cta {
    margin: 0 auto;
    margin-bottom: 30px;
    max-width: 360px;
  }
}
