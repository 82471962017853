@import 'Styling/import.scss';

.journeyTrackerContainer {
  display: flex;
  position: relative;
  top: -8px;
  z-index: 0;

  @include breakpoint(desktop) {
    background-color: $gray80;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  @mixin dynamicColor($color) {
    background-color: $color;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    &::after {
      border-left: 30px solid $color;
      transition: border-color 0.2s ease-in-out;
    }
  }

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    width: 100%;

    @include breakpoint(desktop) {
      flex-direction: row;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }

  li {
    display: flex;
    color: $gray30-opacity-7;
    line-height: 17px;
    padding: 22px 0;
    font-family: 'DMSerifDisplay', serif;
    cursor: pointer;

    @include breakpoint(toDesktop) {
      justify-content: center;
      align-items: center;
      border-bottom: $white-opacity-7 solid 2px;
      position: relative;
      @include dynamicColor($gray7371);

      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &:after {
        content: '';
        height: 10px;
        width: 10px;
        position: absolute;
        background-color: $gray7371;
        bottom: -7px;
        border-top: $white-opacity-7 solid 2px;
        border-left: $white-opacity-7 solid 2px;
        transform: rotate(225deg);
      }

      &:last-of-type {
        &:after {
          display: none;
        }
      }

      &.current {
        @include dynamicColor($gray7372);
        color: $white-opacity-7;

        &:after {
          background-color: $gray7372;
          border-left: $white-opacity-7 solid 2px;
        }
      }

      &:nth-child(1) {
        z-index: 5;
      }

      &:nth-child(2) {
        z-index: 4;
      }

      &:nth-child(3) {
        z-index: 3;
      }

      &:nth-child(4) {
        z-index: 2;
      }
    }
  }

  @include breakpoint(desktop) {
    li {
      flex: 1;
      height: 69px;
      padding: 0;
      padding-bottom: 10px;
      font-size: 14px;
      margin-right: 5px;
      display: inline-flex;
      position: relative;
      @include dynamicColor($gray7371);

      &:hover {
        @include dynamicColor($yellow3);
        color: $white;
      }
      &.current {
        @include dynamicColor($gray7372);
        color: $white-opacity-7;
      }

      &:first-of-type {
        border-top-left-radius: 8px;
      }

      &:last-of-type {
        border-top-right-radius: 8px;
      }

      &::before {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        border-top: 28.5px solid transparent;
        border-left: 30px solid $gray80;
        border-bottom: 28.5px solid transparent;
      }

      &::after {
        content: '';
        display: inline-block;
        background: transparent;
        width: 0;
        height: 0;
        border-top: 28.5px solid transparent;
        border-bottom: 28.5px solid transparent;
        right: -30px;
        position: absolute;
        z-index: 1;
      }

      &:first-child {
        &::before {
          display: none;
        }
      }

      &:last-child {
        margin-right: 0px;
        &::after {
          display: none;
        }
      }
    }

    .content {
      width: 100%;
      display: inline-flex;
      white-space: pre-wrap;
      align-items: center;
      padding-left: 20px;
    }
  }
}
