@import 'Styling/import.scss';

.formWrap {
  display: flex;
  align-content: center;
  align-items: center;
  max-width: 386px;
  width: 100%;
  justify-content: center;
  flex-direction: column;
}
.title {
  color: $white;
  font-family: 'DMSerifDisplay', serif;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 23px;
}

.link {
  color: $white;
  text-decoration: underline;
  text-decoration-color: $white;
  font-size: 14px;
}
.linktxt {
  color: $white;
}

.inputWrap {
  max-width: 386px;
  margin: 0 auto 17px;
  position: relative;

  .usernameField {
    max-width: 386px;
    margin-top: 20px;
  }

  .passField {
    height: 94px;
  }
}
.employercheckBox {
  svg {
    color: $white;
  }
}
.form {
  width: 100%;
  margin-bottom: 18px;
}

.logoWrap {
  margin-left: 0px;
  margin-bottom: 65px;
}

.button {
  height: 66px;
}
.forgotLink {
  display: flex;
  justify-content: center;
  color: $white;
  margin-top: 18px;
}
.imageWrap {
  position: fixed;
  width: 50%;
  height: 100%;
  top: 0;
  right: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('/Assets/Images/login-layout-background.jpg');
}
.forgotPassword {
  color: $white;
}
@media only screen and (max-width: 1024px) {
  .imageWrap {
    display: none;
  }
}
@include breakpoint('desktop') {
  .formWrap {
    margin-top: 60px;
  }
  .inputWrap {
    margin-bottom: 31px;
  }
  .forgotPassword {
    align-self: flex-start;
    color: $white;
  }
  .title {
    font-size: 36px;
    margin-bottom: 21px;
  }
  .link {
    color: $white;
  }
  .linktxt {
    color: $white;
  }
  .forgotLink {
    justify-content: left;
  }
}
