@import 'Styling/import.scss';

.completenessTitle {
  margin-left: 20px;
  margin-bottom: 10px;
  margin-top: 20px;
  font-size: 14px;
  font-weight: bold;
}

.completenessWrapper {
  display: flex;
  flex-direction: column;

  margin-right: 20px;
  margin-bottom: 10px;
  @include breakpoint('desktop') {
    flex-direction: row;
    gap: 30px;
  }
}

.mainDiv {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.topText {
  text-align: center;
  margin-top: 24px;
  font-size: 24px;
  font-family: 'DMSerifDisplay', serif;
  color: $white;
  letter-spacing: 1.2px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
}
.top {
  background-color: $yellow321;
  top: 70px;
  width: 100%;
  padding-bottom: 80px;
}
.avatarWrap {
  background-color: $white2;
  border-radius: 50%;
  width: 170px;
  height: 170px;
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translate(-50%);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $yellow321;
  font-size: 60px;

  img {
    width: 100%;
    height: auto;
  }
}
@include breakpoint(tablet) {
  .avatarWrap {
    left: 40%;
    transform: none;
  }
}
.mainContent {
  position: relative;
  background-color: $gray80;
}
.mainTitle {
  margin-bottom: 40px;
}
.title {
  font-size: 36px;
  text-align: center;
  font-family: 'DMSerifDisplay', serif;
  letter-spacing: 1.8px;
  font-weight: 400;
}
.paragraph {
  font-size: 14px;
  margin-top: 10px;
  line-height: 25px;
  text-align: center;
  font-weight: bold;
}

.completenessText {
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
  color: $black;
  font-size: 14px;
}

.rightContent {
  padding-bottom: 15px;
  margin-top: 20px;
}
.leftSection {
  padding-top: 150px;
  padding-left: 20px;
  padding-bottom: 30px;
}
.edit {
  position: absolute;
  right: 18px;
  top: 20px;
}
.locationContainer {
  display: flex;
}
.city {
  width: 388px;
  margin-right: 30px;
}
.country {
  width: 388px;
}

.industry {
  display: flex;
  padding-left: 20px;
}
.information {
  padding-left: 20px;
  font-size: 14px;
  line-height: 2;
  margin-bottom: 15px;
}
.break {
  display: block;
}
.progressBar {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 7px;
}
.spanText {
  margin-bottom: 5px;
  margin-top: 5px;
}
@include breakpoint('desktop') {
  .mainDiv {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
  .topContainer {
    display: flex;
    align-items: center;
  }
  .topText {
    font-size: 36px;
    margin-left: 240px;
    flex-direction: row;
  }
  .top {
    padding-bottom: 30px;
    padding-top: 30px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .avatarWrap {
    left: 31px;
    top: -80px;
  }
  .mainContent {
    display: grid;
    position: relative;
    width: 100%;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: unset;
  }
  .mainTitle {
    margin-bottom: 40px;
  }
  .title {
    font-size: 36px;
    text-align: none;
  }
  .paragraph {
    font-size: 14px;
    margin-top: 5px;
    line-height: 25px;
    border-bottom: none;
  }
  .leftContent {
    width: 100%;
    padding-top: 30px;
    border-bottom-left-radius: 5px;
    margin-right: 107px;
  }
  .break {
    display: none;
  }
  .leftSection {
    margin-left: 160px;
    padding-top: 0px;
  }
  .completenessText {
    margin-top: 0;
    text-align: none;
    color: rgba(0, 0, 0, 0.3);
    font-size: 14px;
    font-weight: 400;
    margin-top: 10px;
  }
  .rightContent {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 41px;
    border-bottom-right-radius: 5px;
    margin-top: 0px;
    padding-right: 15px;
  }
  .edit {
    position: absolute;
    right: 18px;
    top: 20px;
  }
  .locationContainer {
    display: flex;
  }
  .city {
    width: 388px;
    margin-right: 30px;
  }
  .country {
    width: 388px;
  }

  .industry {
    display: flex;
    padding-left: 30px;
    border-left: 1px solid $gray84;
    word-break: break-all;
  }
  .information {
    padding-left: 20px;
    font-size: 14px;
    line-height: 2;
  }
  .break {
    margin-top: 40px;
  }
  .progressBar {
    display: flex;
    justify-content: center;
  }
  .competnessContainer {
    display: flex;
    flex-direction: column-reverse;
  }
  .spanText {
    margin-bottom: 0;
    margin-top: 0;
  }
}
