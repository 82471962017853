@import 'Styling/import.scss';

.modal {
  @include breakpoint('desktop') {
    max-width: 570px;
  }
}

.containerInterview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.text {
  font-size: 18px;
  text-align: center;
  line-height: 30px;
  color: $grey2;
  font-family: 'DMSerifDisplay', serif;
  margin-bottom: 26px;
}

.description {
  font-size: 14px;
  line-height: 24px;
  color: $grey2;
  text-align: center;
  padding-bottom: 27px;
}

.button {
  color: $darkblue;
  font-size: 14px;
  font-weight: bold;
}
