@import 'Styling/import.scss';

.loadingWrap {
  width: 134px;
  height: 134px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  position: absolute;
  left: -7px;
  top: 23px;
}

.hideCircle {
  display: none;
}

.loading {
  width: 100%;
  height: 100%;
  position: relative;
}
.whiteCircle {
  width: 126px;
  height: 126px;
  position: absolute;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hold {
  position: absolute;
  width: 100%;
  height: 100%;
  clip-path: polygon(50% 0, 0 0, 0 100%, 50% 100%);
  border-radius: 100%;
  background-color: transparent;
}

.fill {
  background-color: $green2;
}

.fill {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%);
}

  .loading .left .fill {
    -webkit-animation: left 0.2s ease-out;
    -moz-animation: left 0.2s ease-out;
    animation: left 0.2s ease-out both;
  }

  .showCircle .loading .left .fill {
    -webkit-animation: left 0s ease-out;
    -moz-animation: left 0s ease-out;
    animation: left 0s ease-out both;
  }

  @keyframes left {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }

  @-webkit-keyframes left {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(180deg);
    }
  }

  .right {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .loading .right .fill {
    -webkit-animation: right 0.2s ease-out;
    -moz-animation: right 0.2s ease-out;
    animation: right 0.2s ease-out both;
    -webkit-animation-delay: 0.2s;
    -moz-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }
  .showCircle .loading .right .fill {
    -webkit-animation: right 0s ease-out;
    -moz-animation: right 0s ease-out;
    animation: right 0s ease-out both;
    -webkit-animation-delay: 0s;
    -moz-animation-delay: 0s;
    animation-delay: 0s;
  }

  @keyframes right {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }

  @-webkit-keyframes right {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(180deg);
    }
  }

  .loading.unreview .left .fill {
    -webkit-animation: leftBack 0.2s ease-out;
    -moz-animation: leftBack 0.2s ease-out;
    animation: leftBack 0.2s ease-out both;
    -webkit-animation-delay: 0.2s;
    -moz-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }

  .showCircle .loading.unreview .left .fill {
    -webkit-animation: leftBack 0s ease-out;
    -moz-animation: leftBack 0s ease-out;
    animation: leftBack 0s ease-out both;
    -webkit-animation-delay: 0s;
    -moz-animation-delay: 0s;
    animation-delay: 0s;
  }
  .loading.unreview .right .fill {
    -webkit-animation: rightBack 0.2s ease-out;
    -moz-animation: rightBack 0.2s ease-out;
    animation: rightBack 0.2s ease-out both;
  }
  .showCircle .loading.unreview .right .fill {
    -webkit-animation: rightBack 0s ease-out;
    -moz-animation: rightBack 0s ease-out;
    animation: rightBack 0s ease-out both;
  }

  @keyframes rightBack {
    0% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  @-webkit-keyframes rightBack {
    0% {
      -webkit-transform: rotate(180deg);
    }
    100% {
      -webkit-transform: rotate(0deg);
    }
  }

  @keyframes leftBack {
    0% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  @-webkit-keyframes leftBack {
    0% {
      -webkit-transform: rotate(180deg);
    }
    100% {
      -webkit-transform: rotate(0deg);
    }
  }

@include breakpoint('toDesktop') {
  .line {
    display: none;
  }
}

@include breakpoint('desktop') {
  .loadingWrap {
    margin: 0;
    position: absolute;
    left: 23px;
    top: -47px;
  }
}
