@import 'Styling/import.scss';

.wrap {
  @include breakpoint('desktop') {
    margin: 0 50px;
  }
  .minifeed {
    .candidateDashboardWrap {
      position: absolute;
      z-index: 110;
      left: 50px;
      right: 50px;
      margin-top: -24px;
    }
  }
}

.linksContainer {
  padding: 0 18px;

  @include breakpoint('desktop') {
    padding: 45px 24px 0 24px;
  }
}

.myOffersContainer {
  margin-top: 30px;
  padding: 0 18px;

  @include breakpoint('desktop') {
    padding-left: 24px;
    margin-top: 50px;
  }
}

.breadcrumbsWrapper {
  display: none;

  @include breakpoint('desktop') {
    display: block;
  }
}
