@import 'Styling/import.scss';

.sidebar {
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  background-color: $black3;

  .avatarImageContainer {
    display: grid;
    grid-template-columns: 28.53% 1fr 28.53%;
    width: 100%;
    align-items: center;
  }

  .darkGrayHr {
    border-top: 1px solid $gray35;
  }
  .firstHrWrap {
    padding-top: 35px;
  }
  .noPublicOccupation {
    margin-top: 24px;
  }
  .noLongerActiveCandidateMessage {
    color: $gray36;
    font-size: 14px;
    line-height: 21.89px;
    margin-top: 9px;
    display: flex;
    justify-content: center;
  }
}
