@import 'Styling/import.scss';

.description {
  font-size: 14px;
  color: $black4;
  margin-bottom: 40px;
  text-align: center;

  @include breakpoint('desktop') {
    color: $black2;
  }
}
