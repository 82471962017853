@import 'Styling/import.scss';

.cardWrap {
  border-radius: 8px;
  background-color: $white;
  width: 100%;
  flex-direction: column;
  position: relative;
  display: grid;
  border: 2px solid $white;
  margin-bottom: 20px;

  .notToClose {
    display: block;
  }

  grid-template-areas:
    'cardHeader cardHeader cardHeader'
    'cardContent cardContent cardContent'
    'tests tests tests';
  grid-template-columns: 200px 1fr 1fr auto;
  grid-template-rows: auto 1fr auto;

  .cardHeader {
    grid-template-areas:
      'cardCheck ctas ctas'
      'avatar position position';
    grid-template-columns: 140px auto 1fr;
    grid-area: cardHeader;
    display: grid;
    padding: 20px;
    border-bottom: 1px solid $gray15;
    position: relative;
    padding-bottom: 66px;
    margin: -2px -2px 0 -2px;
  }

  .cardCheckInput {
    grid-area: cardCheck;
    margin: 0;
    padding: 10px 0;
    width: 30px;
    svg {
      cursor: pointer;
    }
  }
  .rateWrap {
    position: absolute;
    left: 0;
    bottom: 0;
    margin-bottom: 20px;
    margin-left: 24px;
  }

  .candidatePosition {
    position: relative;
    grid-area: position;
    display: flex;
    justify-content: center;
    font-size: 20px;
    padding: 10px 0;
    margin-top: 30px;
    flex-direction: column;
    overflow: hidden;

    .profileUrl {
      font-size: 20px;
      color: $black;
      @include ellipsisSimple;

      &:focus,
      &:active,
      &:hover,
      &:visited {
        color: $black;
      }
    }

    .fullNameWrap {
      text-decoration: none;
      @include ellipsisSimple;
      display: flex;
      flex-direction: column;
      @include breakpoint('desktop') {
        flex-direction: row;
        align-items: center;
      }
    }

    .fullName {
      font-weight: bold;
      color: $yellow;
      font-size: 14px;
    }
    .comma {
      padding-right: 3px;
    }
    .fullNameAdditionalText {
      color: $gray15;
      font-size: 14px;
      line-height: 22px;
      text-decoration: none;
    }

    @include breakpoint('desktop') {
      .fullName {
        top: 30px;
      }
      padding: 0;
      margin-top: 0;
    }
  }

  .candidateUnderline {
    text-decoration: underline;
  }
  .callsToAction {
    grid-area: ctas;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: flex-end;

    .bookmarkWrap {
      display: flex;
      align-items: center;
      color: $gray15;
      text-decoration: underline dotted $gray15;
      font-size: 14px;
      text-underline-position: under;
      cursor: pointer;
      margin-right: 10px;

      .text {
        display: none;
        margin-left: 8px;
        word-break: keep-all;
      }

      @include breakpoint('desktop') {
        .text {
          display: block;
        }
      }
    }
    .popOverListContainer {
      color: $gray30;
      transition: 0.3s fill linear;
    }

    .contactAction {
      margin-left: 20px;
      font-size: 14px;
      color: $blue1;
      border: 1px solid $gray29;
      background-color: $white2;
      border-radius: 8px;
      height: 40px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .moreAction {
      border: 1px solid $gray29;
      background-color: $white2;
      border-radius: 8px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .acceptedCtas {
      display: flex;
      flex-direction: row;
      gap: 20px;

      .descriptionIcon {
        color: $blue1;
        border: 1px solid $gray29;
        background-color: $select-item-background-color;
        border-radius: 8px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .videoIcon {
        color: $blue1;
        border: 1px solid $gray29;
        background-color: $select-item-background-color;
        border-radius: 8px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }

    @include breakpoint('desktop') {
      .rateWrap {
        position: relative;
        margin-bottom: 0;
        margin-left: 0;
      }
    }
  }

  .cardAvatarWrap {
    position: relative;
    width: 100%;

    .cardAvatar {
      grid-area: avatar;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      background-color: $gray-cyan;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      margin-right: 20px;
      position: relative;

      svg {
        color: $orange2;
        font-size: 43px;
      }
    }
    .noBackground {
      background-color: transparent;
    }
    @include breakpoint('desktop') {
      .cardAvatar {
        margin: 0;
        position: absolute;
        left: 30px;
        top: -40px;
      }
    }
  }

  .cardContent {
    padding: 20px;
    grid-area: cardContent;
    min-height: 120px;
    margin: 0 -2px -2px -2px;
  }

  .headerSelected {
    background-color: $gray31;
    border-radius: 8px 8px 0 0;
  }

  &.openedCard {
    border: 2px solid $yellow;
  }

  .testsWrap {
    grid-area: tests;
    margin: 20px;
    padding-top: 20px;
    margin-top: 0;
    border-top: 1px $gray31 solid;
    display: flex;
    flex-direction: row;
    gap: 10px;

    .testLink {
      width: 39px;
      height: 39px;
      border-radius: 50%;
      border: solid 1px $gainsboro;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      background-color: $white2;
      color: $white;
      z-index: 0;
      cursor: pointer;

      div {
        display: flex;
      }
      .badgeGreen {
        background-color: $green3;
        border-radius: 50%;
        > svg {
          font-size: 15px;
        }
      }
      .badgeYellow {
        background-color: $yellow321;
        border-radius: 50%;
        > svg {
          font-size: 15px;
        }
      }
    }

    .chartIcon {
      width: 21px;
      height: 21px;
      color: rgba(0, 0, 0, 0.3);
    }
  }

  @include breakpoint('desktop') {
    grid-template-areas:
      'cardHeader cardHeader cardHeader cardHeader'
      'avatar cardContent cardContent tests';
    grid-template-columns: 200px 1fr 1fr auto;
    grid-template-rows: auto 1fr;

    .cardHeader {
      padding: 20px;
      grid-template-areas: 'cardCheck position ctas';
      grid-template-columns: 180px 1fr auto;
    }

    .cardContent {
      padding-left: 0;
    }

    .testsWrap {
      margin: 20px 20px 0 0;
      padding-top: 0;
      border-top: none;
      flex-direction: column;
    }
  }

  &.myProject {
    .testsWrap {
      padding-top: 12px;
      min-height: 89px;
    }

    .allProjectsList {
      align-items: flex-end;
      margin-bottom: 20px;
      font-size: 14px;
      color: $black-opacity-3;
    }

    @include breakpoint('desktop') {
      grid-template-columns: 200px 31% 31% auto;

      .cardHeader {
        border-bottom: none;
        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          right: 0;
          width: calc(100% - 170px);
          border-top: 1px solid $gray31;
        }
      }

      .testsWrap {
        flex-direction: row-reverse;
      }
    }
  }
  .badges {
    margin-top: 10px;
    display: flex;
    gap: 15px;
  }
}
