@import 'Styling/import.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include breakpoint('desktop') {
    justify-content: flex-start;
    margin-right: 180px;
      min-width: 260px;
  }

  .containerRight {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .icon {
    text-align: right;
    width: 120px;
  }
  .text {
    font-size: 14px;
    line-height: 22px;
    color: $black;
    padding-right: 10px;

    @include breakpoint('desktop') {
      flex-basis: 50%;
    }
  }

  .textIcon {
    text-align: left;
    color: $gray15;
    display: none;
    @include breakpoint('desktop') {
      display: block;
      padding-left: 20px;
    }
  }
}

.hr {
  margin: 10px 0px;
}
