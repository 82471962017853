@import 'Styling/import.scss';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 40px 15px 12px 20px;
  position: relative;
}

@include breakpoint('desktop') {
  .header {
    padding-left: 0;
  }
}

.noPaddingX {
  padding: 21px 0;
}

