@import 'Styling/import.scss';

.employmentInformation {
  .title {
    margin-bottom: 19px;
    &:not(:first-child) {
      margin-top: 19px;
    }
  }

  .hr, .hrView {
    margin: 30px 0 22px;
  }

  .text {
    font-size: 14px;
    line-height: 22px;
    white-space: pre-wrap;

    @include breakpoint('desktop') {
      padding: 0 40px 0 48px;
    }
  }

  @include breakpoint('desktop') {
    .title {
      margin-bottom: 29px;
      &:not(:first-child) {
        margin-top: 29px;
      }
    }
  }

  .editButtonWrap {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}

.modal {
  max-width: 980px;
  min-width: 80%;
  @include breakpoint('toDesktop') {
    .hr.hrStronger {
      margin: 30px 0;
    }
    .title.titleStronger {
      &:first-child, &:not(:first-child) {
        line-height: 25px;
        margin-bottom: 30px;
      }
    }
  }
}

.header {
  h2 {
    font-weight: 400;
  }
}

.sectionItem {
  margin-bottom: 0;
}

.itemHeader {
  @include breakpoint('toDesktop') {
    margin-bottom: 20px;
  }

  div {
    &:first-of-type {
      margin-right: 11px;

      @include breakpoint('desktop') {
        margin-right: 20px;
      }
    }
  }
}

.showMoreContent.showMoreContentStronger {
  @include breakpoint('desktop') {
    hr {
      margin: 20px 50px 20px 48px;
    }
    button {
      margin-left: 40px;
    }
  }
}

.textArea {
  textarea {
    border-radius: 10px;
    font-size: 16px;
    color: $grey2;
    padding: 25px 20px;

    @include breakpoint('toDesktop') {
      padding: 20px;
    }
  }
}
