@import 'Styling/import.scss';

.title {
  font-size: 24px;
  margin-bottom: 40px;
}

.typeAheadContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
}

.inputMainContainer {
  position: relative;
}

.inputContainer {
  display: flex;
  background-color: $white2;
  padding: 10px 8px 0 20px;
  border-radius: 8px;
  min-height: 64px;
  border: 1px solid $input-border;

  &:hover,
  &:focus {
    border: 1px solid $input-focus-border;
  }
  &:active {
    border: 1px solid $input-active-border;
  }
}

.inputWrap {
  flex: 1;
}

.inputChip {
  margin-bottom: 8px;
}

.arrowWrap {
  display: flex;
  align-items: center;
}

.inputTopPlaceholder {
  color: $grey1;
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 12px;
  text-align: left;
}

.inputValues {
  display: flex;
  flex-wrap: wrap;
}

.searchCandidates {
  .inputValues {
    margin-top: 9px;
  }
  .arrowWrap {
    margin-bottom: 10px;
  }
}
.input {
  color: $grey2;
  border: none;
  background-color: #f5f7f8;
  height: 24px;
  font-size: 16px;
  display: block;
  flex: 1 1;
  min-width: 100%;
  margin-bottom: 8px;
  &:focus {
    outline: none;
  }

  &::placeholder {
    color: $grey2;
    font-size: 16px;
  }

  @include breakpoint('tablet') {
    min-width: auto;
    width: 100%;
  }
}

.dropdownContainer {
  position: absolute;
  left: 0;
  right: 0;
  background-color: $white;
  border-radius: 10px;
  padding: 22px 20px;
  display: flex;
  flex-direction: column;
  max-height: 350px;
  overflow-y: auto;
  margin-bottom: 20px;
  z-index: 1;
  @include listBoxShadow();
  @include breakpoint('desktop') {
    bottom: auto;
  }
}

.checkBoxWrap {
  display: flex;
  margin: 0 -20px;
  padding: 0 20px;
  align-items: center;
  line-height: 38px;

  &:hover,
  &:focus {
    background-color: $white2;
    outline: none;
  }

  .icon {
    margin-right: 19px;
  }

  .checkBoxText {
    @include ellipsis(1);
    word-break: break-all;
    color: $grey2;
  }

  .button {
    display: flex;
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
  }
}

.hidden {
  display: none;
}

.white,
.modal {
  .inputContainer {
    background-color: $white;
    padding: 20px;
  }
  .input {
    background-color: $white;
    margin-bottom: 0;
  }
}

.modal,
.modalSimpleWhite {
  @include breakpoint('desktop') {
    margin: $input-modal-top-margin 0 $input-modal-bottom-margin;
  }
}

.simpleWhite,
.modalSimpleWhite {
  .inputContainer {
    background-color: $white;
  }
  .input {
    background-color: $white;
  }
}

.error {
  border: 1px solid $error-border;
  border-radius: 8px;
  &:hover,
  &:focus,
  &:active {
    border: 1px solid $error-border;
  }
}

.likeDropdown {
  .inputContainer {
    padding-right: 20px;
  }
}

.typeAheadContainer.withoutChips {
  .inputContainer {
    padding-bottom: 10px;
    align-items: center;
    overflow: hidden;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    .inputWrap {
      width: calc(100% - 24px);
    }
  }
  .inputValues {
    .input {
      height: auto;
      background-color: transparent;
      margin-bottom: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .dropdownContainer {
    .input {
      background-color: $white;
      font-weight: 700;
      border-bottom: 1px solid $grey1;
      height: 70px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      padding: 0 20px 10px;
      min-height: 50px;
      margin: 0 -20px 10px;
      width: calc(100% + 40px);
    }
  }
  &.collapsed {
    .inputContainer {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}
.typeAheadContainer.blue,
.typeAheadContainer.blue-left {
  .inputContainer {
    background-color: $white;
    min-height: 48px;
  }
  .dropdownContainer {
    width: calc(200% + 22px);
    border-top-left-radius: 0;
    box-shadow: none;
  }

  &.collapsed {
    .inputContainer {
      background-color: rgb(245, 247, 248, 0.2);
      .arrowWrap {
        color: $white;
      }
    }
    .inputValues {
      .input {
        color: $white;
      }
    }
  }
}

.typeAheadContainer.blue-left {
  .dropdownContainer {
    left: calc(-100% - 22px);
    border-top-right-radius: 0;
    border-top-left-radius: 12px;
  }
}

.typeAheadContainer.keyword {
  .dropdownContainer {
    box-shadow: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 1px solid $grey1;
  }
  .checkBoxTextWithCategory {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    line-height: 22px;
    padding: 5px 0;
    .checkBoxTextTranslation {
      flex: 1;
    }
    .checkBoxTextCategory {
      color: $grey1;
      font-size: 12px;
      margin-left: 5px;
    }
  }
  .inputContainer {
    padding-top: 12px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .inputTopPlaceholder {
    margin-bottom: 10px;
  }
  .inputValues {
    height: 28px;
  }
  &.collapsed {
    .inputContainer {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}

.typeAheadContainerWithCategory {
  .dropdownContainer {
    padding: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 1px solid $grey1;
    max-height: 195px;

    .categoryWrap {
      width: 100%;
      .categoryNameWrap {
        background-color: $white2;
        color: $grey2;
        display: flex;
        padding: 0 20px;
        height: 38px;
        align-items: center;
        .categoryNameIcon {
          display: flex;
          align-items: center;
        }
        .categoryName {
          font-size: 14px;
          font-weight: 700;
          margin-left: 4px;
          text-align: left;
          @include ellipsisSimple();
        }
      }
      .categories {
        max-width: 9999px;
        overflow: hidden;
        transition: max-height 0.2s ease-in-out;
        &.categoriesClosed {
          max-height: 0;
        }
        .checkBoxWrap {
          padding: 0 20px 0 45px;
          margin: 0;
          cursor: pointer;
        }
      }
    }
  }
}

.searchTypeAheadContainer {
  .inputValues {
    color: $grey2;
    display: block;
    @include ellipsisSimple();
  }
  .inputValuesText {
    height: 24px;
    font-size: 16px;
  }
  &.collapsed .inputValues {
    color: $white;
  }
}

.typeAheadContainer.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.typeAheadContainer.requests.withoutChips {
  .inputContainer {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;

    .inputValues {
      .input {
        flex: none;
      }
    }
  }

  &.open {
    .inputContainer {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .dropdownContainer {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}
