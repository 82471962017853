@import 'Styling/import.scss';

.formWrap {
  display: flex;
  align-content: center;
  align-items: center;
  max-width: 386px;
  width: 100%;
  justify-content: center;
  flex-direction: column;
}

.description {
  display: inline-block;
  text-align: center;
  margin-bottom: 40px;
}

.link {
  color: $black4;
  text-decoration: underline;
  font-size: 14px;
}

.inputWrap {
  width: 100%;
  margin: 0 auto 37px;
  position: relative;
}

.form {
  width: 100%;
  margin-bottom: 18px;
}

.logoWrap {
  margin-left: 0px;
  margin-bottom: 65px;
}

.imageWrap {
  position: fixed;
  width: 50%;
  height: 100%;
  top: 0;
  right: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('/Assets/Images/login-layout-background.jpg');
}
@media only screen and (max-width: 1024px) {
  .imageWrap {
    display: none;
  }
}
@include breakpoint('desktop') {
  .formWrap {
    margin-top: 60px;
  }
  .link {
    color: $grey3;
  }
  .inputWrap {
    margin-bottom: 31px;
  }
  .forgotPassword {
    align-self: flex-start;
  }
}

.loginButton {
  min-height: 66px;
}

.passwordField {
  margin-bottom: 30px;
}
