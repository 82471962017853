@import 'Styling/import.scss';
.modal {
  @include breakpoint('desktop') {
    max-width: 980px;
    width: 100%;
    :global {
      .content {
        padding: 25px 80px;
      }
    }
  }
}
.title {
  font-size: 24px;
  color: $black;
  font-family: 'DMSerifDisplay', serif;
  margin-bottom: 40px;
  text-transform: uppercase;
}
.attachmentWrap {
  width: 100%;
  
}
.attachmentTitle {
  font-family: 'DMSerifDisplay', serif;
    color: $black;
    text-transform: uppercase;
    margin-right: 10px;
    font-size: 24px;
    line-height: 40px;
}