@import 'Styling/import.scss';
.avatar {
  width: 120px;
  height: 120px;
  margin: 0 auto;
  border-radius: 50%;
  user-select: none;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    width: 100%;
    height: 100%;
    background-color: $yellow3;
    text-transform: uppercase;
    text-align: center;
    line-height: 120px;
    font-size: 60px;
    color: white;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  svg {
    color: $yellow321;
    font-size: 43px;
  }
}
