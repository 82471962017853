@import 'Styling/import.scss';

.modal {
  padding: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    .text {
      margin-top: 17px;
      font-size: 14px;
      color: $white;
      text-align: center;
    }
  }
}
