@import 'Styling/import.scss';

.formWrap {
  display: flex;
  align-content: center;
  align-items: center;
  width: 100%;
  justify-content: center;
  flex-direction: column;
}

.inputWrap {
  width: 100%;
  max-width: 386px;
  margin: 0 auto;
}

.link {
  display: inline-block;
  color: $black4;
  text-decoration: underline;
  font-size: 14px;
  margin-top: 37px;
}

.form {
  width: 100%;
}

.successMessage {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $green2;
  margin-bottom: 25px;
  background: rgba(255,255,255, 0.7);
  padding: 10px;
  border-radius: 10px;

  svg {
    fill: $green2;
    margin-right: 18px;
  }
}

@include breakpoint('desktop') {
  .formWrap {
    margin-top: 60px;
  }
  .link {
    color: $grey3;
    align-self: flex-start;
  }
  .inputWrap {
    margin-bottom: 31px;
  }
  .description {
    max-width: 323px;
  }
}
