@import 'Styling/import.scss';

.subNavigation {
  li {
    &:nth-child(6) {
      color: $black;
    }
  }
}

.contentContainer {
  padding-bottom: 49px;
  background-color: $gray17;

  @include breakpoint('desktop') {
    padding: 0 50px 44px;
  }

  .companyProfileButtonContainer {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    .buttonWrapper {
      max-width: 266px;
      height: 65px;

      @include breakpoint('desktop') {
        max-width: 386px;
      }
    }
  }
}
.sideContentWrap {
  margin-top: 85px;
  padding-left: 25px;
  padding-right: 25px;
}
