@import 'Styling/import.scss';
.modal {
  @include breakpoint('desktop') {
    max-width: 421px;
    :global {
      .content {
        padding: 31px 30px;
      }
    }
  }
}

.deleteModal {
  display: flex;
  align-items: center;
  justify-content: center;
  .icon {
    font-size: 52px;
  }
  p {
    margin-left: 20px;
    font-size: 14px;
    color: $grey2;
    line-height: 22px;
  }
}
