@import 'Styling/import.scss';

.container {
  margin-bottom: 30px;
  @include breakpoint('desktop') {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
}

.titleAndLanguagesContainer {
  display: inline-block;
}

.languagesWrapper {
  margin-left: auto;
  margin-bottom: 20px;
  display: block;
}

.visibility {
  display: flex;
}
.visibilityIcon {
  margin-right: 18px;
  margin-top: 6px;
  margin-left: 5px;
  cursor: pointer;
}

.hr {
  margin-top: 30px;
}

.title {
  line-height: 28px;
}

.deleteFormikItemButtonWrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  .deleteFormikItemButton {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: transparent;
    color: $black;
    height: 100%;
    width: fit-content;
    font-size: 14px;

    div {
      display: flex;
      align-items: center;
      margin-right: 9px;
    }
  }
}

@include breakpoint('tablet') {
  .titleAndLanguagesContainer {
    display: flex;
  }

  .title {
    padding-bottom: 30px;
  }

  .languagesWrapper {
    margin-left: auto;
  }
}

@include breakpoint('desktop') {
  .title {
    padding-bottom: 0;
  }
}
