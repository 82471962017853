@import 'Styling/import.scss';

.container {
  display: flex;
  padding-top: 18px;
  border-bottom: 1px solid $gray98;
  padding-bottom: 15px;
  padding-right: 15px;
  align-items: center;

  @include breakpoint('toDesktop') {
    padding-top: 15px;
    align-items: center;
    padding-bottom: 6px;
    border-bottom: none;
    padding-right: 0;
  }

  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    background-color: $gray335;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .avatarLetters {
    background-color: $yellow3;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 50px;
    min-height: 50px;
    max-height: 50px;
    border-radius: 50%;
    font-size: 20px;
    color: $black;
    padding-top: 2px;

    @include breakpoint('toDesktop') {
      min-width: 36px;
      min-height: 36px;
      font-size: 15px;
    }
  }

  .avatarErrorCandidate {
    background-color: $yellow3;
    color: $white;
  }

  .content {
    flex-grow: 1;
    padding-left: 20px;
    padding-bottom: 15px;

    @include breakpoint('toDesktop') {
      padding-bottom: 0;
    }

    .up {
      display: flex;
      justify-content: space-between;

      .nameUser {
        color: $blue2;
        font-size: 14px;
        font-weight: bold;
        padding-bottom: 6px;

        @include breakpoint('toDesktop') {
          max-width: 150px;
        }
      }

      .dateSent {
        color: $black;
        font-size: 12px;
      }
    }

    .jobTitle {
      color: $gray23;
      font-size: 12px;
      font-weight: bold;
    }
  }
}

.mobileContainer {
  margin-bottom: 15px;
  border-bottom: 1px solid $gray98;
  padding-bottom: 10px;
}

.message {
  font-size: 15px;
  color: $gray335;
}
