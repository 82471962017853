@import 'Styling/import.scss';

.sidebar {
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  background-color: $black3;

  .avatarImageContainer {
    display: grid;
    grid-template-columns: 28.53% 1fr 28.53%;
    width: 100%;
    align-items: center;
  }

  .darkGrayHr {
    border-top: 1px solid $gray35;
  }

  .firstHrWrap {
    padding-top: 35px;
  }

  .imgWrapp {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image {
    overflow: hidden;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    border: none;
    opacity: 0.2;
  }
  .noLongerActiveCandidateMessage {
    color: $gray36;
    font-size: 14px;
    line-height: 21.89px;
    margin-top: 9px;
    display: flex;
    justify-content: center;
  }
}
.avatar {
  width: 120px;
  height: 120px;
  margin: 0 auto;
  border-radius: 50%;
  user-select: none;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    width: 100%;
    height: 100%;
    background-color: $yellow3;
    text-transform: uppercase;
    text-align: center;
    line-height: 120px;
    font-size: 60px;
    color: white;
  }
}
.darkGrayHr {
  border-top: 1px solid $gray35;
}
.hrWrapp {
  padding-top: 35px;
}
.searchCandidatesWrapper {
  margin-top: 46px;
}
