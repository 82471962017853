@import 'Styling/import.scss';

.hr {
  margin-top: 30px;
}

.visibility {
  display: flex;
  align-items: center;
}

.visibilityIcon {
  margin-right: 18px;
  cursor: pointer;
}

.deleteFormikItemButtonWrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  .deleteFormikItemButton {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: transparent;
    color: $black;
    height: 100%;
    width: fit-content;
    font-size: 14px;

    div {
      display: flex;
      align-items: center;
      margin-right: 9px;
    }
  }
}
