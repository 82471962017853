@import 'Styling/import.scss';
.container {
  position: relative;
  z-index: 1;

  &:after {
    z-index: -1;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    background-color: $blue9568;
    opacity: 0.5;
    border: 1px;
  }
  .background {
    position: absolute;
    left: 0px;
    z-index: -1;
    object-fit: cover;
    width: 100%;
    height: 100%;
    filter: blur(50px);
  }
  .contentLayout {
    margin: 0 22px;
    @include breakpoint('1160px') {
      margin: 0 150px;
    }
    .logoContainer {
      display: flex;
      padding-top: 34px;
      padding-bottom: 63px;
      @include breakpoint('768px') {
        display: block;
        padding-top: 42px;
        padding-bottom: 26px;
      }
      .logoStyle {
        width: 160px;
        height: auto;
      }
    }
  }
}
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 110px;
  .logo {
    width: 158px;
    height: 17px;
  }

  .title {
    font-size: 36px;
    color: $orange;
  }
  @include breakpoint(tablet) {
    justify-content: flex-start;
  }
}
.options {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  @include breakpoint(tablet) {
    flex-direction: row;
    width: 100%;
    margin-top: 39px;
  }

  .option {
    padding: 19px 22px 25px;
    background-color: $orange;
    min-height: 144px;
    border-radius: 8px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: $white;
    width: 100%;
    @include breakpoint(tablet) {
      flex-grow: 1;
      margin-right: 50px;
      margin-bottom: 0px;
      &:last-child {
        margin-right: 0;
      }
    }

    .optionIcon {
      font-size: 40px;
      margin-bottom: 14px;
    }

    .optionTitle {
      font-size: 24px;
      font-family: 'DMSerifDisplay', serif;
      margin-bottom: 6px;
    }
    .optionText {
      font-size: 14px;
      a {
        color: $white;
      }
    }
  }
}

.questions {
  display: flex;
  flex-direction: column;
  margin-top: 69px;

  @include breakpoint(tablet) {
    margin-top: 48px;
  }

  .questionUp {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 13px;
    margin-bottom: 20px;
    .questionsTitle {
      font-size: 36px;
      line-height: 40px;
      color: $orange;
      font-family: 'DMSerifDisplay', serif;
    }
  }

  .questionDown {
    display: flex;
    flex-direction: column;
    @include breakpoint(tablet) {
      flex-direction: row;
    }
    .questionCard {
      padding: 32px 20px 20px;
      background-color: rgba(255, 255, 255, 0.3);
      margin-bottom: 30px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      width: 100%;
      @include breakpoint(tablet) {
        flex-grow: 1;
        margin-right: 50px;
        &:last-child {
          margin-right: 0;
        }
      }

      .questionCardTitle {
        font-size: 24px;
        color: $orange;
        font-family: 'DMSerifDisplay', serif;
        margin-bottom: 30px;
        align-self: center;
      }
      .questionCardLink {
        font-size: 14px;
        padding: 20px 0px;
        border-top: 1px solid $gray9928;
        a {
          color: $blue1;
        }
      }
      .questionCardLinkAll {
        padding: 20px 0px 0px;
        border-top: 1px solid $gray9928;
        color: $blue1;
        display: flex;
        justify-content: center;
        font-size: 12px;
        text-decoration: underline;
        a {
          color: $blue1;
        }
        &:hover,
        &:focus,
        &:active {
          text-decoration: none;
        }
      }
    }
  }
}

.promoted {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 20px;
  padding-bottom: 70px;
  @include breakpoint(tablet) {
    flex-direction: row;
    padding-bottom: 20px;
  }

  .promotedCard {
    position: relative;
    .image {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 3;
      border-radius: 8px;
      top: 0px;
      min-height: 289px;
      object-position: right top;
    }

    padding: 42px 50px;
    display: flex;
    flex-direction: column;
    min-height: 289px;
    width: 100%;

    margin-bottom: 30px;
    border-radius: 8px;
    justify-content: flex-end;
    align-items: center;
    font-size: 24px;
    color: $white;
    &:after {
      z-index: 4;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: inline-block;
      background-color: $black;
      opacity: 0.4;
      border-radius: 8px;
      border: 1px;
    }
    @include breakpoint(tablet) {
      flex-grow: 1;
      margin-right: 50px;
      &:last-child {
        margin-right: 0;
      }
    }

    .promotedCardTitle {
      z-index: 5;
      font-size: 24px;
      line-height: 24px;
      color: $white;
      font-family: 'DMSerifDisplay', serif;
      text-align: center;
    }

    .promotedCardBtn {
      z-index: 5;
      margin-top: 25px;
      font-size: 14px;
      color: $white;
      background-color: $orange;
      width: 148px;
      height: 37px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
    }
  }
}
