@import 'Styling/import.scss';
.red {
  color: $red3;
  font-size: 27px;
}
.green {
  color: $green4;
  font-size: 27px;
}
.buttonThumb {
  transition: 0.2s ease-in-out;
  
  &:hover,
  &:focus,
  &:active{
    background-color: $blue2;
    color: $white;
  }
  div {
    margin-right: 5px;
  }
}
