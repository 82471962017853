@import 'Styling/import.scss';

:global {
  .dataProtectionAgreementContainer {
    padding: 0 22px 70px 20px;

    @include breakpoint(tablet) {
      padding: 0 55px 128px 49px;
    }

    .mainTitle {
      text-align: left;
      font-size: 48px;
      font-family: 'DMSerifDisplay', sans-serif;
      line-height: 58px;
      color: $yellow;
      font-weight: normal;
      padding: 0;

      @include breakpoint(tablet) {
        font-size: 60px;
        letter-spacing: 3px;
      }
    }

    .breadcrumbsWrapper {
      display: flex;
      align-items: center;
      padding-bottom: 26px;
      padding-top: 24px;
      border-bottom: 1px solid $grey1;

      @include breakpoint(tablet) {
        padding-top: 56px;
      }
    }

    .subtitle {
      text-align: center;
      margin-top: 20px;
    }

    ol {
      list-style-type: none;
      counter-reset: item;
    }

    .firstLevelOrderedList,
    .annexFirstLevelOrderedList {
      li {
        display: table;
        counter-increment: item;
        padding-bottom: 26px;
        font-family: 'DMSerifDisplay', serif;
        font-size: 24px;
        line-height: 30px;
        color: $black3;
        font-weight: 700;

        &:first-child {
          padding-top: 26px;
        }

        &:before {
          content: counters(item, '.') '. ';
          display: table-cell;
          padding-right: 10px;
          font-weight: 700;
        }
      }
    }

    .secondLevelOrderedList,
    .annexSecondLevelOrderedList,
    .noStyleOrderedList {
      li {
        font-size: 14px;
        line-height: 24px;
        color: $black3;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        padding: 0;
        margin-bottom: 10px;
        padding-bottom: 0;
      }
    }

    .annexSecondLevelOrderedList {
      counter-reset: list;

      li {
        list-style: none;
        counter-reset: item;
      }

      li:before {
        counter-increment: list;
        content: counter(list, lower-alpha) ') ';
      }
    }

    .noStyleOrderedList {
      span {
        margin-bottom: 10px;
      }

      .indentedText {
        margin-bottom: 0;
      }

      li {
        &::before {
          content: '';
        }
      }
    }

    .signatureContainer {
      .place {
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .director {
        margin-top: 10px;
      }
    }

    .annexContainer {
      h1 {
        text-align: center;
        padding-bottom: 0;
      }

      .specificationTitle {
        margin-top: 30px;
      }
    }

    .articleContainer {
      .articleTitle {
        font-weight: bold;
        text-align: center;
        margin-bottom: 10px;
      }

      .underlinedText {
        text-decoration: underline;
      }

      .indentedText {
        margin-left: 30px;
        margin-top: 0;
        padding-top: 0;

        &:first-child {
          padding-top: 0;
        }
      }
    }

    .annexTitle,
    .firstLevelList {
      padding: 26px 0px 35px;
      font-family: 'DMSerifDisplay', serif;
      font-size: 24px;
      line-height: 30px;
      color: $black3;
    }

    p,
    .basicListStyle,
    .noStyleOrderedList {
      font-size: 14px;
      line-height: 24px;
      color: $black3;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
    }

    p,
    li {
      span {
        display: inline-block;
        margin-top: 10px;
      }
    }

    .statement {
      margin-bottom: 26px;
    }
  }
}
