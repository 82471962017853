@import 'Styling/import.scss';

.shackbar {
  position: relative !important;
  left: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  @include breakpoint('mobile') {
    margin-bottom: 0px;
    margin-top: 10px;
    width: 100% !important;
  }

  margin-bottom: 10px;
  @include breakpoint('tablet') {
    bottom: 0px !important;
    right: 0px !important;
    left: 0 !important;
    margin-right: 120px;
  }
}

.shackbarMessage {
  position: relative;
  color: $yellow3;
  font-size: 14px;
  background-color: $black3;

  height: 50px;
  display: block;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  @include breakpoint('mobile') {
    width: 100% !important;
  }

  span {
    padding-left: 40px;
  }

  @include breakpoint('tablet') {
    border-radius: 5px;

    span {
      padding: 0px 24px;
    }
  }
}

.container {
  position: fixed;
  bottom: 0%;
  z-index: 5000;
  right: 0px;
  @include breakpoint('mobile') {
    width: 100% !important;
  }

  .containerFlex {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    position: relative;
  }
}
