@import '/src/Styling/import';

.breadcrumbsWrapper {
  display: none;

  @include breakpoint('desktop') {
    display: block;
  }
}

.pageTempBackground {
  background-color: #efefef;
  width: 100%;
  height: 100vh;
}

.employerRequestsWrap {
  display: flex;
  flex-direction: column;
  background-color: $white;
  position: relative;
  z-index: 0;
  top: -20px;

  .heading {
    font-size: 36px;
    font-family: 'DMSerifDisplay', serif;
    color: $yellow;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-top: 30px;
  }

  @include breakpoint(1440px) {
    .heading {
      font-size: 36px;
      text-align: left;
      padding: 0 30px 20px 30px;
    }
  }

  .contentWrap {
    position: relative;
    overflow: hidden;
    &:before {
      content: '';
      height: 100%;
      width: 2px;
      position: absolute;
      background-color: $gray23;
      top: 80px;
      left: 50px;
    }
  }
}

@include breakpoint(1440px) {
  .employerRequestsWrap {
    border-radius: 8px;
    padding: 30px 0;
  }
}

.tabsWrap {
  display: flex;
  flex-direction: column;
  width: 100%;

  div:first-child {
    border-top: 1px solid $gainsboro;
  }

  .tab {
    color: $blue1;
    width: 100%;
    font-size: 14px;
    border-bottom: 1px solid $gainsboro;
    background-color: $white2;
    padding: 20px;
    padding-top: 15px;
    padding-bottom: 10px;
    cursor: pointer;
  }

  .tabActive {
    color: $gray12;
    background-color: $white;
  }
}

@include breakpoint(1440px) {
  .tabsContainer {
    padding-left: 30px;
    padding-right: 30px;
  }
  .tabsWrap {
    flex-direction: row;
    margin: 20px 0;
    border-bottom: 1px solid $gray23;
    padding-left: 30px;
    padding-right: 30px;

    .tab {
      width: 180px;
      border: 1px solid $gainsboro;
      background-color: $white2;
      padding: 0;
      padding-top: 15px;
      padding-bottom: 10px;
      text-align: center;
      border-bottom: none;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .tabActive {
      position: relative;
      color: $gray12;
      background-color: $white;
    }
    .tabActive::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 4px;
      bottom: -3px;
      left: 0;
      background-color: $white;
      z-index: 4;
    }
  }
}

.btnWidth {
  width: 190px;
  margin: 0 auto;
}

.allCtasWrap {
  display: grid;
  padding: 0 20px;
  padding-top: 30px;
  grid-template-rows: auto auto auto;
  grid-template-columns: 100%;
  gap: 20px;
}

.otherCtasWrap {
  display: grid;
  padding: 0 20px;
  padding-top: 30px;
  grid-template-rows: auto auto auto;
  gap: 20px;
}

@include breakpoint(1440px) {
  .allCtasWrap {
    display: grid;
    padding: 0;
    padding-left: 30px;
    padding-right: 30px;
    grid-template-columns: auto 200px 190px;
    gap: 0 20px;
  }

  .otherCtasWrap {
    display: grid;
    padding: 0;
    grid-template-columns: auto 190px;
    gap: 0 20px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.resetSearchBtn {
  font-size: 14px;
  margin: 0 auto;
  margin-top: 8px;
  margin-bottom: 20px;
  cursor: pointer;
  color: $black-opacity-4;
  text-decoration: underline;
}
.searchResults {
  padding: 0 20px;
}

@include breakpoint(1440px) {
  .resetSearchBtn {
    margin: 0;
    margin-bottom: 8px;
    margin-top: 8px;
    margin-left: auto;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.linksContainer {
  padding: 0 18px;

  @include breakpoint('desktop') {
    padding: 45px 24px 0 24px;
  }
}

.myOffersContainer {
  margin-top: 30px;
  padding: 0 18px;

  @include breakpoint('desktop') {
    padding-left: 24px;
    margin-top: 50px;
  }
}
