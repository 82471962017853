@import 'Styling/import.scss';
.title {
  color: $yellow;
  font-size: 36px;
  text-align: center;
  font-family: 'DMSerifDisplay', serif;
}
.accIcon {
  position: absolute;
}
.text {
  font-size: 16px;
  text-align: center;
  padding-left: 34px;
  padding-right: 34px;
  margin-bottom: 46px;
  margin-top: 21px;
}
.optionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 64px;
  padding-right: 64px;
}
.option {
  border-radius: 8px;
  border: solid 1px $gray29;
  background-color: $white2;
  justify-content: center;
  align-items: center;
  padding: 9px 20px;
  text-align: center;
  color: $blue1;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 30px;
  width: 100%;
  max-width: 222px;
  min-height: 62px;
}
.deviderContainer {
  align-items: center;
  justify-content: center;
  display: flex;
}
.devider::before,
.devider::after {
  display: inline-block;
  content: '';
  border-top: 1px solid $grey1;
  width: 8rem;
  transform: translateY(-1rem);
}
@include breakpoint('desktop') {
  .title {
    color: $yellow;
    font-size: 36px;
    text-align: center;
    font-family: 'DMSerifDisplay', serif;
    margin-bottom: 16px;
  }
  .accIcon {
    position: absolute;
  }
  .text {
    font-size: 16px;
    text-align: center;
    margin-bottom: 46px;
    margin-top: 21px;
  }
  .optionContainer {
    flex-direction: row;
  }
  .option {
    margin-right: 15px;
    font-size: 15px;
    width: 33.33%;
    padding: 9px 35px;
  }
  .deviderContainer {
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .devider::before,
  .devider::after {
    display: inline-block;
    content: '';
    border-top: 1px solid $grey1;
    width: 17rem;
    transform: translateY(-1rem);
  }
}
