@import 'Styling/import.scss';

.section {
  .content {
    @include breakpoint('desktop') {
      margin-left: 35px;
      margin-right: 35px;
    }
  }
  .textContainer {
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    color: $black;
  }
}

@include breakpoint('desktop') {
  .textContainer {
    padding-right: 33px;
  }
  .modalContent {
    width: 800px;
    display: flex;
    flex-direction: column;
  }
  .space {
    width: 386px;
    margin-right: 30px;
  }
  .right {
    width: 386px;
  }
}
.buttonsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
.cancelButton {
  padding-top: 29px;
  color: #505459;
  font-size: 14px;
  text-decoration: underline;
}
.space {
  margin-bottom: 20px;
}

@include breakpoint('toDesktop') {
  .firstTitle.firstTitleStrong:first-child {
    margin: 0 0 30px;
  }
  .title.titleStrong {
    margin: 30px 0;
  }
}
