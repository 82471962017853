.languageWrapper {
  margin-top: 15px;
  border-radius: 8px;
  height: 17px;
}

.buttonsWrapper {
  display: flex;
}

.languageBtn {
  border: 1px solid rgba(0, 0, 0, 0.6);
  border-radius: 8px;
  font-size: 9px;
  padding: 0px 7px 0px 7px;
  margin-left: 20px;
  opacity: 0.3;
  line-height: 2px;
  height: 12px;
  font-family: 'DMSerifDisplay', serif;
  &:hover {
    opacity: 0.6;
  }

  &:first-child {
    margin-left: 5px;
  }
}

.activeLanguage {
  opacity: 0.8;
}
