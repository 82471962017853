@import 'Styling/import.scss';

.commentsContainer {
  display: flex;
  flex-direction: column;
}

.textField {
  max-height: 74px;
  textArea {
    border: 1px solid $input-border;

    &:hover,
    &:focus {
      border: 1px solid $input-focus-border;
    }
    &:active {
      border: 1px solid $input-active-border;
    }
  }
}

.submitButton {
  width: 120px;
  height: 39px;
  background-color: $gray-cyan;
  margin-top: 10px;
  color: $yellow;
  font-size: 14px;
}
