@import 'Styling/import.scss';
.container {
  position: relative;
  padding: 0px 22px 100px;
  z-index: 1;
  background-color: $gray14;
  padding-bottom: 100px;
  @include breakpoint(tablet) {
    padding: 0px 50px 50px;
  }
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 110px;
    .logo {
      width: 158px;
      height: 17px;
    }

    @include breakpoint(tablet) {
      justify-content: flex-start;
    }
  }
}
