@mixin clearFix() {
  &:before,
  &:after {
    content: ' ';
    display: table;
  }

  &:after {
    display: block;
    clear: both;
    height: 1px;
    margin-top: -1px;
    visibility: hidden;
  }
  & {
    *zoom: 1;
  }
}

@mixin listBoxShadow() {
  box-shadow: 2px 1px 8px rgba(0, 0, 0, 0.3);
}
@mixin smallScreens() {
  @media only screen and (max-width: 420px) {
    @content;
  }
}

@mixin breakpoint($point) {
  @if $point == 'tablet' {
    @media only screen and (min-width: 768px) {
      @content;
    }
  } @else if $point == 'desktop' {
    @media only screen and (min-width: 992px) {
      @content;
    }
  } @else if $point == 'toDesktop' {
    @media only screen and (max-width: 991px) {
      @content;
    }
  } @else if $point == 'mediumScreens' {
    @media only screen and (max-width: 1200px) {
      @content;
    }
  } @else if $point == 'smallDesktop' {
    @media only screen and (max-width: 991px) {
      @content;
    }
  } @else if $point == 'mobile' {
    @media only screen and (max-width: 767px) {
      @content;
    }
  } @else {
    @media only screen and (min-width: $point) {
      @content;
    }
  }
}

@mixin ellipsis($lines: 1) {
  // Fallback for non-webkit browsers.
  // Fallback does not render ellipsis.

  // Webkit solution for multiline ellipsis
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  text-overflow: ellipsis;

  // Solution for Opera
  text-overflow: -o-ellipsis-lastline;
}

@mixin ellipsisSimple() {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@mixin breakWord() {
  overflow-wrap: break-word;
  word-break: break-word;
}
