@import 'Styling/import.scss';

.container {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  width: 100%;
  .hr {
    margin: 40px 0 0;
  }
  .firstTitle {
    margin-top: 14px;
  }
}
@include breakpoint('desktop') {
  .container {
    .hr {
      margin: 50px 0 0;
    }
  }
}
.date {
  color: gray;
}
