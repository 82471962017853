@import 'Styling/import.scss';

.modal {
  @include breakpoint('desktop') {
    max-width: 570px;
  }
}

.containerInterview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.text {
  font-size: 18px;
  text-align: center;
  line-height: 30px;
  color: $grey2;
  font-family: 'DMSerifDisplay', serif;
  margin-bottom: 26px;
}

.description {
  font-size: 14px;
  line-height: 24px;
  color: $grey2;
  text-align: center;
  padding-bottom: 27px;
}

.button {
  color: $darkblue;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

.textArea {
  width: 308px;
  margin-bottom: 20px;
  > div > div {
    .textArea {
      height: 146px;
    }
  }
  textArea {
    border: 1px solid $input-border;
    &:hover,
    &:focus {
      border: 1px solid $input-focus-border;
    }
    &:active {
      border: 1px solid $input-active-border;
    }
  }
  @include breakpoint('tablet') {
    width: 511px;
  }
}
