@import 'Styling/import.scss';

.yellowHeaderContainer {
  @include breakpoint('desktop') {
    transform: translateY(10px);
  }

  .yellowHeaderWrapper {
    display: flex;
    background-color: $yellow;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    @include breakpoint('toDesktop') {
      padding: 20px;
    }
  }

  .avatarContainerWrapper {
    display: flex;
    justify-content: center;
    transform: translateY(28px);

    @include breakpoint('desktop') {
      transform: translateY(-28px);
      margin-left: 28px;
    }

    .yellowHeaderAvatar {
      background-color: $white;

      span {
        color: $orange;
      }
    }
  }

  .basicUserInformationsWrapper {
    width: 100%;

    @include breakpoint('desktop') {
      margin-left: 40px;
    }
  }
}
