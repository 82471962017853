@import '/src/Styling/import';

.actionBtn {
  margin-left: 20px;
  font-size: 14px;
  color: $blue1;
  border: 1px solid $gray30;
  background-color: $white2;
  border-radius: 8px;
  height: 40px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
}

.searchHeaderWrap {
  display: flex;
  flex-direction: column;

  .heading {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: 36px;
    font-family: 'DMSerifDisplay', serif;
    color: $yellow;
    border-bottom: 1px $gray30 solid;
    padding-bottom: 20px;
  }

  @include breakpoint('desktop') {
    .heading {
      flex-direction: row;
      justify-content: space-between;
      text-align: left;
    }
  }

  .subscribeBtn {
    border: 1px solid $gray18;
    background-color: $yellow4;
    color: $white;
    font-family: 'Roboto', sans-serif;
    margin: 20px auto 0 0;
  }

  @include breakpoint('desktop') {
    .subscribeBtn {
      margin: 0;
    }
  }

  .testingBtn {
    display: none;
  }

  @include breakpoint('desktop') {
    .testingBtn {
      display: flex;
    }
  }

  .actionsWrap {
    display: flex;
    flex-direction: row;
    width: 100%;

    .title {
      display: none;
    }

    .actions {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 100%;

      .actionsBulk {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 20px 0;
        border-bottom: 1px $gray30 solid;

        .selectAllWrap {
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 40px;

          .selectAll {
            margin: 0;

            svg {
              cursor: pointer;
            }
          }

          .selectAllLabel {
            cursor: pointer;
            user-select: none;
          }
        }
      }

      @include breakpoint('desktop') {
        .actionsBulk {
          justify-content: flex-start;
          width: auto;
          margin: 0;
          border-bottom: none;
        }
      }

      .sortWrap {
        display: flex;
        justify-content: flex-end;
        padding: 20px 0;
        width: 100%;
        border-bottom: 1px $gray30 solid;
        margin-bottom: 20px;

        .popoverTitleWrap {
          font-size: 14px;
          color: $blue1;
          margin-left: 13px;
          margin-right: 8px;
          display: flex;
          align-items: center;

          span {
            margin-right: 10px;
          }
        }
      }

      @include breakpoint('desktop') {
        .sortWrap {
          padding: 20px 0;
          width: auto;
          margin-bottom: 0;
          border-bottom: none;
        }
      }
    }

    @include breakpoint('desktop') {
      .actions {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  @include breakpoint('desktop') {
    .actionsWrap {
      border-bottom: 1px $gray30 solid;
      padding: 0;

      .title {
        display: flex;
        align-items: center;
        width: 240px;
        font-size: 24px;
        font-family: 'DMSerifDisplay', serif;
        color: $gray32;
        text-align: left;
      }
    }
  }
}

@include breakpoint('desktop') {
  .searchHeaderWrap {
    border-radius: 8px;
    margin: 0 auto 20px auto;
  }
}
