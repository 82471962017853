@import 'Styling/import.scss';
.contactUsContainer {
  .mainHeder {
    margin-left: 22px;
  }

  .title {
    font-family: 'DMSerifDisplay', serif;
    color: $black3;
    font-size: 24px;
    align-self: flex-start;
    margin-left: 21px;
  }

  .container {
    width: 100%;
    .inputs {
      display: grid;
      grid-template-columns: 1fr;
      margin-bottom: 20px;
      grid-gap: 20px;
    }
  }
  .form {
    margin: 30px 0px 40px;
    .formContainer {
      border-radius: 8px;
      padding: 30px 20px;
      padding-left: 30px;
      padding-right: 30px;
      background-color: $white;
      width: 100%;
    }
  }
  .buttons {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 40px;

    .cancelBtn {
      color: $grey3;
      font-size: 14px;
      margin: 0 auto;
    }
  }
  .btn {
    position: relative;
  }
  .YellowButton {
    width: 307px;
    align-self: center;
  }
  .subjectDropdown {
    display: none;
  }

  @include breakpoint(desktop) {
    .mainHeder {
      margin-left: 50px;
    }

    .background {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }

    .title {
      margin-left: 50px;
      margin-top: 20px;
    }
    textarea.textArea {
      height: 144px;
    }

    .container {
      padding-left: 50px;
      padding-right: 50px;

      .inputs {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        margin-bottom: 30px;
      }
    }

    .formContainer {
      margin-top: 20px;
      padding: 30px 30px;

      .form {
        margin-top: 21px;
      }
    }

    .cancelButton {
      margin-bottom: 80px;
    }

    .buttons {
      margin-top: 52px;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 100px;
    }

    .btn {
      position: absolute;
    }

    .YellowButton {
      width: 386px;
    }
  }
}
