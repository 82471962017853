@import 'Styling/import.scss';

.testContainer {
  padding: 0 20px 100px 20px;

  @include breakpoint(desktop) {
    padding: 9px 50px 49px 50px;
  }

  .cognitiveIcon {
    margin-right: 19px;
    width: 38px;
    height: 45px;
  }

  .cognitiveTestHeader {
    font-weight: normal;
  }

  .title {
    font-size: 24px;
    font-weight: normal;
    font-family: 'DMSerifDisplay', serif;
    margin-top: 44px;
  }

  .testMicrophoneCameraNotificationWrapper {
    margin-bottom: 37px;
    display: flex;
    justify-content: center;
    margin-top: 44px;

    @include breakpoint(desktop) {
      margin-bottom: 40px;
      padding: 0 120px;
      margin-top: 96px;
    }
  }

  .buttonWrapper {
    display: flex;
    justify-content: center;
  }

  .buttonContainer {
    max-width: 266px;
    width: 100%;
    align-self: center;

    @include breakpoint(desktop) {
      max-width: 386px;
    }
  }
}
