@import 'Styling/import.scss';

.questionsCategoryItem {
  cursor: pointer;
  border-bottom: 1px solid $american-silver;
  padding-top: 20px;
  .question {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .questionLeft {
      color: $orange;
      font-size: 24px;
      font-family: 'DMSerifDisplay', serif;
      font-style: italic;
      margin-right: 26px;
    }
    .questionMiddle {
      font-size: 14px;
      margin-right: auto;
      color: $blue1;
      margin-top: 6px;
      transition: 0.1s ease;
    }
    .questionMiddleMore {
      font-weight: 700;
    }
    .questionRightMore {
      transform: rotate(0deg);
      transition: 0.2s ease-in-out;
    }

    .questionRightMore {
      transform: rotate(180deg);
      transition: 0.2s ease-in-out;
    }
  }
  .answer {
    margin: 20px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
    height: 0px;
    .answerLeft {
      padding: 0px 0px 20px;
      color: $gray997;
      font-size: 24px;
      font-family: 'DMSerifDisplay', serif;
      font-style: italic;
      margin-right: 26px;
    }
    .answerRight {
      margin-top: 5px;
      padding: 0px 0px 20px;
      font-size: 14px;
    }
  }
  .answerMore {
    height: 100%;
  }

  .answerContainer {
    display: flex;
    flex-direction: column;

    .inlineParagraph {
      display: inline;
    }
  }
}
