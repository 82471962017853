@import 'Styling/import.scss';

.contentWrap {
  padding: 20px;
  padding-bottom: 0;
  .contentHeader {
    display: none;
  }

  @include breakpoint(1440px) {
    padding-bottom: 20px;
    .contentHeader {
      display: grid;
      gap: 30px;
      grid-template-columns: 3fr 150px 75px 75px 300px;
      font-size: 12px;
      font-weight: bold;
      color: $gray12;
      border-bottom: 1px solid $gray23;
      padding: 20px 0;
      padding-top: 0;
    }
  }

  .headerTitle {
    display: flex;
    align-items: center;
    min-height: 24px;
    
    &:first-of-type,
    &:nth-of-type(2) {
      text-transform: uppercase;
     
    }
    svg {
      display: none;
    }
  }

  .leftMargin {
    display: flex;
    align-items: center;
    margin-left: 20px;
  }

  .sortBtn {
    cursor: pointer;
    user-select: none;

    svg {
      display: none;
    }
  }

  .active {
    svg {
      display: block;
    }
  }

  .desc {
    svg {
      transform: rotate(180deg);
    }
  }
}
