@import 'Styling/import.scss';

.container {
  .headerWrap {
    padding: 80px 20px 15px 20px;
    background-color: $blue3;

    @include breakpoint(desktop) {
      padding: 40px 50px 15px 50px;
    }

    .title {
      font-family: 'DMSerifDisplay', serif;
      font-size: 36px;
      color: $yellow;
      padding-bottom: 15px;
      margin-top: 25px;
      border-bottom: 1px solid $gray-hr-opacity-2;
    }
  }
  .searchHeaderWrap {
    display: grid;
    padding: 20px 0;
    grid-template-columns: 1fr;
    gap: 20px;

    .searchFieldWrap {
      position: relative;

      .typeahead {
        background-color: $white2;
        border-radius: 8px;

        input {
          height: 44px;
        }
      }
      .textField {
        > div > input {
          background-color: $white2;
        }
      }
      .searchIcon {
        position: absolute;
        right: 15px;
        top: 20px;
        color: $grey1;
        height: 100%;
      }
    }
    .submitBtn {
      min-width: 190px;
    }
    .filterDropdown > div:first-child {
      height: 64px;
    }
    @include breakpoint(1440px) {
      grid-template-columns: 1fr 1fr 1fr 190px;
    }
  }

  .logoWrap {
    display: none;
    width: 160px;
    align-items: center;

    @include breakpoint(desktop) {
      display: flex;
    }

    img {
      width: 100%;
    }
  }

  .progressSpinner {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .actionsWrap {
    display: flex;
    flex-direction: row;
    margin-left: 20px;
    margin-right: 20px;

    .title {
      display: none;
    }

    .actionBtn {
      margin-left: 20px;
      font-size: 14px;
      color: $blue1;
      border: 1px solid $gray29;
      background-color: $white2;
      border-radius: 8px;
      height: 40px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      user-select: none;
      white-space: nowrap;
    }

    .desktopTestingActions {
      margin-left: 20px;
      border: 1px solid $gray29;
      display: none;

      @include breakpoint('desktop') {
        display: flex;
      }
    }

    .actions {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 100%;

      .actionsBulk {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 20px 10px;
        border-bottom: 1px $gray30 solid;

        @include breakpoint('desktop') {
          padding: 20px;
          border-bottom: none;
        }

        .selectAllWrap {
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 40px;

          .selectAll {
            margin: 0;

            svg {
              cursor: pointer;
            }
          }

          .selectAllLabel {
            font-size: 14px;
            color: $blue1;
            white-space: nowrap;
            cursor: pointer;
            user-select: none;
          }
        }

        .mobileActions {
          border: 1px solid $gray29;

          @include breakpoint('desktop') {
            display: none;
          }
        }

        .desktopActions {
          display: none;

          @include breakpoint('desktop') {
            display: flex;
          }
        }
      }

      .popoverTitleWrap {
        font-size: 14px;
        color: $blue1;
        margin-left: 13px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        span {
          margin-right: 10px;
        }
      }

      @include breakpoint('desktop') {
        .actionsBulk {
          justify-content: flex-start;
          width: auto;
          margin: 0;
          border-bottom: none;
        }
      }

      .sortWrap {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 20px 10px;
        width: 100%;
        border-bottom: 1px $gray30 solid;
        margin-bottom: 20px;

        @include breakpoint('desktop') {
          padding: 20px 0;
          width: auto;
          margin-bottom: 0;
          border-bottom: none;
        }

        .sortDirection {
          transform: rotate(90deg);
          color: $black-opacity-3;
          margin-left: 20px;
          cursor: pointer;
        }

        .sortAction {
          width: 100%;

        }

        > div > div {
          margin-left: 0;
          width: 185px;
        }
      }
    }
  }

  @include breakpoint('desktop') {
    .actionsWrap {
      border-bottom: none;
      padding: 0;
      margin-left: 50px;
      margin-right: 50px;

      .title {
        display: flex;
        align-items: center;
        width: 240px;
        font-size: 24px;
        font-family: 'DMSerifDisplay', serif;
        color: $gray32;
        text-align: left;
      }

      .actions {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }

    .candidateListWrap {
      margin-left: 50px;
      margin-right: 50px;
    }
  }
}
