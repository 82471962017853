@import 'Styling/import.scss';

.tooltipStyle {
  :global {
    .MuiTooltip-tooltip {
      background-color: $white;
      color: $grey2;
      padding: 10px 20px;
      border-radius: 5px;
      box-shadow: 7px 7px 7px -2px rgb(0 0 0 / 20%),
        0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
    }
  }
}
