.bubbleContainer {
  height: 28px;
  width: 28px;
  background-color: black;
  position: relative;
  border-radius: 3px;
}

.bubbleContainer::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 28px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid black;
  clear: both;
  z-index: 5;
}

.bubbleText {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 30px;
  color: #fff;
  text-align: center;
  margin-bottom: 8px;
}
