@import 'Styling/import.scss';

.newMessageRecipientContainer {
  display: flex;
  background: $white;
  padding: 11px 20px;
  border-top: 1px solid $white3;
  cursor: pointer;

  .messageParticipantPictureWrap {
    margin-right: 19px;
  }

  &:hover {
    background: $silver23456;
  }
}
