@import 'Styling/import.scss';

.container {
  padding-bottom: 20px; //white space
}

.sectionHeader {
  padding: 0;
  margin-top: 39px;

  @include breakpoint('desktop') {
    margin-top: 41px;
  }
}

.typeahead {
  input {
    &::placeholder {
      color: $grey2;
      font-size: 14px;
    }
  }
}

.sectionItem {
  padding: 40px 18px;
  border-radius: 8px;

  @include breakpoint('desktop') {
    padding: 30px 30px 40px 30px;
  }

  .testsLabels {
    padding-bottom: 20px;
    @include breakpoint(desktop) {
      display: grid;
      grid-template-columns: 29.27% 15.85% 13.41% 16.95% 9.76% 1fr;
    }
  }

  .testCaption {
    display: none;
    p {
      font-weight: bold;
    }

    @include breakpoint('desktop') {
      display: flex;
      line-height: 1.83;
      color: $gray12;
      font-size: 12px;
      padding-bottom: 20px;
    }

    @include breakpoint('desktop') {
      .provider {
        margin-left: 179px;
      }

      .dateOfTest {
        margin-left: 73px;
      }

      .expirationDate {
        margin-left: 31px;
      }

      .info {
        margin-left: 38px;
      }

      .action {
        margin-left: 53px;
      }
    }
  }

  .selectBoxWrapper {
    border-top: 1px solid $gray25;
  }

  .selectBoxWrapper:last-of-type {
    border-bottom: 1px solid $gray25;
  }
}

.modal {
  @include breakpoint('desktop') {
    min-width: 80%;
  }
}

.dateWrapper {
  display: flex;
  width: 100%;

  @include breakpoint(desktop) {
    justify-content: space-between;

    > div {
      width: 50%;

      &:first-of-type {
        margin-right: 30px;
      }
    }
  }
}

.titleWrapper {
  &:not:first-child {
    margin: 29px 0;
  }

  h2 {
    font-weight: 400;
  }
}

.headerSkillTest {
  align-items: start;
  padding-left: 0;
  h2 {
    margin-bottom: 0;
    font-weight: 400;
  }
}
