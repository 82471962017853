@import 'Styling/import.scss';

.container {
  padding: 11px 0px 0px;

  @include breakpoint(desktop) {
    padding: 0px 0px 0px;
  }

  .logoContainer {
    padding-left: 50px;
    padding-top: 40px;
    display: flex;
    align-items: center;
    font-size: 30px;
    color: $white;
    font-family: 'DMSerifDisplay', serif;

    .linkStyle {
      color: white;
    }

    .logoStyle {
      width: 276px;
      height: auto;

      svg {
        margin-left: 25px;
      }
    }
  }

  .containerUp {
    border-bottom: 1px solid white;
    padding: 18px 0px 28px;
    margin-left: 41px;
    margin-right: 41px;
    @include breakpoint(desktop) {
      margin-left: 88px;
      margin-right: 88px;
      padding-bottom: 20px;
    }

    .containerUpTitle {
      text-align: center;
      font-size: 36px;
      font-family: 'DMSerifDisplay', serif;
      color: white;
      padding-bottom: 21px;
      font-weight: normal;
    }

    .containerUpText {
      font-size: 14px;
      color: white;
      text-align: center;
      line-height: 24px;
    }
  }

  .containerMiddle {
    padding-left: 41px;
    padding-right: 41px;
    @include breakpoint(desktop) {
      padding-left: 88px;
      padding-right: 88px;
    }
  }

  .containerBtn {
    display: flex;
    align-self: center;
    justify-content: center;
  }

  .containerBtn span {
    font-size: 18px;
    line-height: 40px;
  }

  .text {
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: white;
    padding-top: 30px;
    padding-bottom: 80px;
    @include breakpoint('desktop') {
      padding-bottom: 45px;
    }
  }

  .btn {
    width: 266px;
    height: 65px;
    @include breakpoint('900px') {
      width: 386px;
    }
  }
}
