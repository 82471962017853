@import 'Styling/import.scss';
.top {
  height: 165px;
  position: relative;
  background-color: $black;
  .bg {
    background-image: url('/Assets/Images/public-profile-header-image.png');
    background-size: cover;
    background-position: center;
    opacity: 0.7;
    width: 100%;
    height: 100%;
  }
}
.main {
  position: relative;
  background: $white;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 60px;
  @include breakpoint('desktop') {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .usernamePlaceholder {
    font-family: 'DMSerifDisplay', serif;
    font-size: 36px;
    color: $black;
  }
  .jobTitle {
    font-size: 20px;
    color: $black;
    border-bottom: $gray15 1px solid;
    margin-top: 20px;
    padding-bottom: 15px;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    align-items: center;

    .toolHolder {
      background-color: $yellow;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 26px;
      height: 26px;
      margin-right: 10px;

      svg {
        fill: $white;
        height: 15px;
      }
    }
    @include breakpoint('desktop') {
      .toolHolder {
        width: 26px;
      }
    }
  }
}
.editBtn {
  position: absolute;
  right: 20px;
  top: 20px;
}
.textContainer {
  line-height: 25px;
  margin-top: 29px;
  margin-bottom: 5px;
  padding-right: 25px;
  color: $black;
}
.birthday {
  display: flex;
  padding-top: 15px;
  padding-bottom: 18px;
  line-height: 35px;
}
.birthdayText {
  padding-left: 15px;
  font-size: 14px;
  font-family: Roboto;
  margin: 8px 0 0;
}
.birthdayIcon {
  line-height: 35px;
  padding-left: 5px;
}
.street {
  display: flex;
  padding-top: 15px;
  line-height: 30px;
  margin-bottom: 15px;
}
.streetText {
  padding-left: 15px;
  padding-right: 5px;
}
.streetIcon {
  margin-left: 5px;
}
.mail {
  display: flex;
  line-height: 25px;
  margin-bottom: 15px;
}
.mailText {
  padding-left: 15px;
}
.phone {
  display: flex;
  line-height: 25px;
}
.phoneNumber {
  padding-left: 15px;
}
.icon {
  line-height: 25px;
  padding-left: 5px;
}
.avatarHolder {
  background-color: $yellow;
  position: absolute;
  left: 50%;
  top: -130px;
  margin-left: -85px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 170px;
  height: 170px;
  svg {
    fill: $white;
    width: 90px;
    height: 90px;
  }
}
.profileAvatar {
  width: 170px;
  height: 170px;

  > span {
    line-height: 170px;
    font-size: 80px;
  }
}
@include breakpoint('desktop') {
  .top {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;
  }
  .main {
    display: flex;
    justify-content: flex-start;
  }
  .title {
    letter-spacing: 1.8px;
    padding-left: 30px;
    margin-top: 90px;
    padding-top: 0px;
    font-family: 'DMSerifDisplay', serif;
    font-weight: 400;
  }
  .streetText {
    padding-left: 10px;
  }
  .textContainer {
    margin-top: 26px;
    margin-left: 5px;
  }
  .leftContainer {
    border-right: solid 1px $onb-divider-color;
    padding-left: 30px;
  }

  .avatarHolder {
    left: 30px;
    margin-left: 0;
  }
}

.modal {
  @include breakpoint('desktop') {
    min-width: 80%;
    .aboutMeModalContent {
      display: flex;
      flex-direction: column;
    }
  }
}

textarea.editAboutMeTxt.editAboutMeTxtArea {
  height: 140px;
}
