@import 'Styling/import.scss';

.sectionContainer {
  display: flex;
  background-color: $gray29-opacity-3;
  border-top: 1px solid $gray35;
  border-bottom: 1px solid $gray35;
  padding: 22px 19px;
  max-height: 162px;
  overflow-y: auto;

  div {
    &:first-child {
      width: 100%;
    }
  }

  &::-webkit-scrollbar {
    width: 14px;
  }

  &::-webkit-scrollbar-track {
    background-color: $black-opacity-3;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: $white-opacity-3;
  }
}
