@import '/src/Styling/import';

.textAreaContainer {
  .textArea {
    font-family: Roboto, sans-serif;
    height: 240px;
    border-radius: 10px;
    background-color: $gray22;
    color: $grey2;
    padding: 10px 35px 10px 20px;
    font-size: 14px;
    line-height: 22px;
    width: 100%;
    border: rgba(255, 255, 255, 0) solid 1px;
    resize: none;
    padding-bottom: 20px;

    &::placeholder {
      font-family: 'Roboto', sans-serif;
      color: $grey1;
      font-size: 14px;
      line-height: 22px;
      font-style: italic;
    }

    &:focus {
      outline: none;
      // border: $gray4 solid 1px;
    }

    &:focus::placeholder {
      color: transparent;
    }
  }

  &.white, &.modal {
    .textArea {
      background-color: $white;
    }
  }

  &.modal {
    margin: $input-modal-top-margin 0 $input-modal-bottom-margin;
    &:last-child {
      margin: $input-modal-top-margin 0 0;
    }
    .secondDiv {
      @include breakpoint('desktop') {
        display: flex;
      }
    }
  }

  .charactersCalculator {
    display: flex;
    justify-content: flex-end;
    color: rgba(0, 0, 0, 0.4);
    font-size: 14px;
  }

  .error {
    border: $error-border solid 1px !important;
  }

  .letterCalculator,
  .letterCalculatorDark {
    position: relative;
    background-color: $white;
    padding: 10px 25px 20px 20px;
    border-radius: 10px;
    border: 1px solid $input-border;

    &:hover,
    &:focus {
      border: 1px solid $input-focus-border;
    }
    &:active {
      border: 1px solid $input-active-border;
    }

    .secondDiv {
      @include breakpoint('desktop') {
        display: flex;
      }
    }

    .horizontalLabelInputWrapper {
      justify-content: flex-start;

      label {
        color: $grey2;
        font-weight: bold;
        line-height: 22px;
      }

      textarea {
        &::placeholder {
          color: $grey1;
          font-style: italic;
        }
      }
    }

    textArea {
      background-color: $white;
      padding: 0;

      &:focus {
        border: none;
      }
    }
  }

  .letterCalculatorDark {
    background-color: $gray22;
    color: $grey2;

    textArea {
      background-color: $gray22;
    }
  }

  .verticalLabelWrapper {
    border-radius: 5px;
    background-color: #f2f5f6;
    margin-top: 30px;
    padding-top: 15px;

    .verticalLabel {
      padding-left: 21px;
      color: #9ca2aa;
      font-size: 14px;
      padding-top: 15px;
    }
    .verticalTextArea {
      margin-top: 0;
    }

    textArea {
      &:focus {
        border: none;
      }
    }
  }
}
