@import 'Styling/import.scss';

.dateTextIn {
  font-size: 14px;
}

.dateTextSoon {
  font-size: 14px;
  color: $red-cinnabar;
}
