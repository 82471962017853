@import 'Styling/import.scss';

.addQuestionFormWrapper {
  .textFieldWrapper {
    margin-bottom: 20px;

    @include breakpoint(desktop) {
      margin-bottom: 30px;
    }
  }

  .hr {
    border-top: 1px solid $grey1;
    margin: 30px 0;
  }

  .questionsTextArea {
    textArea {
      font-size: 16px;
      color: $grey2;
      font-family: Roboto;
      line-height: 22px;

      &::first-letter {
        font-weight: bold;
      }
    }
  }

  .questionsTextField {
    input {
      font-size: 16px;
      color: $grey2;
      font-family: Roboto;
      line-height: 22px;
      font-weight: bold;
    }
  }

  .deleteFormikItemButtonWrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    .deleteFormikItemButton {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background-color: transparent;
      color: $black;
      height: 100%;
      width: fit-content;
      font-size: 14px;

      div {
        display: flex;
        align-items: center;
        margin-right: 9px;
      }

      span {
        color: $grey3;
        text-decoration: underline;
      }
    }
  }
}
