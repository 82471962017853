@import 'Styling/import.scss';
.avatar {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  background-color: $yellow3;
  text-align: center;
  user-select: none;
  text-transform: uppercase;
  overflow: hidden;

  > span {
    line-height: 170px;
    font-size: 80px;
    color: white;
  }
  
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

