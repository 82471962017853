@import 'Styling/import.scss';

.messageParticipantPictureWrap {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .initialsWrap {
    background-color: $yellow;
    width: 100%;
    height: 100%;
    color: $white;
    line-height: 50px;
    font-size: 20px;
    text-align: center;
    a {
      color: $white;
    }
  }
}
