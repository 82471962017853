@import 'Styling/import.scss';

.wrap {
  background-color: $white;
  padding: 0 20px;
  position: relative;
  top: -10px;
  border-radius: 8px;
  @include breakpoint('desktop') {
    top: -18px;
    padding: 0 30px;
  }

  .mainTitleWrap {
    padding: 20px 0 20px;
    text-align: center;
    border-bottom: 1px solid $gray30;

    .mainTitle {
      font-size: 24px;
      font-family: 'DMSerifDisplay', serif;
      color: $yellow;
    }

    .hr {
      display: none;
    }
  }

  .contentHeader {
    border-bottom: 1px solid $gray23;
    display: none;
    margin: 0 20px;
  }
  @include breakpoint(1500px) {
    .contentHeader {
      display: grid;
      gap: 30px;
      grid-template-columns: 5fr 160px 100px 80px 250px;
      font-size: 12px;
      font-weight: bold;
      color: $gray12;
      padding: 20px 0;
      margin: 0;
    }

    .mainTitleWrap {
      padding-bottom: 0;
      text-align: left;
      border: none;

      .mainTitle {
        font-size: 36px;
      }

      .hr {
        border-top: 1px solid $gray23;
        display: block;
        margin-bottom: 0;
      }
    }
  }

  .headerTitle {
    display: flex;
    align-items: center;
    min-height: 24px;
    user-select: none;
    svg {
      display: none;
    }
  }

  .leftMargin {
    display: flex;
    align-items: center;
    margin-left: 20px;
  }

  .linkWrap {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .viewMoreLink {
    color: $grey3;
    font-size: 14px;
    border-bottom: 1px solid $grey3;
    &:hover,
    &:focus,
    &:active {
      border-bottom: 1px solid $white;
    }
  }
  .viewMoreLinkHr {
    margin-left: 10px;
    margin-right: 10px;
    border-right: 1px solid $grey3;
  }
}

.loaderContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .loadingText {
    font-size: 14px;
    line-height: 2.86px;
    color: $black;
    margin-top: 26px;
  }
}
.noResults {
  margin: 15px 93px 0 21px;
  opacity: 0.5;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  color: $black;
  text-align: left;
}
