@import 'Styling/import.scss';
.profileContainer {
  background-color: $gray14;
  .hr {
    margin: 30px 20px 40px;
  }
  .confirmWrap {
    display: flex;
    flex-direction: column;
    border-radius: 30px;
    background-color: $white;
    margin-bottom: 40px;
    box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
    min-height: 94px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 22px;
    font-style: italic;
    padding: 30px 30px;
    text-align: center;
  }
  .confirmContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0 20px 100px;
  }
  .message {
    padding-bottom: 30px;
  }
  .boldText{
    font-weight: bold;
  }
  .link {
      text-decoration: underline;
    
      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
      }
  }
}

@include breakpoint('desktop') {
  .profileContainer {
    padding: 20px 50px;
  }
  .hr {
    margin: 40px 0 40px;
  }
  .confirmWrap {
    max-width: 486px;
    width: 100%;
    padding: 30px 49px;
  }
  .button {
    max-width: 386px;
    width: 100%;
  }
}

.modalAreYouSure{
  @include breakpoint('desktop') {
    width: 500px;
     :global {
     .content {
         padding: 29px 30px;
         font-size: 14px;
         line-height: 22px;
       }

     }
   }
}