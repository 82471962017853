@import 'Styling/import.scss';

.mainContainer {
  margin-top: 65px;
  margin-bottom: 75px;

  @include breakpoint('1160px') {
    margin-top: 260px;
    margin-bottom: 214px;
  }

  h1 {
    margin: 0;
    font-weight: normal;
  }

  .title {
    width: 244px;
    font-size: 34px;
    color: $white;
    font-family: 'DMSerifDisplay', serif;

    @include breakpoint('900px') {
      width: 322px;
    }
    @include breakpoint('1160px') {
      font-size: 58px;
      width: 550px; // title style is the same for all resolutions 1160px+
    }
  }
  .textContainer {
    margin-top: 20px;
    color: $white;
    width: 290px;
    font-weight: 900;
    font-size: 16px;

    @include breakpoint('900px') {
      width: 354px;
    }
    @include breakpoint('1160px') {
      font-size: 20px;
      width: 537px;
    }
  }

  .textContainerNoBold {
    margin-top: 20px;
    color: $white;
    width: 290px;
    font-size: 16px;

    @include breakpoint('900px') {
      width: 345px;
    }
    @include breakpoint('1160px') {
      font-size: 20px;
      width: 560px;
    }
  }

  .buttonContainer {
    display: flex;
    margin-top: 28px;

    .button {
      width: 242px;
      height: 59px;
      padding-left: 10px;
      padding-right: 10px;
    }

    @include breakpoint('900px') {
      .button {
        width: 250px;
        height: 60px;
      }
    }
  }
}
