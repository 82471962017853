@import 'Styling/import.scss';
.myDashboard {
  background-color: $white;
  padding: 28px 30px 27px 29px;
  margin-bottom: 63px;
  margin-top: -24px;
  @include breakpoint('desktop') {
    position: absolute;
    left: 50px;
    right: 50px;
    border-radius: 8px;
  }
}
.title {
  font-size: 36px;
  font-family: 'DMSerifDisplay' serif;
  letter-spacing: 1.8px;
  color: $yellow;
  display: flex;
  justify-content: left;
}
.paragraph {
  font-size: 14px;
  color: $grey2;
  line-height: 25px;
  width: 101%;
}
.paragraphTitleTxt {
  font-size: 14px;
  color: $grey2;
  line-height: 25px;
  width: 72%;
}
.link,
.link:visited,
.link:focus {
  color: $grey2;
  text-decoration: underline;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
}
