@import 'Styling/import.scss';

.formWrap {
  display: flex;
  align-content: center;
  align-items: center;
  width: 100%;
  justify-content: center;
  flex-direction: column;
}
.title {
  color: $white;
}
.description {
  color: $white;
}
.inputWrap {
  width: 100%;
  max-width: 386px;
  min-height: 94px;
  margin: 0 auto;
  .passField {
    margin-bottom: 30px;
  }
}
.resetButton {
  height: 65px;
  font-weight: bold;
}

.link {
  display: flex;
  justify-content: center;
  color: $white;
  text-decoration: underline;
  font-size: 14px;
  margin-top: 18px;
}
.linkTxt {
  color: $white;
  text-decoration: underline;
  text-decoration-color: $white;
}
.form {
  width: 100%;
}

.successMessage {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $green2;
  margin-bottom: 25px;
  background: rgba(255, 255, 255, 0.7);
  padding: 10px;
  border-radius: 10px;

  svg {
    fill: $green2;
    margin-right: 18px;
  }
}

@include breakpoint('desktop') {
  .formWrap {
    margin-top: 60px;
  }
  .title {
    margin-bottom: 21px;
    letter-spacing: 1.8px;
  }
  .description {
    line-height: 25px;
  }
  .link {
    justify-content: left;
  }
  .inputWrap {
    margin-bottom: 31px;
  }
  .description {
    max-width: 323px;
  }
}
