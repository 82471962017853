@import 'Styling/import.scss';

.Container {
  padding: 11px 0px 0px;

  @include breakpoint(desktop) {
    padding: 0px 0px 0px;
  }

  &Up {
    border-bottom: 1px solid;
    color: $black7;
    padding: 0px 0px 28px;
    margin-left: 41px;
    margin-right: 41px;
    @include breakpoint(desktop) {
      margin-left: 88px;
      margin-right: 88px;
      padding-bottom: 20px;
    }

    &Title {
      text-align: center;
      font-size: 36px;
      font-family: 'DMSerifDisplay', serif;
      color: $black;
      padding-bottom: 21px;
    }

    &Text {
      font-size: 14px;
      color: $black4;
      text-align: center;
      line-height: 24px;
    }
  }

  &Middle {
    padding-left: 41px;
    padding-right: 41px;
    @include breakpoint(desktop) {
      padding-left: 88px;
      padding-right: 88px;
    }
  }

  &Down {
    padding: 40px 41px 0px;
    background-color: $black5;
    @include breakpoint(desktop) {
      background-color: rgba(0, 0, 0, 0);

      padding-top: 0px;
      padding-left: 88px;
      padding-right: 88px;
    }

    &Text {
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      padding-bottom: 36px;
      color: $white;
      @include breakpoint(desktop) {
        color: $black2;
        padding-bottom: 26px;
      }
    }
  }

  &Inputs {
    padding-bottom: 40px;
    border-bottom: 1px solid $black6;
    @include breakpoint(desktop) {
      border-bottom: none;
      padding-bottom: 60px;
    }

    &BTN {
      display: flex;
      align-self: center;
      justify-content: center;
    }

    &Row {
      display: flex;
      flex-direction: column;

      > div {
        min-height: 94px;
      }

      &Dropdown {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        margin-bottom: 30px;
        flex-direction: column;

        .one {
          margin-bottom: 30px;
          margin-right: 0px;
          width: 100%;
          @include breakpoint(desktop) {
            margin-bottom: 0px;
            margin-right: 30px;
          }
        }
        .two {
          margin-bottom: 30px;
          width: 100%;
          @include breakpoint(desktop) {
            margin-bottom: 0px;
          }
        }

        @include breakpoint(desktop) {
          flex-direction: row;
        }
      }
      @include breakpoint(desktop) {
        flex-direction: row;
        :first-child {
          margin-right: 31px;
        }
      }
    }
  }
}

.Text {
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: $black7;
  padding-top: 30px;
  padding-bottom: 37px;
  @include breakpoint('desktop') {
    padding-bottom: 27px;
  }
}

.textField {
  margin-bottom: 30px;
  @include breakpoint('desktop') {
    margin-bottom: 40px;
  }
}

.btn {
  max-width: 386px;
}
