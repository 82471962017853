@import 'Styling/import.scss';

.container {
  .content {
    line-height: 22px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    @include breakpoint('desktop') {
      padding: 0 40px 0 48px;
    }
  }
}

.sideBySideContainer {
  display: flex;
  flex-direction: column;
  background-color: $gray44;
  border-top: solid 1px $link-water;
  border-bottom: solid 1px $link-water;
  padding: 20px 0;
  margin: 20px 0 1px;
  width: 100%;
  @include breakpoint('desktop') {
    flex-direction: row;
  }
}

.title.titleStronger {
  @include breakpoint('toDesktop') {
    line-height: 25px;
    font-size: 24px;
    &:first-child, &:not(:first-child) {
      margin-bottom: 30px;
    }
  }
}

.benefitsSectionTitle {
  font-family: 'DMSerifDisplay', serif;
  font-size: 24px;
  margin-bottom: 40px;
  text-transform: uppercase;
  font-weight: 400;
}

.benefitsSubtitle {
  font-size: 20px;
  margin-bottom: 25px;
}

.itemWithBorder {
  border-right: solid 1px $link-water;
  word-wrap: break-word;
  width: 50%;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  @include breakpoint('toDesktop') {
    width: 100%;
    border-right: none;
    border-bottom: solid 1px $link-water;
  }
}

.itemWithoutBorder {
  word-wrap: break-word;
  width: 50%;
  padding-left: 99px;
  padding-top: 10px;
  padding-bottom: 10px;

  @include breakpoint('toDesktop') {
    padding-left: 20px;
    padding-top: 25px;
    width: 100%;
  }
}

.modal {
  @include breakpoint('desktop') {
    min-width: 980px;
  }
}

.checkBoxGroup {
  position: relative;
  padding: 5px 0;
  border: rgba(255, 255, 255, 0) 1px solid;
  display: flex;
  flex-direction: column;
  & + :global(.checkBoxGroupError) {
    padding-left: 0;
    position: absolute;
    bottom: -10px;
  }
}

.header {
  h2 {
    font-weight: 400;
  }
}

.sectionItem {
  margin-bottom: 0;
}

.itemHeader {
  @include breakpoint('toDesktop') {
    align-items: flex-start;
  }

  div {
    &:first-of-type {
      margin-right: 11px;

      @include breakpoint('desktop') {
        margin-right: 20px;
      }
    }
  }
}

.showMoreContent.showMoreContentStronger {
  @include breakpoint('desktop') {
    button {
      margin-left: 40px;
    }
    > hr {
      margin-left: 50px;
      margin-right: 40px;
    }
  }
}

.hr.hrStronger {
  @include breakpoint('toDesktop') {
    margin: 30px 0 25px;
  }
}

.hr.hrStronger2 {
  margin-top: 28px;
  @include breakpoint('toDesktop') {
     margin: 30px 0;
  }
}

.textArea {
  textarea {
    border-radius: 10px;
    font-size: 16px;
    color: $grey2;
    padding: 25px 20px;

    @include breakpoint('toDesktop') {
      padding: 20px;
    }
  }
}

.customBenefitsWrap {
  margin-top: 19px;
}

.modalContent {
  @include breakpoint('desktop') {
    display: flex;
    flex-direction: column;
  }
  .sideBySideContainer {
    border-top: none;
    border-bottom: none;
    background-color: transparent;
    margin: 0;
    padding: 0;
    position: relative;
  }
}

.benefitsText {
  white-space: pre-wrap;
}
