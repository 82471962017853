@import 'Styling/import.scss';

.section {
  .content {
    @include breakpoint('desktop') {
      margin-left: 40px;
      margin-right: 40px;
    }
    .container {
      display: flex;
    }
    .spanText {
      font-size: 14px;
      margin-left: 30px;
      line-height: 30px;
    }
  }
}
.spanText {
  font-size: 14px;
}
.languageContainer {
  display: flex;
  justify-content: space-between;
  line-height: 30px;
}
.hrContainer {
  margin-top: 30px;
}
.buttonsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
.cancelButton {
  padding-top: 29px;
  color: #505459;
  font-size: 14px;
  text-decoration: underline;
}

.hr {
  margin: 30px 0 22px;
}

.modal {
  @include breakpoint('desktop') {
    min-width: 80%;
    .languagesModalContent{
      min-height: 180px;
    }
  }
}
.langBubble {
  display: inline-block;
  width: 37px !important;
}
.levelBubble {
  display: inline-block;
  background-color: #929292 !important;
  margin-left: 10px;
  &:after {
    border-top: 6px solid #929292 !important;
  }
}
.deleteBoxSmall {
  text-align: right;
  display: block;
}
.deleteBoxBig {
  display: none;
}

.langText {
  display: block;
  line-height: 28px;
}
.levelText {
  text-align: center;
  color: #929292;
  line-height: 28px;
}

.shortLangLevel {
  display: block;
}
.longLangLevel {
  display: none;
}

@include breakpoint('992px') {
  .levelText {
    text-align: left;
  }

  .shortLangLevel {
    display: none;
  }
  .longLangLevel {
    display: block;
  }
  .deleteBoxSmall {
    display: none;
  }
  .deleteBoxBig {
    display: block;
  }
  .editModal {
    .langText,
    .levelText {
      line-height: 64px;
    }
    .deleteBoxBig {
      button {
        margin: 18px 9px;
      }
    }
  }

  .title {
    margin-bottom: 29px;
    &:not(:first-child) {
      margin-top: 29px;
    }
  }
  .hr {
    margin: 40px 0 32px;
  }
}
