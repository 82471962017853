@import '/src/Styling/import.scss';

.radioIcon {
  color: $black;
}

.selected {
  position: relative;
  overflow: hidden;
  max-height: 24px;
  .background {
    position: absolute;
    left: 13%;
    top: 14%;
    width: calc(20 / 27 * 100%);
    height: calc(20 / 27 * 100%);
    background-color: $yellow;
    border-radius: 50%;
  }
}
