@import 'Styling/import.scss';
.section {
  display: block;
}
.editSkillsModalSection {
  width: auto;
  background-color: $gray13;
}

.modal {
  :global .content {
    overflow-y: visible;
  }
  @include breakpoint('tablet') {
    min-width: 80%;
  }
  &.modalEdit {
    :global .content {
      overflow-y: auto;
    }
  }
}

.title {
  margin-bottom: 30px;
}

.deleteBoxSmall {
  text-align: right;
  display: block;
}
.deleteBoxBig {
  display: none;
}

.skillName {
  display: block;
  line-height: 28px;
  font-size: 14px;
}
.levelText {
  text-align: center;
  color: #929292;
  line-height: 28px;
}

.shortLangLevel {
  display: block;
}
.longLangLevel {
  display: none;
}

@include breakpoint('tablet') {
  .title {
    margin-bottom: 40px;
  }

  .shortLangLevel {
    display: none;
  }
  .longLangLevel {
    display: block;
  }
  .deleteBoxSmall {
    display: none;
  }
  .deleteBoxBig {
    display: block;
  }

  .deleteBoxBig {
    button {
      margin: 18px 9px;
    }
  }
}
.gridRow {
  display: flex;
  align-items: center;
  flex-direction: column;
  @include breakpoint('tablet') {
    flex-direction: row;
    justify-content: space-between;
  }
}

.showMore {
  display: flex;
  align-items: center;

  p {
    font-size: 14px;
    color: $yellow3;
  }
}

.addTypeAhead {
  padding-bottom: 30px;
  > div > div:last-child {
    z-index: 3;
  }
}
.hrMargin {
  margin: 25px 0px;
  @include breakpoint('tablet') {
    margin: 14px 0px;
  }
}

.editItem {
  align-self: flex-start;

  @include breakpoint('tablet') {
    width: 55%;
    display: flex;
    justify-content: space-between;
  }
}

.HrLine {
  margin: 10px 0px 12px;
}

.HrLastLine {
  margin: 10px 0px 0px;
}

.gridRowModalEdit {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 25px;
  @include breakpoint('tablet') {
    width: 45%;
    padding-bottom: 0px;
  }
}

.skillContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
