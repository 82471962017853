@import 'Styling/import.scss';

.wrap {
  display: inline-block;
  @include breakpoint('tablet') {
    position: relative;
  }

  .triggerWrap {
    .trigger {
      width: 50px !important;
      height: 50px !important;
      cursor: pointer;
      > span {
        line-height: 50px !important;
        font-size: 20px !important;
      }
    }
  }

  .applicationsMenu {
    position: absolute;
    z-index: 5000;
    right: -5px;
    top: 60px;
    display: none;
    width: auto;
    max-width: 95vw;
    overflow: hidden;

    flex-direction: column;
    background-color: $white;
    padding: 20px 30px;
    padding-bottom: 40px;
    border-radius: 30px;
    @media (min-width: 390px) {
      max-width: 380px;
    }

    .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      min-width: 260px;
      padding: 10px;
      padding-left: 15px;
      padding-right: 0;
      border-bottom: 1px solid $gray41;
      user-select: none;
      cursor: pointer;

      .iconWrap {
        color: $black;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      .navigateIcon {
        color: $gray18;
        margin-left: auto;
      }

      .text {
        white-space: nowrap;
        padding-left: 35px;
        font-size: 12px;
        color: $grey2;
      }
    }

    .dropdownHeader {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      padding-top: 10px;
      padding-bottom: 20px;
      border-bottom: 1px solid $gray41;
      user-select: none;

      .avatarWrap {
        .avatar {
          width: 50px;
          height: 50px;
        }
      }

      .initials {
        color: $grey2;
        background-color: $gray10;
        font-size: 16px;
        width: 50px;
        height: 50px;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .userInfo {
        margin-left: 20px;
        width: calc(100% - 70px);
      }

      .fullName {
        font-family: 'DMSerifDisplay', serif;
        font-size: 18px;
        @include ellipsis(2);
        overflow-wrap: break-word;
      }
      .profileUrl {
        font-size: 12px;

        text-decoration: underline;
        color: $grey3;
      }
    }
  }

  .menuOpen {
    display: flex;
  }
}

.active::before {
  position: absolute;
  z-index: -1;
  content: '';
  width: 50px;
  height: 50px;
  box-shadow: 0 0 15px 2px $white-opacity-3;
  border-radius: 50%;
  top: 0;
  left: 0;
}
