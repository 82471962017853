@import 'Styling/import.scss';

.row {
  @include breakpoint('tablet') {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
  }
}

.passwordRow {
  margin-bottom: 30px;
  > div{ margin-bottom: 30px;
  @include breakpoint('tablet') {
    margin-bottom: 0px
  }}

}

.link {
  text-decoration: underline;
  color: $grey3;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
}

.sidebar {
  display: none;
}

.description {
  max-width: 388px;
  text-align: center;
  align-self: center;
  margin: 0 auto;
}

@include breakpoint('desktop') {
  .container {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
  }
  .formContainer {
    flex: 1;
    display: flex;
    justify-content: center;
    padding: 0 42px;
  }
  .form {
    max-width: 802px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .sidebar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $black3;
    width: 25%;
    height: 100%;
    padding: 0 3.47%;
  }

  .sidebarTitle {
    color: $yellow;
    width: auto;
    text-align: left;
    margin-bottom: 30px;
  }

  .listItem {
    font-size: 16px;
    line-height: 36px;
    color: $white;
    list-style: inside;
    span {
      margin-left: -10px;
    }
  }
  .buttonContainer {
    max-width: 386px;
    width: 100%;
    align-self: center;
  }
}

.passInput{
   input {
    background-color:$white2;
  }
  @include breakpoint('desktop') {
    input {
      background-color:$white;
    }

  }
}

