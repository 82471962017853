@import 'Styling/import.scss';

.hintWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0 24px;
  padding-top: 20px;

  .closeButton {
    background-color: $white;
    width: 31px;
    height: 30px;
    border-radius: 50%;
    margin-bottom: 7px;

    svg {
      color: $black;
    }
  }

  .helpfulHintContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100%;
    height: 113px;
    padding: 21px 30px 24px 30px;
    background-color: $white;
    border-radius: 30px;
    margin-right: 10px;

    h3 {
      font-family: 'DMSerifDisplay', serif;
      margin-bottom: 16px;
      color: $yellow3;
      font-size: 16px;
    }

    p {
      color: $grey2;
      font-size: 14px;
      line-height: 24px;
    }
  }
}
