@import 'Styling/import.scss';

.container {
  color: $gray36;

  .titleWrap {
    margin-top: 30px;
    display: flex;
    align-items: center;
    .icon {
      font-size: 48px;
      fill: $gray36;
    }
    .title {
      margin-left: 20px;
      font-size: 24px;
      font-family: 'DMSerifDisplay', serif;
    }
  }
  .hr {
    border-top: 1px solid $gray36;
  }
  .text {
    font-size: 14px;
    line-height: 22px;
  }
  .approvalWrap {
    display: flex;
    color: $red-error;
    align-items: center;
    margin-bottom: 20px;

    .approvalIcon {
      fill: $red-error;
    }
    .approvalTitle {
      font-size: 20px;
      margin-left: 9px;
    }
  }
  .progressContainer {
    margin-top: 9px;
    position: relative;
    display: flex;
    flex-direction: column;
    .progressNumber {
      width: 100%;
      text-align: right;
    }
    .progressWrap {
      width: 100%;
      background-color: $cyan-dark;
      height: 16px;
      border-radius: 8px;
      margin-top: 3px;
      .progress {
        background-color: $yellow3;
        height: 16px;
        border-radius: 8px;
      }
    }
  }
}
