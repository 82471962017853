@import 'Styling/import.scss';

.wrap {
  position: relative;
  display: inline-block;
  z-index: 1;
  .trigger {
    fill: $yellow;
  }

  .applicationsMenu {
    position: absolute;
    right: -85px;
    top: 45px;
    display: none;
    width: auto;
    max-width: 90vw;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .iconWrap {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        background-color: $gray6;
        border-radius: 50%;
        padding: 5px;

        svg {
          width: 30px;
          height: 30px;
        }
      }

      .text {
        margin-top: 10px;
        font-size: 14px;
        text-align: center;
        height: 10px;
      }
    }
  }
  .menuOpen {
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: repeat(3, auto);
    background-color: white;
    gap: 30px;
    padding: 40px;
    border-radius: 30px;
  }
  a, a:visited {
    color: $black;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
  }
}

.active::before {
  position: absolute;
  z-index: -1;
  content: '';
  width: 50px;
  height: 50px;
  background-color: $gray29-opacity-3;
  border-radius: 50%;
  top: -13px;
  left: -12px;
}
