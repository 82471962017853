@import 'Styling/import.scss';

.title {
  color: $yellow;
  font-size: 36px;
  text-align: center;
  font-family: 'DMSerifDisplay', serif;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}
.mainContainer {
  background-color: $white;
  border-radius: 8px;
  margin-top: 11px;
  padding-bottom: 19px;
  padding-top: 12px;
  margin-bottom: 30px;
}

.description {
  display: flex;
  flex-direction: column;
  padding: 15px 20px 0;
}

.titleContainer {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $icon-gray;
  padding: 0 10px 10px;

  .icon {
    border: 2px solid $icon-gray;
    border-radius: 50%;
    width: 64px;
    height: 64px;
    margin-right: 19px;
    margin-left: 11px;
  }
  .header {
    color: $yellow3;
  }
  .headerGrey {
    color: $grey1;
  }
}
.cardTitle {
  font-size: 20px;
  color: $yellow3;
}
.cardTitleGrey {
  color: $black;
}
.descriptionSpec {
  display: flex;
  align-items: center;
  margin-bottom: 19px;
  font-size: 14px;

  .descriptionIcon {
    margin-right: 19px;
    color: $grey1;
  }
  .descriptionText {
    color: $grey1;
    margin-right: 5px;
  }
}

.viewButton {
  border-radius: 8px;
  border: solid 1px $gray29;
  background-color: $white2;
  justify-content: center;
  align-items: center;
  padding: 7px 10px;
  text-align: center;
  color: $blue1;
  max-width: calc(100% - 40px);
  :visited {
    color: $blue1;
  }
  align-self: center;

  .link {
    color: $blue2;
    font-size: 14px;
    line-height: 14px;
    display: inline-block;
  }
}



@include breakpoint('desktop') {
  .cardTitle {
    font-size: 18px;
  }
}
