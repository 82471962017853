@import 'Styling/import.scss';

.container {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  width: 100%;
  .hr {
    margin: 40px 0 0;
  }
  .firstTitle {
    margin-top: 14px;
  }
}

@include breakpoint('desktop') {
  .container {
    .hr {
      margin: 50px 0 0;
    }
  }
}

.otherOcuppations {
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 20px;

  .checkBoxLabel {
    text-align: start;
    font-size: 14px;
    color: $grey2;
    line-height: 18px;
    cursor: pointer;
    margin-left: 18px;
    margin-right: 18px;
  }

  .otherOcuppationsTextField {
    position: absolute;
    top: 40px;
    left: 0;
    background-color: $white2;
    border-radius: 6px;

    max-width: 270px;

    input {
      font-size: 14px;
      height: 32px;
      background-color: $white2;
      border-radius: 6px;
      line-height: 18px;
      padding: 0;
      padding-left: 10px;
    }
  }

  .hidden {
    display: none;
  }
}

.errorIcon {
  color: $red-error2;
}
