@import 'Styling/import.scss';

.sidebar {
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  background-color: $black3;

  .avatarImageContainer {
    display: grid;
    grid-template-columns: 28.53% 1fr 28.53%;
    width: 100%;
    align-items: center;
  }

  .darkGrayHr {
    border-top: 1px solid $gray35;
  }
  .hrWrapp {
    padding-top: 35px;
  }
}
.aboutCandidate {
  margin-top: 16px;
}
