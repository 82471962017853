@import 'Styling/import.scss';
.mainContainer {
  padding-bottom: 59px;

  .cardsContainer {
    display: grid;
    grid-template-columns: minmax(auto, 354px);
    justify-content: center;
    grid-column-gap: 14px;
    grid-row-gap: 36px;
    margin: 0 39px;
    margin-bottom: 24px;
    justify-items: center;

    @include breakpoint(900px) {
      grid-template-columns: minmax(248px, 27.56%) minmax(248px, 27.56%);
      margin-bottom: 40px;
      padding-left: 230px;
      padding-right: 230px;
    }

    @include breakpoint(1160px) {
      grid-template-columns:
        minmax(248px, 21.38%) minmax(248px, 21.38%) minmax(248px, 21.38%)
        minmax(248px, 21.38%);
      margin-bottom: 60px;
    }
    @include breakpoint(2560px) {
      grid-template-columns:
        minmax(248px, 14.38%) minmax(248px, 14.38%) minmax(248px, 14.38%)
        minmax(248px, 14.38%);
    }
    @include breakpoint(3000px) {
      grid-template-columns:
        minmax(248px, 12.38%) minmax(248px, 12.38%) minmax(248px, 12.38%)
        minmax(248px, 12.38%);
    }
    @include breakpoint(4000px) {
      grid-template-columns:
        minmax(248px, 8.38%) minmax(248px, 8.38%) minmax(248px, 8.38%)
        minmax(248px, 8.38%);
    }
  }

  .titleWrapper,
  .title {
    font-size: 41px;
    font-family: 'DMSerifDisplay', serif;
    color: $white;
    text-shadow: 3px 5px 30px $gray28;
    font-weight: 400;

    @include breakpoint('1160px') {
      font-size: 58px;
    }
  }

  .titleWrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 34px;

    @include breakpoint('900px') {
      margin-bottom: 25px;
    }

    @include breakpoint('1160px') {
      margin-bottom: 56px;
    }

    @include breakpoint('1440px') {
      margin-bottom: 41px;
    }
  }

  .card {
    background-color: $blue2-opacity-4;
    border-radius: 10px;
    backdrop-filter: blur(23.5px);
    padding: 24px 15px 33px 20.6px;
  }

  h3 {
    font-size: 24px;
    color: $orange;
    margin-bottom: 12px;
    line-height: 30px;
  }

  p {
    font-size: 20px;
    color: $white;
    line-height: 24px;
  }

  .buttonContainer {
    display: flex;
    justify-content: center;

    .button {
      width: 160px;
      height: 42px;
      padding-left: 10px;
      padding-right: 10px;
    }

    @include breakpoint('900px') {
      .button {
        width: 250px;
        height: 60px;
      }
    }
  }
}
