@import 'Styling/import.scss';

.contentItemContainer {
  border-top: 1px solid $gray35;
  padding-top: 22px;
  padding-bottom: 22px;
  width: 100%;

  &:first-of-type {
    border-top: none;
    padding-top: 0;
  }
}
