@import 'Styling/import.scss';

.modal {
  > div:nth-child(2) {
    padding: 24px 20px;

    @include breakpoint('desktop') {
      min-height: 115px;
      display: flex;
      align-items: center;
    }
  }

  @include breakpoint('desktop') {
    min-width: 421px;
  }
}

.modalContent {
  max-width: 300px;
  display: flex;
  align-items: center;
  .icon {
    color: $black;
    margin-right: 20px;
  }
  .text {
    color: $brown;
    line-height: 22px;
  }
}
