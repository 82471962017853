@import 'Styling/import.scss';

.title {
  font-size: 24px;
  margin-bottom: 40px;
}

.typeAheadContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
}

.inputMainContainer {
  position: relative;
  border: 1px solid $input-border;
  border-radius: 8px;
  &:hover,
  &:focus {
    border: 1px solid $input-focus-border;
  }
  &:active {
    border: 1px solid $input-active-border;
  }
}

.inputContainer {
  display: flex;
  background-color: $white2;
  padding: 10px 8px 0 20px;
  border-radius: 10px;
}

.inputWrap {
  flex: 1;
}

.inputChip {
  margin-bottom: 8px;
}

.arrowWrap {
  display: flex;
  align-items: center;
}

.inputTopPlaceholder {
  color: $grey1;
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 12px;
  text-align: left;
}

.inputValues {
  display: flex;
  flex-wrap: wrap;
}

.input {
  color: $grey2;
  border: none;
  background-color: #f5f7f8;
  height: 24px;
  font-size: 16px;
  display: block;
  flex: 1 1;
  min-width: 100%;
  margin-bottom: 8px;
  &:focus {
    outline: none;
  }

  &::placeholder {
    color: $grey2;
    font-size: 16px;
  }

  @include breakpoint('tablet') {
    min-width: auto;
  }
}

.dropdownContainer {
  position: absolute;
  left: 0;
  right: 0;
  background-color: $white;
  border-radius: 10px;
  padding: 22px 20px;
  display: flex;
  flex-direction: column;
  max-height: 350px;
  overflow-y: auto;
  margin-bottom: 20px;
  z-index: 1;
  @include listBoxShadow();
  @include breakpoint('desktop') {
    bottom: auto;
  }
}

.checkBoxWrap {
  display: flex;
  margin: 0 -20px;
  padding: 0 20px;
  align-items: center;
  line-height: 38px;

  &:hover,
  &:focus {
    background-color: $white2;
    outline: none;
  }

  .icon {
    margin-right: 19px;
  }

  .checkBoxText {
    @include ellipsis(1);
    word-break: break-all;
    color: $grey2;
  }

  .button {
    display: flex;
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
  }
}

.hidden {
  display: none;
}

.white, .modal {
  .inputContainer {
    background-color: $white;
    padding: 20px 8px 20px 20px;
  }
  .input {
    background-color: $white;
    margin-bottom: 0;
  }
}

.modal {
  @include breakpoint('desktop') {
    margin: $input-modal-top-margin 0 $input-modal-bottom-margin;
  }
}

.error {
  border: 1px solid $error-border;
  border-radius: 8px;
  &:hover,
  &:focus,
  &:active {
    border: 1px solid $error-border;
  }
}
