@import 'Styling/import.scss';

.actionWrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;

  .confirmationAction {
    font-size: 14px;
    color: $blue1;
    flex-grow: 1;
  }

  .confirmedMessage {
    display: flex;
    font-size: 14px;
    align-items: center;
    color: $blue1;

    span {
      margin-left: 6px;
    }

    svg {
      color: $green3;
    }
  }

 .startInterviewBtn {
   padding: 7px 15px;
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 9px;
   background-color: $yellow3;
   border: solid 1px $gray31;
   color: $white;
   cursor: pointer;
   white-space: nowrap;

   a {
     display: flex;
     align-items: center;
     justify-content: center;
   }

   svg {
     color: $white;
   }

   span {
     margin-left: 5px;
     color: $white;
   }
 }

 .startInterviewBtnDisabled {
   background-color: $white2;
   color: $gray12;
   cursor: not-allowed;

   svg {
     color: $gray12;
   }
   span {
     color: unset;
   }
 }
     
  .moreAction {
    color: $gray30;
    transition: .3s fill linear;
  }

  .button {
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9px;
    background-color: $white2;
    border: solid 1px $gray31;
    cursor: pointer;
    white-space: nowrap;
  }
}
