/* roboto-100 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url('/Assets/Fonts/roboto-v30-latin-ext_latin-100.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/Assets/Fonts/roboto-v30-latin-ext_latin-100.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('/Assets/Fonts/roboto-v30-latin-ext_latin-100.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-100.woff') format('woff'),
    /* Modern Browsers */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-100.ttf') format('truetype'),
    /* Safari, Android, iOS */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-100.svg#Roboto')
      format('svg'); /* Legacy iOS */
}
/* roboto-100 - latin italic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url('/Assets/Fonts/roboto-v30-latin-ext_latin-100italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/Assets/Fonts/roboto-v30-latin-ext_latin-100italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-100italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-100italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-100italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-100italic.svg#Roboto')
      format('svg'); /* Legacy iOS */
}

/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('/Assets/Fonts/roboto-v30-latin-ext_latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/Assets/Fonts/roboto-v30-latin-ext_latin-300.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('/Assets/Fonts/roboto-v30-latin-ext_latin-300.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-300.woff') format('woff'),
    /* Modern Browsers */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-300.ttf') format('truetype'),
    /* Safari, Android, iOS */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-300.svg#Roboto')
      format('svg'); /* Legacy iOS */
}
/* roboto-300 - latin italic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('/Assets/Fonts/roboto-v30-latin-ext_latin-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/Assets/Fonts/roboto-v30-latin-ext_latin-300italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-300italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-300italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-300italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-300italic.svg#Roboto')
      format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('/Assets/Fonts/roboto-v30-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/Assets/Fonts/roboto-v30-latin-ext_latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('/Assets/Fonts/roboto-v30-latin-ext_latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-regular.svg#Roboto')
      format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin italic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('/Assets/Fonts/roboto-v30-latin-ext_latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/Assets/Fonts/roboto-v30-latin-ext_latin-italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('/Assets/Fonts/roboto-v30-latin-ext_latin-italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-italic.woff') format('woff'),
    /* Modern Browsers */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-italic.svg#Roboto')
      format('svg'); /* Legacy iOS */
}

/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('/Assets/Fonts/roboto-v30-latin-ext_latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/Assets/Fonts/roboto-v30-latin-ext_latin-500.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('/Assets/Fonts/roboto-v30-latin-ext_latin-500.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-500.woff') format('woff'),
    /* Modern Browsers */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-500.ttf') format('truetype'),
    /* Safari, Android, iOS */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-500.svg#Roboto')
      format('svg'); /* Legacy iOS */
}

/* roboto-500 - latin italic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: url('/Assets/Fonts/roboto-v30-latin-ext_latin-500italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/Assets/Fonts/roboto-v30-latin-ext_latin-500italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-500italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-500italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-500italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-500italic.svg#Roboto')
      format('svg'); /* Legacy iOS */
}

/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('/Assets/Fonts/roboto-v30-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/Assets/Fonts/roboto-v30-latin-ext_latin-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('/Assets/Fonts/roboto-v30-latin-ext_latin-700.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-700.woff') format('woff'),
    /* Modern Browsers */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-700.svg#Roboto')
      format('svg'); /* Legacy iOS */
}

/* roboto-700 - latin italic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url('/Assets/Fonts/roboto-v30-latin-ext_latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/Assets/Fonts/roboto-v30-latin-ext_latin-700italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-700italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-700italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-700italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-700italic.svg#Roboto')
      format('svg'); /* Legacy iOS */
}
/* roboto-900 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url('/Assets/Fonts/roboto-v30-latin-ext_latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/Assets/Fonts/roboto-v30-latin-ext_latin-900.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('/Assets/Fonts/roboto-v30-latin-ext_latin-900.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-900.woff') format('woff'),
    /* Modern Browsers */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-900.ttf') format('truetype'),
    /* Safari, Android, iOS */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-900.svg#Roboto')
      format('svg'); /* Legacy iOS */
}
/* roboto-900 - latin italic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  src: url('/Assets/Fonts/roboto-v30-latin-ext_latin-900italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/Assets/Fonts/roboto-v30-latin-ext_latin-900italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-900italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-900italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-900italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/Assets/Fonts/roboto-v30-latin-ext_latin-900italic.svg#Roboto')
      format('svg'); /* Legacy iOS */
}

/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('/Assets/Fonts/montserrat-v24-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/Assets/Fonts/montserrat-v24-latin-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('/Assets/Fonts/montserrat-v24-latin-700.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/Assets/Fonts/montserrat-v24-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('/Assets/Fonts/montserrat-v24-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/Assets/Fonts/montserrat-v24-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
  /* roboto-300 - latin */
}

/* dm-serif-display-regular - latin */
@font-face {
  font-family: 'DMSerifDisplay';
  font-style: normal;
  font-weight: 400;
  src: url('/Assets/Fonts/DMSerifDisplay-Regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/Assets/Fonts/DMSerifDisplay-Regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('/Assets/Fonts/DMSerifDisplay-Regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('/Assets/Fonts/DMSerifDisplay-Regular.woff')
      format('woff'),
    /* Modern Browsers */ url('/Assets/Fonts/DMSerifDisplay-Regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('/Assets/Fonts/DMSerifDisplay-Regular.svg')
      format('svg'); /* Legacy iOS */
}

/* dm-serif-display-italic - latin */
@font-face {
  font-family: 'DMSerifDisplay';
  font-style: italic;
  font-weight: 400;
  src: url('/Assets/Fonts/DMSerifDisplay-Italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/Assets/Fonts/DMSerifDisplay-Italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('/Assets/Fonts/DMSerifDisplay-Italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('/Assets/Fonts/DMSerifDisplay-Italic.woff')
      format('woff'),
    /* Modern Browsers */ url('/Assets/Fonts/DMSerifDisplay-Italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('/Assets/Fonts/DMSerifDisplay-Italic.svg')
      format('svg'); /* Legacy iOS */
}

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('/Assets/Fonts/montserrat-v24-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/Assets/Fonts/montserrat-v24-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('/Assets/Fonts/montserrat-v24-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/Assets/Fonts/montserrat-v24-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/Assets/Fonts/montserrat-v24-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/Assets/Fonts/montserrat-v24-latin-regular.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'FingerPaint';
  font-style: normal;
  font-weight: 400;
  src: url('/Assets/Fonts/temp/finger-paint-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/Assets/Fonts/temp/finger-paint-v15-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('/Assets/Fonts/temp/finger-paint-v15-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/Assets/Fonts/temp/finger-paint-v15-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('/Assets/Fonts/temp/finger-paint-v15-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/Assets/Fonts/temp/finger-paint-v15-latin-regular.svg#FingerPaint')
      format('svg'); /* Legacy iOS */
}
