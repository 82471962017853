@import '/src/Styling/import';

.container {
  .radioBtnGroup {
    position: relative;
    padding: 5px;
    border: rgba(255, 255, 255, 0) 1px solid;
    height: inherit;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr;
    @include breakpoint(768px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include breakpoint(1024px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .wrap {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
  }

  .label {
    color: $grey2;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    cursor: pointer;
  }

  .translation {
    text-align: start;
    padding: 0 18px;
    max-width: calc(100% - 24px);
  }

  .hidden {
    display: none;
  }

  .error {
    border: 1px solid $red-error2;
  }

  .errorIcon {
    color: $red-error2;
  }
}
