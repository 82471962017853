@import 'Styling/import.scss';

.container {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  width: 100%;
  .hr {
    margin: 40px 0 0;
  }
  .firstTitle {
    margin-top: 14px;
  }
  .occupation {
    height: 150px;
    background-color: $gray22;
    border-radius: 10px;
    padding: 20px;
    position: relative;
    .occupationText {
      color: $grey2;
      font-size: 16px;
      text-align: left;
      font-weight: 700;
      padding-bottom: 10px;
    }
    .deleteIcon {
      font-size: 25px;
      position: absolute;
      right: 15px;
      top: 15px;
    }
    .enterOccupationInput {
      > div > div:first-child {
        margin-bottom: 19px;
      }
      > div > div:last-child {
        margin-top: -40px;
      }
      > div > div:first-child,
      input {
        border: none;
        background-color: $gray22;
        padding: 0;
      }
      :global .arrowWrap {
        display: none;
      }
    }
    .sliderContainer {
      padding: 0 20px;
    }
  }
  .occupations .occupation {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .errorBorder {
    border: $error-border solid 1px;
    border-radius: 10px;
    position: relative;
  }
}

@include breakpoint('desktop') {
  .container {
    .hr {
      margin: 50px 0 0;
    }
    .occupation {
      height: 120px;
      display: flex;
      align-items: flex-end;
      .enterOccupationInput {
        width: 50%;
      }
      .occupationText {
        width: 50%;
        padding-left: 20px;
      }
      .enterOccupationInput {
        > div > div:first-child {
          margin: 0;
        }
        > div > div:first-child,
        input {
          padding: 18px 0 5px 8px;
          height: 40px;
        }
        > div > div:last-child {
          margin-top: 0;
        }
      }
      .sliderContainer {
        width: 50%;
        .sliderWrap {
          width: 80%;
        }
      }
    }
    .occupations .occupation {
      margin-bottom: 14px;
      flex-direction: row;
    }
  }
}
