@import 'Styling/import.scss';

.button {
  width: 100%;
  font-size: 18px;
  line-height: 40px;
  text-align: center;
  color: $white;
  height: 64px;
  border-radius: 8px;
  background-color: $blue1;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.back {
  text-align: center;
  color: $grey3;
  background-color: transparent;
  border: $white;
  font-size: 14px;
  text-decoration: underline;
  width: auto;
  height: auto;
  line-height: initial;
  padding: 15px 20px 15px;
  &:hover,
  &:focus,
  &:active {
    color: $blue2;
    text-decoration: none;
  }
  @include breakpoint('desktop') {
    padding: 22px;
    position: absolute;
    left: 30px;
  }
}

.modalSubmit,
.modalProfileSubmit {
  border-radius: 8px;
  border: solid 1px $gray18;
  background-color: $gray19;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: $blue1;
  padding: 22px 24px;
  height: auto;
  line-height: 19px;
  width: auto;
  transition: 0.2s ease-in-out;

  &:hover,
  &:focus,
  &:active {
    border: solid 1px $blue2;
    background-color: $blue2;
    color: $white;
  }
  &[disabled] {
    &:hover,
    &:focus,
    &:active {
      border: solid 1px $gray18;
      background-color: $gray19;
      color: $blue1;
    }
  }
}

.bright {
  border-radius: 8px;
  border: solid 1px $gray18;
  background-color: $gray19;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: $blue1;
  padding: 12px 24px;
  height: auto;
  line-height: 19px;
  width: auto;
  transition: 0.2s ease-in-out;

  &:hover,
  &:focus,
  &:active {
    border: solid 1px $blue2;
    background-color: $blue2;
    color: $white;
  }
}

.modalProfileSubmit {
  @include breakpoint('desktop') {
    min-width: 222px;
    background-color: $white2;
    transition: 0.2s ease-in-out;

    &:hover,
    &:focus,
    &:active {
      color: $white;
      background-color: $blue2;
    }
  }
}

.acceptRevoke {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 39px;
  border-radius: 8px;
  border: solid 1px $gray18;
  background-color: $gray19;
  font-size: 14px;
  color: $blue1;
  max-width: 150px;
  transition: 0.2s ease-in-out;
  &:hover,
  &:focus,
  &:active {
    border: solid 1px $blue2;
    background-color: $blue2;
    color: $white;
  }

  @include breakpoint('desktop') {
    min-width: 150px;
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumbModal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 39px;
  border-radius: 8px;
  border: solid 1px $gray18;
  background-color: $gray19;
  font-size: 14px;
  color: $darkblue;
  width: 222px;
  height: 65px;
  font-weight: bold;
  transition: 0.2s ease-in-out;

  &:hover,
  &:focus,
  &:active {
    border: solid 1px $blue2;
    background-color: $blue2;
    color: $white;
  }
  @include breakpoint('desktop') {
    font-size: 16px;
  }
}

.thumb {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: solid 1px $gray31;
  background-color: $white2;
  font-size: 14px;
  color: $blue1;
  height: 40px;
  min-width: 102px;
  padding: 0px 17px;
  font-weight: normal;
}
