@import 'Styling/import.scss';

.section {
  .content {
    @include breakpoint('desktop') {
      margin-left: 35px;
      margin-right: 35px;
    }
  }
}

.text {
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  color: $black;
  @include breakpoint('desktop') {
    padding-right: 33px;
  }
}
.modal {
  @include breakpoint('desktop') {
    min-width: 80%;
  }
}

.employmentDateUp {
  margin-bottom: 30px;
  @include breakpoint('desktop') {
    display: flex;
    margin-bottom: 0px;
  }
  & > div {
    margin-bottom: 20px;
    width: 100%;
    @include breakpoint('desktop') {
      &:first-child {
        margin-right: 30px;
      }
    }
  }
  margin-bottom: 40px;
}
.employmentDateDown {
  @include breakpoint('desktop') {
    display: flex;
    padding-top: 0px;
    border-top: none;
  }
  & > div {
    margin-bottom: 30px;
    width: 100%;
  }
}
