@import 'Styling/import.scss';
.deviderContainer {
  align-items: center;
  justify-content: center;
  display: flex;
}
.devider::before,
.devider::after {
  display: inline-block;
  content: '';
  border-top: 1px solid $grey1;
  width: 8rem;
  transform: translateY(-1rem);
}
@include breakpoint('desktop') {
  .deviderContainer {
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .devider::before,
  .devider::after {
    display: inline-block;
    content: '';
    border-top: 1px solid $grey1;
    width: 17rem;
    transform: translateY(-1rem);
  }
}
