@import 'Styling/import.scss';

.mainAssessmentsContainer {
  background-color: $gray14;
  padding: 25px 20px 100px 20px;
  min-height: 100vh;

  @include breakpoint(desktop) {
    padding: 10px 50px 49px 50px;
  }

  .assessmentsHeader {
    font-weight: normal;
  }
}
