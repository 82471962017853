@import 'Styling/import.scss';

.tooltipStyle {
  :global {
    .MuiTooltip-tooltip {
      background-color: white;
      color: rgba(0, 0, 0, 0.87);
      max-width: 600px;
      box-shadow: 7px 7px 7px -2px rgb(0 0 0 / 20%),
        0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
    }
    .MuiTooltip-arrow {
      color: white;
    }
  }
}

.wrap {
  z-index: 0;
  .background {
    background-color: $black6;
    position: relative;
    width: 149px;
    height: 43px;
    overflow: hidden;
    border-radius: 8px;
  }
  @include breakpoint('desktop') {
    .background {
      margin-left: 0;
    }
  }
  .percent {
    position: relative;
    width: 100%;
    height: 100%;
    top: 8px;
    left: 20px;
    z-index: 1;
    color: $white;
    font-size: 24px;
    font-weight: bold;
  }

  .progressBackground {
    background-color: orange;
    position: absolute;
    top: 0;
    height: 100%;
  }
}
