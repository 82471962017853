@import 'Styling/import.scss';

.container {
  .cardsWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .title {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    h1 {
      color: $yellow;
      font-size: 36px;
      text-align: center;
      font-family: 'DMSerifDisplay', serif;
      max-width: 250px;
    }
  }

  .break {
    height: 40px;
    display: flex;
    align-items: center;
  }
  @include breakpoint('tablet') {
    .title h1 {
      max-width: 100%;
    }
    .cardsWrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 40px;
    }
  }
  @include breakpoint('1200px') {
    .cardsWrapper {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
