@import 'Styling/import.scss';

.circleProgressBarContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .circleSvg {
    display: block;
  }

  .circleBackground,
  .circleProgress {
    fill: none;
  }

  .circleBackground {
    stroke: none;
  }

  .circleProgress {
    stroke: $yellow;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .circleText {
    position: absolute;
    font-size: 24px;
    color: $yellow;
    z-index: 10;
  }

  img {
    position: absolute;
    width: 120px;
    height: 120px;
    filter: brightness(50%);
    border-radius: 50%;
  }
 
}


  .image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $yellow3;
    text-transform: uppercase;
    text-align: center;
    line-height: 120px;
    font-size: 60px;
    color: white;

    position: absolute;
      width: 120px;
      height: 120px;
      filter: brightness(50%);
      border-radius: 50%;
  
}