@import 'Styling/import.scss';

.myProjectsWrap {
  padding: 36px 25px 20px;

  .hrBottom15 {
    margin-bottom: 15px;
  }

  .hrTop15 {
    margin-top: 15px;
  }

  .hrBottom0 {
    margin-bottom: 0;
  }

  .title {
    display: inline-flex;
    align-items: center;
    color: $gray36;
    font-size: 24px;
    font-family: 'DMSerifDisplay', serif;
    .star {
      font-size: 48px;
    }
  }

  .projectList {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    .project {
      display: inline-flex;
      align-items: center;
      height: 50px;
      font-weight: bold;
      color: $gray36;
      font-size: 14px;
      border-bottom: 1px solid $gray335;
    }
  }
  .translation {
    margin-left: 12px;
    color: $gray36;
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
  }
  .viewMoreLink {
    font-size: 12px;
    text-decoration: underline;
    color: $gray36;
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
  }
}
