@import 'Styling/import.scss';

.wrap {
  width: auto;
  height: 100%;
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  text-align: left;
  position: relative;
  white-space: nowrap;
  z-index: 0;
  padding: 0 5px;
}

.text {
  display: inline-flex;
  align-items: center;
}

.svg {
  position: absolute;
  top: 25%;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1 ;
}
