@import '/src/Styling/import';

.breadcrumbsWrapper {
  display: none;

  @include breakpoint(desktop) {
    display: block;
    margin-left: 50px;
  }
}

.pageWrap {
  margin-top: 15px;

  .requestsWrap {
    display: flex;
    flex-direction: column;
    background-color: $white;
    margin-bottom: 20px;
    padding: 10px;

    .heading {
      font-size: 24px;
      font-family: 'DMSerifDisplay', serif;
      color: $yellow;
      border-bottom: 1px $gray29 solid;
      text-align: center;
    }

    .searchToolbar {
      display: grid;
      flex-direction: row;
      padding: 20px;
      padding-bottom: 0;
      grid-template-columns: 1fr;
      gap: 20px;

      .searchFieldWrap {
        position: relative;

        .typeahead {
          background-color: $white2;
          border-radius: 8px;

          input {
            height: 30px;
          }
        }

        .searchIcon {
          position: absolute;
          right: 15px;
          top: 15px;
          color: $grey1;
        }
      }
    }

    @include breakpoint(1440px) {
      .heading {
        font-size: 36px;
        text-align: left;
        margin: 20px 20px 0 20px;
        padding-bottom: 20px;
      }
    }
  }

  .resetSearchBtn {
    font-size: 14px;
    margin: 0 auto;
    margin-top: 8px;
    margin-bottom: 20px;
    cursor: pointer;
    color: $black-opacity-4;
    text-decoration: underline;
  }

  .filterDropdown > div:first-child {
    height: 50px;
  }

  @include breakpoint(1440px) {
    .requestsWrap {
      border-radius: 8px;

      .searchToolbar {
        grid-template-columns: 1fr auto auto auto;
      }

      .filterDropdown {
        width: 100%;
        width: 190px;
      }
    }

    .resetSearchBtn {
      margin: 0;
      margin-bottom: 8px;
      margin-top: 8px;
      margin-left: auto;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  @include breakpoint(1440px) {
    margin: 15px 50px 0 50px;
  }
}
