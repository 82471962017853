@import 'Styling/import.scss';

.formWrap {
  display: flex;
  align-content: center;
  align-items: center;
  width: 100%;
  justify-content: center;
  flex-direction: column;
}

.inputWrap {
  width: 100%;
  max-width: 386px;
  margin: 0 auto;
}
.resetButton {
  height: 65px;
}
.title {
  color: $white;
  font-family: 'DMSerifDisplay', serif;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 23px;
}
.description {
  color: $white;
}

.link {
  color: $white;
  text-decoration: underline;
  text-decoration-color: $white;
  font-size: 14px;
  margin-top: 37px;
  display: flex;
  justify-content: center;
}
.linkTxt {
  color: $white;
}

.form {
  width: 100%;
}

.successMessage {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $green2;
  margin-bottom: 25px;
  background: rgba(255, 255, 255, 0.7);
  padding: 10px;
  border-radius: 10px;

  svg {
    fill: $green2;
    margin-right: 18px;
  }
}

@include breakpoint('desktop') {
  .formWrap {
    margin-top: 60px;
  }
  .link {
    display: flex;
    justify-content: left;
  }
  .inputWrap {
    margin-bottom: 31px;
  }
  .title {
    font-size: 36px;
  }
}
