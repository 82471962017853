@import 'Styling/import.scss';

.HomePageStepsContainer {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 40px;

  @include breakpoint('900px') {
    padding-bottom: 211px;
  }

  @include breakpoint('1160px') {
    padding-bottom: 198px;
  }

  .h2 {
    color: $white;
    font-family: 'DMSerifDisplay', serif;
    font-size: 41px;
    line-height: 41px;
    margin-bottom: 56px;
    z-index: 1;
    font-weight: 400;
    text-shadow: 3px 5px 30px $gray37;
    text-transform: uppercase;

    @include smallScreens() {
      font-size: 36px;
    }

    @include breakpoint('1160px') {
      .h2 {
        font-size: 58px;
        margin-bottom: 76px;
        margin-top: 75px;
      }
    }

    @include breakpoint('1440px') {
      margin-top: 86px;
    }
    @include breakpoint('1920px') {
      margin-left: 65px;
    }
  }

  .steps {
    display: flex;
    flex-direction: column;
    .step {
      display: flex;
      .number {
        // font-family: 'FingerPaint', serif;
        font-size: 50px;
        margin-bottom: -5px;
        color: $orange;
        font-family: 'Roboto', sans-serif;
      }
      .text {
        font-size: 18px;
        line-height: 22px;
        font-weight: 400;
        color: $white;
        margin-left: 40px;
        max-width: 262px;

        @include breakpoint('1160px') {
          max-width: 371px;
          font-size: 24px;
          line-height: 29px;
        }
      }
      &.step1 {
        margin-bottom: 26px;
        @include breakpoint('1160px') {
          margin-bottom: 33px;
        }
      }
      &.step2 {
        margin-bottom: 25px;
        @include breakpoint('1160px') {
          margin-bottom: 34px;
        }
      }
      &.step3 {
        margin-bottom: 50px;
        @include breakpoint('1160px') {
          margin-bottom: 52px;
        }
      }
      &.step4 {
        margin-bottom: 37px;
        @include breakpoint('1160px') {
          margin-bottom: 34px;
        }
      }
    }
  }

  .buttonWrap {
    .yellowButton {
      width: 160px;
      height: 42px;
      padding-left: 10px;
      padding-right: 10px;
      text-transform: uppercase;
    }

    @include breakpoint('1160px') {
      .yellowButton {
        font-size: 20px;
        width: 250px;
        height: 60px;
      }
    }
  }
}
