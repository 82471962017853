$text-color: #fff;
$background-color: #fff;
$paragraph-color: #48555e;
$font-family: 'Roboto';
$success: #198754;
$warning: #fff3cd;
$onb-divider-color: #b5b9c0;
$select-item-background-color: #f5f7f8;
$border-background-color: #d3d3d3;

$white: #fff;
$white2: #f5f7f8;
$white3: #dcdee1;
$white43535135: #f2f3f5;
$white-opacity-2: rgba(255, 255, 255, 0.2);
$white-opacity-3: rgba(255, 255, 255, 0.3);
$white-opacity-4: rgba(255, 255, 255, 0.4);
$white-opacity-7: rgba(255, 255, 255, 0.7);
$white43535135: #f2f3f5;
$black: #000000;
$black2: #373636;
$black3: #303738;
$black4: #272727;
$black5: #24292a;
$black6: #393d3e;
$black7: #242a2b;
$black315451354: #292929;
$black-opacity-3: rgba(0, 0, 0, 0.3);
$black-opacity-7: rgba(0, 0, 0, 0.7);
$black-opacity-5: rgba(0, 0, 0, 0.5);
$black-opacity-6: rgba(0, 0, 0, 0.6);
$black-opacity-1: rgba(0, 0, 0, 0.1);
$black-opacity-4: rgba(0, 0, 0, 0.4);
$vampire-black: #0b0b0b;
$red-error: #e64144;
$red-error2: #ff4e4e;
$red-error333: #e54343;
$red-cinnabar: #e44145;
$red384354354: #9f0303;
$red35123534: #ffe5e5;
$red3: #db2a36;
$brown: #47453b;
$brown1: #f6c353;
$brown12313213: #784a04;
$grey: #7b7b7b;
$grey1: #9ca2aa;
$grey1-opacity-5: rgba(156, 162, 170, 0.5);
$grey1-opacity-1: rgba(156, 162, 170, 0.1);
$dark-gray1: #2c3436;
$grey2: #59574c;
$grey3: #63676c;
$gray4: #707070;
$gray5: #a2a2a2;
$gray6: #bbbcbd;
$gray7: #e4e4e4;
$gray8: #9da2ab;
$gray9: #dedede;
$gray10: #c2c2c2;
$gray11: #b5b5b5;
$gray12: #a6a6a7;
$gray13: #f7f7f7;
$gray14: #efefef;
$gray15: #949494;
$gray16: #ced0d3;
$gray17: #eff0f0;
$gray18: #d5d5d5;
$gray19: #f3f5f6;
$gray20: #8a9099;
$gray21: #d7d7d7;
$gray23: #494e4f;
$gray22: #f2f5f6;
$gray23: #cdd0d4;
$gray25: #c4c8cc;
$gray26: #b3b3b4;
$gray227: #828282;
$gray92: #ebebeb;
$gray27: #505459;
$gray28: #959596;
$gray9928: #bdc0c4;
$gray997: #a4a4a5;
$silver: #8a919a;
$silver2: #adaeae;
$silver23456: #f5f5f5;
$gray29: #b7b7b7;
$gray30-opacity-7: rgba(48, 55, 56, 0.7);
$gray80: #cccccc;
$gray84: #d6d6d6;
$gray29: #c2c5ca;
$gray30: #8e8e8e;
$gray29: #8e8e8e;
$gray29: #b7b7b7;
$gray30-opacity-7: rgba(48, 55, 56, 0.7);
$gray80: #cccccc;
$gray84: #d6d6d6;
$gray29: #c2c5ca;
$gray30: #8e8e8e;
$gray30: #7b7c7d;
$gray31: #dddddd;
$gray32: #c8cbcf;
$gray33: #2c3436;
$green: #8ac651;
$green35153432: #c5f4c1;
$gray29: #dddddd;
$gray29-opacity-3: rgba(73, 78, 79, 0.3);
$gray30: #8e8e8e;
$gray31: #cacaca;
$gray35: #494e4f;
$gray36: #838383;
$gray37: #959595;
$gray38: #e5e6e8;
$gray7371: #c1c1c1;
$gray7372: #969696;
$gray41: #dcdcdc;
$gray42: #dddfe0;
$gray97: #e6e6e6;
$gray98: #e6e8ea;
$gray34: #b7b7b7;
$gray35: #7b7c7d;
$gray80: #cccccc;
$gray84: #d6d6d6;
$gray92: #ebebeb;
$gray227: #828282;
$gray228: #dddddd;
$gray229: #808080;
$gray335: #494e4f;
$gray39: #9ca1a9;
$gray40: #6c6a5e;
$icon-gray: #c8ccd1;
$silver: #8a919a;
$silver2: #adaeae;
$gray43: #bcc1c7;
$gray44: #f9f9fa;
$gray45: #9ca1a9;
$gray-opacity-5: rgba(89, 87, 76, 0.5);
$green2: #8bc650;
$green: #8ac651;
$green3: #8ac550;
$green3-opacity-1: rgba(138, 197, 80, 0.1);
$green3-opacity2: rgba(138, 197, 80, 0.2);
$green4: #79bd3f;
$green35153432: #c5f4c1;
$green43545434: #daffed;
$green68455348: #015f44;
$dark-green: #006400;
$blue1: #113a60;
$blue2: #003a60;
$blue3: #234c71;
$blue351: #d9ecfb;
$blue2-opacity-8: rgba(0, 58, 96, 0.8);
$blue2-opacity-7: rgba(0, 58, 96, 0.7);
$blue2-opacity-6: rgba(0, 58, 96, 0.6);
$blue2-opacity-4: rgba(0, 58, 96, 0.4);
$blue2-opacity-5: rgba(0, 58, 96, 0.5);
$blue12: #00f5ff;
$blue351: #d9ecfb;
$light-blue: #f2f5f6;
$dark-blue: #0f2b4d;
$darkblue: #0f2b4d;
$yellow: #fbb015;
$yellow1: #f6c451;
$yellow251515: #fff6e4;
$orange: #f8a114;
$orange2: #fbb016;
$orange3: #f6deb5;
$orange354: #ffa07d;
$orange1: #f8a114;
$orange2: #ff9f7d;
$yellow2: #f8a114;
$yellow321: #fbb116;
$yellow3: #fcb116;
$yellow24523: #f6c353;
$yellow4: #f6c353;
$yellow251515: #fff6e4;
$link-water: #cdd0d4;
$link-water2: #c5c8cc;
$american-silver: #cacdd2;
$gainsboro: #ddd;
$cyan-blue: #cacdd1;
$blue9568: #003a60;
$chinese-silver: #cccccc;
$dim-gray: #626262;
$nobel: #959595;
$grey-gausian-blur: rgba(47, 55, 56, 0.7);
$cyan-dark: #474d4e;
$gray-cyan: #494e4f;
$gray-hr-opacity-2: rgba(239, 239, 239, 2);
$anchor-gray: #5b6162;
$text-shadow-1: 3px 5px 30px $nobel;
$darkblue: #0f2b4d;
$pink: #f5cef6;
$pink2: #df46d2;
$pink: #df46d2;
$purple: #5c07c6;
$purple13153135: #e8e2f7;
$purple15343568: #631fb1;
$pink: #df46d2;
$faux-blue-bolt: #00b3fe;
$pink456: #f5cef6;
$purple15343568: #631fb1;
$purple13153135: #e8e2f7;
$guyabano: #f8f8f8;
$pending_text: #631fb1;
$pending_background: #e8e2f7;
$input-border: #dddddd;
$input-focus-border: #9ca1a9;
$input-active-border: #003a60;
$error-border: #e54343;
$error-text: #e54343;
$orange1: #fbb20d;
$offer-request-type: #c5f4c1;
$offer-request-type-text: #59574c;
$placement-confirmed: #015f44;
$placement-pending: #631fb1;
$rejected-text-color: #9f0303;
$pending-text-color: #5c07c6;
$expired-text-color: #292929;

$theme-colors: (
  'success': (
    'color': #0f5132,
    'background-color': #d1e7dd,
    'border-color': #badbcc,
    'border-radius': 5px,
  ),
  'warning': (
    'color': #664d03,
    'background-color': #fff3cd,
    'border-color': #ffecb5,
  ),
  'error': (
    'color': #842029,
    'background-color': #f8d7da,
    'border-color': #f5c2c7,
  ),
) !default;

$title-modal-top-margin: 4px;
$title-modal-bottom-margin: 8px;

$input-modal-top-margin: 5px;
$input-modal-bottom-margin: 10px;
$input-modal-height: 64px;

$hr-modal-top-margin: 10px;
$hr-modal-bottom-margin: 15px;

//$hr-side-margin: 0px;
//$modal-slider-top-margin: 90px;
//$modal-legend-top-margin: 160px;
$modal-content-padding-top: 22px;
$modal-content-padding-bottom: 40px;
$modal-content-side-padding: 78px;

//$input-top-margin: 5px;
//$input-bottom-margin: 10px;
//
