@import 'Styling/import.scss';

.section {
  .content {
    @include breakpoint('desktop') {
      margin-left: 40px;
      margin-right: 40px;
    }
  }
}
.submitWrap {
  background-color: $white;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

@include breakpoint('desktop') {
  .modalWrapper {
    width: 800px;
  }
}
.mainContent {
  display: flex;
  background-color: $yellow;
  border-radius: 5px;
}

.title {
  font-size: 24px;
  color: $white;
  font-family: 'DMSerifDisplay', serif;
}
.pararaph {
  font-size: 14px;
  color: $white;
  margin-top: 15px;
}

.addPersonaButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  background-color: transparent;
  color: $black;
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
