@import '/src/Styling/import';

.conversationsWrap {
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: $white;
  border-radius: 8px 0 0 8px;

  @include breakpoint('toDesktop') {
    border-radius: 8px;
  }

  .headerWrap {
    padding: 0 30px;
    width: 100%;

    @include breakpoint('toDesktop') {
      padding: 0 21px;
    }

    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 1px solid $gray23;
      width: 100%;
      padding: 10px 0;

      @include breakpoint('toDesktop') {
        padding: 7px 0;
      }

      .heading {
        text-transform: uppercase;
        margin-right: auto;
        color: $gray12;
        font-weight: bold;
        font-size: 12px;
      }

      .moreIconBtn {
        fill: $black-opacity-5;
      }

      .newMessageBtn {
        padding: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 9px;
        background-color: $white2;
        border: solid 1px $gray31;
        cursor: pointer;
        white-space: nowrap;
        margin-left: 10px;

        svg {
          fill: $black-opacity-5;
          transform: scaleX(-1);
        }
      }

      .resetFilterButton {
        padding: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 9px;
        background-color: $white2;
        border: solid 1px $gray31;
        cursor: pointer;
        white-space: nowrap;
        margin-left: 10px;

        svg {
          fill: $black-opacity-5;
          transform: scaleX(-1);
        }
      }
    }
  }

  .subHeaderWrap {
    padding: 0 30px;
    width: 100%;

    .subHeader {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid $gray23;
      width: 100%;
      margin-right: auto;
      text-transform: uppercase;
      padding: 10px 0;

      .heading {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-right: auto;
        color: $black-opacity-5;
        font-size: 12px;
        font-weight: bold;

        .selectedLabel {
          margin-left: 3px;
        }
      }

      .cancelManageBtn,
      .selectAllBtn {
        cursor: pointer;
        user-select: none;
      }

      .cancelManageBtn {
        margin-right: 5px;
        svg {
          fill: $black-opacity-5;
        }
      }

      .selectAllBtn {
        color: $blue2;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
      }

      .selectedAction {
        margin-left: 10px;
        cursor: pointer;
        user-select: none;

        svg {
          fill: $black-opacity-5;
        }
      }
    }
  }

  .listItemsWrap {
    overflow-y: auto;
    height: 100%;
    width: 100%;
    max-height: 400px;
    width: 100%;

    &::-webkit-scrollbar {
      width: 6px;
      border-radius: 3px;
      background: transparent;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      width: 6px;
      border-radius: 3px;
      background-color: rgba($color: $gray-cyan, $alpha: 0.3);
    }
  }
}

.hideConversationsWrap {
  @include breakpoint('toDesktop') {
    display: none;
  }
}
