@import 'Styling/import.scss';

.requestItemWrap {
  display: flex;
  flex-direction: column;
  padding: 20px 0 30px;

  @include breakpoint(1500px) {
    display: grid;
    border-top: none;
    padding: 0;
    gap: 30px;
    grid-template-columns: 5fr 60px 85px 120px 260px;
    padding-top: 10px;
  }

  .detailWrap {
    display: grid;
    grid-template-columns: 3fr 5fr;
    align-items: center;
    margin-bottom: 18px;

    .label {
      font-size: 12px;
      color: $gray12;
    }

    @include breakpoint(1500px) {
      margin-bottom: 10px;
      .label {
        display: none;
      }
    }
  }
  .detailWrap:last-child {
    margin-top: 30px;
    margin-bottom: 0;

    @include breakpoint(1500px) {
      margin-top: 0;
      margin-bottom: 10px;
    }
  }

  .flexContainer {
    display: flex;
  }
  @include breakpoint(1500px) {
    .detailWrap {
      display: flex;
      flex-direction: row;
    }
  }
  .detailsValue {
    font-size: 14px;
    background-color: $blue2;
    border: solid 1px $gray31;
    border-radius: 8px;
    padding: 10px 13px;
    width: 100%;
    cursor: pointer;
    max-width: 150px;
  }

  .proposedTime {
    display: flex;
    flex-direction: row;
    text-align: left;

    .date {
      font-size: 14px;
      padding-right: 5px;
      margin-right: 5px;
      border-right: black solid 1px;
    }
    .time {
      font-size: 14px;
    }

    @include breakpoint(1500px) {
      display: flex;
      flex-direction: column;
      text-align: center;

      .date {
        font-size: 14px;
        padding: 0;
        margin: 0;
        border-right: none;
      }

      .time {
        font-size: 12px;
      }
    }
  }
  @include breakpoint(1500px) {
    max-width: none;
  }

  .statusValue {
    font-size: 14px;
  }

  .yellowText {
    color: $yellow;
  }
  .brownText {
    color: $brown12313213;
  }
  .greenText {
    color: $green68455348;
  }

  .redCinnabarText {
    color: $red-cinnabar;
  }

  .redText {
    color: $rejected-text-color;
  }

  .orange2Text {
    color: $orange2;
  }

  .purpleText {
    color: $pending_text;
  }

  .expirationValue {
    font-size: 14px;
  } 

  .blackText{
    color: $expired-text-color
  }
  .justifyEnd {
    justify-content: end;
  }

  .hideOnSmall {
    display: none;
  }

  .grayStarIcon {
    color: $black-opacity-6;
    font-size: 18px;
  }
}
.selectedCandidateRow {
  background-color: $green3-opacity2;
  border: solid 1px #8bc650;
  border-radius: 10px;
  outline: solid 1px #8bc650;
}

.candidateRow {
  border-bottom: 1px solid $gray23;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}
