@import 'Styling/import.scss';

.main {
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  position: relative;

  background-image: url('/Assets/Images/O-nas-background-350px.jpg');

  @include breakpoint('420px') {
    background-image: url('/Assets/Images/O-nas-background-420px.jpg');
  }
  @include breakpoint('900px') {
    background-image: url('/Assets/Images/O-nas-background-900px.jpg');
  }
  @include breakpoint('1160px') {
    background-image: url('/Assets/Images/O-nas-background-1160px.jpg');
  }
  @include breakpoint('1440px') {
    background-image: url('/Assets/Images/O-nas-background-1440px.jpg');
  }
  @include breakpoint('1920px') {
    background-image: url('/Assets/Images/O-nas-background-1920px.jpg');
  }
  @include breakpoint('2560px') {
    background-image: url('/Assets/Images/O-nas-background-2560px.jpg');
  }
  @include breakpoint('3000px') {
    background-image: url('/Assets/Images/O-nas-background-3000px.jpg');
  }
  @include breakpoint('4000px') {
    background-image: url('/Assets/Images/O-nas-background-4000px.jpg');
  }

  //   .overflow {
  // position: absolute;
  // top: 0;
  // left: 0;
  // width: 100%;
  // height: 100%;
  //   }

  .content {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 175px);
    padding: 0 30px;
    padding-bottom: 100px;
    color: $white;
    font-size: 18px;

    @include breakpoint('900px') {
      padding: 0 45px;
    }

    @include breakpoint('1160px') {
      max-width: 1020px;
      padding: 0;
    }
    @include breakpoint('1920px') {
      max-width: 1350px;
    }
  }

  h1 {
    text-align: center;
    margin-top: 100px;
    margin-bottom: 40px;

    @include breakpoint('900px') {
      margin-bottom: 100px;
    }

    .title {
      font-size: 23px;
      font-family: 'DMSerifDisplay', serif;

      @include breakpoint('900px') {
        font-size: 58px;
      }
    }
  }

  p {
    font-size: 14px;
    margin-bottom: 24px;

    @include breakpoint('900px') {
      font-size: 18px;
    }
  }

  h2 {
    font-size: 28px;
    font-weight: bold;
    padding-top: 40px;
    padding-bottom: 40px;
    border-top: solid 1px $yellow3;
    font-size: 18px;

    @include breakpoint('900px') {
      font-size: 28px;
    }
  }
}
.mainContainer {
  background: $blue2-opacity-5;
  backdrop-filter: blur(38.8px);
}
