@import 'Styling/import.scss';

.seekerProfileWrap {
  display: grid;
  grid-template-columns: 3fr 5fr;
  align-items: center;
  width: 100%;
  height: 100%;

  .avatarWrap {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;

    .avatarImg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .initialsAvatarWrap {
      background-color: $yellow;
      width: 100%;
      height: 100%;
      color: $white;
      line-height: 50px;
      font-size: 20px;
      text-align: center;
      a {
        color: $white;
      }
    }

    .avatarLockedWrap {
      background-color: $gray-cyan;
      width: 100%;
      height: 100%;
      color: $white;
      line-height: 50px;
      font-size: 20px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      .lockIcon {
        color: $yellow321;
      }
      a {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .seekerInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .jobTitle {
      font-size: 14px;
      color: $blue1;
      font-weight: bold;
      a {
        color: $blue1;
      }
    }

    .fullName {
      font-size: 12px;
      color: $yellow3;
      font-weight: bold;
      a {
        color: $yellow3;
      }
    }

    .age,
    .location {
      font-size: 12px;
      color: $black;
      font-style: italic;
      a {
        color: $black;
      }
    }
  }
}
.withComma {
  padding-right: 3px;
}
@include breakpoint(1500px) {
  .seekerProfileWrap {
    display: flex;
    flex-direction: row;
  }
  .seekerInfo {
    margin-left: 19px;
  }
}
