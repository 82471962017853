@import 'Styling/import.scss';

.timeoutModal.timeoutModal {
  max-width: 420px;

  :global {
    .content {
      font-size: 14px;
      line-height: 24px;
      color: $grey2;
      padding: 28px 24px;
    }
  }
  div > div ~ button {
    display: none;
  }
}