@import 'Styling/import.scss';

.container {
  margin: 0 auto;
  font-size: 14px;
  margin-top: 19px;

  &:first-of-type {
    margin-top: 0;
  }
}

.invisibleLink {
  display: none;
}

.sectionHeader {
  padding: 0;
}

.sectionItem {
  padding: 0;
  margin: 0;
  border-radius: 8px;
}

.testsContainer {
  width: 100%;
  padding: 41px 19px;

  @include breakpoint('desktop') {
    padding: 30px;
    padding-bottom: 40px;
  }
}

.completedTestContainer {
  background-color: $green3-opacity-1;
}

.psychologyIcon {
  width: 20px;
  height: 20px;
  color: $yellow2;
}

.testContainer {
  padding-bottom: 30px;

  &:last-of-type {
    border-bottom: 1px solid $gray25;
  }

  @include breakpoint('desktop') {
    padding-bottom: 0;
  }
}

.testsLabels {
  padding-bottom: 20px;
  @include breakpoint(desktop) {
    display: grid;
    grid-template-columns: 29.27% 15.85% 13.41% 16.95% 9.76% 1fr;
  }
}

.testMobileDateWrapper {
  margin-right: 6px;
}

.testMobileExpirationDateWrapper {
  margin-right: 17px;
  max-width: 67px;
}

.testMobileLabel {
  font-size: 12px;
  font-weight: bold;
  color: $gray12;
  line-height: 16px;
  margin-right: 28px;

  @include breakpoint(desktop) {
    display: none;
  }
}

.testMobileInfoWrapper {
  margin-right: 59px;
}

.testContent {
  display: flex;
  border-top: 1px solid $link-water2;

  .chartIcon {
    width: 18px;
    height: auto;
    color: $black-opacity-3;
  }

  @include breakpoint('desktop') {
    border-top: 1px solid $gray23;
    height: 59px;
  }

  .dynamicTestValues {
    margin-left: 6px;

    @include breakpoint('desktop') {
      display: grid;
      grid-template-columns: 29.4% 16% 13.4% 16% 10.5% 1fr;
      width: 100%;
      margin-left: 0;

      div {
        display: flex;
        align-items: center;
      }
    }

    .testWrapper {
      display: flex;
      align-items: flex-start;
      margin-top: 20px;
      color: $blue1;
      line-height: 16px;

      @include breakpoint('desktop') {
        align-items: center;
        margin-top: 0;
      }
    }

    .infoWrapper {
      display: flex;
      align-items: center;
      margin-top: 10px;
      margin-bottom: 12px;

      @include breakpoint('desktop') {
        margin: 0;
      }
    }

    .button {
      width: 110px;
      height: 39px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      padding: 0;

      span {
        line-height: normal;
      }

      @include breakpoint('desktop') {
        font-size: 12px;
        margin-top: 0;
      }
    }

    .pendingButton {
      border: none;
      background-color: transparent;
      cursor: default;

      span {
        img {
          width: 110px;
          height: 39px;
        }
      }
    }

    .removeViewResultsButton {
      display: none;
    }

    .infoButton {
      width: 39px;
      height: 39px;
      border-radius: 50%;
      border: solid 1px $gainsboro;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      background-color: $white2;
      div {
        display: flex;
      }
    }
  }
}

.cognitiveIcon {
  width: 21px;
  height: 26px;
}

.psychometicIcon {
  width: 20px;
  height: 24px;
}

.completedTestIcon {
  filter: brightness(0) saturate(100%) invert(75%) sepia(21%) saturate(1027%)
    hue-rotate(47deg) brightness(91%) contrast(91%);
}

.dynamicAction {
  margin-top: 8px;

  @include breakpoint(desktop) {
    margin-top: 0;
  }

  .viewResultsLink {
    border-radius: 8px;
    border: solid 1px $gray18;
    background-color: $gray19;
    font-weight: bold;
    text-align: center;
    color: $blue1;
    line-height: 19px;
    transition: 0.2s ease-in-out;

    &:hover,
    &:focus,
    &:active {
      border: solid 1px $blue2;
      background-color: $blue2;
      color: $white;
    }
  }
}

.modalTestsContainer {
  .modalTitle {
    text-align: center;
    margin-bottom: 35px;
    font-size: 24px;
    font-family: 'DMSerifDisplay', serif;
    text-transform: uppercase;
    font-weight: 400;
  }

  a {
    text-decoration: underline;
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
  }

  .modalText {
    color: $grey2;
    font-size: 14px;

    a {
      color: $grey2;
      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
      }
    }
  }

  .checkboxTextWrapper {
    display: flex;
  }
}

.assessmentsHeader {
  padding-top: 39px;
  padding-bottom: 18px;

  @include breakpoint(desktop) {
    padding-top: 35px;
    padding-bottom: 15px;
  }

  &:first-of-type {
    padding-top: 18px;

    @include breakpoint(desktop) {
      padding-top: 17px;
    }
  }

  h2 {
    margin-bottom: 0;
    line-height: 30px;
    font-weight: normal;
  }
}
