@import 'Styling/import.scss';

.formWrap {
  display: flex;
  align-content: center;
  align-items: center;
  max-width: 390px;
  width: 100%;
  justify-content: center;
  flex-direction: column;


  .emailVerificationTitleWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;


    .emailVerificationTitle {
      margin-bottom: 21px;
    }
  }

  .description {
    display: inline-block;
    text-align: center;
  }

  .links {
    width: 100%;
    margin: 27px 0 18px;

    .buttonWrap {
      width: 100%;
      margin: 0 auto 18px;
    }

    .link {
      color: $grey2;
      text-decoration: underline;
      font-size: 14px;
    }
  }
}

@include breakpoint('desktop') {
  .links {
    margin-top: 27px;

    .buttonWrap {
      margin-bottom: 18px;
    }
  }
}

