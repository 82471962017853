@import 'Styling/import.scss';

.formWrap {
  display: flex;
  align-content: center;
  align-items: center;
  max-width: 390px;
  width: 100%;
  justify-content: center;
  flex-direction: column;
}

.description {
  display: inline-block;
  text-align: center;
}

.form {
  width: 100%;
  margin-bottom: 18px;
}

.logoWrap {
  margin-left: 0px;
  margin-bottom: 65px;
}

.imageWrap {
  position: fixed;
  width: 50%;
  height: 100%;
  top: 0;
  right: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('/Assets/Images/login-layout-background.jpg');
}
@media only screen and (max-width: 1024px) {
  .imageWrap {
    display: none;
  }
}
@include breakpoint('desktop') {
  .formWrap {
    margin-top: 60px;
  }
  .link {
    color: $grey3;
  }
  .inputWrap {
    margin-bottom: 31px;
  }
}

.loginButton {
  min-height: 66px;
}

.passwordField {
  margin-bottom: 30px;
}

.successMessage {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $dark-green;
  margin-bottom: 25px;
  font-size: 14px;

  svg {
    fill: $dark-green;
    margin-right: 18px;
  }
}

.emailVerificationHr {
  width: 194px;
  border-top: 1px solid $black;
  margin-top: 27px;
  margin-bottom: 30px;
}

.emailVerificationTitle {
  margin-bottom: 21px;
}
