@import 'Styling/import.scss';

.popoverListWrap {
  width: 150px;
  position: absolute;
  background-color: $white;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  border-radius: 4px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  @include breakpoint('tablet') {
    width: 195px;
  }
  @media only screen and (min-width: 1500px) {
    &.fixedLeftOne {
      margin-left: -153px;
    }
  }
  &.fixedLeftTwo {
    margin-left: -17px;
  }

  .popoverListItem {
    border-bottom: 1px $gray32 solid;
    padding: 10px;
    font-size: 14px;
    color: $blue1;
    cursor: pointer;
    text-align: left;
    &:hover {
      background-color: $white2;
    }
  }

  &.hidden {
    display: none;
  }

  div:last-child {
    border: none;
  }
}
