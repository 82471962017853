@import '/src/Styling/import.scss';

.container {
  position: relative;
  width: 100%;

  .buttonWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 48px;
    background-color: rgb(245, 247, 248, 0.2);
    color: $white;
    padding: 8px;
    height: 48px;
    border-radius: 8px;
    overflow: hidden;
    .label {
      margin-right: 8px;
      padding-left: 12px;
      @include ellipsisSimple();
    }
    .arrow {
      transform: rotate(0deg);
      transition: 0.2s all ease !important;
    }
    .rotate {
      transform: rotate(180deg);
      transition: 0.2s all ease !important;
    }
  }

  .dropdown {
    position: absolute;
    bottom: -51px;
    display: none;
    overflow: hidden;
    border-radius: 0 8px 8px;
    .input {
      width: 100%;
      height: 50px;
      margin: 0;
      padding: 18px;
      border: none;
      outline: none;
    }
  }

  &.open {
    .buttonWrap {
      background-color: $white;
      color: $grey2;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .dropdown {
      display: block;
      width: calc(200% + 22px);
      color: $grey2;
    }
  }

  &.open.left .dropdown {
    left: calc(-100% - 22px);
    border-radius: 8px 0 8px 8px;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
