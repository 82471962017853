@import 'Styling/import.scss';

.actionWrap {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  justify-content: flex-start;

  @include breakpoint(1500px) {
    justify-content: flex-end;
  }
  .confirmationAction {
    font-size: 14px;
  }

  .confirmedMessage {
    display: flex;
    font-size: 14px;
    align-items: center;
    color: $blue1;

    span {
      margin-left: 6px;
    }

    svg {
      color: $green3;
    }
  }

  .moreAction {
    color: $gray30;
    transition: .3s fill linear;
  }

  .startInterviewBtn {
    padding: 7px 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9px;
    background-color: $yellow3;
    border: solid 1px $gray31;
    color: $white;
    cursor: pointer;
    white-space: nowrap;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      color: $white;
    }

    span {
      margin-left: 5px;
      color: $white;
    }
  }

  .startInterviewBtnDisabled {
    background-color: $white2;
    color: $gray12;
    cursor: not-allowed;

    svg {
      color: $gray12;
    }
    span {
      color: unset;
    }
  }

  .button {
    padding: 7px 15px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9px;
    background-color: $white2;
    border: solid 1px $gray31;
    cursor: pointer;
    white-space: nowrap;
    color: $blue1;
    font-size: 14px;
  }

  .buttonDisabled {
    background-color: $white;
    color: $gray12;
    position: relative;

    svg {
      color: $green3;
      font-size: 14px;
      position: absolute;
      top: 13px;
      left: -7px;
    }
  }
}
