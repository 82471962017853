@import 'Styling/import.scss';

.removeIcon {
  color: $yellow321;
}

.star {
  cursor: pointer;
}
.yellowStar {
  color: $yellow321;
}

.bookmarkWrap {
  display: flex;
  align-items: center;
  color: $gray15;
  text-decoration: underline dotted $gray15;
  font-size: 14px;
  text-underline-position: under;
  cursor: pointer;
  margin-right: 10px;
 

  .text {
    display: none;
    margin-left: 8px;
    word-break: keep-all;
  }

  @include breakpoint('desktop') {
    .text {
      display: block;
    }
  }
}


.currentProjectsSearch,
.newProjectsName,
.newProjectsDropdown {
  margin-top: 30px;
}

.noProjects {
  margin-top: 30px;
  width: 100%;
  font-style: italic;
  color: $gray15;
  text-align: center;
}

.currentProjectsItemsWrap {
  height: 240px;
  overflow-y: auto;
  border-radius: 8px;
  background-color: $white;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 30px;

  &::-webkit-scrollbar {
    width: 14px;
  }

  &::-webkit-scrollbar-track {
    background-color: $black-opacity-3;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: $white-opacity-3;
  }

  .currentProjectsItem {
    height: 60px;
    border-bottom: 1px solid $gray15;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
@include breakpoint('desktop') {
  .modal {
      width: 980px;
    }
  }