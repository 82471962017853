@import '/src/Styling/import';

.header {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: $white;
  border-radius: 8px;

  @include breakpoint('toDesktop') {
    background-color: transparent;
  }

  .subHeading {
    display: flex;
    align-items: center;
    width: 100%;
    border-top: 1px solid $grey1;
    margin-top: 10px;
    padding-top: 30px;
    color: $black2;
    font-size: 24px;
    font-family: 'DMSerifDisplay', serif;
    text-transform: uppercase;

    @include breakpoint('toDesktop') {
      margin-top: 20px;
    }

    .unreadCount {
      display: inline-block;
      margin-left: 10px;
      font-family: Roboto, sans-serif;
      background-color: $red-error;
      border-radius: 50%;
      line-height: 20px;
      text-align: center;
      width: 20px;
      height: 20px;
      font-size: 11px;
      color: $white;

      @include breakpoint('toDesktop') {
        margin-left: 15px;
      }
    }

    @include breakpoint('desktop') {
      display: none;
    }
  }

  .searchToolbarWrap {
    grid-template-columns: 1fr;
    display: grid;
    flex-direction: row;
    width: 100%;
    padding-bottom: 9px;

    @include breakpoint('toDesktop') {
      padding-botttom: 22px;
    }

    .searchToolbar {
      display: grid;
      flex-direction: row;
      padding-bottom: 0;
      margin-top: 20px;
      grid-template-columns: 1fr;
      gap: 20px;
      width: 100%;

      @include breakpoint('toDesktop') {
        padding-bottom: 15px;
        border-bottom: 1px solid $gray39;
        margin-bottom: 30px;
      }

      .searchFieldWrap {
        position: relative;

        .searchIcon {
          position: absolute;
          right: 15px;
          top: 13px;
          color: $grey1;
        }

        .textField {
          input {
            background-color: $white2;
            height: 48px;
            font-size: 14px;
            border: solid 1px transparent;

            &:hover,
            &:focus {
              border: solid 1px $gray39;
            }
            &:active {
              border: solid 1px $blue2;
            }
          }
        }
      }

      .filterDropdown > div:first-child {
        min-width: 190px;
        height: 48px;
        border: solid 1px transparent;

        p {
          font-size: 14px;
          color: $grey2;
        }

        &:hover,
        &:focus {
          border: solid 1px $gray39;
        }
        &:active {
          border: solid 1px $blue2;
        }
      }

      .searchSubmit {
        background-color: $white2;
        width: 200px;
        margin: 0 auto;
        font-size: 14px;
        font-weight: normal;
        color: $blue2;

        &:hover {
          color: $white;
          background-color: $blue2;
        }

        &:active {
          color: $white;
          background-color: $blue2;
        }

        &:focus {
          color: $white;
          background-color: $blue2;
        }
      }

      @include breakpoint('desktop') {
        padding: 0 20px;
        grid-template-columns: 1fr auto auto;
      }
    }
  }

  .resetSearch {
    font-size: 14px;
    text-decoration: underline;
    color: $gray12;
    margin: 0 auto;
    cursor: pointer;
    user-select: none;
    margin-top: 12px;

    @include breakpoint('desktop') {
      margin-left: auto;
      margin-right: 20px;
      margin-top: 5px;
    }
    &:hover {
      color: $blue2;
      text-decoration: none;
    }
    &:active {
      color: $blue2;
      text-decoration: none;
    }
    &:focus {
      color: $blue2;
      text-decoration: none;
    }
  }
}
