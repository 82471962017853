@import 'Styling/import.scss';
@import 'Styling/import.scss';

.section {
  .content {
    @include breakpoint('desktop') {
      margin-left: 35px;
      margin-right: 35px;
    }
  }
}

.workExperiencesContent{
  @include breakpoint('desktop') {
    display: flex;
    flex-direction: column;
  }
}

.text {
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  color: $black;
  @include breakpoint('desktop') {
    padding-right: 33px;
  }
}

.textField {
  margin-bottom: 30px;
}

.modal {
  @include breakpoint('desktop') {
    min-width: 80%;
  }
}

.employmentDateUp {
  margin-bottom: 30px;
  @include breakpoint('desktop') {
    display: flex;
    margin-bottom: 0px;
  }
  & > div {
    margin-bottom: 20px;
    width: 100%;
    @include breakpoint('desktop') {
      &:first-child {
        margin-right: 30px;
      }
    }
  }
}
.employmentDateDown {
  padding-top: 30px;
  border-top: $white 1px solid;
  @include breakpoint('desktop') {
    display: flex;
    padding-top: 0px;
    border-top: none;
  }
  & > div {
    margin-bottom: 20px;
    width: 100%;
    @include breakpoint('desktop') {
      &:first-child {
        margin-right: 30px;
      }
    }
  }
}

.employerCurrentLabel {
  display: flex;
  font-size: 14px;
  color: $brown;
  line-height: 22px;
  margin: 30px 0 20px;

  @include breakpoint('desktop') {
    margin: 0;
  }
}
