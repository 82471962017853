@import 'Styling/import.scss';

.pageWrap {
  height: 100%;

  @include breakpoint('desktop') {
    margin: 0 50px;
  }

  .mainTitle {
    font-family: 'DMSerifDisplay', serif;
    font-size: 36px;
    color: $yellow;
    margin: 0 20px;
    width: 70%;
    margin-bottom: 20px;

    @include breakpoint('desktop') {
      margin: 0;
      margin-bottom: 35px;
    }
  }
  .breadcrumbs {
    margin-left: 15px;
  }
  @include breakpoint('desktop') {
    .breadcrumbs {
      margin-left: 0;
    }
  }

  .title {
    margin-left: 20px;
    margin-right: 20px;

    @include breakpoint('desktop') {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .cardItemWrap {
    background-color: $white;
    border-radius: 8px;
    margin-top: 20px;
    margin-bottom: 30px;
    padding: 20px;

    @include breakpoint('desktop') {
      padding: 30px;
    }
    .description {
      font-size: 14px;
      line-height: 20px;
    }
    .dropdown {
      z-index: 0;
    }
  }

  .ctasWrap {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    gap: 20px;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;

    @include breakpoint('desktop') {
      justify-content: center;
      flex-direction: row;
      grid-template-rows: none;
      padding-left: 0;
      padding-right: 0;
    }

    .saveBtn {
      width: 100%;
      height: 76px;

      @include breakpoint('desktop') {
        height: 65px;
        width: 400px;
      }
    }

    .cancelBtn {
      color: $grey3;
      font-size: 14px;
      border-bottom: 1px solid $grey3;
      margin: 0 auto;

      @include breakpoint('desktop') {
        position: absolute;
        left: 0;
        top: 28px;
        &:hover,
        &:focus,
        &:active {
          color: $blue2;
          border-bottom: none;
        }
      }
    }
  }
}

.linksContainer {
  padding: 45px 24px 0;
}
