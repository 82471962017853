@import '/src/Styling/import';

.container {
  .title {
    padding: 0;
  }

  h2.title {
    margin: 32px 0 27px;
  }

  div.title {
    padding: 0 0 27px;
  }

  h2.title,
  div.title h2 {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
  }

  .uycvTitleContainer {
    display: flex;
    flex-direction: column;
    .title {
      margin-top: 20px;
    }
  }

  .subtitle {
    display: flex;
  }

  .link {
    padding-top: 6px;
    margin-bottom: 27px;
    a {
      color: $grey3 !important;
      text-decoration: underline;
      font-size: 14px;
    }
  }

  .buttonWrap {
    display: flex;
    margin-top: 50px;
    margin-bottom: 130px;
    align-items: center;
    justify-content: center;
  }

  .nextButton {
    width: 100%;
    max-width: 266px;
  }

  .educationContainer {
    display: flex;
    flex-direction: column;

    .educationItem {
      width: 100%;
      &:first-child {
        margin-bottom: 30px;
      }
    }
    .educationItem2 {
      margin-top: 20px;
      margin-left: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      > button {
        font-size: 14px;
      }
    }
  }

  .educationContainer ~ .educationContainer {
    margin-top: 40px;
  }

  .yearsOfExpContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    .yearsOfExpItem {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;

      width: 100%;
      .yearsOfExpSubItem1 {
        width: 100%;
        margin-bottom: 30px;
      }
      .yearsOfExpSubItem2 {
        width: 100%;
      }
      .yearsOfExpSubItem3 {
        margin-top: 20px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        > button {
          font-size: 14px;
        }
      }
    }
  }

  .hr {
    margin: 50px 0 38px;
  }

  .hr2 {
    margin: 30px 0 38px;
  }

  .categories {
    grid-template-columns: 1fr;
  }
}

@include breakpoint('desktop') {
  .container {
    h2.title,
    div.title h2 {
      font-size: 24px;
      line-height: 30px;
    }

    .uycvTitleContainer {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .link {
        padding-top: 0;
        margin-bottom: 0;
      }
    }

    .buttonWrap {
      margin-bottom: 100px;
    }

    .nextButton {
      width: 386px;
      max-width: 386px;
    }

    .hr {
      margin: 50px 0 37px;
    }

    .hr2 {
      margin: 25px 0 37px;
    }

    .educationContainer {
      flex-direction: row;

      .educationItem {
        &:first-child {
          margin-bottom: 0;
          margin-right: 15px;
        }
        &:last-child {
          margin-bottom: 0;
          margin-left: 15px;
        }
      }
      .educationItem2 {
        margin-top: 0;
      }
    }
    .yearsOfExpContainer {
      .yearsOfExpItem {
        flex-direction: row;
        .yearsOfExpSubItem1 {
          margin-right: 14px;
          margin-bottom: 0;
        }
        .yearsOfExpSubItem2 {
          margin-left: 14px;
        }
        .yearsOfExpSubItem3 {
          margin-top: 0;
        }
      }
    }
  }
}

@include breakpoint('1200px') {
  .container .yearsOfExpContainer {
    flex-direction: row;
    .yearsOfExpItem {
      flex-direction: row;
      &:first-child {
        margin-right: 14px;
        width: 40%;
      }
      &:last-child {
        width: 60%;
        margin-left: 14px;
      }
    }
  }
}
