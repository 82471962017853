@import 'Styling/import.scss';

.offerItemContainer {
  padding-bottom: 16px;
  border-bottom: 1px solid $gray-cyan;

  .titleContainer {
    display: flex;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 17px;

    svg {
      color: $gray36;
      margin-right: 19px;
      width: 20;
      height: 20;
    }

    h2 {
      font-size: 14px;
      color: $gray36;
    }
  }

  .buttonsWrapper {
    display: grid;
    font-size: 14px;
    grid-template-columns: 1fr 1fr;

    .confirmPlacementButton {
      color: $yellow321;
      background-color: $gray-cyan;
      height: 43px;
      width: 225px;
      font-size: 14px;
    }

    .rejectPlacementButton {
      color: $gray36;
      background-color: transparent;
      text-decoration: underline;
      font-size: 14px;
      height: auto;
    }
  }
}
