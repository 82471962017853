@import 'Styling/import.scss';

.mainContainer {
  padding: 0 10px 100px 10px;

  @include breakpoint('desktop') {
    padding: 25px 50px 100px 50px;
  }

  .breadcrumbsWrapper {
    margin-bottom: 25px;

    @include breakpoint('toDesktop') {
      display: none;
    }
  }

  .downloadAndPrintHeader {
    padding-bottom: 10px;
  }

  .getBackToAssessmentsWrapper {
    display: inline-flex;
    margin-top: 43px;
    &:visited, &:focus {
      color: $grey3;
    }

    @include breakpoint('toDesktop') {
      margin-top: 38px;
    }

    svg {
      margin-right: 18px;
      color: $black;
    }

    span {
      display: inline-flex;
      align-items: center;
    }

    > span > span {
      color: $grey3;
      font-size: 14px;
      text-decoration: underline;
    }
  }

  .psychometricTestResultsContainer {
    .testResultsContent {
      background-color: $white;
      border-radius: 8px;
      padding: 29px;
      position: relative;

      @include breakpoint('toDesktop') {
        margin-top: -10px;
        padding: 18px;
      }

      h1,
      h2 {
        color: $yellow;
        font-weight: 400;
        font-family: 'DMSerifDisplay', sans-serif;
      }

      h1 {
        font-size: 36px;
        padding-bottom: 21px;
        border-bottom: 1px solid $gray23;
        margin: 0 0 31px 0;

        @include breakpoint('toDesktop') {
          padding-bottom: 15px;
          margin: 0 0 29px 0;
        }
      }

      h2 {
        font-size: 24px;
        text-transform: uppercase;
      }
    }

    .resultsSearchContainer {
      display: flex;
      padding-bottom: 31px;
      margin-bottom: 31px;
      border-bottom: 1px solid $gray23;

      @include breakpoint('toDesktop') {
        flex-direction: column;
      }
    }

    .progressBarWrapper {
      width: 50%;
      margin-right: 40px;

      @include breakpoint('toDesktop') {
        width: 100%;
        margin-right: 0;
        margin-bottom: 31px;
      }
    }

    .dropdownWrapper {
      width: 50%;

      @include breakpoint('toDesktop') {
        width: 100%;
      }
    }

    .grayProfileBox {
      border-radius: 8px;
      background-color: $anchor-gray;
      padding: 17px 20px 23px 20px;

      width: 389px;

      p {
        font-size: 24px;
        font-weight: bold;
        color: $white;
        text-transform: uppercase;
      }

      @include breakpoint('toDesktop') {
        width: 100%;
        padding: 17px 20px 23px 20px;
      }
    }
  }
}

.psychometricTestResultsFooter {
  padding-top: 30px;
  padding-bottom: 20px;
}
