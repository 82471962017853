@import 'Styling/import.scss';

.breadcrumbsContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .icon {
    margin-right: 9px;
    color: $gray11;
    font-size: 25px;
    margin-bottom: 2px;
  }

  .icon.white {
    color: $white;
    font-size: 25px;
  }

  .breadcrumbsNav {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    .navigationItem {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $black;
    }

    li {
      font-size: 12px;
      margin-left: 5px;
      color: $gray12;
      cursor: pointer;
      padding-top: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      
      &:first-child {
        margin-left: 0;
        &:hover,
        &:focus,
        &:active {
          text-decoration: underline;
        }
      }
      &:nth-child(2):hover,
      &:nth-child(2):focus,
      &:nth-child(2):active {
        text-decoration: underline;
      }
    }

    .noPointerElement {
      cursor: default;
    }

    .blackStyleNavigationItem {
      color: $black;
    }
  }

  .underlinedElements {
    text-decoration: underline;
    &:hover,
    &:focus,
    &:active {
      text-decoration: none !important;
    }
  }
}
.breadcrumbsNav.white {
  li {
    color: $white;
  }
  .blackStyleNavigationItem {
    color: $white;
  }

  p {
    color: $white;
  }
}
