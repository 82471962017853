@import 'Styling/import.scss';

.contentWrap {
  position: relative;
  background-color: $gray17;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .content {
    position: relative;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;

    &.contentLogoWrapFixedRight {
      padding-top: 10px;
    }
  }

  .mainContentFooterWrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    &.mainContentFooterWrapperFixedRight {
      min-height: calc(100vh - 60px);
    }

    .footerWrapper {
      padding: 12px 0;

      @include breakpoint('desktop') {
        border-top: 1px solid $grey1;
      }
    }

    .content {
      flex: 1 1 0;
    }
  }

  .mainContentFooterWrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .footerWrapper {
      padding: 12px 0;

      @include breakpoint('desktop') {
        border-top: 1px solid $grey1;
      }
    }

    .content {
      flex: 1 1 0;
    }
  }

  &Background {
    background-color: rgba(255, 255, 255, 0);
    backdrop-filter: blur(10px);
    min-height: 100vh;
    height: 100%;
  }

  .contentLogoWrap {
    position: fixed;
    top: 30px;
    left: 50%;
    margin-left: -75px;
    z-index: 5;

    .logo {
      width: 150px;
    }
  }

  .withoutDesktopLogo {
    @include breakpoint('desktop') {
      display: none;
    }
  }
  .centralContent {
    display: flex;
    flex-direction: column;
    padding-top: 84px;
    flex: 1;
    &Background {
      background-color: rgba(255, 255, 255, 0.275);
      min-height: 100vh;
      height: 100%;
    }

    &.contentLogoWrapFixedRight {
      padding-top: 60px;
    }
  }

  @include breakpoint('desktop') {
    padding: 0 100px 0 100px;
    .contentLogoWrap {
      position: absolute;
      z-index: unset;
      left: 150px;
      margin-left: 0;

      &.contentLogoWrapFixedRight {
        left: 100px;
      }
      &.myProjectsLogo {
        left: 120px;
      }
    }
    &.logoFixedRight {
      .contentLogoWrap,
      .contentLogoWrap.contentLogoWrapFixedRight {
        left: 150px;
      }
    }
  }

  @include breakpoint('1200px') {
    padding-right: 360px;
    &.rightSidebarClosed {
      padding-right: 100px;
    }
  }
  &.withoutLogo {
    .centralContent {
      padding-top: 0;
    }
  }
}

.white {
  background-color: $white;
}

.background {
  width: 100vw;
  height: 100%;
  top: 0;
  right: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: calc(50% - 280px) top;
  background-image: url('/Assets/Images/login-layout-background.jpg');
  @include breakpoint('desktop') {
    background-position: calc(50% - 0px) top;
    // background-size: 110%;
  }
}
.employer {
  width: 100vw;
  height: 100%;
  top: 0;
  right: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: calc(50% - 280px) top;
  background-image: url('/Assets/Images/employerBGblurred.png');
  @include breakpoint('desktop') {
    background-position: calc(50% - 0px) top;
  }
}

.contentWrap.blue .content.contentLogoWrapFixedRight {
  padding-top: 0;
}
