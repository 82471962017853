@import 'Styling/import.scss';
.mainContainer {
  background-color: $blue2-opacity-6;
  width: 100%;
  backdrop-filter: blur(29px);

  .wrap {
    display: flex;
    flex-direction: column;
    gap: 47px;
    width: 100%;
    max-width: 980px;
    margin: 0 auto;
    padding-top: 39px;
    padding-bottom: 50px;

    @include breakpoint('900px') {
      padding-bottom: 75px;
      flex-direction: row;
    }

    @include breakpoint('1160px') {
      padding: 125px 0;
    }
  }

  .item {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    text-align: center;

    @include breakpoint('1160px') {
      max-width: 980px;
    }

    .title {
      font-size: 41px;
      color: $white;
      font-family: 'DMSerifDisplay', serif;
      font-weight: 400;
      text-shadow: 3px 5px 30px $gray37;
      margin-bottom: 11px;
      text-transform: uppercase;

      @include breakpoint('900px') {
        margin-bottom: 15px;
        font-size: 41px;
      }
      @include breakpoint('1160px') {
        font-size: 40px;
        margin: 0 auto;
        margin-bottom: 61px;
      }
    }
    .textContainer {
      color: $white;
      font-weight: 900;
      font-size: 16px;
      padding: 0 28px;

      @include breakpoint('1160px') {
        font-size: 24px;
        padding: 0;
        margin: 0 auto;
        max-width: 369px;
        padding-bottom: 38px;
      }
    }
    .textContainerSecond{
      color: $white;
      font-weight: 900;
      font-size: 16px;
      padding: 0 28px;
      @include breakpoint('1160px') {
        font-size: 24px;
        padding: 0;
        margin: 0 auto;
        max-width: 369px;
        padding-bottom: 38px;
        margin-bottom: 80px;
      }
    }
    @include breakpoint('desktop') {
      .button {
        margin-right: 29px;
        height: 60px;
      }
    }
    .buttonContainer {
      display: flex;
      justify-content: center;
      margin-top: 27px;
    }

    .yellowButton {
      width: 160px;
      height: 42px;
      padding-left: 10px;
      padding-right: 10px;
      text-transform: uppercase;
    }

    @include breakpoint('1160px') {
      .yellowButton {
        width: 250px;
        height: 60px;
      }
    }
  }
}
