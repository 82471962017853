@import 'Styling/import.scss';

.messages {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  .title {
    font-size: 14px;
    font-style: italic;
    color: $gray36;
  }

  .messagesContainer {
    display: flex;
    background-color: $gray29-opacity-3;
    border-top: 1px solid $gray35;
    border-bottom: 1px solid $gray35;
    padding: 22px 19px;
    max-height: 162px;
    overflow-y: auto;

    div {
      &:first-child {
        width: 100%;
      }
    }

    &::-webkit-scrollbar {
      width: 14px;
    }

    &::-webkit-scrollbar-track {
      background-color: $black-opacity-3;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: $white-opacity-3;
    }
  }

  .contentItemContainer {
    border-top: 1px solid $gray35;
    padding-top: 22px;
    padding-bottom: 22px;
    width: 100%;

    &:first-of-type {
      border-top: none;
      padding-top: 0;
    }
  }

  .titleContainer {
    display: flex;
    background-color: rgba(73, 78, 79, 0.3);
    border-top: 1px solid $gray30;
    border-bottom: 1px solid $gray30;
    padding: 22px 19px;
    max-height: 162px;
    font-size: 14px;
    font-style: italic;
    color: $gray36;
  }
}
