@import 'Styling/import.scss';

.modal {
  @include breakpoint('desktop') {
    max-width: 570px;
    :global {
      .content {
        padding: 29px 54px;
      }
    }
  }
}
.modalDetails {
  @include breakpoint('desktop') {
    max-width: 720px;
    :global {
      .content {
        padding: 25px 35px 20px;
      }
    }
  }
}

.containerInterview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .text {
    font-size: 18px;
    text-align: center;
    line-height: 30px;
    color: $grey2;
    font-family: 'DMSerifDisplay', serif;
    margin-bottom: 26px;
  }

  .paragraph {
    display: flex;
    font-size: 14px;
    color: $grey2;
    text-align: center;
    margin-bottom: 26px;
    line-height: 24px;
  }

  .timeText {
    padding-left: 20px;
    font-size: 14.5px;
    color: $white;
  }

  .icon {
    color: $white;
    margin-top: 15px;
    margin-bottom: 20px;
  }
  .buttons {
    display: flex;
  }

  .line {
    padding: 0px 15px;
    color: $darkblue;
  }
  .button {
    color: $darkblue;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    &:hover, &:focus, &:active{
     text-decoration: underline;
    }
  }
}
