@import 'Styling/import.scss';
.facetCandidateCardContainer {
  display: flex;
  z-index: 0;
  .facetContainer {
    display: flex;
    flex-direction: column;
  }
  .cardsWrap {
    flex: 1;
  }
}
