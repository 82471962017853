@import '/src/Styling/import';

.modal {
  @include breakpoint('desktop') {
    min-width: 980px;
  }
  .description {
    font-size: 14px;
    color: $grey3;
    margin: 19px 0 26px;
    @include breakpoint('desktop') {
      margin: 4px 0 15px;
    }
  }
  .warning {
    display: inline-flex;
    align-items: center;
    padding-left: 5px;
    margin-top: -5px;
    @include breakpoint('desktop') {
      margin: -5px 0 10px;
    }
    span {
      margin-left: 18px;
      font-size: 14px;
      color: $grey2;
    }
  }
  .checkbox {
    margin: 20px 0 0;

    @include breakpoint('desktop') {
      margin: 12px 0 10px;
    }

    &.disabledCheckbox {
      svg {
        color: rgba(0, 0, 0, 0.3);
      }
      label {
        position: relative;
      }
      &:before {
        content: '';
        background-color: $gray14;
        width: 16px;
        height: 16px;
        position: absolute;
        left: 4px;
        top: 4px;
      }
    }
  }

}
