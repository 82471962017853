@import 'Styling/import.scss';

.wrap {
  background-color: $gray14;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  position: relative;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .card {
    display: flex;
    flex-direction: column;
    max-width: 650px;
    overflow: hidden;
    background-color: $white;

    @include breakpoint('desktop') {
      border-radius: 8px;
    }

    .header {
      background-color: $blue2;
      display: flex;
      align-items: center;
      justify-content: center;

      .logo {
        max-width: 223px;
        max-height: 23px;
        margin: 20px;
      }
    }

    .content {
      margin: 20px 25px;

      @include breakpoint('desktop') {
        margin: 30px 50px;
      }

      .title {
        font-family: 'DMSerifDisplay', serif;
        color: $blue2;
        font-weight: bold;
        font-size: 40px;
        text-align: center;
        padding-bottom: 20px;
        margin-bottom: 30px;
        border-bottom: solid 1px $grey1-opacity-5;
      }

      .description {
        font-size: 16px;
        padding-bottom: 20px;
        border-bottom: solid 1px $grey1-opacity-5;
        margin-bottom: 30px;
      }

      .subtitle {
        margin-bottom: 30px;
      }

      .submitBtn {
        max-width: 380px;
        margin: 0 auto;
        margin-bottom: 30px;
      }

      .checkboxContent {
        font-size: 16px;
      }

      .warning {
        color: $red-error;
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 20px;
      }

      .helpText {
        margin-bottom: 50px;
        font-size: 14px;
      }

      .footer {
        font-size: 14px;
      }
    }
  }
}
