@import 'Styling/import.scss';

.pageWrap {
  @include breakpoint('desktop') {
    margin: 0 50px;
  }
  .contentHeader {
    display: none;
  }

  @include breakpoint(1500px) {
    .contentHeader {
      display: grid;
      gap: 30px;
      grid-template-columns: 5fr 60px 85px 120px 260px;
      font-size: 12px;
      font-weight: bold;
      color: $gray12;
      border-bottom: 1px solid $gray23;
      border-top: 1px solid $gray23;
      padding: 20px 0;
    }
  }

  .sortBtn {
    display: flex;
    align-items: center;
    min-height: 24px;
    user-select: none;
    svg {
      display: none;
    }
  }

  .leftMargin {
    display: flex;
    align-items: center;
    margin-left: 20px;
  }

  .active {
    svg {
      display: block;
    }
  }

  .desc {
    svg {
      transform: rotate(180deg);
    }
  }
}
.loaderContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .loadingText {
    font-size: 14px;
    line-height: 2.86px;
    color: $black;
    margin-top: 26px;
  }
}
.noResults {
  margin: 15px 93px 0 21px;
  opacity: 0.5;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  color: $black;
  text-align: left;
}
