@import 'Styling/import.scss';

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .consentsContainer {
    padding: 25px 20px 100px 20px;

    @include breakpoint(desktop) {
      padding: 12px 50px 49px 50px;
    }
  }

  .title {
    font-weight: normal;
  }
}
