@import 'Styling/import.scss';

.mainHeaderContainer {
  .titleWrapper {
    display: flex;
    align-items: center;

    .title {
      font-family: 'DMSerifDisplay', serif;
      font-size: 36px;
      color: $orange;
      line-height: 1.11;
      letter-spacing: 1.8px;
    }
  }

  .breadcrumbsWrapper {
    margin-top: 28px;
    margin-bottom: 19px;

    @include breakpoint(desktop) {
      margin-top: 35px;
      margin-bottom: 18px;
    }
  }

  .thickHrContainer {
    margin-bottom: 18px;

    .thickHr {
      background-color: $grey1;
      height: 1px;
      border-top: none;
      opacity: 1;
      margin: 0;
    }
  }
}

.breadcrumbsWrapper.white {
  margin-top: 29px;
  margin-bottom: 20px;

  @include breakpoint(desktop) {
    margin-top: 18px;
    margin-bottom: 19px;
  }
}
