@import 'Styling/import.scss';

.reviewCandidate {
  padding-bottom: 100px;
  @include breakpoint(desktop) {
    padding: 0 50px 100px 50px;
  }

  .mainTitle {
    font-size: 36px;
    color: $yellow;
    letter-spacing: 1.8px;
    margin-bottom: 56px;
  }

  .hr {
    border-top: 1px solid $grey1;
    margin-top: 24px;
  }

  .titleContainer {
    padding: 0 23px;

    @include breakpoint(desktop) {
      padding: 0;
    }
  }

  .reviewCardContainer {
    display: flex;
    margin-top: 16px;
    background-color: $white;
    padding: 30px 23px 0 23px;

    @include breakpoint(desktop) {
      border-radius: 8px;
      padding: 30px 29px 0 47px;
    }

    .iconContainer {
      margin-right: 50px;
    }
  }

  .avatarHolder {
    .profileAvatar {
      width: 50px;
      height: 50px;

      @include breakpoint(desktop) {
        width: 140px;
        height: 140px;
        margin-right: 50px;
      }

      > span {
        line-height: 50px;
        font-size: 20px;

        @include breakpoint(desktop) {
          line-height: 140px;
          font-size: 50px;
        }
      }
    }
  }

  .yellowButtonRow {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 37px;

    @include breakpoint(desktop) {
      margin-top: 40px;
    }

    .yellowButtonWrapper {
      width: fit-content;
    }
  }

  .formContainer {
    width: 100%;

    .textField {
      input {
        background-color: $white2;
        height: 363px;

        @include breakpoint(desktop) {
          height: 144px;
        }
      }
    }

    .hrWrapper {
      margin-top: 18px;
      margin-bottom: 30px;
    }

    .formFlexItem {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid $gray23;
      align-items: center;
    }

    .formRow {
      width: 100%;
      padding: 20px 0;
      align-items: center;

      @include breakpoint(desktop) {
        display: grid;
        grid-template-columns: 134px 1fr;
        height: 49px;
        padding: 0;
      }

      .title {
        font-size: 14px;
        color: $grey1;
        align-self: center;
      }

      .value {
        font-size: 16px;
        color: $grey2;
        margin-top: 9px;

        @include breakpoint(desktop) {
          margin-top: 0;
        }
      }
      .grayText {
        font-size: 14px;
        color: $grey1;
      }
    }
  }
}
