@import 'Styling/import.scss';

.section {
  .itemHeader {
    margin-bottom: 20px;
  }
  .hr {
    margin: 20px 0;
  }
  .galleryWrap {
    margin: 0;
  }
  :global(.react-blueimp-thumbnails) {
    display: flex;
    flex-wrap: wrap;
    > div {
      width: 100%;
      margin-bottom: 20px;

      img {
        width: 100%;
      }
    }

    @include breakpoint('tablet') {
      > div {
        width: calc((100% - 25px) / 2);
        margin: 0 12.5px 25px;
        &:nth-child(2n + 1) {
          margin-left: 0;
        }
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
    @include breakpoint('desktop') {
      > div {
        width: calc((100% - 75px) / 4);
        &:nth-child(4n) {
          margin-right: 0;
        }
        &:nth-child(4n + 1) {
          margin-left: 0;
        }
        &:nth-child(4n + 2),
        &:nth-child(4n + 3) {
          margin: 0 12.5px 25px;
        }
      }
    }
  }

  @include breakpoint('desktop') {
    .itemHeader {
      margin-bottom: 30px;
    }
    .hr {
      margin: 30px 0 20px;
    }
  }
}

.modal {
  .secondTitle {
    margin-bottom: 22px;
  }
  .apiErrorField {
    text-align: center;
  }
  @include breakpoint('desktop') {
    min-width: 980px;
    .firstTitle.firstTitle:first-child {
      margin-bottom: 0;
    }
    .textField > div:last-child {
      position: absolute;
    }
  }
}

.dndContainer {
  display: flex;
  align-items: center;

  > div {
    width: 100%;
  }

  .item {
    display: flex;
    flex-direction: column;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    .firstRow {
      display: flex;
      align-items: center;
      margin-bottom: 18px;
    }

    .imageItemContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;

      .image {
        width: 107px;
      }
    }
    .mobileOnly {
      @include breakpoint('desktop') {
        display: none;
      }
    }
    .desktopOnly {
      display: none;
      @include breakpoint('desktop') {
        display: block;
        flex: 1;
        margin: 0 30px;
      }
    }
  }

  .handle {
    margin-right: 10px;
  }
}

.header {
  h2 {
    font-weight: 400;
  }
}

.sectionItem {
  margin-bottom: 0;
}

.itemHeader {
  @include breakpoint('toDesktop') {
    margin-bottom: 20px;
  }

  div {
    &:first-of-type {
      margin-right: 11px;

      @include breakpoint('desktop') {
        margin-right: 20px;
      }
    }
  }
}

.showMoreContent.showMoreContentStronger {
  hr {
    margin: 0 0 20px 0;
  }
  @include breakpoint('desktop') {
    margin: 0 50px 0 48px;
  }
}
