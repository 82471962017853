@import 'Styling/import.scss';

.sidebarLink {
  display: block;
  background-color: $cyan-dark;
  border-radius: 8px;
  line-height: 50px;
  padding: 0 17px 0 23px;
  font-size: 16px;
  font-weight: bold;
  color: $gray36;
  width: 100%;
  text-align: left;
  &:link,
  &:visited {
    color: $gray36;
  }
  &:hover,
  &:focus,
  &:active {
    color: $yellow3;
    background-color: #585d5e;
    .linkText {
      color: #aaa;
    }
  }
  &.active {
    background-color: $yellow3;
    color: $white;
    pointer-events: none;
    .linkText {
      color: $white;
    }
  }
  &.active {
    .arrowIcon {
      display: none;
    }
  }
  .arrowIcon {
    color: #f8a114;
    float: right;
    display: block;
    margin: 13px 0;
  }

  .contentWrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .iconTextWrapper {
      display: flex;
      align-items: center;
      padding: 15px 0;

      svg {
        margin-right: 20px;
      }

      p {
        line-height: 20px;
      }
    }
  }
}
.withMarginBottom {
  margin-bottom: 10px;
}
