@import '/src/Styling/import.scss';

.background {
  background-color: rgba(211, 185, 185, 0);
  width: 100vw;
  height: 100vh;
  z-index: 1;
  top: 0;
  left: 0;
  position: fixed;
  display: block;
}

.dropdownContainer {
  position: relative;
  cursor: pointer;
}

.dropdown {
  font-size: 16px;
  color: $grey2;
  pointer-events: none;
  cursor: pointer;

  &Label {
    font-size: 14px;
    color: $grey1;
    padding-bottom: 12px;
  }

  &BTN {
    background-color: $white2;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 20px;
    border-radius: 8px;
    position: relative;
    z-index: 0;
  }
}

.dropdownList {
  padding: 14px 0;
  border: $gray9 2px solid;
  border-radius: 12px;
  background-color: $white;
  box-shadow: 0 0 15px 2px $gray9;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
  z-index: 1;
}

.hidden {
  display: none;
}

.arrow {
  transform: rotate(0deg);
  transition: 0.2s all ease !important;
}

.rotate {
  transform: rotate(180deg);
  transition: 0.2s all ease !important;
}

.label:hover {
  color: black;
  background-color: $white2;
}

.label {
  color: $grey2;
  font-size: 14px;
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 38px;
  cursor: pointer;
  padding: 0 18px;
}

.radio {
  display: none;
}

.translation {
  margin: 0 18px;
}

.errorBorder {
  border: $red-error2 solid 1px;
  border-radius: 3px;
  position: relative;
}

.white, .modal {
  .dropdownBTN {
    background-color: $white;
    border: 1px solid $input-border;
    &:hover,
    &:focus {
      border: 1px solid  $input-focus-border;
    }
    &:active {
      border: 1px solid $input-active-border;
    }
  }
}

.modal {
  @include breakpoint('desktop') {
    margin: $input-modal-top-margin 0 $input-modal-bottom-margin;
    overflow-y: visible;
  }
}
