@import 'Styling/import.scss';

.container {
  min-height: 100px;
  width: 100%;
  background-color: $red-error;
  display: flex;
  align-items: flex-start;
  padding: 22px 30px 22px 35px;
  transition: .5s all linear;
  overflow: hidden;

  &.closed {
    min-height: 0;
    height: 0;
    padding: 0 20px;

    .warningWrap, .textWrap, .closeWrap {
      opacity: 0;
    }
  }

  .warningWrap, .textWrap, .closeWrap {
    opacity: 1;
    transition: .5s opacity linear;
  }

  .warningWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 38px;
    overflow: hidden;
    margin-right: 24px;

    .icon {
      font-size: 48px;
      color: $white;
    }
  }

  .textWrap {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    line-height: 18px;
    color: $white;
    flex: 1;

    p {
      span{
        font-weight: bold;
        font-size: 14px;
      }
    }
  }

  .closeWrap {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 0 0 20px;

    button {
      width: 26px;
      height: 26px;
      background-color: $gray229;
    }

    .icon {
      position: absolute;
      left: -3px;
      top: -3px;
      color: $white;
      font-size: 36px;
    }
  }


  @include breakpoint('desktop') {
    padding: 30px 50px;
    align-items: center;

    &.closed {
      padding: 0 50px;
    }

    .warningWrap {
      margin-right: 20px;
    }

    .textWrap p {
      font-size: 14px;
      span{
        font-size: 16px;
      }
    }

    .closeWrap {
      margin: 0 0 0 20px;
    }
  }
}
