@import '/src/Styling/import';

.container {
  .title {
    margin: 32px 0 27px;
    font-size: 18px;
    font-weight: 700;
  }
  .uycvTitleContainer {
    display: flex;
    flex-direction: column;
    .title {
      margin-top: 20px;
    }
  }

  .subtitle {
    display: flex;
  }

  .link {
    padding-top: 6px;
    margin-bottom: 27px;
    a {
      color: $grey3 !important;
      text-decoration: underline;
      font-size: 14px;
    }
  }

  .buttonWrap {
    display: flex;
    margin-top: 50px;
    margin-bottom: 130px;
    align-items: center;
    justify-content: center;
  }
  .nextButton {
    width: 100%;
    max-width: 266px;
  }
  .disclaimerText {
    font-size: 14px;
    color: $grey3;
  }
  .error {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: $red-error;
  }
}

@include breakpoint('desktop') {
  .container {
    .title {
      font-size: 24px;
    }
    .uycvTitleContainer {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .link {
        padding-top: 0;
        margin-bottom: 0;
      }
    }
    .buttonWrap {
      margin-bottom: 100px;
    }
    .nextButton {
      width: 386px;
      max-width: 386px;
    }
  }
}

.modalErrorText {
  color: $red-error;
  font-size: 18px;
  font-family: 'DMSerifDisplay', serif;
  text-align: center;
  line-height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
