@import 'Styling/import.scss';

.mainAssessmentsContainer {
  background-color: $gray14;
  padding: 20px 20px 100px 20px;
  min-height: 100vh;

  @include breakpoint(desktop) {
    padding: 10px 50px 49px 50px;
  }

  .success {
    margin-top: 20px;
    text-align: center;
  }

  .link {
    text-align: center;
    cursor: pointer;
    padding-bottom: 20px;
    margin-top: 15px;
    text-decoration: underline;
  }

  .assessmentsSuccessTitle {
    font-weight: normal;
  }
}
